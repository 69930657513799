export type Document = {
	created_at: string;
	file_id: number;
	file_name: string;
	file_url: string;
	file_type: documentFileType;
	status: DocumentStatus;
	sender_name: string;
	sender_is_igc_user: boolean;
};

export const DocumentTypeOptions = [
	{
		id: 1,
		slug: 'other',
		name: 'Outros'
	},
	{
		id: 2,
		slug: 'infopack',
		name: 'Info Pack'
	},
	{
		id: 3,
		slug: 'welcomeletter',
		name: 'Welcome Letter'
	},
	{
		id: 4,
		slug: 'teaser',
		name: 'Teaser'
	}
];

export type documentFileType = 'infopack' | 'welcomeletter' | 'teaser' | 'other';

export type DocumentStatus = 'approved' | 'pending' | 'denied';
