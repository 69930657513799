import axios from "axios";
import { toastNotify } from "src/helpers/toastNotify";
import { clearLocalStorage } from "src/helpers/storage";

const client = axios.create({ baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:8000" });

client.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		if (error.response.status === 401) {
			if (error.response.data.detail === "Invalid token.") {
				clearLocalStorage();
				window.location.replace('/business/login');
				return;
			}
			toastNotify("Email ou senha inválidos.", "error");
		}
		if (error.response.status === 500) {
			toastNotify(error.response.data.user_message, "error");
		}

		if (error.response.status === 400) {
			toastNotify(error.response.data.user_message || error.response.data.message, "error");
		}
		return Promise.reject(error);
	}
);

client.interceptors.request.use(async (config: any) => {
	const token = localStorage.getItem("@App:token");
	if (token) {
		config.headers = {
			Authorization: `Token ${token.replace(/["]/g, "")}`,
			Accept: "application/json"
			// 'Content-Type': 'application/x-www-form-urlencoded'
		};
	}
	return config;
});

export default client;
