import { UploadIcon } from '@heroicons/react/outline';
import { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
export interface UploadProps {
	businessId: number;
	acceptFileType?: any;
	multiple?: boolean;
	setFile: (files) => void;
}

const UploadToS3: FC<UploadProps> = ({
	businessId,
	acceptFileType = undefined,
	multiple,
	setFile
}) => {
	const onDrop = (files) => {
		setFile(files[0]);
	};

	const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
		accept: acceptFileType,
		onDrop,
		multiple
	});

	const renderDragMessage = useCallback(() => {
		if (!isDragActive) {
			return (
				<div
					className={`flex flex-col h-52 justify-around items-center py-4 border-brand-secondaryMedium bg-[#F4F5F6]`}
				>
					<UploadIcon className="h-[30px] w-[30px] text-neutral-low " />
					<p className="text-center font-normal text-base text-neutral-low text-opacity-40">
						Arraste o arquivo <br></br> ou <br></br>Selecione um arquivo para upload
					</p>
				</div>
			);
		}

		if (isDragReject) {
			return (
				<div className={`flex justify-center h-52 items-center py-4 border-[#e57878]`}>
					Tipo de arquivo não suportado
				</div>
			);
		}
		return (
			<div className={`flex justify-center h-52 items-center py-4 border-[#78e5d5]`}>
				Solte o arquivo aqui
			</div>
		);
	}, [isDragActive, isDragReject]);

	return (
		<>
			<div
				style={{ transition: 'height 0.2s ease' }}
				className={`${
					isDragActive ? 'border-[#78e5d5]' : isDragReject ? 'border-[#e57878]' : 'border-[#ddd]'
				} border-[1px] border-dashed rounded cursor-pointer`}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				{renderDragMessage()}
			</div>
		</>
	);
};

export default UploadToS3;
