import { FC, Fragment, useState } from "react";
import { TabSelector } from "src/components/TabSelector";
import { Tab, TABS } from "../../../Igc/CompanyDetails/components/TabsContent/Target";
import {
	SelectedBusinessInvestorsParams,
	approveBusinessInvestor,
	setBusinessInvestorParams,
	rejectBusinessInvestor
} from "src/services/target";
import { InvestorBusinessDTO, MatchStatus } from "src/domain/Target";
import { XCircleIcon, CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/outline";
import LoadingIcon from "src/assets/icons/Loading";
import { toastNotify } from "src/helpers/toastNotify";
import { useAuth } from "src/hooks/useAuth";
import Modal from "src/components/Modal";

interface ClientTargetNavListProps {
	selectedTab: Tab;
	setSelectedTab: (tab: Tab) => void;
	fetchSelectedInvestors: (params?: SelectedBusinessInvestorsParams) => Promise<void>;
	tabLoading: boolean;
	selectedInvestors: InvestorBusinessDTO[];
	tabsScrollTopRef: React.MutableRefObject<null | HTMLDivElement>;
	setMatchStatusTabOption: (option: MatchStatus) => void;
}

const ClientTargetNavList: FC<ClientTargetNavListProps> = ({
	selectedTab,
	setSelectedTab,
	fetchSelectedInvestors,
	setMatchStatusTabOption,
	tabLoading,
	selectedInvestors,
	tabsScrollTopRef
}) => {
	const { user } = useAuth();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedInvestor, setSelectedInvestor] = useState<InvestorBusinessDTO | null>(null);

	const handleItemApproval = async (investor: InvestorBusinessDTO) => {
		try {
			const params = {
				businessId: Number(user?.business_id),
				investorId: investor.id
			} as setBusinessInvestorParams;
			await approveBusinessInvestor(params);
			await fetchSelectedInvestors();
			toastNotify("Investidor aprovado com sucesso", "success");
		} catch (error) {
			console.error(error);
			toastNotify("Erro ao aprovar investidor", "error");
		}
	};

	const handleItemRejection = async (investor: InvestorBusinessDTO) => {
		try {
			const params = {
				businessId: Number(user?.business_id),
				investorId: investor.id
			} as setBusinessInvestorParams;
			await rejectBusinessInvestor(params);
			await fetchSelectedInvestors();
			toastNotify("Investidor rejeitado com sucesso", "info");
		} catch (error) {
			console.error(error);
			toastNotify("Erro ao reprovar investidor", "error");
		}
	};

	const handleInfoClick = (investor: InvestorBusinessDTO) => {
		setSelectedInvestor(investor);
		setIsOpen(true);
	};

	return (
		<div className="h-full w-full md:h-5/6">
			<nav className="bg-neutral-high border-neutral-medium sticky top-[3.1rem] flex justify-around border-b border-opacity-50 bg-white md:relative md:top-0 md:justify-start md:px-10">
				<TabSelector
					isActive={selectedTab === TABS.RECOMMENDED}
					onClick={() => {
						setMatchStatusTabOption("recomendado");
						setSelectedTab(TABS.RECOMMENDED);
					}}
				>
					{TABS.RECOMMENDED}
				</TabSelector>
				<TabSelector
					isActive={selectedTab === TABS.APPROVED}
					onClick={() => {
						setMatchStatusTabOption("aprovado");
						setSelectedTab(TABS.APPROVED);
					}}
				>
					{TABS.APPROVED}
				</TabSelector>
				<TabSelector
					isActive={selectedTab === TABS.REJECTED}
					onClick={() => {
						setMatchStatusTabOption("reprovado");
						setSelectedTab(TABS.REJECTED);
					}}
				>
					{TABS.REJECTED}
				</TabSelector>
			</nav>

			<div className="h-full overflow-y-auto">
				{tabLoading ? (
					<div className="flex h-full items-center justify-center">
						<LoadingIcon />
					</div>
				) : (
					<Fragment>
						<div ref={tabsScrollTopRef}></div>
						{selectedInvestors.map((investor, idx) => (
							<div
								key={idx}
								className="flex w-full flex-col items-start justify-between border-b border-[#000] border-opacity-10 py-1.5 px-2"
							>
								<section className="flex w-full justify-between">
									<span className="flex flex-col">
										<p className="my-auto text-lg">{investor.tradeName}</p>
										<p className="flex items-center gap-1 text-base text-auxiliar-grey-500 md:hidden">
											<InformationCircleIcon
												className="h-6 w-6 text-brand-secondaryMedium hover:cursor-pointer"
												onClick={() => handleInfoClick(investor)}
											/>
											<span className="font-medium">Estágio:</span> {investor.stage.name}
										</p>
									</span>

									<span className="flex items-center gap-2">
										<span className="hidden rounded-full bg-brand-secondaryMedium bg-opacity-70 px-2 py-1 text-sm text-white md:block">
											{investor.stage.name}
										</span>
										<InformationCircleIcon
											className="hidden h-9 w-9 text-brand-secondaryMedium hover:cursor-pointer md:block"
											onClick={() => handleInfoClick(investor)}
										/>
										{(selectedTab === TABS.RECOMMENDED || selectedTab === TABS.APPROVED) && (
											<button onClick={() => handleItemRejection(investor)}>
												<XCircleIcon className={`h-9 w-9 text-brand-error text-opacity-30 hover:text-opacity-70`} />
											</button>
										)}
										{(selectedTab === TABS.RECOMMENDED || selectedTab === TABS.REJECTED) && (
											<button onClick={() => handleItemApproval(investor)}>
												<CheckCircleIcon className={`h-9 w-9 text-brand text-opacity-50 hover:text-opacity-90`} />
											</button>
										)}
									</span>
								</section>
							</div>
						))}
					</Fragment>
				)}
			</div>
			{selectedInvestor && (
				<Modal title={selectedInvestor.tradeName} onClose={() => setIsOpen(false)} open={isOpen}>
					<div className="group relative z-50 flex flex-col gap-2">
						<span className="flex gap-2">
							<p className="text-lg">Estágio:</p>
							<span className="w-min whitespace-nowrap rounded-full bg-brand-secondaryMedium bg-opacity-70 px-2 py-1 text-sm text-white">
								{selectedInvestor.stage.name}
							</span>
						</span>
						<p className="text-lg">{selectedInvestor.stage.description}</p>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default ClientTargetNavList;
