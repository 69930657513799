import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button/Button';
interface CompanyCollaborationProps {}

const CompanyCollaboration: FC<CompanyCollaborationProps> = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="max-w-[744px] mx-auto mt-40 h-screen">
				<div className="mb-2">
					<h3 className={`text-2xl text-brand-primary font-bold`}>Agradecemos pela colaboração!</h3>
				</div>
				<p className="text-neutral-medium text-base font-normal mb-6">
					Suas informações serão revisadas pelo nosso time e em até 3 dias úteis voltaremos para
					você por email (se ativado) e por notificação no site. Fique atento às suas notificações!
				</p>

				<div className="flex w-[100%] justify-center items-center">
					<Button
						className="w-[744px]"
						color="secondary"
						onClick={() => {
							navigate('/business/dashboard');
						}}
					>
						Ir para dashboard
					</Button>
				</div>
			</div>
		</>
	);
};

export default CompanyCollaboration;
