import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'src/assets/icons/Loading';
import { BusinessExtendedView } from 'src/domain/BusinessExtendedView';
import { BusinessSummaryView } from 'src/domain/Summary';
import { formatMoney } from 'src/helpers/formatMoney';
import Page from 'src/layouts/Logged/components/Page';
import {
	cnpjMask,
	compareFinanceReport,
	phoneMask
} from 'src/pages/Business/Dashboard/components/Utils';
import { getBusinessExtendedViewById } from 'src/services/summary/getBusinessExtendedViewById';
import { getBusinessSummaryById } from 'src/services/summary/getBusinessFinanceReportView';
interface TemplatePageProps {}

const SummaryPage: FC<TemplatePageProps> = () => {
	const { id } = useParams();
	const [businessExtended, setBusinessExtended] = useState<BusinessExtendedView>();
	const [summary, setSummary] = useState<BusinessSummaryView>();
	const [loadingSummary, setLoadingSummary] = useState<boolean>(true);

	const handleInitFinanceReport = async () => {
		try {
			const business_id = Number(id);
			const businessFinanceReportResponse = await getBusinessSummaryById(business_id);

			const sortedFinanceReports =
				businessFinanceReportResponse.data.finance_reports.sort(compareFinanceReport);

			if (businessFinanceReportResponse.data) {
				setSummary({
					...businessFinanceReportResponse.data,
					finance_reports: sortedFinanceReports
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleInitBusinessExtendedView = async () => {
		try {
			const params = {
				business_id: Number(id)
			};
			const businessExtendedViewResponse = await getBusinessExtendedViewById(params);
			if (businessExtendedViewResponse.data) {
				setBusinessExtended(businessExtendedViewResponse.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		handleInitFinanceReport();
		handleInitBusinessExtendedView();
		setLoadingSummary(false);
	}, []);

	if (loadingSummary) {
		return (
			<div className="flex h-screen items-center justify-center">
				<Loading color="primary" size={12} />
			</div>
		);
	}

	const formatNumberToMoney = (num: number): string => {
		return num.toLocaleString('pt-BR', {
			minimumFractionDigits: 2,
			style: 'currency',
			currency: 'BRL'
		});
	};

	return (
		<>
			<Page>
				<div className="max-w-4xl">
					{/* Summary - Nome*/}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Resumo - Empresa e Proprietário
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span className="font-semibold">Nome: </span>
									{summary?.full_name}
								</p>
							</li>
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span className="font-semibold">Email: </span>
									{summary?.email}
								</p>
							</li>
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span className="font-semibold">Telefone: </span>
									{summary?.phone && phoneMask(summary?.phone)}
								</p>
							</li>
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span className="font-semibold">CNPJ: </span>
									{summary?.cnpj && cnpjMask(summary?.cnpj)}
								</p>
							</li>
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span className="font-semibold">Politicamente exposto: </span>
									{summary?.is_political ? 'Sim' : 'Não'}
								</p>
							</li>
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span className="font-semibold">Website: </span>
									{summary?.website}
								</p>
							</li>
							<li className="list-none">
								<p className="text-neutral-medium text-lg font-normal">
									<span className="font-semibold">Finance Report: </span>
								</p>
							</li>
							{summary &&
								summary.finance_reports.map((financeReport, index) => {
									return (
										<li key={`${financeReport.year + index}`}>
											<p className="text-neutral-medium text-lg font-normal">
												{financeReport.year}
												{' | '}
												{financeReport.type === 1 ? 'EBITDA' : 'Receita Líquida'}
												{' - '}
												<span className="text-brand-primaryMedium">
													<span className="font-semibold">{formatMoney(financeReport.amount.toString())}</span>
												</span>{' '}
											</p>
										</li>
									);
								})}
						</ul>
					</section>
					{/* Etapa 02 - Resumo */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">Etapa 02 - Resumo</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									{businessExtended?.briefing}
								</p>
							</li>
						</ul>
					</section>
					{/* Etapa 03 - Diferenciais do negócio */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 03 - Diferenciais do negócio
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									{businessExtended?.business_differentials}
								</p>
							</li>
						</ul>
					</section>
					{/* Etapa 04 - Linha do tempo */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 04 - Linha do tempo
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_timelines?.map((item, index) => {
								const year = item.date.split('-')[0];
								return (
									<li key={`${item.date + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span className="text-brand-primaryMedium">{year}</span>{' - '}
											{item.event_description}
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 05 - Objetivos */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">Etapa 05 - Objetivos</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_goals?.map((item, index) => {
								return (
									<li key={`${item.name + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span>{item.name}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 06 - Investidores previamente interessados */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 06 - Investidores previamente interessados
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_interested_investors?.map((item, index) => {
								return (
									<li key={`${item.investor + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span className="text-brand-primaryMedium">{item.investor}</span>
											<br />
											<span>{item.investor_interest}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 07 - Potenciais investidores */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 07 - Potenciais investidores
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_potential_investors?.map((item, index) => {
								return (
									<li key={`${item.investor + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span>{item.investor}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 08 - Concorrentes */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 08 - Concorrentes
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_competitors?.map((item, index) => {
								return (
									<li key={`${item + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span>{item}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 10 - Endividamento */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 10 - Endividamento
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span className="">
										{businessExtended?.debt_amount &&
											formatNumberToMoney(businessExtended?.debt_amount)}
									</span>
								</p>
							</li>
						</ul>
					</section>
					{/* Etapa 11 - Auditoria */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">Etapa 11 - Auditoria</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span>{businessExtended?.audited_by}</span>
								</p>
							</li>
						</ul>
					</section>
					{/* Etapa 12 - Clientes */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">Etapa 12 - Clientes</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span>{businessExtended?.client_amount}</span>
								</p>
							</li>
						</ul>
					</section>
					{/* Etapa 13 - Percentual de cada cliente */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 13 - Percentual de cada cliente
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_main_clients?.map((item, index) => {
								return (
									<li key={`${item.name + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span className="text-brand-primaryMedium">{item.share}%</span>
											{' - '}
											<span>{item.name}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 14 - Estrutura societária */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 14 - Estrutura societária
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_partners?.map((item, index) => {
								return (
									<li key={`${item.name + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span className="text-brand-primaryMedium">{item.share}%</span>
											{' - '}
											<span>{item.name}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 16 - Local da sede */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 16 - Local da sede
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span className="text-brand-primaryMedium">Estado:</span>{' '}
									<span>{businessExtended?.main_state}</span>
								</p>
							</li>
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									<span className="text-brand-primaryMedium">Cidade:</span>{' '}
									<span>{businessExtended?.main_city}</span>
								</p>
							</li>
						</ul>
					</section>
					{/* Etapa 17 - Locais de operação */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 17 - Locais de operação
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_states?.map((item, index) => {
								return (
									<li key={`${item.state_id + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span>{item.state_name}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 18 - Tipos de imóveis */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 18 - Tipos de imóveis
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_leases?.map((item, index) => {
								const validUntilFormat = `${item.valid_until.split('-')[1]}/${item.valid_until.split('-')[0]}`;
								return (
									<li key={`${item.name + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span className="text-brand-primaryMedium">{item.name}</span>
											{' - '}
											<span>{validUntilFormat}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 19 - Principais produtos e serviços */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 19 - Principais produtos e serviços
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_main_products?.map((item, index) => {
								return (
									<li key={`${item.name + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span className="text-brand-primaryMedium">{item.share}%</span>
											{' - '}
											<span>{item.name}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
					{/* Etapa 20 - Canais de distribuição */}
					<section className="mb-12 mt-10">
						<p className="mb-2 text-2xl font-semibold text-brand-primary">
							Etapa 20 - Canais de distribuição
						</p>
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							{businessExtended?.business_sales_types?.map((item, index) => {
								return (
									<li key={`${item.name + index}`}>
										<p className="text-neutral-medium text-lg font-normal">
											<span className="text-brand-primaryMedium">{item.share}%</span>
											{' - '}
											<span>{item.name}</span>
										</p>
									</li>
								);
							})}
						</ul>
					</section>
				</div>
			</Page>
		</>
	);
};

export default SummaryPage;
