import { TrashIcon } from "@heroicons/react/outline";
import moment from "moment";
import { FC, useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { removeLetters } from "src/helpers";
import { Step } from "../../configs/models";

const CompanyTimeline: FC<Step> = ({
	onSubmit,
	maxInputs,
	loadingStep,
	control,
	register,
	errors,
	setValue,
	getStepData
}) => {
	const initialData = { event_description: "", date: moment(new Date()).format("yyyy") };

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "business_timelines"
	});

	useEffect(() => {
		if (fields.length === 0) append(initialData);
	}, [fields]);

	return (
		<div className="flex flex-col items-start justify-between ">
			<ul className="w-full ">
				{fields.map((field, index) => {
					return (
						<li className="mb-4 flex w-full" key={field.id}>
							<div className="mr-3 w-[63%]">
								<Input
									type="text"
									{...field}
									{...register(`business_timelines.${index}.event_description`)}
									fieldError={errors.business_timelines?.[index]?.event_description}
								/>
							</div>
							<div className={` mr-1 w-[30%]`}>
								<Controller
									defaultValue={`${new Date().getFullYear()}`}
									control={control}
									name={`business_timelines.${index}.date`}
									render={({ field }) => (
										<Input
											type="text"
											{...field}
											onChange={({ target }) => {
												const value = removeLetters(target.value);

												setValue(`business_timelines.${index}.date`, value, { shouldDirty: true });
											}}
											maxLength={4}
											value={field.value.split("-")[0] || ""}
											fieldError={errors.business_timelines?.[index]?.date}
										/>
									)}
								/>
							</div>
							<div className={`flex w-6 items-center ${index === 0 && "hidden"}`}>
								<TrashIcon
									className="cursor-pointer"
									onClick={() => remove(index)}
									color="#bb7127"
								/>
							</div>
						</li>
					);
				})}
			</ul>

			<div className="mb-3 flex w-full">
				<Button
					type="button"
					className="mr-3"
					color="outline-secondary"
					size="large"
					disabled={maxInputs === fields.length}
					onClick={() => append(initialData)}
				>
					Nova Entrada
				</Button>
				<Button
					disabled={control.getFieldState("business_timelines").invalid}
					loading={loadingStep}
					onClick={(e) => {
						e.preventDefault();

						const business_timelines = getStepData("business_timelines").map((value) => {
							const year = value.date.split("-")[0];
							value.date = `${year}-01-01`;
							return value;
						});

						onSubmit({ business_timelines });
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</div>
	);
};

export default CompanyTimeline;
