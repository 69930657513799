import { FC, Ref, forwardRef } from "react";
import { FieldError, RefCallBack, RegisterOptions, UseFormRegister } from "react-hook-form";
import { ExtendedRegistration } from "src/domain/Register";

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	fieldError: FieldError | undefined;
	height?: string;
	ref?: RefCallBack;
	registerName?: keyof ExtendedRegistration;
	registerProps?: RegisterOptions<ExtendedRegistration, keyof ExtendedRegistration>;
}

const Textarea = forwardRef(
	(
		{ height, fieldError, registerName, ...otherProps }: TextareaProps,
		ref: Ref<HTMLTextAreaElement>
	) => {
		return (
			<>
				<textarea
					ref={ref}
					className={`mb-2 w-full px-3 py-3 ${
						height ? height : "h-28"
					} resize-none rounded bg-[#F4F5F6] ${
						Boolean(fieldError)
							? "border border-brand-error focus:border-brand-error focus:ring-brand-error active:border-brand-error"
							: "border-0"
					}`}
					{...otherProps}
				/>
				{Boolean(fieldError) && (
					<span className="mb-2 text-brand-error">{fieldError?.message}</span>
				)}
			</>
		);
	}
);

export default Textarea;
