import AWS from 'aws-sdk';

const s3 = new AWS.S3({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});

const uploadTeaser = async (Key: string, Bucket: string, Body: any) => {
	try {
		const upload = await s3
			.upload({
				Key,
				Bucket,
				Body
			})
			.promise();

		return upload;
	} catch (err) {
		console.error(err);
	}
};

const listTeasers = async (id: string | undefined) => {
	try {
		const { Contents } = await s3
			.listObjectsV2({
				Bucket: `${process.env.REACT_APP_AWS_S3_ENVIRONMENT}`,
				Prefix: `business/teaser/${id}`
			})
			.promise();

		return Contents?.map((content) => content.Key?.replace(/.*(\/)/, ''));
	} catch (error) {
		console.error(error);
		return [];
	}
};

const getSpecificTeaserForDownload = async (
	id: string | undefined,
	fileExtension: 'pdf' | 'pptx'
) => {
	try {
		const file = await s3
			.getObject({
				Bucket: `${process.env.REACT_APP_AWS_S3_ENVIRONMENT}/business/teaser/${id}/${fileExtension}`,
				Key: `teaser.${fileExtension}`
			})
			.promise();

		const blob = new Blob([file.Body as BlobPart], { type: file.ContentType });

		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = `teaser.${fileExtension}`;
		document.body.appendChild(link);
		link.click();

		link.remove();
		URL.revokeObjectURL(url);
	} catch (err) {
		console.error(err);
	}
};

export { uploadTeaser, listTeasers, getSpecificTeaserForDownload };
