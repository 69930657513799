import exameLogo from "./images/exame-logo.png";

export const posts = [
	{
		id: 1,
		href: "https://exame.com/exame-in/com-igc-como-socia-overa-nasce-com-foco-em-mas-de-menor-porte/",
		title: "Com IGC como sócia, OverA nasce com foco em M&As de menor porte",
		headline:
			"Com 14 mandatos, boutique quer dar tratamento VIP a um mercado hoje pouco coberto no Brasil – e que soma mais de 30% das fusões e aquisições",
		datetime: "2023-09-11",
		date: "11 Set 2023",
		company: {
			name: "Exame",
			image: exameLogo
		}
	}
];
