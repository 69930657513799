export const formatCnpj = (cnpj: string) => {

	const cleanCnpj = cnpj.replace(/[^0-9 ]/g, '');
	if (cleanCnpj.length <= 2) {
		return cleanCnpj;
	}
	let chooseReplaceString: number;
	if (cleanCnpj.length < 6) {
		chooseReplaceString = 0;
	} else if (cleanCnpj.length < 9) {
		chooseReplaceString = 1;
	} else if (cleanCnpj.length < 13) {
		chooseReplaceString = 2;
	} else {
		chooseReplaceString = 3;
	}

	const replaceStrings = ['$1.$2', '$1.$2.$3', '$1.$2.$3/$4', '$1.$2.$3/$4-$5'];

	const replaceString = replaceStrings[chooseReplaceString];
	return cleanCnpj
		.replace(/(\d{1,2})?(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d{1,2})?/, replaceString)
		.substring(0, 18);
};
