import { FC } from 'react';
import { CheckboxModel } from '../form/models';

const Checkbox: FC<CheckboxModel> = ({ option, handleSelect, isSelected }) => {
	return (
		<fieldset className="space-y-5">
			<div className="relative flex items-center">
				<div className="flex items-center h-5">
					<input
						id={option.id.toString()}
						aria-describedby={`${option.id}-description`}
						name={option.id.toString()}
						type="checkbox"
						className="focus:ring-brand-secondary h-6 w-6 text-brand-secondary border-gray-300 rounded checked:bg-brand-secondary cursor-pointer"
						checked={isSelected}
						onChange={() => handleSelect(option)}
					/>
				</div>
				<div className="ml-3 text-sm">
					<label
						htmlFor={option.id.toString()}
						className="font-normal text-base cursor-pointer"
					>
						{option.label}
					</label>
					<span id={`${option.id}-description`} className="text-neutral-medium ml-2 cursor-pointer">
						{option.description}
					</span>
				</div>
			</div>
		</fieldset>
	);
};

export default Checkbox;
