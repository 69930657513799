import { QAResponse } from 'src/domain/QA';
import client from '../client';
export const getQAList = async (business_id: number) => {
	const url = `business/businessqa/`;
	return client.get<Array<QAResponse>>(url, {
		params: {
			business_id: business_id
		}
	});
};
