import {
	ChevronLeftIcon,
	ChevronRightIcon,
	LogoutIcon,
	ViewListIcon
} from "@heroicons/react/outline";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "src/assets/icons/Loading";
import {
	ExtendedRegistration,
	ExtendedRegistrationForm,
	formatFormToRender
} from "src/domain/Register";
import { toastNotify } from "src/helpers/toastNotify";
import { useAuth } from "src/hooks/useAuth";
import history from "src/services/history";
import { getExtenderRegister } from "src/services/register/getExtenderRegister";
import { setExtenderRegisters } from "src/services/register/setExtenderRegisters";
import StepContent from "./components/StepContent";
import Summary from "./components/summary/Summary";
import { validationSchemaBySteps } from "./configs/models";
import defaultSteps from "./components/Steps/defaultSteps";
import {
	Categories,
	getStepByField,
	reviewEveryCheckedState,
	summaryCategories
} from "src/domain/Summary";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const ExtendedRegister: FC = () => {
	const navigate = useNavigate();
	const [isLast, setIsLast] = useState(false);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [loadingStep, setLoadingStep] = useState<boolean>(false);
	const [showSummary, setShowSummary] = useState<boolean>(false);
	const [categories, setCategories] = useState<Categories[]>(summaryCategories);
	const currentValidationSchema = validationSchemaBySteps[currentStep];

	const { user } = useAuth();
	const {
		register,
		watch,
		control,
		trigger,
		getFieldState,
		setValue,
		getValues: getFormValues,
		formState: { errors, isLoading }
	} = useForm<ExtendedRegistrationForm>({
		defaultValues: async () => {
			try {
				const defaultValues = formatFormToRender(
					(await getExtenderRegister("business", { business_id: user?.business_id })).data
				);

				setCategories(reviewEveryCheckedState(categories, defaultValues));

				return defaultValues;
			} catch (err) {
				return formatFormToRender({
					business_id: user ? user.business_id : null,
					full_register_step: 0,
					briefing: "",
					business_differentials: [],
					business_timelines: [],
					business_goals: [],
					business_interested_investors: [],
					business_potential_investors: [],
					business_competitors: [],
					business_files: [],
					debt_amount: "",
					audited_by: "",
					client_amount: null,
					business_main_clients: [],
					business_partners: [],
					clt_employees_amount: null,
					pj_employees_amount: null,
					main_state_id: null,
					main_state: "",
					main_city_id: null,
					main_city: "",
					business_states: [],
					business_leases: [],
					business_main_products: [],
					business_sales_types: [],
					business_custom_goal: null
				});
			}
		},
		// resolver: yupResolver(currentValidationSchema),
		mode: "onChange"
	});

	function updateSingleCategory(field: string | undefined, statusNumber: 0 | 1 | 2) {
		if (!field) return;

		const stepID = getStepByField(field);

		setCategories((old) =>
			old.map((item, index) => {
				if (index === stepID) {
					item.status = statusNumber;
				}

				return item;
			})
		);
	}

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (!name) return;

			//@ts-ignore
			const formattedName: keyof ExtendedRegistrationForm = name.split(".")[0];

			if (getFieldState(formattedName).isDirty) return updateSingleCategory(formattedName, 1);
		});

		return () => subscription.unsubscribe();
	}, [watch]);

	const setExtenderRegisterData = (value: any) => {
		value["business_id"] = user ? user.business_id : null;
		value["full_register_step"] = currentStep + 1;

		setExtenderRegisters("business", value)
			.then((response) => {
				toastNotify(response.data.user_message, "success");
				if (isLast) return navigate("/business/cadastro-finalizado");

				return setCurrentStep(currentStep + 1);
			})
			.finally(() => {
				setLoadingStep(false);
			});
	};

	const goBack = () => {
		history.back();
	};

	const nextStep = () => {
		setLoadingStep(false);

		setCurrentStep(currentStep + 1);
	};

	const previousStep = () => {
		setCurrentStep(currentStep - 1);
	};

	const setStepData = async (stepData: object | null) => {
		if (!stepData) return nextStep();

		const isStepValid = await trigger();

		if (!isStepValid) return;

		const keys = Object.keys(stepData);

		for (const key of keys) {
			updateSingleCategory(key, 2);
		}

		setLoadingStep(true);

		return setExtenderRegisterData(stepData);
	};

	useEffect(() => {
		if (!user) return;

		const params = {
			business_id: user.business_id
		};

		getExtenderRegister("business", params).then((result) => {
			if (isLast) return navigate("/business/cadastro-finalizado");

			return setCurrentStep(result.data.full_register_step);
		});
	}, []);

	useEffect(() => {
		trigger();

		const nextStep =
			defaultSteps.find((step) => step.stepNumber === currentStep) || defaultSteps[0];

		setIsLast(nextStep.isLast === true);

		setCurrentStep(nextStep.stepNumber);
	}, [currentStep]);

	return (
		<>
			<Summary
				isOpen={showSummary}
				setOpen={setShowSummary}
				step={currentStep}
				setStep={setCurrentStep}
				categories={categories}
				setCategories={setCategories}
			/>
			<div className="h-full w-full bg-white px-2 pt-4 pb-10 sm:px-6 md:px-12">
				<div className="flex justify-between">
					<button
						className="flex items-center whitespace-nowrap bg-transparent text-[16px] font-normal text-brand-secondary"
						onClick={goBack}
					>
						<LogoutIcon className="mr-2 text-brand-secondary " height="25" /> Salvar e sair
					</button>
					<div className="flex items-center text-[16px] font-normal text-brand-secondary">
						<ChevronLeftIcon
							className={`mx-2 cursor-pointer text-brand-secondary ${
								currentStep === 0 ? "hidden" : null
							}`}
							height={25}
							onClick={previousStep}
						/>
						<ChevronRightIcon
							className={`mx-2 cursor-pointer text-brand-secondary ${isLast ? "hidden" : null}`}
							height={25}
							onClick={nextStep}
						/>
					</div>
					<button
						onClick={() => setShowSummary(!showSummary)}
						className="flex text-[16px] font-normal text-brand-secondary"
					>
						<ViewListIcon height={25} className="mr-2 text-brand-secondary" /> Sumário
					</button>
				</div>

				{isLoading ? (
					<div className="flex h-screen items-center justify-center">
						<Loading color="primary" size={12} />
					</div>
				) : (
					<form>
						<StepContent
							step={defaultSteps[currentStep]}
							toPreviousStep={previousStep}
							onSubmit={setStepData}
							getStepData={getFormValues}
							loadingStep={loadingStep}
							register={register}
							errors={errors}
							control={control}
							setValue={setValue}
							watch={watch}
						/>
					</form>
				)}
			</div>
		</>
	);
};

export default ExtendedRegister;
