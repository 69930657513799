import { FC } from 'react';

const Page: FC<{
	children: any;
	id?: string;
}> = ({ children, id }) => {
	return (
		<div id={id} className="px-4 pb-3 pt-6 sm:px-6 md:px-8">
			{children}
		</div>
	);
};

export default Page;
