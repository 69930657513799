import React, { FC, ReactElement } from 'react';
import Button from 'src/components/Button/Button';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import Page from 'src/layouts/Logged/components/Page';

type HeaderProps = {
	children?: React.ReactElement;
	title: string;
	goBack?: () => void;
	img?: string;
	button?: ReactElement | undefined;
};

const Header: FC<HeaderProps> = ({ title, goBack, button }) => {
	return (
		<Page>
			<header className='h-min flex justify-between flex-wrap items-center w-[100%] bg-neutral-high'>
				<div className='flex items-center'>
					{
						goBack && (
							<Button
								type='button'
								size='small'
								className='rounded-none border-0 px-0 w-auto'
								color='outline-secondary'
								onClick={goBack}
							>
								<ChevronLeftIcon width={30} className='text-brand-primary' />
							</Button>
						)
					}

					<h1 className='flex flex-start items-center font-bold text-3xl text-brand-primary'>
						{title}
					</h1>
				</div>

				{button}
			</header>
		</Page>
	);
};

export default Header;
