import { InvestorBusinessStageDTO } from "src/domain/Investor";
import client from "../client";

export type InvestorBusinessStageParams = {
    stage_id: number;
}

export const updateInvestorBusinessStage = (businessId: number, investorId: number, params: InvestorBusinessStageParams) => {
	return client.patch(`igc/${businessId}/investor/${investorId}/update`, params);
};

export const getInvestorBusinessStages = () =>
	client.get<InvestorBusinessStageDTO[]>(`/igc/investor_stages`);
