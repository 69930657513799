import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { FC, Fragment } from 'react';
import { Selectable } from '../../form/models';

interface MultipleSelectProps {
	error?: boolean;
	errorMessage?: string | undefined;
	options: Selectable[];
	values: Selectable[] | undefined;
	onChange: any;
}
const SingleSelect: FC<MultipleSelectProps> = ({
	error,
	errorMessage,
	options,
	values,
	onChange
}) => {
	const handleDisplayValue = () => {
		return (
			<>
				<div className="flex justify-betweeen items-center w-full truncate">
					{!values || values.length === 0
						? 'Selecione uma opção'
						: values
								.map((value) => {
									return value.name;
								})
								.join(', ')}
				</div>
				<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
					<ChevronDownIcon className="h-5 w-5 text-neutral-low" aria-hidden="true" />
				</span>
			</>
		);
	};

	const optionIsSelected = (option: Selectable): boolean => {
		if (values) {
			const response = values.filter((value) => value.id === option.id);
			if (response && response.length > 0) {
				return true;
			}
			return false;
		}
		return false;
	};
	return (
		<>
			<Listbox value={values} onChange={onChange} multiple>
				<div className="relative mt-1">
					<Listbox.Button
						className={`flex items-center h-14 bg-[#F4F5F6] relative w-full rounded-lg shadow-sm px-4 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
							error
								? 'border focus:ring-brand-error border-brand-error active:border-brand-error focus:border-brand-error '
								: 'border-0'
						}`}
					>
						{handleDisplayValue()}
					</Listbox.Button>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Listbox.Options className="absolute z-10 mt-1 w-full bg-[#F4F5F6] shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
							{options.map((option, index) => (
								<Listbox.Option
									key={index}
									className={({ active }) =>
										`relative cursor-default select-none py-2 pl-10 pr-4 ${
											active
												? 'text-white bg-brand-primary'
												: optionIsSelected(option)
												? 'bg-neutral-medium text-brand'
												: 'text-gray-900'
										}`
									}
									value={option}
								>
									{({ selected }) => (
										<>
											<span
												className={`block truncate ${
													optionIsSelected(option) ? 'font-medium' : 'font-normal'
												}`}
											>
												{option.name}
											</span>
											{optionIsSelected(option) ? (
												<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			</Listbox>
			{error && <span className="mb-2 text-brand-error">{errorMessage}</span>}
		</>
	);
};

export default SingleSelect;
