import React from 'react';
import { Link } from 'react-router-dom';

export interface IHomePageProps {}

const DevTests: React.FunctionComponent<IHomePageProps> = () => {
	return (
		<div className="flex justify-center p-8">
			<div>
				<h1 className="text-center font-bold font-xl mb-8">HomePage</h1>
				<ul>
					<li className="block w-[200px] mb-4 p-4 rounded-md text-center text-white bg-brand-primary">
						<Link to="/">Landing Page</Link>
					</li>
					<li className="block w-[200px] mb-4 p-4 rounded-md text-center text-white bg-brand-primary">
						<Link to="igc/login">Igc Login</Link>
					</li>
					<li className="block w-[200px] mb-4 p-4 rounded-md text-center text-white bg-brand-primary">
						<Link to="igc/dashboard">Igc Dashboard</Link>
					</li>
					<li className="block w-[200px] mb-4 p-4 rounded-md text-center text-white bg-brand-primary">
						<Link to="finder/login">Finder Login</Link>
					</li>
					<li className="block w-[200px] mb-4 p-4 rounded-md text-center text-white bg-brand-primary">
						<Link to="business/dashboard">Finder Dashboard</Link>
					</li>
					<li className="block w-[200px] mb-4 p-4 rounded-md text-center text-white bg-brand-primary">
						<Link to="business/login">Business Login</Link>
					</li>
					<li className="block w-[200px] mb-4 p-4 rounded-md text-center text-white bg-brand-primary">
						<Link to="business/dashboard">Business Dashboard</Link>
					</li>
					<li className="block w-[200px] mb-4 p-4 rounded-md text-center text-white bg-brand-primary">
						<Link to="/finder/in/about">About</Link>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default DevTests;
