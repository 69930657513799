export function getValueFromMap(
	map: Map<string | string[], number>,
	key: string
): number | undefined {
	for (const [mapKey, value] of Array.from(map)) {
		if (Array.isArray(mapKey) && mapKey.includes(key)) {
			return value;
		} else if (mapKey === key) {
			return value;
		}
	}
	return undefined;
}
