import { FC } from "react";
import type { Step } from "../configs/models";

const StepContent: FC<Step> = ({
	step,
	onSubmit,
	getStepData,
	loadingStep,
	register,
	errors,
	control,
	setValue,
	watch
}) => {
	return (
		<div className="mx-auto mt-40 max-w-[744px]">
			<div className="mb-2">
				<h3
					className={`text-left text-2xl ${
						step.stepNumber === 0 ? "text-brand-primary" : "text-neutral-low"
					} mb-6 font-bold`}
				>
					{step.title}
					<br />
					{step.optional && <span className="text-base font-normal">(opcional)</span>}
				</h3>
			</div>
			{step.stepNumber === 0 && (
				<iframe
					src="https://www.youtube.com/embed/Hu0owiTirGo"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowFullScreen
					className="mb-6"
				></iframe>
			)}
			<p className="text-neutral-medium mb-6 justify-between text-base font-normal">
				{step.description}
			</p>
			<step.component
				onSubmit={onSubmit}
				getStepData={getStepData}
				maxInputs={step.maxInputs}
				loadingStep={loadingStep}
				register={register}
				errors={errors}
				control={control}
				setValue={setValue}
				watch={watch}
			/>
		</div>
	);
};

export default StepContent;
