import { FC } from "react";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { removeLetters } from "src/helpers";
import { Step } from "../../configs/models";

const CompanyEmployeesAmount: FC<Step> = ({ onSubmit, getStepData, loadingStep, errors, register }) => {
	return (
		<>
			<div className="mb-4 w-full">
				<Input
					placeholder="Digite sua resposta"
					type="text"
					{...register("clt_employees_amount")}
					fieldError={errors.clt_employees_amount}
					mask="currency"
					maxLength={13}
				/>
			</div>
			<h3 className={`text-neutral-low mb-6 text-left text-2xl font-bold`}>Quantos funcionários são PJ?</h3>
			<div className="mb-4 w-full">
				<Input
					placeholder="Digite sua resposta"
					type="text"
					{...register("pj_employees_amount")}
					fieldError={errors.pj_employees_amount}
					mask="currency"
					maxLength={13}
				/>
			</div>
			<div className="flex w-full">
				<Button
					loading={loadingStep}
					disabled={Boolean(errors.clt_employees_amount) || Boolean(errors.pj_employees_amount)}
					onClick={(e) => {
						e.preventDefault();

						const cltEmployees = getStepData("clt_employees_amount");

						const pjEmployees = getStepData("pj_employees_amount");

						if (cltEmployees && pjEmployees)
							return onSubmit({
								clt_employees_amount: removeLetters(cltEmployees.toString()),
								pjEmployees: removeLetters(pjEmployees.toString())
							});
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</>
	);
};

export default CompanyEmployeesAmount;
