import { FC, useEffect, useRef, useState } from "react";
import Button from "src/components/Button/Button";
import ChatRoom from "src/components/Chat/ChatRoom";
import { sendChatNotificationToAdmin } from "src/domain/Chat";
import { useAuth } from "src/hooks/useAuth";
import { useChatMessages } from "src/hooks/useChatMessages";
import { useDebounce } from "src/services/Utils";
import { addNewMessage, updateLastMessageInChat, updateUser, uploadChatFile } from "src/services/firebase";
import { PaperClipIcon } from "@heroicons/react/outline";
import { PreviewImage } from "src/components/Chat/PreviewImage";
import { AnimatePresence } from "framer-motion";
import { isFileImage } from "src/helpers/isFileImage";

const BusinessChat: FC = () => {
	const { user } = useAuth();
	const { setNewChatMessages } = useChatMessages();
	const userId = user?.business_id!;
	const [sendingMessage, setSendingMessage] = useState(false);
	const scrollPoint = useRef<null | HTMLDivElement>(null);
	const textAreaRef = useRef<null | HTMLTextAreaElement>(null);
	const [message, setMessage] = useState<string>("");
	const debouncedFn = useDebounce(sendChatNotificationToAdmin, 120000);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [previewOpened, setPreviewOpened] = useState(false);

	function selectFile({ target }: { target: HTMLInputElement }) {
		if (!target.files || target.files.length === 0) return;

		const file = target.files[0];

		setPreviewOpened(true);

		return setSelectedFile(file);
	}

	function closePreview() {
		return setPreviewOpened(false);
	}

	async function uploadFile(file: File) {
		try {
			const { metadata } = await uploadChatFile(file, user?.business_id);

			const uploadMessage = isFileImage(file) ? `image:${metadata.name}` : `file:${metadata.name}`;

			handleSendMessage(uploadMessage);

			setPreviewOpened(false);
		} catch (err) {
			console.error(err);
		}
	}

	const handleSendMessage = async (message: string | null) => {
		if (!message || message.trim().length === 0) {
			return;
		}

		setSendingMessage(true);

		await addNewMessage({
			chatId: userId,
			senderId: userId,
			text: message.trim(),
			senderRole: "business"
		});

		updateLastMessageInChat(userId, userId);

		debouncedFn(userId, message.trim());

		setMessage("");
		scrollPoint.current?.scrollIntoView({ behavior: "smooth" });

		setSendingMessage(false);
	};

	useEffect(() => {
		(async () => {
			if (user?.business_id) {
				await updateUser("users", user?.business_id, "last_chat_view");
				return setNewChatMessages(user.role, user.business_id);
			}
		})();
	}, []);

	return (
		<>
			<div className="flex h-[94vh] w-full flex-col gap-2 p-4 md:h-screen">
				<h2 className="my-2 hidden px-4 text-3xl font-bold text-brand-primary md:inline">Chat</h2>
				<div className="relative flex h-[85vh] w-full flex-col items-center rounded-lg border-2 border-brand-secondary border-opacity-50 bg-brand-secondaryMedium bg-opacity-5 xl:h-[90vh]">
					<AnimatePresence>
						{previewOpened && <PreviewImage upload={uploadFile} closePreview={closePreview} file={selectedFile} />}
					</AnimatePresence>
					<ChatRoom scrollRef={scrollPoint} />
					<form
						onSubmit={(e) => {
							e.preventDefault();
							handleSendMessage(message);
						}}
						className={`flex w-full items-center gap-2 p-2 ${previewOpened ? "hidden" : "block"}`}
					>
						<textarea
							name="message"
							ref={textAreaRef}
							value={message}
							className="textarea textarea-bordered h-full w-full resize-none bg-white leading-5 text-[#000] active:outline-none"
							placeholder="Mensagem"
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									e.preventDefault();
									handleSendMessage(message);
								}
							}}
							onChange={({ target }) => setMessage(target.value)}
						></textarea>
						<label
							htmlFor="file-upload"
							className="flex h-14 w-14 cursor-pointer items-center justify-center rounded-md bg-brand-secondary hover:brightness-110"
							tabIndex={0}
						>
							<PaperClipIcon height={30} color="white" />
						</label>
						<input id="file-upload" type="file" className="hidden" onChange={selectFile} />
						<Button type="submit" className={`w-max ${sendingMessage && "loading"}`}>
							{sendingMessage ? "Enviando" : "Enviar"}
						</Button>
					</form>
				</div>
			</div>
		</>
	);
};

export default BusinessChat;
