import { FC, useCallback, useEffect, useState } from "react";
import { Bar, ComposedChart, LabelList, Legend, Line, XAxis } from "recharts";
import Loading from "src/assets/icons/Loading";
import TeaserImg from "src/assets/images/teaser.png";
import { Teaser } from "src/domain/Teaser";
import { useAuth } from "src/hooks/useAuth";
import Page from "src/layouts/Logged/components/Page";
import history from "src/services/history";
import { getTeaserByCompanyId } from "src/services/teaser/getTeaserByCompanyId";
import { getSpecificTeaserForDownload, listTeasers } from "src/services/teaser/s3Connection";
export interface TeaserPageProps {}

const TeaserPage: FC<TeaserPageProps> = () => {
	const [teaser, setTeaser] = useState<Teaser>();
	const { user } = useAuth();
	const [loadingTeaser, setLoadingTeaser] = useState<boolean>(true);
	const [initialFiles, setInitialFiles] = useState<(string | undefined)[]>();

	const handleDownload = () => {
		return (
			initialFiles?.includes("teaser.pdf") &&
			getSpecificTeaserForDownload(user?.business_id.toString(), "pdf")
		);
	};

	const handleInit = useCallback(async () => {
		getTeaserByCompanyId(String(user?.role), Number(user?.business_id))
			.then((response) => {
				if (response.data) {
					setTeaser(response.data);
				}
			})
			.catch(() => {
				history.push("/business/complete-seu-cadastro");
			})
			.finally(() => {
				setLoadingTeaser(false);
			});

		setInitialFiles(await listTeasers(user?.business_id.toString()));
	}, [user]);

	useEffect(() => {
		handleInit();
	}, [handleInit]);

	const data = [
		{
			year: "2017",
			value: 51.9,
			percent: 28.6,
			step: 75.9,
			cagr: ""
		},
		{
			year: "2018",
			value: 81.1,
			percent: 29.6,
			step: 239.4,
			cagr: ""
		},
		{
			year: "2019",
			value: 107.6,
			percent: 30.9,
			step: 239.4,
			cagr: "CAGR: 41,0%"
		},
		{
			year: "2020",
			value: 131.8,
			percent: 30.5,
			step: 239.4,
			cagr: ""
		},
		{
			year: "2021E",
			value: 239.4,
			percent: 27.9,
			step: null,
			cagr: ""
		}
	];

	const CustomizedDotText = (props) => {
		const { cx, cy, value } = props;

		return (
			<svg width="40" height="20" x={cx - 20} y={cy - 10}>
				<rect
					width="40"
					height="20"
					x="0"
					y="0"
					style={{ fill: "#fff", strokeWidth: 5, stroke: "#2D4E4F" }}
				></rect>
				<text fill="#4A6365" textAnchor="middle" x="50%" y="50%" dominantBaseline="middle" dy={2}>
					{value}
				</text>
			</svg>
		);
	};

	if (loadingTeaser) {
		return (
			<div className="flex h-screen items-center justify-center">
				<Loading color="primary" size={12} />
			</div>
		);
	}

	if (!teaser?.business_teaser_approved) {
		return (
			<Page>
				<div className="relative h-[75vh] max-h-[75vh] w-full">
					<span className="flex h-full flex-col items-center sm:justify-center md:flex-row md:gap-6">
						<h2 className="m-0 max-w-xl text-center text-lg font-medium sm:mb-2 sm:text-2xl">
							Estamos trabalhando no teaser para a sua empresa. Você será notificado assim que
							tivermos uma versão disponível.
						</h2>
					</span>
				</div>
			</Page>
		);
	}

	return (
		<>
			<Page id="pdfDoc">
				<div
					id="container"
					style={{
						backgroundImage: `linear-gradient(45deg, #bb712788, #70893988), url(${TeaserImg})`
					}}
					className="relative min-h-[20rem] w-full rounded-2xl bg-cover bg-center bg-no-repeat"
				>
					<div className="text-neutral-high absolute top-60 left-10 flex items-center gap-2 text-[2rem]  leading-[3rem] text-white">
						<label className="font-bold"> Teaser </label>
						<label className="flex text-lg">
							baixar
							<svg
								onClick={handleDownload}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className={`h-6 w-6 ${
									initialFiles?.includes("teaser.pdf")
										? "cursor-pointer opacity-100"
										: "cursor-not-allowed opacity-50"
								}`}
							>
								<path d="M12 1.5a.75.75 0 01.75.75V7.5h-1.5V2.25A.75.75 0 0112 1.5zM11.25 7.5v5.69l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V7.5h3.75a3 3 0 013 3v9a3 3 0 01-3 3h-9a3 3 0 01-3-3v-9a3 3 0 013-3h3.75z" />
							</svg>
						</label>
					</div>
				</div>
				<section className="mt-12 mb-12 flex justify-center">
					<p className="mb-2 text-5xl font-semibold text-brand-primary">
						{teaser?.business_main_sector} - {teaser?.business_main_subsector}
					</p>
				</section>
				<section className="mb-12 mt-10">
					<p className="mb-2 text-2xl font-semibold text-brand-primary">Overview da campanha</p>
					<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
						<li>
							<p className="text-neutral-medium text-lg font-normal">{teaser?.business_briefing}</p>
						</li>
						<li>
							<span className="text-neutral-medium text-lg font-normal">
								As principais ofertas da Companhia são:{" "}
								<ul className="list-decimal pl-6 marker:text-brand-primaryMedium">
									{teaser?.business_main_products.map((product, index) => {
										return (
											<li key={`${product.product_name + index}`}>
												<p className="text-neutral-medium text-lg font-normal">
													{product.product_name}
												</p>
											</li>
										);
									})}
								</ul>
							</span>
						</li>
					</ul>
				</section>
				<section className="mb-12">
					<p className="mb-2 text-2xl font-semibold text-brand-primary">Tese de investimento</p>
					<ul className="list-decimal pl-6 marker:text-brand-primaryMedium">
						{teaser?.business_differentials.map((differential, index) => {
							return (
								<li key={`${differential + index}`}>
									<p className="text-neutral-medium text-lg font-normal">{differential}</p>
								</li>
							);
						})}
					</ul>
				</section>
				<section className="mb-12">
					<p className="mb-2 text-2xl font-semibold text-brand-primary">Informações Específicas</p>
					<span className="text-neutral-medium text-lg font-normal">
						<ul className="list-disc pl-6 marker:text-brand-primaryMedium">
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									Clientes: {teaser?.business_client_amount}
								</p>
							</li>
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									Funcionários: {teaser?.business_employees_amount}
								</p>
							</li>
						</ul>
					</span>
				</section>
			</Page>
		</>
	);
};

export default TeaserPage;
