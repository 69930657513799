import { FC } from 'react';
import { useTabs } from 'react-headless-tabs';
import Button from 'src/components/Button/Button';
import { TabSelector } from 'src/components/TabSelector';
import Header from 'src/layouts/Logged/components/Header';
import ContractPage from './components/TabsContent/Contract';
import DealPage from './components/TabsContent/Deal';
import history from 'src/services/history';
export interface FindersDashboardPageProps {}

const FinderDashboardPage: FC = () => {
	const [selectedTab, setSelectedTab] = useTabs(['deals', 'contracts']);
	return (
		<>
			<Header
				title="Dashboard"
				img=""
				button={
					<Button
						type="button"
						size="medium"
						className="md:w-[214px] w-full"
						color="secondary"
						onClick={() => history.push('/finder/recommendbusiness')}
					>
						Indicar Empresa
					</Button>
				}
			>
				<nav className="flex justify-around md:justify-start md:px-10 bg-neutral-high border-b border-neutral-medium border-opacity-50 z-50">
					<TabSelector isActive={selectedTab === 'deals'} onClick={() => setSelectedTab('deals')}>
						Deals
					</TabSelector>
					<TabSelector
						isActive={selectedTab === 'contracts'}
						onClick={() => setSelectedTab('contracts')}
					>
						Contratos
					</TabSelector>
				</nav>
			</Header>
			<>
				{selectedTab === 'deals' ? <DealPage /> : selectedTab === 'contracts' && <ContractPage />}
			</>
		</>
	);
};

export default FinderDashboardPage;
