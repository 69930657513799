import { FC } from 'react';
export interface MatchStatusTagProps {
	status: number | string;
}

const Tag: FC<MatchStatusTagProps> = ({ status }) => {
	const STATUS = {
		pending: {
			slug: 'Aguardando aprovação',
			color: ' bg-tags-warning text-brand-warning'
		},
		denied: { slug: 'Negado', color: 'bg-tags-error text-brand-error' },
		approved: { slug: 'Aprovado', color: 'bg-tags-success text-brand-primary' },

		recomendado: {
			slug: 'Aguardando Match',
			color: ' bg-tags-warning text-brand-warning'
		},
		reprovado: { slug: 'Reprovado', color: 'bg-tags-error text-brand-error' },
		aprovado: { slug: 'Aprovado', color: 'bg-tags-success text-brand-primary' },

		false: { slug: 'Aguardando assinatura', color: 'bg-tags-warning text-brand-warning' },
		true: { slug: 'Assinado', color: 'bg-tags-success text-brand-primary' },

		1: { slug: 'Informações Recebidas', color: 'bg-tags-warning text-brand-warning' },
		2: { slug: 'Proposta Apresentada', color: 'bg-tags-warning text-brand-warning' },
		3: { slug: 'Negociação', color: 'bg-tags-warning text-brand-warning' },
		4: { slug: 'Mandato ativo', color: 'bg-tags-warning text-brand-warning' },
		5: { slug: 'Mandato ativo', color: 'bg-tags-warning text-brand-warning' },
		6: { slug: 'Mandato ativo', color: 'bg-tags-warning text-brand-warning' },
		7: { slug: 'Mandato ativo', color: 'bg-tags-warning text-brand-warning' }
	};
	return (
		<>
			<span
				className={` ${STATUS[status].color} w-fit font-normal text-sm flex flex-col justify-center items-start border-rad py-1 px-2 gap-2 h-7 rounded-[100px]`}
			>
				{STATUS[status].slug}
			</span>
		</>
	);
};

export default Tag;
