import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, PlayIcon } from '@heroicons/react/solid';
import { FC, Fragment } from 'react';
import { VideoSectionProps, maVideoProps } from '../../content';

interface DisclosureProps {
	videoSection: VideoSectionProps;
	loading: boolean;
	setLoading: (loading: boolean) => void;
	defaultOpen?: boolean;
	selectedVideo: maVideoProps;
	setSelectedVideo: (video: maVideoProps) => void;
}

const DashboardDisclosure: FC<DisclosureProps> = ({
	videoSection,
	setLoading,
	selectedVideo,
	setSelectedVideo,
	defaultOpen = false
}) => {
	return (
		<Disclosure defaultOpen={defaultOpen}>
			{({ open }) => (
				<Fragment>
					<Fragment>
						<Disclosure.Button className="focus-visible:ring-purple-500 mt-2 flex w-full justify-between rounded-lg bg-brand-secondary p-4 text-left text-sm font-medium text-white shadow-md hover:bg-[#a36021] focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
							<span>{videoSection.title}</span>
							<ChevronDownIcon
								className={`${
									open && 'rotate-180 transform'
								} text-purple-500 h-5 w-5 transition-all`}
							/>
						</Disclosure.Button>
						{videoSection.videos.map((video, index) => (
							<Disclosure.Panel
								key={index}
								className={`text-gray-500 my-1 rounded-md border border-[#ddd] bg-auxiliar-grey-100 p-4 text-sm font-medium text-[#958777] shadow-sm hover:cursor-pointer hover:border-none hover:bg-[#e4ddca] hover:text-brand-secondary
								${selectedVideo.id === video.id && 'border-none bg-[#e4ddca]'}`}
								onClick={() => {
									setLoading(true);
									setSelectedVideo(video);
									setLoading(false);
								}}
							>
								<span
									className={`flex items-center justify-between ${
										selectedVideo.id === video.id && 'text-brand-secondary'
									}`}
								>
									{video.title}
									{selectedVideo.id === video.id && (
										<div className="h-6 w-6 text-[#958777]">
											<PlayIcon />
										</div>
									)}
								</span>
							</Disclosure.Panel>
						))}
					</Fragment>
				</Fragment>
			)}
		</Disclosure>
	);
};

export default DashboardDisclosure;
