import { FC, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import OverALogo from "../OverALogo";
import UserMenu from "./components/UserMenu";
import { getUserMenuItems } from "./configs/userConfigs";
import DesktopMenuContainer from "./styled/DesktopMenuContainer";
import {
	menuItemActiveClasses,
	menuItemClasses,
	menuItemDefaultClasses,
	wrapContentClasses
} from "./styled/elementsStyles";
import { useChatMessages } from "src/hooks/useChatMessages";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

const SidebarMenuDesktop: FC = () => {
	const location = useLocation();
	const { user, hasNewMessages } = useAuth();
	const { setNewChatMessages } = useChatMessages();

	useEffect(() => {
		const { pathname } = location;

		if (user)
			setNewChatMessages(
				user?.role,
				user.igc_user_id ? user.igc_user_id : user?.business_id,
				pathname
			);
	}, [location]);

	return (
		<DesktopMenuContainer>
			{}
			<div className={wrapContentClasses}>
				<div className="w-full">
					<Link to={"/"}>
						<OverALogo color="light" />
					</Link>
				</div>
				<nav className="mt-5 flex-1 space-y-1">
					{getUserMenuItems(user?.role).map((item) => (
						<Link
							key={item.name}
							to={item.toPath}
							className={classNames(
								item.name === "chat" && hasNewMessages ? "text-red-500" : "text-white",
								location.pathname === item.toPath ? menuItemActiveClasses : menuItemDefaultClasses,
								menuItemClasses
							)}
						>
							{item.icon}
							{item.label}
						</Link>
					))}
				</nav>
			</div>
			<UserMenu />
		</DesktopMenuContainer>
	);
};

export default SidebarMenuDesktop;
