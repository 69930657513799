import { FC } from 'react';
import InputField from '../base/input';
import { Input } from './models';

const TradeNameField: FC<Input> = ({ onChange, initialValue }) => {
	const validateName = (name: string) => {
		return name.length >= 3;
	};

	return (
		<>
			<InputField
				initialValue={initialValue}
				fieldLabel="Nome fantasia"
				type="text"
				id="name"
				placeholder="Digite o nome fantasia da empresa"
				errorText=""
				validateValue={validateName}
				onChange={onChange}
			/>
		</>
	);
};

export default TradeNameField;
