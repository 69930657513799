import { FC } from 'react';
import { formatCnpj } from '../../../helpers';
import InputField from '../base/input';
import { Input } from './models';

const CNPJField: FC<Input> = ({ onChange, initialValue }) => {
	const handleMask = (CNPJ: string) => {
		return formatCnpj(CNPJ);
	};

	 function validateCNPJ(cnpj: string) {
		if (!cnpj) return false;

		if (cnpj.length > 18) return false;

		const digitsOnly = /^\d{14}$/.test(cnpj)
		const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(cnpj);

		if (!digitsOnly && !validFormat) return false;

		const match = cnpj.match(/\d/g);
		const numbers = Array.isArray(match) ? match.map(Number) : [];

		if (numbers.length !== 14) return false;
		
		const items = [...numbers]
		if (items.length === 1) return false;

		const calc = (x: number) => {
			const slice = numbers.slice(0, x);
			let factor = x - 7;
			let sum = 0;

			for (let i = x; i >= 1; i--) {
				const n = slice[x - i];
				sum += n * factor--;
				if (factor < 2) factor = 9;
			}
			
			const result = 11 - (sum % 11);

			return result > 9 ? 0 : result;
		};

		const digits = numbers.slice(12);
	
		const digit0 = calc(12);
		if (digit0 !== digits[0]) return false;
		const digit1 = calc(13);

		return digit1 === digits[1];
	};

	return (
		<>
			<InputField
				initialValue={initialValue}
				fieldLabel="CNPJ"
				type="text"
				id="CNPJ"
				placeholder="Digite seu CNPJ"
				errorText="Verifique se o CNPJ foi digitado corretamente"
				useMask={true}
				mask={handleMask}
				validateValue={validateCNPJ}
				onChange={onChange}
			/>
		</>
	);
};

export default CNPJField;
