import { FC, useEffect, useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { FileMetadata, getFileFromStorage, getNameById } from "src/services/firebase";
import { ptBR } from "date-fns/locale";
import { formatDistanceToNow } from "date-fns";
import { DownloadIcon } from "@heroicons/react/outline";
import download from "downloadjs";
interface IChatMessageProps {
	message: string;
	createdAt: number;
	senderId: number;
	senderRole: "igc" | "business";
	chatId?: number;
}

const ChatMessage: FC<IChatMessageProps> = ({ message, createdAt, senderId, senderRole, chatId }) => {
	const { user } = useAuth();
	const [senderName, setSenderName] = useState<string | null>(null);
	const date = new Date(createdAt);
	const isAdmin = user?.role === "igc";
	const [messageFormat, setMessageFormat] = useState<"text" | "image" | "file">("text");
	const [file, setFile] = useState<FileMetadata>();

	// @ts-ignore
	const userId = isAdmin ? user?.igc_user_id : user?.business_id;

	const fetchName = async () => {
		if (!user) return null;
		const name = await getNameById(senderId);
		setSenderName(name);
	};

	async function handleImageFormat(message: string) {
		const messageFormat = message.split(":")[0];

		if (messageFormat === "image" || messageFormat === "file") {
			setMessageFormat(messageFormat);

			const file = await getFileFromStorage(message.split(":")[1], chatId ?? user?.business_id);

			setFile(file);
		}
	}

	async function downloadFile(url: string | undefined, fileName: string | undefined, contentType: string | undefined) {
		if (!url || !fileName || !contentType) return;

		const fileFetched = await fetch(url);

		const fileBlob = await fileFetched.blob();

		download(fileBlob, fileName, contentType);
	}

	useEffect(() => {
		fetchName();
		handleImageFormat(message);
	}, []);

	const sameUserMessage = userId === senderId && user?.role === senderRole;

	return (
		<>
			<li className={`chat ${sameUserMessage ? "chat-end" : "chat-start"}`}>
				<div className={`chat-header ${sameUserMessage ? "text-brand-secondary" : "text-brand"}`}>
					{senderName}{" "}
					<time
						className={`text-xs opacity-50
            ${sameUserMessage ? "text-brand-secondary" : "text-brand"} `}
					>
						{formatDistanceToNow(date, { locale: ptBR })}
					</time>
				</div>
				<div
					className={`chat-bubble whitespace-pre-wrap break-words bg-opacity-90 text-white
          ${sameUserMessage ? "bg-brand-secondaryMedium" : "bg-brand-primaryMedium"}`}
				>
					{messageFormat === "image" && (
						<img
							src={file?.url}
							alt="Imagem"
							className="max-h-96 max-w-md cursor-pointer"
							onClick={() => downloadFile(file?.url, file?.name, file?.contentType)}
						/>
					)}
					{messageFormat === "file" && (
						<p className="flex items-center font-bold">
							{file?.name}
							<span>
								<DownloadIcon
									height={20}
									className="cursor-pointer"
									onClick={() => downloadFile(file?.url, file?.name, file?.contentType)}
								/>
							</span>
						</p>
					)}
					{messageFormat === "text" && message}
				</div>
			</li>
		</>
	);
};

export default ChatMessage;
