import { SearchIcon } from '@heroicons/react/outline';
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import Loading from 'src/assets/icons/Loading';
import Button from 'src/components/Button/Button';
import CollaboratorModal from 'src/components/CollaboratorModal';
import Input from 'src/components/Input';
import Modal from 'src/components/Modal';
import Pagination from 'src/components/Pagination';
import { Selectable } from 'src/components/ui/form/models';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import { Collaborator } from 'src/domain/Collaborator';
import { useAuth } from 'src/hooks/useAuth';
import Header from 'src/layouts/Logged/components/Header';
import Page from 'src/layouts/Logged/components/Page';
import { getCollaborators } from 'src/services/collaborator/getCollaborators';
export interface IgcCollaboratorProps {}

const IgcCollaborator: FC = () => {
	const [loadingCollaborators, setLoadingCollaborators] = useState<boolean>(true);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [total, setTotal] = useState<number>(1);
	const [collaborators, setCollaborators] = useState<Array<Collaborator>>([]);
	const [originalCollaborators, setOriginalCollaborators] = useState<Array<Collaborator>>([]);
	const { user } = useAuth();
	const pageLimit = 10;
	const [filter, setFilter] = useState<Selectable>({
		id: 1,
		name: 'Todos',
		value: null
	});
	const [filterInput, setFilterInput] = useState<string>('');

	const [isOpenCollaboratorModal, setOpenCollaboratorModal] = useState<boolean>(false);
	const [isOpenDeleteCollaboratorModal, setIsOpenDeleteCollaboratorModal] = useState<boolean>(false);

	const [collaboratorSelected, setCollaboratorSelected] = useState<Collaborator | undefined>(undefined);

	const options: Array<Selectable> = [
		{
			id: 1,
			name: 'Todos',
			value: null
		}
	];

	const handleFiltering = (filter: Selectable) => {
		setFilter(filter);
		setLoadingCollaborators(true);
		setCollaborators(searchTable(filter.value));
	};

	const handleFilteringInput = (filter: string) => {
		setFilterInput(filter);
		setLoadingCollaborators(true);
		setCollaborators(searchTableInput(filter));
	};

	const searchTable = (userType: string): Array<Collaborator> => {
		setTimeout(() => {
			setLoadingCollaborators(false);
		}, 500);
		if (userType === null) {
			return originalCollaborators;
		}
		if (originalCollaborators) {
			return originalCollaborators.filter(
				(collaborator) => collaborator.igc_user_type === userType
			);
		}
		return [];
	};

	const searchTableInput = (filter: string): Array<Collaborator> => {
		setTimeout(() => {
			setLoadingCollaborators(false);
		}, 500);
		if (filter === '') {
			return onPageChanged(originalCollaborators);
		}
		filter = filter.toLowerCase();
		if (originalCollaborators) {
			return onPageChanged(
				originalCollaborators.filter((collaborator) => {
					if (
						collaborator.full_name.toLowerCase().includes(filter) ||
						collaborator.email.toLowerCase().includes(filter)
					) {
						return collaborator;
					}
					return '';
				})
			);
		}
		return onPageChanged([]);
	};

	useEffect(() => {
		getCollaborators(String(user?.role))
			.then((response) => {
				setTotal(response.data.length);
				setCollaborators(onPageChanged(response.data));
				setOriginalCollaborators(response.data);
			})
			.finally(() => {
				setLoadingCollaborators(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onPageChanged = (value: Array<Collaborator>) => {
		setTotal(value.length);
		const offset = (currentPage - 1) * pageLimit;
		const currentCompanies = value.slice(offset, offset + pageLimit);
		return currentCompanies;
	};
	useEffect(() => {
		setLoadingCollaborators(true);
		setCollaborators(onPageChanged(originalCollaborators));
		setTimeout(() => {
			setLoadingCollaborators(false);
		}, 500);
		// setOriginalCollaborators(onPageChanged(collaborators));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);
	return (
		<>
			<Header
				title={'Lista de Colaboradores'}
				img=""
				button={
					<div className="w-96" >
						
						<SingleSelect
							placeholder="Filtrar por"
							options={options}
							value={filter}
							onChange={(e: Selectable) => handleFiltering(e)}
						/>
					</div>
				}
			/>

			{isOpenCollaboratorModal && (
				<CollaboratorModal
					isOpen={isOpenCollaboratorModal}
					onClose={setOpenCollaboratorModal}
					collaborator={collaboratorSelected}
				/>
			)}

			{isOpenDeleteCollaboratorModal && (
				<Modal 
					title=""
					onClose={setIsOpenDeleteCollaboratorModal}
					open={isOpenDeleteCollaboratorModal}
					size="xsmall"
					description='Tem certeza que deseja excluir este colaborador?'
				>
					<div className='grid grid-cols-2 items-center justify-center gap-2'>
						<Button 
							color='outline-secondary'
							onClick={() => setIsOpenDeleteCollaboratorModal(false)} 
							className='h-12'
						> 
							Cancelar 
						</Button>
						<Button className='h-12'> Excluir </Button>
					</div>
				</Modal>
			)}
{/* className="grid grid-cols-2 items-center justify-center gap-2" */}
			<Page>
				<div className="w-full mb-6 flex justify-between items-center flex-col md:flex-row">
					<div className="w-full sm:w-96">
						<Input
							placeholder="Pesquisar"
							prefixIcon={<SearchIcon color="text-neutral-medium" height={20} />}
							error={false}
							errorMessage={''}
							value={filterInput}
							onChange={(e) => {
								handleFilteringInput(e.currentTarget.value);
							}}
						/>
					</div>
					<Button 
						size='small'
						onClick={() => {
							setCollaboratorSelected(undefined);
							setOpenCollaboratorModal(true)
						}}>
						Adicionar Colaborador
					</Button>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="w-full">
						<thead>
							<tr>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Colaborador
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Email
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Status login
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Função
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Ações
								</th>
							</tr>
						</thead>
						<tbody className=" divide-y divide-neutral-medium divide-opacity-50">
							{collaborators && collaborators.length === 0 && !loadingCollaborators ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										Nenhum colaborador encontrado
									</td>
								</tr>
							) : loadingCollaborators ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										<Loading color="primary" size={12} />
									</td>
								</tr>
							) : (
								collaborators?.map((collaborator, index) => (
									<tr key={index}>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="flex items-center justify-start">
												<div className="text-sm font-normal text-left text-neutral-medium">
													{collaborator.full_name}
												</div>
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{collaborator.email}
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{collaborator.last_login
													? moment(collaborator.last_login).locale('pt-br').format('DD MMM YYYY')
													: ''}
											</div>
										</td>
										<td className=" pr-6 py-4 whitespace-nowrap text-sm font-normal text-neutral-medium">
											{collaborator.igc_user_type}
										</td>
										<td className="flex pr-6 py-4 whitespace-nowrap ">
											<PencilIcon 
												onClick={() => {
													setOpenCollaboratorModal(true);
													setCollaboratorSelected(collaborator);
												}}
												color="text-neutral-medium" 
												height={24} 
												className='mr-2 cursor-pointer' 
											/>
											<TrashIcon 
												onClick={() => {
													setIsOpenDeleteCollaboratorModal(true);
													setCollaboratorSelected(collaborator);
												}}
												color="text-neutral-medium" 
												height={24} 
												className='cursor-pointer'
											/>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
					{collaborators.length > 0 && (
						<Pagination
							currentPage={currentPage}
							total={total}
							pageLimit={pageLimit}
							setCurrentPage={setCurrentPage}
							loading={loadingCollaborators}
						/>
					)}
				</div>
			</Page>
		</>
	);
};

export default IgcCollaborator;
