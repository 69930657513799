
import { ErrorMessage, Field } from "formik";

const PreRegisterInput = ({ label, name, placeholder, type, responsiveStyle }) => {
    return (
        <div className={responsiveStyle}>
            <label htmlFor={name} className="mb-2 block text-sm font-medium text-auxiliar-highlight-orange">
                {label}
            </label>
            <Field
                className="w-full rounded-lg border border-b-auxiliar-grey-500 px-4 py-3 text-sm"
                type={type}
                id={name}
                name={name}
                placeholder={placeholder}
            />
            <ErrorMessage className="text-[12px] text-red-500" name={name} component="p" />
        </div>
    );
};

export default PreRegisterInput;
