import {
	CompanyClientAmount,
	CompanyDifferentials,
	CompanyEmployeesAmount,
	CompanyMainProducts,
	CompanySummary
} from "../../components/Steps";
import CompanyAuditedBy from "../../components/Steps/CompanyAuditedBy";
import CompanyCompetitors from "../../components/Steps/CompanyCompetitors";
import CompanyDebtAmount from "../../components/Steps/CompanyDebtAmount";
import CompanyDocs from "../../components/Steps/CompanyDocs";
import CompanyInterestedInvestors from "../../components/Steps/CompanyInterestedInvestors";
import CompanyLeases from "../../components/Steps/CompanyLeases";
import CompanyLocal from "../../components/Steps/CompanyLocal";
import CompanyMainClients from "../../components/Steps/CompanyMainClients";
import CompanyPartners from "../../components/Steps/CompanyPartners";
import CompanyPotencialInvestors from "../../components/Steps/CompanyPotencialInvestors";
import CompanyPurposeSale from "../../components/Steps/CompanyPurposeSale";
import CompanySalesTypes from "../../components/Steps/CompanySalesTypes";
import CompanyStates from "../../components/Steps/CompanyStates";
import CompanyTimeline from "../../components/Steps/CompanyTimeline";
import { FieldData } from "../models";

const defaultSteps: Array<FieldData> = [
	{
		stepNumber: 1,
		title: "Nos dê um breve resumo da empresa",
		description:
			"Você pode incluir informações sobre o ramo de atuação da empresa, o modelo de negócios, principais acontecimentos (marcos como clientes importantes, abertura de unidades, expansão regional, nova planta, etc) e números atuais (como número de clientes, de funcionários, de unidades, etc).",
		component: CompanySummary
	},
	{
		stepNumber: 2,
		title: "Quais são os diferenciais do seu negócio?",
		description:
			"O que o torna especial? Quanto mais detalhes puder compartilhar (e com números, se aplicável), mais completa fica a análise. Fatores que podem ser diferenciais são: tecnologia inovadora, localizações privilegiadas, marca reconhecida, etc.",
		maxInputs: 10,
		component: CompanyDifferentials
	},
	{
		stepNumber: 3,
		title: "Linha do tempo da empresa:",
		description:
			"Adicione fatos relevantes da sua história. Exemplos de eventos: Clientes ou número de clientes expressivo(s); Expansão regional; Recebimento de investimento. (adicione uma nova entrada caso tenha mais de uma opção).",
		component: CompanyTimeline,
		maxInputs: 15
	},
	{
		stepNumber: 4,
		title: "Qual é o objetivo da transação almejada?",
		description: "Marque todas as opções que se aplicam:",
		component: CompanyPurposeSale
	},
	{
		stepNumber: 5,
		title: "Algum investidor já manifestou interesse na empresa previamente?",
		description: "",
		component: CompanyInterestedInvestors,
		maxInputs: 10
	},
	{
		stepNumber: 6,
		title: "Quais investidores poderiam se interessar por investir na sua empresa? Liste quais empresas.",
		optional: true,
		maxInputs: 10,
		component: CompanyPotencialInvestors
	},
	{
		stepNumber: 7,
		title: "Quais são os principais concorrentes da sua empresa?",
		component: CompanyCompetitors
	},
	{
		stepNumber: 8,
		title: "Documentos financeiros da sua empresa",
		description:
			"Precisamos dos balancetes da sua companhia conforme instruções para gerar o Balanço Patrimonial e o DRE (Demonstrativo de Resultado do Exercício) da sua empresa. Recomendamos que os documentos sejam enviados em formato excel.",
		component: CompanyDocs
	},
	{
		stepNumber: 9,
		title: "Qual é o endividamento da companhia?",
		component: CompanyDebtAmount
	},
	{
		stepNumber: 10,
		title: "A empresa é auditada?",
		component: CompanyAuditedBy
	},
	{
		stepNumber: 11,
		title: "Qual foi o número de clientes da empresa no ano passado?",
		component: CompanyClientAmount
	},
	{
		stepNumber: 12,
		title:
			"Se algum deles representou mais do que 2% das vendas, liste abaixo o nome desses clientes e o percentual correspondente.",
		optional: true,
		component: CompanyMainClients
	},
	{
		stepNumber: 13,
		title: "Como é a estrutura societária da empresa?",
		description: "Indique os sócios da sua empresa e a porcentagem de participação de cada um. ",
		component: CompanyPartners
	},
	{
		stepNumber: 14,
		title: "Quantos funcionários são regime CLT?",
		component: CompanyEmployeesAmount
	},
	{
		stepNumber: 15,
		title: "Em qual estado e cidade fica a sede da sua empresa?",
		component: CompanyLocal
	},
	{
		stepNumber: 16,
		title: "A empresa possui operação em outros estados?",
		component: CompanyStates,
		optional: true
	},
	{
		stepNumber: 17,
		title: "Os imóveis da empresa são próprios ou alugados?",
		optional: false,
		component: CompanyLeases
	},
	{
		stepNumber: 18,
		title:
			"Quais os principais produtos e/ou serviços que a empresa oferece? Qual a porcentagem de cada um deles na receita total? Inclua apenas aqueles que representam 2% ou mais da receita.",
		description: "Exemplo: iPhone - 57%; Serviços - 15%; MacBook - 9%; iPad - 6%; Outros - 13%.",
		component: CompanyMainProducts
	},
	{
		stepNumber: 19,
		title:
			"Quais são os canais de distribuição para os produtos chegarem até o consumidor final? E qual porcentagem de cada canal?",
		description: "Exemplo: Venda online - 40%; Site próprio - 20%; Lojas físicas revendedoras - 40%.",
		component: CompanySalesTypes,
		isLast: true
	}
];

export default defaultSteps;
