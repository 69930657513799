export const tabs = [
	{
		id: 'us',
		title: 'Quem somos?',
		content:
			'Oferecemos a agentes de negócio a oportunidade de monetizar sua carteira de contatos em parceria com a IGC de um jeito fácil e inteligente. '
	},
	{
		id: 'finder',
		title: 'Quem é o Finder?',
		content:
			'Buscamos em nossos Finders pessoas bem relacionadas e com know-how de determinado setor e determinadas regiões do país.'
	},
	{
		id: 'start',
		title: 'Vamos começar?',
		content:
			'Agora você está pronto para começar a colaborar com a OverA. Dê o primeiro passo na nossa parceria agora mesmo.'
	}
];

export const getTabContent = (index: number) => {
	return tabs[index].content;
};
