import { TrashIcon } from "@heroicons/react/outline";
import { BaseSyntheticEvent, FC } from "react";
import { Controller, ControllerRenderProps, useFieldArray } from "react-hook-form";
import Button from "src/components/Button/Button";
import { BusinessMainClient, ExtendedRegistrationForm } from "src/domain/Register";
import Input from "../custom-components/Input";
import { Step } from "../../configs/models";

const initialData: BusinessMainClient = {
	name: "",
	share: null
};

const CompanyMainClients: FC<Step> = ({
	onSubmit,
	getStepData,
	maxInputs,
	loadingStep,
	control,
	register,
	errors,
	setValue
}) => {
	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "business_main_clients"
	});

	function handleOnChange(
		event: BaseSyntheticEvent,
		field: ControllerRenderProps<ExtendedRegistrationForm, `business_main_clients.${number}.share`>
	) {
		const { target } = event;

		if (Number(target.value) > 100)
			return setValue(field.name, 100, {
				shouldDirty: true,
				shouldValidate: true
			});

		return setValue(field.name, Number(target.value), {
			shouldDirty: true,
			shouldValidate: true
		});
	}

	return (
		<>
			<ul className="mb-6 w-full">
				{fields.map((field, index) => (
					<li className="mb-3 flex w-full" key={index}>
						<div className="mr-3 w-[80%]">
							<Input
								{...field}
								type="text"
								placeholder="Digite sua resposta"
								{...register(`business_main_clients.${index}.name`)}
								fieldError={errors.business_main_clients?.[index]?.name}
							/>
						</div>
						<div className="relative mr-1 w-[13%]">
							<Controller
								control={control}
								name={`business_main_clients.${index}.share`}
								render={({ field, fieldState }) => (
									<Input
										{...field}
										type="number"
										onChange={(e) => handleOnChange(e, field)}
										value={field.value ? field.value : undefined}
										fieldError={fieldState.error}
										sufix="%"
										ref={field.ref}
									/>
								)}
							/>
						</div>
						<div className="flex w-6 items-center">
							<TrashIcon className="cursor-pointer" onClick={() => remove(index)} color="#bb7127" />
						</div>
					</li>
				))}
			</ul>

			<div className="flex w-full">
				<Button
					type="button"
					className={`mr-3`}
					color="outline-secondary"
					size="large"
					disabled={maxInputs === fields.length}
					onClick={() => append(initialData)}
				>
					Nova Entrada
				</Button>
				<Button
					loading={loadingStep}
					size="large"
					disabled={Boolean(errors.business_main_clients)}
					onClick={(e) => {
						e.preventDefault();

						return onSubmit({ business_main_clients: getStepData("business_main_clients") });
					}}
				>
					Próximo
				</Button>
			</div>
		</>
	);
};

export default CompanyMainClients;
