import { Template } from 'src/domain/Template';
import client from '../client';
export const getTemplate = async (business_id: number) => {
	const url = `igc/template_email_business/`;
	return client.get<Template>(url, {
		params: {
			business_id: business_id
		}
	});
};
