import { removeSpecialChars } from '../../helpers';

export const formatFinderForm = (
    registerForm: any
) => {

    const userName = registerForm.email
    const [firstName, ...last_name_parts] = registerForm.name.split('-');
    const lastName = last_name_parts.join(" ")
    const cpf = removeSpecialChars(registerForm.cpf)
    const phone = removeSpecialChars(registerForm.phone)
    const requestBody = JSON.stringify({
        username: userName,
        first_name: firstName,
        last_name: lastName,
        email: registerForm.email,
        password: registerForm.password,
        phone: phone,
        cpf: cpf,
        cnpj: registerForm.cnpj || "",
        activity_sector: registerForm.sector,
        city: registerForm.city,
        state: registerForm.uf
    });
    return requestBody
};
