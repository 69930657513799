import {
	CheckCircleIcon,
	ExclamationCircleIcon,
	ExclamationIcon,
	LinkIcon
} from '@heroicons/react/solid';
import { FC } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { IFile, useFiles } from 'src/contexts/files';
interface FileListProps {}
const FileList: FC<FileListProps> = () => {
	const { uploadedFiles: files, deleteFile } = useFiles();

	return (
		<>
			<ul className="mt-2">
				{files.map((uploadedFile: IFile) => (
					<li className="flex justify-between items-center text-[#444] mt-4" key={uploadedFile.id}>
						<div className="flex items-center">
							<div className="flex flex-col">
								<strong>{uploadedFile.name}</strong>
								<span className="text-xs text-[#999] mt-1">
									{uploadedFile.readableSize}
									{uploadedFile.waiting && (
										<button
											className="border-0 bg-transparent text-[#e57878] ml-1 cursor-pointer"
											onClick={(e) => deleteFile(uploadedFile.id)}
										>
											Excluir
										</button>
									)}
								</span>
							</div>
						</div>

						<div>
							{!uploadedFile.uploaded && !uploadedFile.error && !uploadedFile.waiting && (
								<CircularProgressbarWithChildren
									styles={{
										root: { width: 24 },
										path: { stroke: '#bb7127' }
									}}
									value={uploadedFile.progress || 0}
									strokeWidth={10}
									className="relative"
								>
									<div
										className={`absolute top-1 text-xs ${
											Number(uploadedFile.progress) >= 0 && Number(uploadedFile.progress) < 10
												? 'left-2'
												: 'left-1'
										}`}
									>
										<strong>{String(uploadedFile.progress)}</strong>
									</div>
								</CircularProgressbarWithChildren>
							)}

							{uploadedFile.url && !uploadedFile.waiting && (
								<a href={uploadedFile.url} target="_blank" rel="noopener noreferrer">
									<LinkIcon style={{ marginRight: 8 }} height={24} color="#222" />
								</a>
							)}

							{uploadedFile.uploaded && <CheckCircleIcon height={24} color="#78e5d5" />}
							{uploadedFile.error && <ExclamationCircleIcon height={24} color="#e57878" />}
							{uploadedFile.waiting && <ExclamationIcon height={24} color="#e5e378" />}
						</div>
					</li>
				))}
			</ul>
		</>
	);
};

export default FileList;
