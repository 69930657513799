const Notifications = () => {
	return (
		<div className="text-medium relative w-full overflow-y-hidden rounded-xl border-2 border-brand-secondary border-opacity-30 p-2 shadow-lg lg:h-1/2">
			<h4 className="mb-2 border-b border-brand-secondary border-opacity-20 font-medium text-brand-secondary">
				Quadro de avisos
			</h4>
			<div className="flex h-4/5 mb-20 xl:mb-0 flex-col items-center justify-center overflow-y-auto">
				<div className="max-h-full overflow-y-auto">Nenhum aviso disponível no momento.</div>
			</div>
		</div>
	);
};

export default Notifications;
