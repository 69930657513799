import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { postInvestor } from 'src/domain/Investor';
import { formatCnpj, removeLetters } from 'src/helpers';
import { getSubSectors } from 'src/services/domain/getSubSectors';
import * as Yup from 'yup';
import Button from '../Button/Button';
import Input from '../Input';
import Modal from '../Modal';
import { Selectable } from '../ui/form/models';
import SingleSelect from '../ui/form/Selects/SingleSelect';
import { Collaborator } from 'src/domain/Collaborator';

interface params {
	isOpen: boolean;
	onClose: Dispatch<SetStateAction<boolean>>;
	collaborator?: Collaborator;
}

function CollaboratorModal({ isOpen, onClose, collaborator }: params) {
	const [functionSelected, setFunctionSelected] = useState<Selectable>();
	const [functionOptions, setFunctionOptions] = useState<Selectable[]>([{
		id: 0,
		name: 'admin',
		value: 'admin'
	}]);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Campo obrigatório'),
		email: Yup.string().required('Campo obrigatório'),
		igc_user_type: Yup.string().required('Campo obrigatório'),
	});

	const formik = useFormik({
		initialValues: {
			full_name: '',
			email: '',
			igc_user_type: ''
		},
		validationSchema: validationSchema,
		validateOnMount: true,
		enableReinitialize: true,
		onSubmit: (values) => {
		}
	});

	useEffect(() => {
		if (collaborator) {
			const { email, full_name, igc_user_type } = collaborator;
			formik.setValues({
				email,
				full_name,
				igc_user_type
			})
			setFunctionSelected(functionOptions.find(f => f.value === igc_user_type));
		}
	}, [collaborator])

	return (
		<Modal
			title={`${collaborator ? 'Editar' : 'Adicionar'} Colaborador`}
			onClose={onClose}
			open={isOpen}
		>
			<form onSubmit={formik.handleSubmit}>
				<h2 className="mb-1 text-lg font-semibold text-brand-primary">Nome:</h2>
				<Input
					value={formik.values.full_name}
					onChange={formik.handleChange('full_name')}
					error={Boolean(formik.touched.full_name && formik.errors.full_name)}
					errorMessage={formik.errors.full_name}
				/>
				<h2 className="mb-1 text-lg font-semibold text-brand-primary">Email:</h2>
				<Input
					value={formik.values.email}
					onChange={formik.handleChange('email')}
					error={Boolean(formik.touched.email && formik.errors.email)}
					errorMessage={formik.errors.email}
				/>
				<h2 className="mb-1 text-lg font-semibold text-brand-primary">Função:</h2>
				<SingleSelect
					placeholder="Função"
					options={functionOptions}
					value={functionSelected}
					onChange={(value) => {
						setFunctionSelected(value);
						formik.setFieldValue('igc_user_type', value);
					}}
				/>
				<Button className="mt-3 mb-4" type="submit">
					{collaborator ? 'Editar' : 'Adicionar'} 
				</Button>
			</form>
		</Modal>
	);
}

export default CollaboratorModal;
