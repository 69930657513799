import { FC, FormEvent, useState } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import Button from 'src/components/Button/Button';
import Logo from '../images/Logo.png';
import OverA from 'src/assets/images/over_a_marca_01.png';
import EmailField from '../../../components/ui/form/EmailField';
import PasswordField from '../../../components/ui/form/PasswordField';
import { Link } from 'react-router-dom';

export interface IFinderLoginProps {}

const FinderLogin: FC<IFinderLoginProps> = () => {
	const { login } = useAuth();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const role = 'finder';

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		setLoading(true);
		login(email, password, role);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const isDisabled = (): boolean | undefined => {
		return !email || !password;
	};

	return (
		<div>
			<form onSubmit={handleSubmit} className=" flex flex-col">
				<div className=" flex justify-center items-center mb-12">
					<img className="text-center" height={45} width={184} src={OverA} alt="overa" />
				</div>
				<p className="font-bold mb-6 text-2xl text-neutral-low">Entrar</p>
				<div className="block mb-8">
					<EmailField onChange={(val: any) => setEmail(val)} />
				</div>
				<div className="block mb-8">
					<PasswordField onChange={(val: any) => setPassword(val)} showRules={true} />
				</div>
				<div>
					<Button type="submit" color="secondary" loading={loading} disabled={isDisabled()}>
						Entrar
					</Button>
				</div>
				<p className="text-center mt-4">
					Esqueceu sua senha?{' '}
					<Link to="/finder/reset_password" className="text-brand-secondary">
						Redefinir	
					</Link>
					<br></br>
					Novo na OverA?{' '}
					<Link to="/finder/register" className="text-brand-secondary">
						Cadastre-se
					</Link>
				</p>

				<div className="flex justify-center items-center mt-20">
					{' '}
					<img src={Logo} alt="man on the phone" />
				</div>
			</form>
		</div>
	);
};

export default FinderLogin;
