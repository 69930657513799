import { FC } from 'react';
import { removeLetters } from 'src/helpers';
import { formatMoney } from 'src/helpers/formatMoney';
import InputField from '../base/input';
import { Input } from './models';

const FinanceReportField: FC<Input> = ({ onChange, parameters, initialValue }) => {
	const handleMask = (money: string) => {
		if (money !== '') return formatMoney(money);
		else return '';
	};

	const validateValue = (money: string) => {
		if (money !== '') return !isNaN(parseInt(removeLetters(money)));
		if (parameters?.optional === true) return true;
		return false;
	};

	const customText = () => (parameters.customText ? ` ${parameters.customText}` : '');

	const fieldLabel = () => {
		if (parameters.type === 'ebitda') {
			return `EBITDA ${parameters.year}`;
		} else if (parameters.type === 'liquid_revenue') {
			return `Receita Líquida ${parameters.year}`;
		}
		return `Dado Financeiro ${parameters.year}`;
	};
	const isOptional = () => {
		if (parameters?.optional === true) {
			return ' (opcional)';
		}
		return '';
	};
	
	return (
		<>
			<InputField
				isMoney={true}
				initialValue={initialValue}
				fieldLabel={fieldLabel() + isOptional() + customText()}
				type="text"
				id={`finance_report_${parameters.type}_${parameters.year}`}
				placeholder="Digite o valor"
				errorText="Verifique se o valor foi digitado corretamente"
				useMask={true}
				mask={handleMask}
				validateValue={validateValue}
				onChange={onChange}
				showTip={parameters.showTip}
				tipMethod={parameters.tipMethod}
			/>
		</>
	);
};

export default FinanceReportField;
