import { FC, useState } from 'react';
import Select from '../../base/select';
import { Input, Selectable } from '../models';

const PoliticallyExposedSelect: FC<Input> = ({ onChange }) => {
	const [options] = useState<Selectable[]>([
		{
			name: 'Sim',
			id: 1,
			value: true
		},
		{
			name: 'Não',
			id: 2,
			value: false
		}
	]);

	const handleChange = (selected: Selectable[]) => {
		onChange(selected[0].value, true);
	};

	return (
		<>
			<Select
				fieldLabel="Você é uma pessoa politicamente exposta?"
				selectableValues={options}
				singleSelect={true}
				onChange={handleChange}
			/>
		</>
	);
};

export default PoliticallyExposedSelect;
