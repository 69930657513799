import {
	CheckCircleIcon,
	ExclamationCircleIcon,
	EyeIcon,
	QuestionMarkCircleIcon
} from '@heroicons/react/solid';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import './style.css';

//declaring props outside the FC for organization purposes
type Props = {
	fieldLabel?: string;
	type: string;
	id: string;
	placeholder: string;
	errorText?: string;
	validateValue: any;
	useMask?: boolean;
	mask?: any;
	onChange: any;
	optional?: boolean;
	showIcon?: boolean;
	isPassword?: boolean;
	initialValue?: string;
	color?: string;
	isMoney?: boolean;
	showTip?: boolean;
	tipMethod?: any;
	disabled?: boolean;
	maxLength?: number;
};

const InputField: FC<PropsWithChildren<Props>> = ({
	fieldLabel,
	type,
	id,
	placeholder,
	errorText,
	validateValue,
	useMask,
	mask,
	onChange,
	optional,
	color = 'light',
	showIcon = true,
	isPassword = false,
	initialValue = '',
	isMoney = false,
	showTip = false,
	tipMethod = () => {},
	disabled = false,
	maxLength
}) => {
	const [isValidValue, setIsValidValue] = useState<boolean>(true);
	const [isTyping, setIsTyping] = useState<boolean>(false);
	const [value, setValue] = useState(initialValue);
	const [validValue, setValidValue] = useState<any>();
	const [passwordShown, setPasswordShown] = useState<boolean>(false);
	const [disabledColor, setDisabledColor] = useState<string>('');

	useUpdateEffect(() => {
		if (validateValue(value) || (value === '' && optional === true)) {
			setValidValue(value);
		} else {
			setValidValue(undefined);
		}
	}, [isValidValue, value]);

	useUpdateEffect(() => {
		onChange(validValue, isValidValue);
	}, [validValue, isValidValue]);

	const handleChange = (e: any) => {
		setIsTyping(true);
		const newValue = e.target.value;
		const val = useMask ? mask(newValue) : newValue;
		setValue(val);
		setIsValidValue(validateValue(val));
	};

	const onBlur = () => {
		validateFlow();
	};

	const validateFlow = () => {
		setIsValidValue(validateValue(value) || (value === '' && optional === true));
		setValue(value);
		setIsTyping(false);
		onChange(value, isValidValue);
	};

	useEffect(() => {
		disabled ? setDisabledColor('#CCCCCC') : setDisabledColor('');
	}, [disabled]);

	//alternate state when clicking the showPassword "eye" button
	const togglePassword = () => {
		setPasswordShown((prevPasswordShown) => !prevPasswordShown);
	};

	const handleErrorMessage = () => {
		return value === '' ? 'Insira um valor válido' : errorText;
	};

	const getColor = () => {
		const colors = {
			light: {
				bg: 'bg-white',
				text: 'text-neutral-low'
			},
			medium: {
				bg: 'bg-auxiliar-grey-100',
				text: 'text-neutral-low'
			}
		};

		return colors[color];
	};

	return (
		<>
			{fieldLabel && (
				<label htmlFor={id} className="text-gray-800 mb-2 block text-sm font-medium">
					<p>
						<span className="cardSpan">
							<span className="text">{fieldLabel}</span>
							{showTip && (
								<span className="icon">
									<button onClick={() => tipMethod()} className="mr-3" color="outline-secondary">
										<QuestionMarkCircleIcon className="icone_interrogacao"></QuestionMarkCircleIcon>
									</button>
								</span>
							)}
						</span>
					</p>
				</label>
			)}
			<div className="relative rounded-md shadow-sm">
				<input
					maxLength={maxLength ? maxLength : isMoney ? 30 : 80}
					type={passwordShown || !isPassword ? 'text' : 'password'}
					name={id}
					id={id}
					className={`placeholder-neutral-low focus:ring-highlight-orange focus:border-highlight-orange relative block w-full rounded-md p-3 pr-10 focus:outline-none sm:text-sm ${
						isValidValue
							? `border-neutral-medium ${getColor().text} ${getColor().bg}`
							: 'border-red-300 text-red-900'
					}`}
					placeholder={placeholder}
					aria-invalid={isValidValue}
					aria-describedby={`${id}-error`}
					value={value}
					onChange={handleChange}
					onBlur={onBlur}
					onInput={onBlur}
					disabled={disabled}
					style={{ backgroundColor: disabledColor }}
				/>
				{showIcon ? (
					<div
						className={`pointer-events-none visible absolute inset-y-0 right-0 flex items-center pr-3`}
					>
						{isValidValue ? (
							<CheckCircleIcon
								className={`h-5 w-5 ${value !== '' ? 'text-green-500' : 'text-neutral-low'}`}
							/>
						) : (
							<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
						)}
					</div>
				) : (
					<EyeIcon
						onClick={togglePassword}
						className={`w-5" viewBox="0 0 20 20" fill="currentColor" absolute inset-y-3 right-0 flex h-5 cursor-pointer items-center pr-3 ${
							isPassword ? 'visible' : 'hidden'
						}`}
					></EyeIcon>
				)}
			</div>
			{errorText && (
				<p
					className={`mt-2 text-xs text-red-600 ${
						!isValidValue && !isTyping ? 'visible' : 'invisible'
					}`}
					id={`${id}-error`}
				>
					{handleErrorMessage()}
				</p>
			)}
		</>
	);
};

export default InputField;
