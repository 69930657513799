import { FC } from 'react';
import { useAuth } from 'src/hooks/useAuth';

const UserMenu: FC = () => {
	const { logout, user } = useAuth();

	const handleLogout = () => {
		logout();
	};

	return (
		<div className="fill-neutral-medium flex flex-shrink-0 border-t border-white bg-brand-primary px-8 py-2">
			<a href="/" className="group block flex-shrink-0">
				<div className="flex items-center">
					<div></div>
					<div>
						<p className="text-base font-medium text-white">{user?.full_name}</p>
						<p
							onClick={handleLogout}
							className="font-small text-sm text-white group-hover:text-brand-secondary"
						>
							Logout
						</p>
					</div>
				</div>
			</a>
		</div>
	);
};

export default UserMenu;
