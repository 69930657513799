export const TabSelector = ({
	isActive,
	children,
	onClick
}: {
	isActive: boolean;
	children: React.ReactNode;
	onClick: () => void;
}) => (
	<button
		className={` inline-flex items-center pb-2 md:px-4 md:py-2 border-b-2 font-normal text-base cursor-pointer whitespace-nowrap ${
			isActive
				? 'border-brand-primary text-brand-primary focus:outline-none focus:text-indigo-800 focus:border-indigo-700'
				: 'text-neutral-medium text-opacity-50  border-transparent hover:text-gray-600 hover:border-gray-300 focus:text-gray-600 focus:border-gray-300'
		}`}
		onClick={onClick}
	>
		{children}
	</button>
);
