import { useCallback, useRef } from "react";
import { DocumentTypeOptions, documentFileType } from "src/domain/Document";

export const debounce = function <T extends (...args: any[]) => void>(
	callback: T,
	debounceDelay: number = 300,
	immediate: boolean = false
) {
	let timeout: ReturnType<typeof setTimeout> | null;

	return function <U>(this: U, ...args: Parameters<typeof callback>) {
		const context = this;

		if (immediate && !timeout) {
			callback.apply(context, args);
		}
		if (typeof timeout === "number") {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			timeout = null;
			if (!immediate) {
				callback.apply(context, args);
			}
		}, debounceDelay);
	};
};

export const formatCriteria = (criteria: string) => {
	const criteriaOptions = {
		NDA_SIGNED: "1 - NDA Assinado",
		EXCESSIVE_SIZE: "2 - No Go por tamanho",
		LOOKED_FOR_DEAL_IN_SUB_SECTOR: "3 - Deal apresentado no subsetor",
		INTEL: "4 - Intel",
		NEOWAY: "5 - Neoway"
	};

	return criteriaOptions[criteria] || "";
};

export const mapDocumentFileType = (fileType: documentFileType) => {
	return DocumentTypeOptions.find((type) => type.slug === fileType)?.name;
};

export function useDebounce(fn, delay: number) {
	const timeoutRef = useRef<number | undefined>(undefined);

	function debouncedFn(...args) {
		window.clearTimeout(timeoutRef.current);
		timeoutRef.current = window.setTimeout(() => {
			fn(...args);
		}, delay);
	}

	return debouncedFn;
}
