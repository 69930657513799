import { FC, useEffect, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { transformSubsectorDomain } from '../../../../helpers/transformSubsectorDomain';
import Select from '../../base/select';
import { Selectable } from '../models';

const SubsectorSelect: FC<{ onChange: any; chosenSector: number }> = ({
	onChange,
	chosenSector
}) => {
	const [subsectors, setSubsectors] = useState<Selectable[]>([]);
	const [selected, setSelected] = useState<Selectable[]>([]);
	useEffect(() => {
		setSelected([]);
		fetch(
			`${
				process.env.REACT_APP_API_URL ?? 'http://localhost:8000'
			}/business/activity_sub_sectors/${chosenSector}/`
		)
			.then((res) => res.json())
			.then((json) => {
				const jsonFormatted = json.map((subsector: any) => {
					return transformSubsectorDomain(subsector);
				});
				setSubsectors(jsonFormatted);
			});
	}, [chosenSector]);

	const handleChange = (selected: Selectable[]) => {
		if (selected.length === 1) {
			onChange(selected[0].id, true);
			setSelected(selected);
		} else onChange(undefined, false);
	};

	useUpdateEffect(() => {
		handleChange(selected);
	}, [selected]);

	return (
		<>
			<br />
			<Select
				fieldLabel="Sub-setor"
				selectableValues={subsectors}
				singleSelect={true}
				onChange={handleChange}
				selectedValueInput={selected}
			/>
		</>
	);
};

export default SubsectorSelect;
