import { FC, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import OverA from 'src/assets/images/over_a_marca_01.png';
import { toastNotify } from 'src/helpers/toastNotify';
import { deleteResetSolicitation } from 'src/services/recovery/deleteSolicitation';
import Logo from '../images/Logo.png';
import { Link, useParams } from 'react-router-dom';

export interface IgcDeleteResetSolicitationProps {}
	
const IgcDeleteResetSolicitation: FC<IgcDeleteResetSolicitationProps> = () => {
    const {token} = useParams()
	const role = 'igc';


	useEffect(() => {
        deleteResetSolicitation(String(token)).then(()=>{
			toastNotify("Solicitação para reset de senha deletado com sucesso!", "success")
		}).catch((error)=>{
			toastNotify(error.response.data.user_message , 'error');
		})
		setTimeout(() => {
		}, 1000);
    }, [token]);

	return (
		<div>
			<form className=" flex flex-col">
				<div className=" flex justify-center items-center mb-12">
					<img className="text-center" height={45} width={184} src={OverA} alt="overa" />
				</div>  
                <p className="font-bold text-brand-primary text-medium mb-4">Solicitação de reset de senha cancelada!</p>
					<p className="text-sm text-neutral-medium">
						Vale ressaltar que é importante entrar em contato conosco pra investigarmos o acontecido.
						<br />
					</p>				
				<p className="text-center mt-4">
					<Link to={`/${role}/login`} className="block text-brand-secondary text-center">
						Voltar para o loginn
					</Link>
				</p>
				<div className="flex justify-center items-center mt-20">
					{' '}
					<img src={Logo} alt="man on the phone" />
				</div>
			</form>
		</div>
	);
};

export default IgcDeleteResetSolicitation;
