import { FC } from 'react';
import InputField from '../base/input';
import { Input } from './models';

const CityField: FC<Input> = ({ onChange }) => {
	const validateCity = (city: string) => {
		return city.length >= 3;
	};
	return (
		<>
			<InputField
				fieldLabel="Cidade"
				type="text"
				id="city"
				placeholder="Digite o nome da Cidade"
				errorText=""
				validateValue={validateCity}
				onChange={onChange}
			/>
		</>
	);
};

export default CityField;
