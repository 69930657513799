import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'src/assets/icons/Loading';
import TeaserImg from 'src/assets/images/teaser.png';
import Button from 'src/components/Button/Button';
import Checkbox from 'src/components/ui/base/checkbox';
import { Teaser } from 'src/domain/Teaser';
import { toastNotify } from 'src/helpers/toastNotify';
import { useAuth } from 'src/hooks/useAuth';
import download from 'downloadjs';
import Page from 'src/layouts/Logged/components/Page';
import { getTeaserByCompanyId } from 'src/services/teaser/getTeaserByCompanyId';
import { getTeaserDownload } from 'src/services/teaser/getTeaserDownload';
import { toggleTeaser } from 'src/services/teaser/toggleTeaser';
import Modal from 'src/components/Modal';
import {
	uploadTeaser,
	listTeasers,
	getSpecificTeaserForDownload
} from 'src/services/teaser/s3Connection';
import UploadToS3 from 'src/components/UploadS3';
import { XCircleIcon, ClockIcon } from '@heroicons/react/outline';
export interface TeaserPageProps {}

export interface initialFiles {
	[key: string]: File | null;
}

const TeaserPage: FC<TeaserPageProps> = () => {
	const navigate = useNavigate();
	const [teaser, setTeaser] = useState<Teaser>();
	const { user } = useAuth();
	const [loadingTeaser, setLoadingTeaser] = useState<boolean>(true);
	const { id } = useParams();
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const [loadingUploadTeaser, setLoadingUploadTeaser] = useState<boolean>(false);
	const [fileType, setFileType] = useState<boolean>(false);
	const [file, setFile] = useState<File | null>(null);
	const [initialFiles, setInitialFiles] = useState<(string | undefined)[]>();

	const handleToggleTeaser = async () => {
		try {
			await toggleTeaser(String(user?.role), Number(id));
			handleInit();
			toastNotify('Alteração realizada com sucesso!', 'success');
		} catch (err) {
			toastNotify('Alteração inválida!', 'error');
		}
	};

	const handleInit = useCallback(async () => {
		getTeaserByCompanyId(String(user?.role), Number(id))
			.then((response) => {
				if (response.data) {
					setTeaser(response.data);
				}
			})
			.finally(() => {
				setLoadingTeaser(false);
			});

		setInitialFiles(await listTeasers(id));
	}, [user, id]);

	useEffect(() => {
		handleInit();
	}, [handleInit]);

	const handleDownload = async (fileExtension: 'pptx' | 'pdf') => {
		if (fileExtension === 'pptx' && !initialFiles?.includes('teaser.pptx')) {
			getTeaserDownload(String(user?.role), Number(id)).then((res) => {
				const content = res.headers['content-type'];
				download(res.data, 'teaser.pptx', content);
			});
		} else {
			await getSpecificTeaserForDownload(id, fileExtension);
		}
	};

	const handleUpload = async () => {
		setLoadingUploadTeaser(true);

		const fileExtension = file?.type === 'application/pdf' ? 'pdf' : 'pptx';

		const fileName = `teaser.${fileExtension}`;

		try {
			const upload =
				file &&
				(await uploadTeaser(
					fileName,
					`${process.env.REACT_APP_AWS_S3_ENVIRONMENT}/business/teaser/${id}/${fileExtension}`,
					file
				));

			setFile(null);
			setModalOpen(false);
			setInitialFiles(await listTeasers(id));

			toastNotify('Arquivo enviado com sucesso!', 'success');
		} catch (err) {
			toastNotify('Houve um erro ao realizer upload do arquivo!', 'error');
		} finally {
			setLoadingUploadTeaser(false);
		}
	};

	if (loadingTeaser) {
		return (
			<div className="flex justify-center items-center h-screen">
				<Loading color="primary" size={12} />
			</div>
		);
	}

	return (
		<>
			<Page>
				<div
					id="container"
					style={{
						backgroundImage: `linear-gradient(45deg, #bb712788, #70893988), url(${TeaserImg})`
					}}
					className="bg-center bg-cover bg-no-repeat w-full min-h-[20rem] relative rounded-2xl"
				>
					<label className="absolute top-60 left-10  text-white text-[2rem] leading-[3rem] font-bold">
						Sobre a Empresa
					</label>
				</div>
				<div id={id} className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-auxiliar-grey-200 mt-4">
					<div className="flex justify-end items-center">
						<Checkbox
							handleSelect={handleToggleTeaser}
							isSelected={teaser?.business_teaser_approved}
							option={{ id: 'business_teaser_approved', label: 'Aprovar teaser' }}
						/>
						<Button
							onClick={() => navigate(`/igc/editTeaser/${id}`)}
							size="large"
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}
						>
							Editar Teaser
						</Button>
					</div>
				</div>
				<section className="mb-12 flex justify-center">
					<p className="text-brand-primary text-5xl font-semibold mb-2">
						{teaser?.business_main_sector} - {teaser?.business_main_subsector}
					</p>
				</section>
				<section className="mb-12 mt-10">
					<p className="text-brand-primary text-2xl font-semibold mb-2">Overview da campanha</p>
					<ul className="list-disc marker:text-brand-primaryMedium pl-6">
						<li>
							<p className="text-neutral-medium text-lg font-normal">{teaser?.business_briefing}</p>
						</li>
						<li>
							<span className="text-neutral-medium text-lg font-normal">
								As principais ofertas da Companhia são:{' '}
								<ul className="list-decimal marker:text-brand-primaryMedium pl-6">
									{teaser?.business_main_products.map((product, index) => {
										return (
											<li key={`${product.product_name + index}`}>
												<p className="text-neutral-medium text-lg font-normal">
													{product.product_name}
												</p>
											</li>
										);
									})}
								</ul>
							</span>
						</li>
					</ul>
				</section>
				<section className="mb-12">
					<p className="text-brand-primary text-2xl font-semibold mb-2">Tese de investimento</p>
					<ul className="list-decimal marker:text-brand-primaryMedium pl-6">
						{teaser?.business_differentials.map((differential, index) => {
							return (
								<li key={`${differential + index}`}>
									<p className="text-neutral-medium text-lg font-normal">{differential}</p>
								</li>
							);
						})}
					</ul>
				</section>
				<section className="grid">
					<p className="text-brand-primary text-2xl font-semibold mb-2">Informações Específicas</p>
					<span className="text-neutral-medium text-lg font-normal">
						<ul className="list-disc marker:text-brand-primaryMedium pl-6">
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									Clientes: {teaser?.business_client_amount}
								</p>
							</li>
							<li>
								<p className="text-neutral-medium text-lg font-normal">
									Funcionários: {teaser?.business_employees_amount}
								</p>
							</li>
						</ul>
					</span>
					<Modal
						open={isModalOpen}
						onClose={() => {
							setFile(null);
							setModalOpen(false);
						}}
						title={`Upload Teaser ${fileType ? '(PDF)' : '(PPTX)'}`}
						description="Insira abaixo o arquivo de teaser que deseja adicionar para o empresário. Clique em confirmar para enviar."
					>
						<UploadToS3
							businessId={Number(id)}
							setFile={setFile}
							acceptFileType={
								fileType
									? { 'application/pdf': ['.pdf'] }
									: {
											'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
												'.pptx'
											]
									  }
							}
						/>
						{file && (
							<div className="flex items-center justify-between mt-4 mb-4 mr-3 ml-3">
								<p>{file.name}</p>
								{loadingUploadTeaser ? (
									<ClockIcon height={20} />
								) : (
									<XCircleIcon
										height={20}
										onClick={() => setFile(null)}
										className="cursor-pointer"
									/>
								)}
							</div>
						)}
						<div className="flex justify-between mt-7">
							<Button
								onClick={() => setModalOpen(false)}
								className="mr-3"
								color="outline-secondary"
							>
								Cancelar
							</Button>
							<Button
								onClick={handleUpload}
								className="ml-3"
								color="secondary"
								disabled={file === null || loadingUploadTeaser}
							>
								Confirmar
							</Button>
						</div>
					</Modal>

					<div className="flex gap-4 mt-10">
						<Button
							type="button"
							size="small"
							color="outline-secondary"
							className="mt-4"
							onClick={() => handleDownload('pptx')}
							disabled={initialFiles === undefined || !initialFiles?.includes('teaser.pptx')}
						>
							Download Teaser (PPTX)
						</Button>
						<Button
							type="button"
							size="small"
							color="outline-secondary"
							className="mt-4"
							onClick={() => handleDownload('pdf')}
							disabled={initialFiles === undefined || !initialFiles?.includes('teaser.pdf')}
						>
							Download Teaser (PDF)
						</Button>
						<Button
							type="button"
							size="small"
							color="secondary"
							className="mt-4"
							onClick={() => {
								setFileType(false);
								setModalOpen(true);
							}}
						>
							Upload Teaser (PPTX)
						</Button>
						<Button
							type="button"
							size="small"
							color="secondary"
							className="mt-4"
							onClick={() => {
								setFileType(true);
								setModalOpen(true);
							}}
						>
							Upload Teaser (PDF)
						</Button>
					</div>
				</section>
			</Page>
		</>
	);
};

export default TeaserPage;
