export interface VideoSectionProps {
	title: string;
	videos: maVideoProps[];
}

export interface maVideoProps {
	id: number;
	title: string;
	url: string;
}

export const maVideoSections = [
	{
		title: "1. Início da jornada",
		videos: [
			{
				id: 0,
				title: "Tudo o que você precisa saber sobre M&A",
				url: "https://www.youtube.com/embed/_UMzxzs2aC4"
			},
			{
				id: 1,
				title: "Contrato de Trabalho",
				url: "https://www.youtube.com/embed/Co-x1yrjQy4"
			}
		]
	},
	{
		title: "2. Preparação",
		videos: [
			{
				title: "Quanto vale a minha empresa?",
				id: 2,
				url: "https://www.youtube.com/embed/OX0gm0w5zuM"
			}
		]
	}
] as VideoSectionProps[];

export const CommercialFAQSection = [
	{
		question: "Quanto tempo dura o processo de M&A?",
		answer:
			"O tempo de um processo varia e depende da organização da empresa, da agilidade dos vendedores a fornecerem informações e do interesse de investidores na fase de roadshow. Salvo exceções (em que o cliente, por exemplo, já tem uma proposta anterior à contratação da overA), a maioria das transações ocorre entre 7 a 14 meses desde a assinatura do contrato."
	},
	{
		question:
			"Tenho que definir a estrutura de transação desejada antes de começar o processo com a overA?",
		answer:
			"Não. Nós temos a mente aberta para diferentes estruturas de transação e essa característica aumenta os possíveis investidores, consequentemente aumentando a competitividade pela empresa."
	},
	{
		question: "Como funciona a remuneração da overA?",
		answer:
			"A overA tem uma remuneração mensal fixa, e uma remuneração em caso de sucesso da transação."
	},
	{
		question: "A OverA atua como assessor jurídico nas transações?",
		answer:
			"Não atuamos como assessores jurídicos, mas, diferente da maioria dos M&A advisors, temos um time capacitado para dialogar com advogados."
	},
	{
		question: "A overA atua como auditor nas transações?",
		answer: "Não atuamos como auditores nas transações."
	},
	{
		question: "A overA atua assessorando potenciais compradores?",
		answer:
			"Nosso posicionamento é e sempre será de apenas atuar ao lado do empresário vendedor. Jamais seremos remunerados pelo lado comprador, evitando, assim, qualquer conflito de interesse."
	},
	{
		question: "Posso ter mais de um assessor atuando no meu process de M&A?",
		answer:
			"Apenas atuamos com exclusividade para garantir que o processo está coordenado e que, com isso, controlamos a exposição da empresa e aumentamos a competitividade pela companhia."
	},
	{
		question: "A overA poderá falar com algum potencial investidor sem a minha autorização?",
		answer: "Nunca abordamos algum investidor sem a autorização de nossos clientes."
	},
	{
		question: "Não quero expor informações sensíveis para concorrentes. Como vocês lidam com isso?",
		answer: "Todo material referente ao seu deal precisa ser aprovado por você, sempre."
	},
	{
		question:
			"A partir do momento em que contrato a overA, tenho algum tipo de obrigação em fazer uma transação (ou multa ao decidir não fazer)?",
		answer:
			"Não existe nenhuma obrigação a fazer uma transação – a decisão é sempre do cliente. Não cobramos nenhuma multa de rescisão, apenas pedimos que, pelos próximos 12 meses contados a partir da rescisão do contrato, a overA faça jus à remuneração definida em contrato se alguma transação ocorrer. Assim, se o empresário quiser usar todos os materiais e alvos que apresentamos, somos remunerados pelo nosso trabalho."
	},
	{
		question: "Minha empresa tem dívida. Como funciona a remuneração da overA nesse caso?",
		answer:
			"Em caso de sucesso da transação, os investidores irão considerar o valor a ser pago pela sua companhia em um debt-free, cash-free basis, isto é, como se todas as dívidas tivessem sido quitadas e o caixa adicional disponível na companhia já tivesse sido distribuído aos antigos acionistas. "
	},
	{
		question: "Como funciona o conceito de remuneração mínima?",
		answer:
			"Para que tenhamos um incentivo alinhado entre nossos projetos, temos uma remuneração de sucesso mínima, devido apenas no cenário de uma transação ser consumada. Também, já tivemos muitas experiências em que o investidor encantou nosso cliente, seja por sinergias a destravar, cultura ou valuation maior por um percentual menor; em todos os casos, nosso trabalho será o mesmo e não queremos ter incentivos a enviesar para alguma proposta específica. Assim, a remuneração mínima nos deixa isentos de qualquer incentivo perverso e nos remunera pelo nosso trabalho."
	},
	{
		question:
			"Já estou negociando com um comprador. Devo excluir ele do escopo do processo da overA?",
		answer:
			"Já trabalhamos em diversos casos nos quais o cliente estava em negociação com o comprador, inclusive com propostas na mesa. Nessas situações, sabemos que podemos agregar valor ao trazer competição para o processo e táticas negociais para a discussão do contrato de compra e venda e da due diligence."
	},
	{
		question: "Como será meu contato com a overA no dia a dia?",
		answer: "Você terá um líder de projeto como ponto focal, com todo o suporte do time da overA."
	}
];

export const ContractualFAQSection = [
	{
		question: "O Contrato de trabalho da overA segue um padrão de mercado?",
		answer:
			"Sim, temos diversos parceiros e contatos mundo afora que utilizamos como referência para revisão constante do nosso contrato de assessoria."
	},
	{
		question: "Por que a overA não se responsabiliza por riscos jurídicos transacionais?",
		answer:
			"Regulatoriamente, estamos impedidos de fornecer serviços jurídicos / opiniões legais, de maneira que apenas advogados podem exercer essa função e ser responsabilizados por isso."
	},
	{
		question: "O que é “Prazo Tail”?",
		answer:
			"É uma prática de mercado que impede que o cliente atue de má-fé e utilize a estrutura de transação montada pela overA para fazer a transação sozinho (materiais, apresentações a investidores e processo negocial). Dessa maneira, a partir do momento que o cliente nos descontrata, temos um prazo mínimo que entendemos que, se ele fizer uma transação, devemos ser remunerados."
	},
	{
		question: "Por que os sócios também precisam assinar o contrato na pessoa física?",
		answer:
			"Porque ele é o verdadeiro dono do ativo que será transacionado, o tomador de decisão e o beneficiário final dos valores de uma transação de venda de participação societária – protagonizando o papel do real cliente que nos legitima a fazer esse processo."
	},
	{
		question: "A remuneração da overA é líquida de impostos sobre a receita?",
		answer:
			"Sim, seguimos o padrão internacional de precificar nossos projetos levando em consideração que receberemos valores líquidos e não brutos."
	},
	{
		question: "Como funciona a cláusula de responsabilidade limitada?",
		answer:
			"Caso o cliente entenda que a overA causou uma perda a ele, iremos indenizá-lo no valor máximo de R$ 100 mil reais."
	},
	{
		question: "Por que o contrato elege a Comarca da Capital do Estado de São Paulo como foro?",
		answer:
			"Pois é uma das únicas do brasil que possui uma câmara especializada em direito empresarial e acreditamos que é o mais apropriado para julgar esse tipo de disputa."
	},
	{
		question: "O contrato protege a troca e utilização de informações confidenciais?",
		answer:
			"Entendemos que como assessores financeiros temos o dever lateral implícito de zelar pelas informações confidenciais de nossos clientes."
	},
	{
		question: "Por que o contrato sugere vários tipos diferentes de transação?",
		answer:
			"Em nossa experiência de assessores financeiros em grandes transações de M&A, já testemunhamos os mais variados veículos a serem utilizados para a consumação de uma transação. Além disso, garantimos ao nosso cliente a liberdade de mudar de ideia a qualquer momento, mas precisamos estar protegidos caso isso aconteça."
	}
];
