import { FC, useState } from 'react';
import LoadingIcon from 'src/assets/icons/Loading';
import { VideoSectionProps, maVideoProps } from '../../content';
import DashboardDisclosure from '../Disclosure';

interface MASectionProps {
	selectedVideo: maVideoProps;
	maVideoSections: VideoSectionProps[];
	setSelectedVideo: (video: maVideoProps) => void;
}

const MASection: FC<MASectionProps> = ({ selectedVideo, maVideoSections, setSelectedVideo }) => {
	const [loading, setLoading] = useState(false);
	return (
		<div className="text-medium relative w-full overflow-hidden rounded-xl border-2 border-brand-secondary border-opacity-30 p-2 shadow-lg">
			<h4 className="mb-2 border-b border-brand-secondary border-opacity-20 font-medium text-brand-secondary">
				Trilha de M&A
			</h4>
			<div className="w-ful relative mb-4 max-h-full px-2 sm:overflow-y-auto xl:mb-20">
				{loading ? (
					<div className="flex h-40 items-center justify-center rounded-md bg-[#ddd] shadow-sm">
						<LoadingIcon />
					</div>
				) : (
					<div className="relative w-full xl:pb-10">
						<iframe
							className="aspect-video w-full rounded-md bg-[#ddd] shadow-sm"
							src={selectedVideo.url}
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
						/>
						{maVideoSections.map((videoSection, index) => (
							<DashboardDisclosure
								key={index}
								loading={loading}
								setLoading={setLoading}
								videoSection={videoSection}
								selectedVideo={selectedVideo}
								setSelectedVideo={setSelectedVideo}
								defaultOpen={true}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default MASection;
