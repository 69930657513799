import { useState } from 'react';
import DashboardFAQDisclosure from '../FAQDisclosure';
import Modal from 'src/components/Modal';
import { CommercialFAQSection, ContractualFAQSection } from '../../content';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

const FAQ = () => {
	const [modalOpen, setModalOpen] = useState(false);

	const faqSection = [
		{
			title: 'Comercial',
			section: CommercialFAQSection
		},
		{
			title: 'Contratual',
			section: ContractualFAQSection
		}
	];

	return (
		<>
			<div className="mt-2 w-full rounded-xl border-2 border-brand-secondary border-opacity-30 p-2 shadow-lg">
				<h4 className="mb-2 border-b border-brand-secondary border-opacity-20 font-medium text-brand-secondary">
					FAQ + Contato
				</h4>
				Qualquer solicitação de ajuda,{' '}
				<span
					className="font-bold text-brand-secondary hover:cursor-pointer"
					onClick={() => setModalOpen(true)}
				>
					clique aqui
				</span>{' '}
				para consultar o FAQ ou enviar um e-mail para{' '}
				<a
					className="font-bold text-brand-secondary hover:cursor-pointer"
					href="mailto:atendimento@overacapital.com.br"
				>
					atendimento@overacapital.com.br
				</a>
				<br />
				<br />{' '}
			</div>
			<Modal open={modalOpen} onClose={() => setModalOpen(false)} title="FAQ">
				<div className="flex h-full max-h-[70vh] w-full max-w-full flex-col overflow-y-auto ">
					{ faqSection.map(f => (
						<Disclosure defaultOpen={false}>
						{({ open }) => (
							<>
								<>
									<Disclosure.Button className="mt-2 flex w-full justify-between border-b border-brand-secondary border-opacity-10 p-4 text-left text-base font-semibold text-brand-secondary hover:bg-[#fcf5e1] focus:outline-none">
										<span>{f.title}</span>
										<ChevronDownIcon
											className={`${
												open && 'rotate-180 transform'
											} text-purple-500 h-5 w-5 transition-all`}
										/>
									</Disclosure.Button>
									<Disclosure.Panel
										className={`my-1 rounded-md border border-none p-4 text-sm font-medium text-[#958777] shadow-sm`}
									>
										<span className={``}>
											{f.section.map((section, index) => (
												<DashboardFAQDisclosure
													question={section.question}
													answer={section.answer}
													key={index}
												/>
											))}
										</span>
									</Disclosure.Panel>
								</>
							</>
						)}
						</Disclosure>
						))
					}
				</div>
			</Modal>
		</>
	);
};

export default FAQ;
