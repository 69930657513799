import { FC, useState } from 'react';
import InputField from '../base/input';
import { PasswordInput } from './models';

const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~])[A-Za-z\d`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]{8,}$/;

const PasswordField: FC<PasswordInput> = ({ showRules, onChange, initialValue, onError }) => {
	const [valid, setValid] = useState(true);
	const maxLength = 8;

	const validatePassword = (password: string) => {
		const isValid = PASSWORD_REGEX.test(password);
		if (onError) {
			let isError = false;
			if (password.length < maxLength) isError = true;
			
			onError(isError);
		}
		
		setValid(isValid);
		return isValid;
	};
	return (
		<>
			<InputField
				initialValue={initialValue}
				fieldLabel="Senha"
				type="password"
				id="password"
				placeholder="Insira uma senha"
				validateValue={validatePassword}
				onChange={onChange}
				showIcon={false}
				isPassword={true}
			/>
			{showRules && (
				<p
					className={`text-xs mt-2 ${!valid ? 'text-red-500' : ''} ${
						showRules ? 'visible' : 'hidden'
					}`}
				>
					Deve conter no mínimo 8 caracteres <br />
					Letras maiúsculas e minúsculas <br />
					Ao menos um número e um caracter especial
				</p>
			)}
		</>
	);
};

export default PasswordField;
