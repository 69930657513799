import { ErrorMessage, Field } from "formik";
import { Link } from "react-router-dom";

const PreRegisterInputCheckbox = () => {
    return (
        <div className="col-span-6 ">
            <Field
                className="bg-gray-100 border-gray-300 focus:ring-blue-500 h-4 w-4 rounded"
                type="checkbox"
                id="terms_of_use_and_privacy"
                name="terms_of_use_and_privacy"
            />
            <label className="ml-2" htmlFor="terms_of_use_and_privacy">
                Li e aceito os{" "}
                <Link className="text-auxiliar-highlight-orange" to="/privacy-policy-lgpd-overa.pdf" target="__blank">
                    termos e condições
                </Link>
                .
            </label>
            <ErrorMessage className="text-[12px] text-red-500" name="terms_of_use_and_privacy" component="p" />
        </div>
    );
};

export default PreRegisterInputCheckbox;