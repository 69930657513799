import { ArrowRightIcon, DocumentTextIcon, FolderIcon } from "@heroicons/react/outline";
import { DownloadIcon } from "@heroicons/react/solid";
import download from "downloadjs";
import { FC } from "react";
import { FolderItem } from "src/domain/Sharepoint";
import { getFile } from "src/services/sharepoint/getFile";

interface Props {
	folderItem: FolderItem;
	setFolderName: React.Dispatch<React.SetStateAction<string>>;
	folderName: string;
}

async function downloadFile(folder: string, fileName: string) {
	getFile(folder, fileName).then((response) => {
		const content = response.headers["content-type"];
		download(response.data, fileName, content);
	});
}

const ItemCard: FC<Props> = ({ folderItem, setFolderName, folderName }: Props) => {
	return (
		<div
			className="my-4 grid max-w-4xl cursor-pointer grid-cols-[75px_1fr_75px] rounded-md bg-auxiliar-grey-100 p-2 hover:bg-auxiliar-grey-200"
			onClick={
				folderItem.type === "folder"
					? () => setFolderName(folderItem.path)
					: () => downloadFile(folderName, folderItem.name)
			}
		>
			<div className="flex place-items-center">
				{folderItem.type === "folder" ? (
					<FolderIcon height={50} className="place-self-center" />
				) : (
					<DocumentTextIcon height={50} className="place-self-center" />
				)}
			</div>
			<div>
				<strong>{folderItem.name}</strong>
				<p>modificado: {folderItem.updated_at}</p>
			</div>
			{folderItem.type === "folder" ? (
				<ArrowRightIcon height={25} className="place-self-center" />
			) : (
				<DownloadIcon height={25} className="place-self-center" />
			)}
		</div>
	);
};

export default ItemCard;
