import { motion } from "framer-motion";
import { XCircleIcon, DocumentTextIcon } from "@heroicons/react/outline";
import Button from "../Button/Button";
import { isFileImage } from "src/helpers/isFileImage";

interface Props {
	closePreview: () => void;
	file: File | null;
	upload: (file: File) => void;
}

export const PreviewImage = ({ closePreview, file, upload }: Props) => {
	if (!file) return null;

	return (
		<motion.div
			className="absolute top-0 left-0 z-50 flex h-full max-h-full w-full flex-col gap-0 bg-[#faf0e5] px-4 xl:gap-32"
			initial={{ height: 0, opacity: 0 }}
			animate={{ height: "100%", opacity: 1 }}
			exit={{ height: 0, opacity: 0 }}
			transition={{ duration: 0.2 }}
		>
			<div className="flex items-center justify-between">
				<h2 className="font-bold text-brand-primary">Preview</h2>
				<XCircleIcon height={40} onClick={closePreview} color="#E0964C" className="cursor-pointer" />
			</div>
			<div className="flex flex-col items-center justify-evenly">
				{isFileImage(file) ? (
					<img src={URL.createObjectURL(file)} alt="Preview Imagem" className="h-2/3 max-h-96 place-self-center" />
				) : (
					<DocumentTextIcon height={200} />
				)}
				<p className="font-bold">{file?.name}</p>
			</div>
			<Button type="submit" className="w-1/3 place-self-center" onClick={() => upload(file)}>
				Enviar
			</Button>
		</motion.div>
	);
};
