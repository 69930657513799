import { IFile } from "src/contexts/files";
import { Document } from "src/domain/Document";

export interface ExtendedRegistration {
	business_id: number | null;
	full_register_step: number;
	briefing: string | null;
	business_differentials: Array<string>;
	business_timelines: Array<BusinessTimeline>;
	business_goals: Array<BusinessGoals>;
	business_interested_investors: Array<CustomInterestedInvestor>;
	business_potential_investors: Array<BusinessPotentialInvestor>;
	business_competitors: Array<string>;
	business_files: Array<BusinessFile | IFile | Document>;
	debt_amount: string;
	audited_by: string | null;
	client_amount: number | null;
	business_main_clients: Array<BusinessMainClient>;
	business_partners: Array<BusinessPartner>;
	clt_employees_amount: number | null;
	pj_employees_amount: number | null;
	main_state_id: number | null;
	main_state: string;
	main_city_id: number | null;
	main_city: string;
	business_states: Array<BusinessStates>;
	business_leases: Array<BusinessLease>;
	business_main_products: Array<BusinessMainProduct>;
	business_sales_types: Array<BusinessSalesType>;
	business_custom_goal: string | null;
}

export interface ExtendedRegistrationForm
	extends Omit<ExtendedRegistration, "business_differentials" | "business_competitors"> {
	business_differentials: BusinessStrings[];
	business_competitors: BusinessStrings[];
	is_audited: boolean;
	is_rented: boolean;
	has_operation_in_multiple_states: boolean;
	business_investors_has_interest: boolean;
}

export type BusinessStrings = {
	name: string;
};

export type BusinessPartner = {
	name: string;
	share: number | null;
};

export type BusinessMainProduct = {
	name: string;
	share: number | null;
};
export type BusinessSalesType = {
	name: string;
	share: number | null;
};

export type BusinessTimeline = {
	event_description: string;
	date: string;
};

export type BusinessMainClient = {
	name: string;
	share: number | null;
};

export type BusinessLease = {
	name: string;
	valid_until: string;
};

export type CustomInterestedInvestor = {
	investor: string;
	investor_interest: string;
};

export type InterestedInvestor = {
	business_interested_investor_id: number;
	investor_id: number;
	investor: string;
	investor_interest_id: number;
	investor_interest: string;
};

export type BusinessGoals = {
	goal_id: number;
	name: string;
};

export type BusinessPotentialInvestor = {
	investor: string;
};

export type BusinessStates = {
	state_id: number;
	state_name: string;
};
export type BusinessFile = {
	file_id: number;
	file_name: string;
	created_at: string;
	manager: string;
	status: string;
};

export function checkIfHasEmptyValues(arr: Array<any>) {
	return arr.some((obj: object) => Object.values(obj).some((value) => value === undefined));
}

export function formatFormToRender(formValues: ExtendedRegistration) {
	let { business_differentials, business_competitors, debt_amount, audited_by, ...rest } = formValues;

	const formOnlyObjects = {
		is_rented: false,
		is_audited: false,
		has_operation_in_multiple_states: false,
		business_investors_has_interest: false
	};

	const formatedDifferentials = business_differentials.map((name) => ({ name }));

	const formatedCompetitors = business_competitors.map((name) => ({ name }));

	if (formValues.audited_by) formOnlyObjects.is_audited = true;

	if (formValues.business_leases.length > 0) formOnlyObjects.is_rented = true;

	if (formValues.business_states.length > 0) formOnlyObjects.has_operation_in_multiple_states = true;

	if (formValues.business_interested_investors.length > 0) formOnlyObjects.business_investors_has_interest = true;

	if (!debt_amount) debt_amount = "";

	if (!audited_by) audited_by = "";

	return {
		...rest,
		...formOnlyObjects,
		audited_by,
		debt_amount,
		business_competitors: formatedCompetitors,
		business_differentials: formatedDifferentials
	};
}
