import { SearchIcon } from '@heroicons/react/outline';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FC, useEffect, useState } from 'react';
import Loading from 'src/assets/icons/Loading';
import Input from 'src/components/Input';
import Pagination from 'src/components/Pagination';
import { Selectable } from 'src/components/ui/form/models';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import { Deal } from 'src/domain/Contract';
import { Sector } from 'src/domain/Sector';
import { useAuth } from 'src/hooks/useAuth';
import Page from 'src/layouts/Logged/components/Page';
import { getDeals } from 'src/services/contract/getContracts';
import { getSectors } from 'src/services/domain/getSectors';

interface DealPageProps {}

const DealPage: FC<DealPageProps> = () => {
	const [loadingDeals, setLoadingDeals] = useState<boolean>(true);
	const [deals, setDeals] = useState<Array<any>>();
	const [total, setTotal] = useState<number>(1); //Usar na paginação depois
	const [currentPage, setCurrentPage] = useState<number>(1); //Usar na paginação depois
	const [originalDeals, setOriginalDeals] = useState<Array<Deal>>([]);
	const { user } = useAuth();
	const pageLimit = 10;
	const [filterInput, setFilterInput] = useState<string>('');
	const [filter, setFilter] = useState<Selectable>({
		id: 0,
		name: 'Filtrar por',
		value: null
	});
	const [options, setOptions] = useState<Selectable[]>([]);

	const handleFilteringInput = (filter: string) => {
		setFilterInput(filter);
		setLoadingDeals(true);
		setDeals(searchTableInput(filter));
	};

	const handleFiltering = (filter: Selectable) => {
		setFilter(filter);
		setLoadingDeals(true);
		setDeals(searchTable(filter.name));
	};

	const searchTable = (activity_sector: string): Array<Deal> => {
		setTimeout(() => {
			setLoadingDeals(false);
		}, 500);
		if (activity_sector === 'Todos') {
			return originalDeals;
		}
		if (originalDeals) {
			return originalDeals.filter((deal) => deal.activity_sector === activity_sector);
		}
		return [];
	};

	const searchTableInput = (filter: string): Array<Deal> => {
		setTimeout(() => {
			setLoadingDeals(false);
		}, 500);
		if (filter === '') {
			return onPageChanged(originalDeals);
		}
		filter = filter.toLowerCase();
		if (originalDeals) {
			return onPageChanged(
				originalDeals.filter((deal) => {
					if (
						deal.name.toLowerCase().includes(filter) ||
						deal.email.toLowerCase().includes(filter) ||
						deal.activity_sector.toLowerCase().includes(filter)
					) {
						return deal;
					}
					return '';
				})
			);
		}
		return onPageChanged([]);
	};

	const onPageChanged = (value: Array<Deal>) => {
		setTotal(value.length);
		const offset = (currentPage - 1) * pageLimit;
		const currentDeals = value.slice(offset, offset + pageLimit);
		return currentDeals;
	};

	const mapperOptions = (sectors: Sector[]): Selectable[] => {
		const map = sectors.map((sector) => {
			return {
				id: sector.id,
				name: sector.name,
				slug: sector.slug,
				value: sector.id
			};
		});
		map.unshift({
			id: 17862367838,
			name: 'Todos',
			slug: 'all',
			value: 0
		});

		return map;
	};

	useEffect(() => {
		//primeira vez que loga
		getDeals(String(user?.role))
			.then((response) => {
				setTotal(response.data.length);
				setDeals(onPageChanged(response.data));
				setOriginalDeals(response.data);
			})
			.finally(() => {
				setLoadingDeals(false);
			});
		getSectors().then((response) => {
			setOptions(mapperOptions(response.data));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoadingDeals(true);
		setDeals(onPageChanged(originalDeals));
		setTimeout(() => {
			setLoadingDeals(false);
		}, 500);
		// setOriginalDeals(onPageChanged(Deals));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	return (
		<>
			<Page>
				<div className="flex justify-between items-center mb-5 w-full">
					<div className="sm:w-96">
						<Input
							placeholder="Pesquisar"
							prefixIcon={<SearchIcon color="text-neutral-medium" height={20} />}
							bgColor="bg-neutral-high"
							error={false}
							errorMessage={''}
							value={filterInput}
							onChange={(e) => {
								handleFilteringInput(e.currentTarget.value);
							}}
						/>
					</div>
					<div className="w-48 mb-5">
						<SingleSelect
							bgColor="bg-neutral-high"
							placeholder="Filtrar por"
							options={options}
							value={filter}
							onChange={(e: Selectable) => handleFiltering(e)}
						/>
					</div>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="w-full">
						<thead>
							<tr>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Empresário
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Email
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Data do cadastro
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Setor
								</th>
							</tr>
						</thead>
						<tbody className=" divide-y divide-neutral-medium divide-opacity-50">
							{deals && deals.length === 0 && !loadingDeals ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										Nenhum contrato encontrado
									</td>
								</tr>
							) : loadingDeals ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										<Loading color="primary" size={12} />
									</td>
								</tr>
							) : (
								deals?.map((contract) => (
									<tr key={contract.business_recommendation_id}>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="flex items-center justify-start">
												<div className="text-sm font-normal text-left text-neutral-medium">
													{contract.name}
												</div>
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{contract.email}
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{contract.registered
													? moment(contract.recommended_at).locale('pt-br').format('DD MMM YYYY')
													: ''}
											</div>
										</td>
										<td className=" pr-6 py-4 whitespace-nowrap text-sm font-normal text-neutral-medium">
											{contract.activity_sector}
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
					<Pagination
						currentPage={currentPage}
						total={total}
						pageLimit={pageLimit}
						setCurrentPage={setCurrentPage}
						loading={loadingDeals}
					/>
				</div>
			</Page>
		</>
	);
};

export default DealPage;
