import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import TabTitle from './components/TabTitle';
import Button from 'src/components/Button/Button';
import { getTabContent, tabs } from './content';
import shakeHands from './images/shake-hands.png';
import history from 'src/services/history';

export interface IAboutPageProps {}

const AboutPage: React.FunctionComponent<IAboutPageProps> = () => {
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const isFirstTab = () => {
		return activeTabIndex === 0;
	};
	const isLastTab = () => {
		return activeTabIndex === tabs.length - 1;
	};
	const navigateNext = () => {
		setActiveTabIndex(activeTabIndex + 1);
	};
	const navigatePrevious = () => {
		setActiveTabIndex(activeTabIndex - 1);
	};

	const navigation = {
		isFirstTab: isFirstTab,
		isLastTab: isLastTab,
		next: navigateNext,
		previous: navigatePrevious
	};

	return (
		<div
			id="container"
			style={{ backgroundImage: `url(${shakeHands})` }}
			className="bg-center bg-cover bg-no-repeat w-full h-screen relative text-neutral-high"
		>
			<div className="flex flex-col justify-evenly absolute top-0 h-screen w-full z-1 bg-gradient-to-r from-gradient-blue-start to-gradient-blue-end">
				<div className="px-6">
					<ul className="flex justify-center  items-center my-8 cursor-pointer">
						{tabs.map((tab, index) => {
							return (
								<TabTitle
									key={tab.id}
									onClick={setActiveTabIndex}
									tab={tab}
									activeTab={activeTabIndex}
									index={index}
								/>
							);
						})}
					</ul>
				</div>
				<div className="px-6 w-ful sm:mt-44 flex justify-center items-center">
					<div className="flex justify-center items-center">
						<div
							className={`text-brand-secondary ${
								navigation.isFirstTab() ? 'invisible' : ''
							} cursor-pointer`}
							onClick={() => navigation.previous()}
						>
							<ChevronLeftIcon className="text-brand-secondary h-10 w-10" />
						</div>
						<div className="mx-10 max-w-3xl text-2xl align-middle text-center">
							{getTabContent(activeTabIndex)}
						</div>
						<div
							className={`text-brand-secondary cursor-pointer ${
								navigation.isLastTab() ? 'invisible' : ''
							}`}
							onClick={() => navigation.next()}
						>
							<ChevronRightIcon className="text-brand-secondary h-10 w-10" />
						</div>
					</div>
				</div>
				<div className="px-6 w-ful sm:mt-44 flex justify-center items-center">
					<Button
					className={isLastTab() ? 'md:w-[214px] w-full' : 'hidden'}
					type="button"
					size="medium"
					color="secondary"
					onClick={() => history.push('/finder/recommendbusiness')}
					>
					Indicar Empresa
					</Button>
				</div>
			</div>
		</div>
		// <div className="relative h-full z-0 text-white">
		// 	<img className="absolute h-screen w-screen z-1" src={shakeHands} alt="hand shake" />
		// 	<div className="absolute top-10 h-full w-full z-1 bg-gradient-to-r from-gradient-blue-start to-gradient-blue-end">
		// 		<div className="p-6">
		// 			<ul className="flex justify-center  items-center my-8 mb-40 cursor-pointer">
		// 				{tabs.map((tab, index) => {
		// 					return (
		// 						<TabTitle
		// 							key={tab.id}
		// 							onClick={setActiveTabIndex}
		// 							tab={tab}
		// 							activeTab={activeTabIndex}
		// 							index={index}
		// 						/>
		// 					);
		// 				})}
		// 			</ul>
		// 			<div className="pt-40 w-full">
		// 				<div className="flex justify-center items-center">
		// 					<div
		// 						className={`text-brand-secondary my-auto ${
		// 							navigation.isFirstTab() ? 'invisible' : ''
		// 						} cursor-pointer`}
		// 						onClick={() => navigation.previous()}
		// 					>
		// 						<ChevronLeftIcon className="text-brand-secondary h-10 w-10" />
		// 					</div>
		// 					<div className="mx-10 max-w-3xl text-2xl align-middle text-center">
		// 						{getTabContent(activeTabIndex)}
		// 					</div>
		// 					<div
		// 						className={`text-brand-secondary my-auto ${
		// 							navigation.isLastTab() ? 'invisible' : ''
		// 						}`}
		// 						onClick={() => navigation.next()}
		// 					>
		// 						<ChevronRightIcon className="text-brand-secondary h-10 w-10" />
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default AboutPage;
