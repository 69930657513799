import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "src/components/Button/Button";
import PreRegisterInput from "./PreRegisterInput";
import PreRegisterInputCheckbox from "./PreRegisterInputCheckbox";
import PreRegisterInputPhone from "./PreRegisterInputPhone";

const PreRegisterForm = ({ onSubmit }) => {
    const validationBusiness = Yup.object({
        trade_name: Yup.string().required("*O nome da empresa é obrigatório"),
        partner_name: Yup.string().required("*O nome do responsável é obrigatório"),
        email: Yup.string().email("*Insira um email válido").required("*O email é obrigatório"),
        phone: Yup.string().min(11, "*Numero de telefone inválido").required("*O telefone da empresa é obrigatório"),
        business_website: Yup.string(),
        terms_of_use_and_privacy: Yup.boolean().oneOf([true], "*Você deve aceitar os termos de uso")
    });

    return (
        <Formik
            initialValues={{
                trade_name: "",
                partner_name: "",
                email: "",
                phone: "",
                business_website: "",
                terms_of_use_and_privacy: false
            }}
            validationSchema={validationBusiness}
            onSubmit={onSubmit}
        >
            <Form className=" p-5">
                <div className="grid w-full grid-cols-6 gap-5">
                    <PreRegisterInput
                        label="Nome da empresa:"
                        name="trade_name"
                        placeholder="Insira o nome da empresa"
                        type="text"
                        responsiveStyle="sm:col-span-6 md:col-span-3 lg:col-span-3"
                    />
                    <PreRegisterInput
                        label="Nome do responsável:"
                        name="partner_name"
                        placeholder="Insira o nome do responsável"
                        type="text"
                        responsiveStyle="sm:col-span-6 md:col-span-3 lg:col-span-3"
                    />
                    <PreRegisterInput
                        label="E-mail:"
                        name="email"
                        placeholder="Insira seu email"
                        type="text"
                        responsiveStyle="sm:col-span-6 md:col-span-2 lg:col-span-2"
                    />

                    <PreRegisterInputPhone
                        label="Telefone:"
                        name="phone"
                        placeholder="(00) 00000-0000"
                        type="text"
                        responsiveStyle="sm:col-span-6 md:col-span-2 lg:col-span-2"
                    />
                    <PreRegisterInput
                        label="Site da empresa:"
                        name="business_website"
                        placeholder="www.seusite.com"
                        type="text"
                        responsiveStyle="sm:col-span-6 md:col-span-2 lg:col-span-2"
                    />
                    <PreRegisterInputCheckbox />
                    <div className="flex items-center justify-center sm:col-span-6 md:col-span-4 lg:col-span-6">
                        <Button
                            color="secondary"
                            className="shadow-indigo-500/40 mb-5 mt-5 w-9/12 cursor-pointer shadow-lg "
                            size="large"
                            type="submit"
                        >
                            Cadastrar
                        </Button>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default PreRegisterForm;