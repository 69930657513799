import { FC, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import CityField from 'src/components/ui/form/CityField';
import CPFField from 'src/components/ui/form/CPFField';
import EmailField from 'src/components/ui/form/EmailField';
import NameField from 'src/components/ui/form/NameField';
import PasswordField from 'src/components/ui/form/PasswordField';
import PhoneField from 'src/components/ui/form/PhoneField';
import SectorSelect from 'src/components/ui/form/Selects/SectorSelect';
import UFSelect from 'src/components/ui/form/Selects/UFSelect';
import { toastNotify } from 'src/helpers/toastNotify';
import { useAuth } from 'src/hooks/useAuth';
import OverALogo from '../../../components/OverALogo';
import { registerUser } from '../../../services';
import { verifyUniqueness } from '../../../services/user/emailUniqueness';

export interface IFinderRegisterProps {}

const FinderRegister: FC = () => {
	const { login } = useAuth();
	const [currentStep, setCurrentStep] = useState(0);
	const [valid, setValid] = useState([
		[],
		[false, true, false],
		[false, false, false],
		[false, false]
	]);
	const [values, setValues] = useState({});
	const [stepValid, setStepValid] = useState(false);
	const role = 'finder';
	const maxStep = 3;

	const nextStep = () => {
		changeStep(currentStep + 1);
	};
	const previousStep = () => {
		changeStep(currentStep - 1);
	};

	const changeStep = (newStep) => {
		setCurrentStep(newStep);
		updateStepValid(newStep);
	};

	const updateValue = (step, index, value, isValueValid, field) => {
		valid[step][index] = isValueValid;
		setValid(valid);
		updateStepValid(step);
		values[field] = value;
		setValues(values);
	};

	const updateStepValid = (step = currentStep) => {
		setStepValid(!valid[step].includes(false));
	};

	const finishRegister = () => {
		registerUser(role, values).then((login_dados) => {
			login(login_dados.email, login_dados.password, role);
		});
	};

	const handleVerifyUniqueness = (event: FormEvent) => {
		event.preventDefault();
		verifyUniqueness(values['email'])
			.then((res) => {
				if (res.data.email_available) {
					nextStep();
				} else {
					toastNotify(res.data.user_message, 'error');
				}
			})
			.catch((error) => {
				toastNotify(error.response.data.user_message, 'error');
			});
		setTimeout(() => {}, 1000);
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			if (stepValid) {
				if (currentStep === 1) {
					verifyUniqueness(values['email'])
						.then((res) => {
							if (res.data.email_available) {
								nextStep();
							} else {
								toastNotify(res.data.user_message, 'error');
							}
						})
						.catch((error) => {
							toastNotify(error.response.data.user_message, 'error');
						});
					return;
				}
				nextStep();
			}
		}
	};

	return (
		<div onKeyDown={handleKeyDown}>
			<OverALogo color="dark" />
			{currentStep === 0 && (
				<>
					<p className="font-bold text-brand-primary text-medium mb-4">Faça seu cadastro</p>
					<p className="text-sm text-neutral-medium">
						Para entender melhor sobre o seu negócio, precisaremos coletar algumas informações.
						<br />
						<br /> Nosso objetivo é entregar uma estimativa de valor assertiva e uma análise
						completa da a sua empresa.
						<br />
						<br /> Não se preocupe, todos os seus dados estarão seguros e confidencializados com a
						gente.
					</p>
					<button
						type="button"
						className="inline-flex align-center items-center px-4 py-2 shadow-sm text-base font-sm rounded-md text-white justify-center bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full mt-4 mb-8"
						onClick={nextStep}
					>
						Continuar
					</button>
					<Link to={`/${role}/login`} className="block text-brand-secondary text-center">
						Voltar para o login
					</Link>
				</>
			)}
			<div className={currentStep === 1 ? '' : 'hidden'}>
				<p className="font-bold text-brand-primary text-large mb-4">Cadastro</p>
				<div>
					<EmailField
						onChange={(val: string, valid: boolean) => updateValue(1, 0, val, valid, 'email')}
					></EmailField>
					<PhoneField
						onChange={(val: string, valid: boolean) => updateValue(1, 1, val, valid, 'phone')}
					></PhoneField>
					<PasswordField
						showRules={true}
						onChange={(val: string, valid: boolean) => updateValue(1, 2, val, valid, 'password')}
					></PasswordField>
				</div>
				<button
					type="button"
					className={`inline-flex align-center items-center px-4 py-2 shadow-sm text-base font-sm rounded-md text-white justify-center bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full mt-4 mb-8 ${
						stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
					}`}
					onClick={handleVerifyUniqueness}
					disabled={!stepValid}
				>
					{false ? 'Finalizar cadastro' : 'Próximo'}
				</button>
				<button onClick={previousStep} className={`block mx-auto text-brand-secondary text-center`}>
					Voltar
				</button>
			</div>

			<div className={currentStep === 2 ? '' : 'hidden'}>
				<p className="font-bold text-brand-primary text-large mb-4">Cadastro</p>
				<div>
					<NameField
						onChange={(val: string, valid: boolean) => updateValue(2, 0, val, valid, 'name')}
					></NameField>
					<CPFField
						onChange={(val: string, valid: boolean) => updateValue(2, 1, val, valid, 'cpf')}
					></CPFField>
					<SectorSelect
						onChange={(val: string, valid: boolean) => updateValue(2, 2, val, valid, 'sector')}
					></SectorSelect>
				</div>
				<button
					type="button"
					className={`inline-flex align-center items-center px-4 py-2 shadow-sm text-base font-sm rounded-md text-white justify-center bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full mt-4 mb-8 ${
						stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
					}`}
					onClick={nextStep}
					disabled={!stepValid}
				>
					{false ? 'Finalizar cadastro' : 'Próximo'}
				</button>
				<button onClick={previousStep} className={`block mx-auto text-brand-secondary text-center`}>
					Voltar
				</button>
			</div>
			<div className={currentStep === 3 ? '' : 'hidden'}>
				<p className="font-bold text-brand-primary text-large mb-4">Cadastro</p>
				<div>
					<UFSelect
						onChange={(val: string, valid: boolean) => updateValue(3, 0, val, valid, 'uf')}
					></UFSelect>
					<CityField
						onChange={(val: string, valid: boolean) => updateValue(3, 1, val, valid, 'city')}
					></CityField>
				</div>
				<button
					type="button"
					className={`inline-flex align-center items-center px-4 py-2 shadow-sm text-base font-sm rounded-md text-white justify-center bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full mt-4 mb-8 ${
						stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
					}`}
					onClick={finishRegister}
					disabled={!stepValid}
				>
					{currentStep === maxStep ? 'Finalizar cadastro' : 'Próximo'}
				</button>
				<button onClick={previousStep} className={`block mx-auto text-brand-secondary text-center`}>
					Voltar
				</button>
			</div>
		</div>
	);
};

export default FinderRegister;
