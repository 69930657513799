import { DownloadIcon, SearchIcon } from '@heroicons/react/outline';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FC, useEffect, useState } from 'react';
import Loading from 'src/assets/icons/Loading';
import Input from 'src/components/Input';
import Pagination from 'src/components/Pagination';
import { Selectable } from 'src/components/ui/form/models';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import { Contract } from 'src/domain/Contract';
import { Sector } from 'src/domain/Sector';
import { useAuth } from 'src/hooks/useAuth';
import Page from 'src/layouts/Logged/components/Page';
import { getContracts } from 'src/services/contract/getContracts';
import { getSectors } from 'src/services/domain/getSectors';

interface ContractPageProps {}

const ContractPage: FC<ContractPageProps> = () => {
	const [loadingContracts, setLoadingContracts] = useState<boolean>(true);
	const [contracts, setContracts] = useState<Array<Contract>>();
	const [total, setTotal] = useState<number>(1); //Usar na paginação
	const [currentPage, setCurrentPage] = useState<number>(1); //Usar na paginação
	const [originalContracts, setOriginalContracts] = useState<Array<Contract>>([]);
	const { user } = useAuth();
	const pageLimit = 10;
	const [filterInput, setFilterInput] = useState<string>('');
	const [filter, setFilter] = useState<Selectable>({
		id: 0,
		name: 'Filtrar por',
		value: null
	});

	const [options, setOptions] = useState<Selectable[]>([]);

	const handleFilteringInput = (filter: string) => {
		setFilterInput(filter);
		setLoadingContracts(true);
		setContracts(searchTableInput(filter));
	};

	const handleFiltering = (filter: Selectable) => {
		setFilter(filter);
		setLoadingContracts(true);
		setContracts(searchTable(filter.name));
	};

	const searchTable = (sector: string): Array<Contract> => {
		setTimeout(() => {
			setLoadingContracts(false);
		}, 500);
		if (sector === 'Todos') {
			return originalContracts;
		}
		if (originalContracts) {
			return originalContracts.filter((contract) => contract.sector === sector);
		}
		return [];
	};

	const searchTableInput = (filter: string): Array<Contract> => {
		setTimeout(() => {
			setLoadingContracts(false);
		}, 500);
		if (filter === '') {
			return onPageChanged(originalContracts);
		}
		filter = filter.toLowerCase();
		if (originalContracts) {
			return onPageChanged(
				originalContracts.filter((contract) => {
					if (
						contract.contract_name.toLowerCase().includes(filter) ||
						contract.email.toLowerCase().includes(filter) ||
						contract.sector.toLowerCase().includes(filter)
					) {
						return contract;
					}
					return '';
				})
			);
		}
		return onPageChanged([]);
	};

	const onPageChanged = (value: Array<Contract>) => {
		setTotal(value.length);
		const offset = (currentPage - 1) * pageLimit;
		const currentContracts = value.slice(offset, offset + pageLimit);
		return currentContracts;
	};

	const mapperOptions = (sectors: Sector[]): Selectable[] => {
		const map = sectors.map((sector) => {
			return {
				id: sector.id,
				name: sector.name,
				slug: sector.slug,
				value: sector.id
			};
		});
		map.unshift({
			id: 17862367838,
			name: 'Todos',
			slug: 'all',
			value: 0
		});

		return map;
	};

	useEffect(() => {
		//primeira vez que loga
		getContracts(String(user?.role))
			.then((response) => {
				setTotal(response.data.length);
				setContracts(onPageChanged(response.data));
				setOriginalContracts(response.data);
			})
			.finally(() => {
				setLoadingContracts(false);
			});
		getSectors().then((response) => {
			setOptions(mapperOptions(response.data));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoadingContracts(true);
		setContracts(onPageChanged(originalContracts));
		setTimeout(() => {
			setLoadingContracts(false);
		}, 500);
		// setOriginalContracts(onPageChanged(contracts));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	return (
		<>
			<Page>
				<div className="flex justify-between items-center mb-5 w-full">
					<div className="sm:w-96">
						<Input
							placeholder="Pesquisar"
							prefixIcon={<SearchIcon color="text-neutral-medium" height={20} />}
							bgColor="bg-neutral-high"
							error={false}
							errorMessage={''}
							value={filterInput}
							onChange={(e) => {
								handleFilteringInput(e.currentTarget.value);
							}}
						/>
					</div>
					<div className="w-48 mb-5 ">
						<SingleSelect
							bgColor="bg-neutral-high"
							placeholder="Filtrar por"
							options={options}
							value={filter}
							onChange={(e: Selectable) => handleFiltering(e)}
						/>
					</div>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="w-full">
						<thead>
							<tr>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Contrato
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Data da assinatura
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Setor
								</th>
							</tr>
						</thead>
						<tbody className=" divide-y divide-neutral-medium divide-opacity-50">
							{contracts && contracts.length === 0 && !loadingContracts ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										Nenhum contrato encontrado
									</td>
								</tr>
							) : loadingContracts ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										<Loading color="primary" size={12} />
									</td>
								</tr>
							) : (
								contracts?.map((contract) => (
									<tr key={contract.contract_id}>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="flex items-center justify-start">
												<div className="text-sm font-normal text-left text-neutral-medium">
													{contract.contract_name}
												</div>
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{moment(contract.signed_at).locale('pt-br').format('DD MMM YYYY')}
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{contract.sector}
											</div>
										</td>
										<td className="text-center pr-2 py-4 whitespace-nowraptext-sm font-normal  text-neutral-medium">
											<a
												target="_blank"
												rel="noopener noreferrer"
												href={contract.contract_url}
												className="inline-flex  text-brand-secondary ml-0 hover:brightness-110 hover:saturate-100 active:scale-105"
											>
												<DownloadIcon width={20} color="" className="cursor-pointer" />
											</a>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
					<Pagination
						currentPage={currentPage}
						total={total}
						pageLimit={pageLimit}
						setCurrentPage={setCurrentPage}
						loading={loadingContracts}
					/>
				</div>
			</Page>
		</>
	);
};

export default ContractPage;
