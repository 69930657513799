import { FC } from "react";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { removeLetters } from "src/helpers";
import { Step } from "../../configs/models";

const CompanyClientAmount: FC<Step> = ({ onSubmit, getStepData, loadingStep, errors, register }) => {
	return (
		<div className="flex flex-col items-start justify-between">
			<div className="mb-4 w-full">
				<Input
					placeholder="Digite sua resposta"
					type="text"
					{...register("client_amount")}
					fieldError={errors.client_amount}
					mask="currency"
					maxLength={13}
				/>
			</div>
			<div className="flex w-full">
				<Button
					loading={loadingStep}
					disabled={Boolean(errors.client_amount)}
					onClick={(e) => {
						e.preventDefault();

						const value = getStepData("client_amount");

						if (value) return onSubmit({ client_amount: Number(removeLetters(value.toString())) });
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</div>
	);
};

export default CompanyClientAmount;
