import { FC, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'src/components/Button/Button';
import OverALogo from '../../../components/OverALogo';
import EmailField from '../../../components/ui/form/EmailField';
import PasswordField from '../../../components/ui/form/PasswordField';
import { useAuth } from '../../../hooks/useAuth';

export interface IBusinessLoginProps {}

interface IBusinessLoginError {
	email: boolean;
	password: boolean;
}

const BusinessLogin: FC = () => {
	const { login } = useAuth();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState<IBusinessLoginError>({
		email: true,
		password: true
	})
	const [loading, setLoading] = useState<boolean>(false);
	const role = 'business';

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		setLoading(true);
		login(email, password, role);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const isDisabled = (): boolean | undefined => {
		return error.email || error.password;
	};
	
	return (
		<form onSubmit={handleSubmit}>
			<OverALogo color="dark" />
			<p className="font-bold text-xl mb-6">Entrar</p>
			<div className="block mb-2">
				<EmailField 
					onChange={(val: any) => setEmail(val)} 
					onError={(isValid: boolean) =>  setError({...error, email: isValid})} 
				/>
			</div>
			<div className="block mb-8">
				<PasswordField 
					onChange={(val: any) => setPassword(val)}
					onError={(isValid: boolean) =>  setError({...error, password: isValid})}
				/>
			</div>
			<div>
				<Button
					className="text-xl"
					type="submit"
					color="secondary"
					loading={loading}
					disabled={isDisabled()}
				>
					Entrar
				</Button>
			</div>
			<p className="text-center mt-4">
				Esqueceu sua senha?{' '}
				<Link to="/business/reset_password" className="text-brand-secondary">
					Redefinir
				</Link>
				<br></br>
				Novo na OverA?{' '}
				<Link to="/business/register" className="text-brand-secondary">
					Cadastre-se
				</Link>
			</p>
		</form>
	);
};

export default BusinessLogin;
