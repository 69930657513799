import { FC, FormEvent, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import OverA from 'src/assets/images/over_a_marca_01.png';
import Button from 'src/components/Button/Button';
import EmailField from '../../../components/ui/form/EmailField';
import PasswordField from '../../../components/ui/form/PasswordField';
import { useAuth } from '../../../hooks/useAuth';
import Logo from '../images/Logo.png';
export interface IIgcLoginProps {}

interface IIgcLoginError {
	email: boolean;
	password: boolean;
}


const IgcLogin: FC<IIgcLoginProps> = () => {
	const { login } = useAuth();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState<IIgcLoginError>({
		email: true,
		password: true
	})
	const [loading, setLoading] = useState<boolean>(false);
	const role = 'igc';
	const maxLength = 8;

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		setLoading(true);
		login(email, password, role);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const isDisabled = (): boolean | undefined => {
		return error.email || error.password;
	};

	return (
		<div>
			<form onSubmit={handleSubmit} className=" flex flex-col">
				<div className=" flex justify-center items-center mb-12">
					<img className="text-center" height={45} width={184} src={OverA} alt="overa" />
				</div>
				<p className="font-bold mb-6 text-2xl text-neutral-low">Entrar</p>
				<div className="block mb-2">
					<EmailField 
						onChange={(val: any) => setEmail(val)} 
						onError={(isValid: boolean) =>  setError({...error, email: isValid})} 
					/>
				</div>
				<div className="block mb-8">
					<PasswordField 
						onChange={(val: any) => setPassword(val)}
						onError={(isValid: boolean) =>  setError({...error, password: isValid})}
					/>
				</div>
				<div>
					<Button type="submit" color="secondary" loading={loading} disabled={isDisabled()}>
						Entrar
					</Button>
				</div>
				<p className="text-center mt-4">
					Esqueceu sua senha?{' '}
					<Link to="/igc/reset_password" className="text-brand-secondary">
						Redefinir	
					</Link>
				</p>

				<div className="flex justify-center items-center mt-20">
					{' '}
					<img src={Logo} alt="man on the phone" />
				</div>
			</form>
		</div>
	);
};

export default IgcLogin;
