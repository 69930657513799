import { UploadIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import "moment/locale/pt-br";
import { FC, Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import Loading from "src/assets/icons/Loading";
import QAImg from "src/assets/images/qa.png";
import Button from "src/components/Button/Button";
import FileList from "src/components/FileList";
import Modal from "src/components/Modal";
import Upload from "src/components/Upload";
import Checkbox from "src/components/ui/base/checkbox";
import AnswerField from "src/components/ui/form/AnswerField";
import { useFiles } from "src/contexts/files";
import { Question } from "src/domain/Question";
import { toastNotify } from "src/helpers/toastNotify";
import Page from "src/layouts/Logged/components/Page";
import { getQAList } from "src/services/qa/getQAList";
import { setQA } from "src/services/qa/setQA";
interface QAPageProps {}

const QAPage: FC<QAPageProps> = () => {
	const [loadingQA, setLoadingQA] = useState<boolean>(true);
	const [questions, setQuestions] = useState<Question[]>([]);
	const [questionAnswerChanged, setQuestionAnswerChanged] = useState<
		{ questionChanged: boolean; answerChanged: boolean }[]
	>([]);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { handleUploadFiles, uploadLoading, uploadedFiles, clearUploadedFiles } = useFiles();
	const { id } = useParams();

	const updateChangedState = (questionIndex: number, questionChanged: boolean, answerChanged: boolean) => {
		const updatedQuestionAnswerChanged = [...questionAnswerChanged];
		updatedQuestionAnswerChanged[questionIndex] = {
			...updatedQuestionAnswerChanged[questionIndex],
			questionChanged,
			answerChanged
		};
		setQuestionAnswerChanged(updatedQuestionAnswerChanged);
	};

	const handleChangeAnswer = (index, value) => {
		const newQuestions = [...questions];
		newQuestions[index] = { ...newQuestions[index], answer: value };
		setQuestions(newQuestions);
		updateChangedState(index, false, true);
	};

	const updateQuestionText = (index, value) => {
		const newQuestions = [...questions];
		newQuestions[index] = { ...newQuestions[index], question: value };
		setQuestions(newQuestions);
		updateChangedState(index, true, false);
	};

	const saveQuestion = async (questionIndex: number, saveAllRequest: boolean = false) => {
		const questionToSave = { ...questions[questionIndex], business_id: Number(id) };
		const response = await setQA(questionToSave);
		if (response.status !== 200) return toastNotify("Erro ao salvar questão", "error");

		const updatedQuestion = {
			...questionToSave,
			id: response.data.id
		};

		setQuestions((prevQuestions) =>
			prevQuestions.map((prevQuestion, index) => (index === questionIndex ? updatedQuestion : prevQuestion))
		);

		!saveAllRequest && toastNotify("Questão salva", "success");
		updateChangedState(questionIndex, false, false);
	};

	const handleList = async (business_id: number) => {
		try {
			setLoadingQA(true);
			const response = await getQAList(business_id);
			setQuestionAnswerChanged(new Array(response.data.length).fill({ questionChanged: false, answerChanged: false }));
			setQuestions(response.data);
		} finally {
			setLoadingQA(false);
		}
	};

	const handleAddQuestion = () => {
		setQuestions((old) => [
			...old,
			{
				answer: "",
				approved: false,
				question: "",
				id: 0,
				business_id: 0,
				frequentlyqa: 0,
				explanation: ""
			}
		]);
		setQuestionAnswerChanged((old) => [...old, { questionChanged: false, answerChanged: false }]);
	};

	const notHaveFileToUpload = (): boolean => {
		if (uploadedFiles.length === 0) return true;
		return uploadedFiles.every((file) => file.uploaded);
	};

	const handleCloseModal = () => {
		clearUploadedFiles();
		setIsOpen(false);
	};

	const handleSaveAll = async () => {
		const savePromises = questions.map((_, index) => saveQuestion(index, true));

		const results = await Promise.allSettled(savePromises);

		const successfulSaves = results.filter((result) => result.status === "fulfilled");
		const failedSaves = results.filter((result) => result.status === "rejected");

		if (successfulSaves.length > 0) {
			const message =
				successfulSaves.length > 1
					? `${successfulSaves.length} perguntas foram salvas com sucesso`
					: `${successfulSaves.length} pergunta foi salva com sucesso`;
			toastNotify(message, "success");
		}
		if (successfulSaves.length === 0) {
			toastNotify("Ocorreu um erro! Nenhuma alteração foi salva", "error");
		} else if (failedSaves.length > 0) {
			results.forEach((result, index) => {
				if (result.status === "rejected") {
					toastNotify(`Erro ao salvar a pergunta ${index + 1}: ${questions[index].question.slice(0, 30)}...`, "error");
				}
			});
		}

		setQuestionAnswerChanged((old) =>
			old.map((item, index) => {
				if (results[index].status === "fulfilled") {
					return { questionChanged: false, answerChanged: false };
				}
				return item;
			})
		);
	};

	useEffect(() => {
		handleList(Number(id));
	}, [id]);

	if (loadingQA) {
		return (
			<div className="flex h-screen items-center justify-center">
				<Loading color="primary" size={12} />
			</div>
		);
	}

	return (
		<Fragment>
			<Page>
				<div
					id="container"
					style={{ backgroundImage: `linear-gradient(45deg, #bb712788, #70893988), url(${QAImg})` }}
					className="relative min-h-[20rem] w-full rounded-2xl bg-cover bg-center bg-no-repeat"
				>
					<label className="absolute top-60 left-10 text-[2rem] font-bold leading-[3rem] text-white">Q&A</label>
				</div>

				<div className="mt-3 mb-12 flex w-full flex-col items-center justify-start gap-2 md:flex-row">
					<Button onClick={handleAddQuestion} size="large" className="flex items-center justify-around">
						<PlusIcon className="h-6 w-6" />
						Adicionar pergunta
					</Button>

					<Button onClick={() => setIsOpen(true)} size="large" className="flex items-center justify-around">
						<UploadIcon className="h-6 w-6" />
						Carregar arquivo
					</Button>
				</div>

				{questions.map(({ question, answer, approved, explanation }, index) => (
					<section className="mb-8" key={index}>
						<div className="flex w-full flex-col">
							<AnswerField
								onChange={(value: string) => updateQuestionText(index, value)}
								placeholder="Pergunta"
								initialValue={question}
								maxLength={255}
							/>
						</div>
						<div className="mt-2 flex flex-col items-start gap-2 md:flex-row">
							<TextareaAutosize
								minRows={1}
								maxRows={5}
								placeholder="Resposta"
								value={answer}
								className="mb-2 text-sm w-full resize-none rounded-lg border bg-white py-3 pl-2 pr-3"
								onChange={(e) => handleChangeAnswer(index, e.target.value)}
							/>
							<div className="flex items-center gap-2 md:flex-col md:items-start">
								<Button
									type="button"
									size="small"
									style={{ height: 44 }}
									color="secondary"
									disabled={
										!questionAnswerChanged[index].questionChanged && !questionAnswerChanged[index].answerChanged
									}
									onClick={() => saveQuestion(index)}
								>
									Salvar
								</Button>
								<Checkbox
									option={{ id: "", label: "Aprovada" }}
									handleSelect={() => {
										questions[index].approved = !questions[index].approved;
										saveQuestion(index);
									}}
									isSelected={approved}
								/>
							</div>
							<div></div>
						</div>
					</section>
				))}
				{questions.length > 0 && (
					<Button type="button" size="small" style={{ height: 44 }} color="secondary" onClick={handleSaveAll}>
						Salvar Tudo
					</Button>
				)}
			</Page>

			<Modal
				open={isOpen}
				onClose={handleCloseModal}
				title="Adicionar arquivo"
				description="Insira abaixo o(s) arquivo(s) que deseja adicionar para o empresário. Clique em confirmar para enviar."
			>
				<Upload
					businessId={Number(id)}
					acceptFileType={{
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]
					}}
				/>
				<FileList />
				<div className="mt-7 flex justify-between">
					<Button onClick={() => handleCloseModal()} className="mr-3" color="outline-secondary">
						Cancelar
					</Button>
					<Button
						loading={uploadLoading}
						onClick={() => handleUploadFiles("igc/upload_excel_qa/")}
						className="ml-3"
						color="secondary"
						disabled={notHaveFileToUpload()}
					>
						Confirmar
					</Button>
				</div>
			</Modal>
		</Fragment>
	);
};

export default QAPage;
