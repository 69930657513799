import { FC, useEffect, useState } from "react";
import Button from "src/components/Button/Button";
import { Selectable } from "src/components/ui/form/models";
import SingleSelect from "src/components/ui/form/Selects/SingleSelect";
import { getCitiesbyState } from "src/services/domain/getCitiesbyState";
import { getStates } from "src/services/domain/getStates";
import { Step } from "../../configs/models";

const CompanyLocal: FC<Step> = ({ onSubmit, getStepData, loadingStep, errors, setValue }) => {
	const [optionsStates, setOptionsStates] = useState<Selectable[]>([]);
	const [optionsCities, setOptionsCities] = useState<Selectable[]>([]);

	const [selectedOptionsStates, setSelectedOptionsStates] = useState<Selectable>({
		// @ts-ignore
		id: getStepData("main_state_id"),
		name: getStepData("main_state"),
		value: getStepData("main_state_id")
	});

	const [selectedOptionsCities, setSelectedOptionsCities] = useState<Selectable>({
		// @ts-ignore
		id: getStepData("main_city_id"),
		name: getStepData("main_city"),
		value: getStepData("main_city_id")
	});

	const mapperOptions = (values: any[]): Selectable[] =>
		values.map(({ id, name }) => ({ id: id, name: name, value: id }));

	useEffect(() => {
		getStates().then((res) => setOptionsStates(mapperOptions(res.data)));
	}, []);

	useEffect(() => {
		const stateId = getStepData("main_state_id");

		if (stateId) {
			getCitiesbyState(stateId.toString()).then((response) => {
				setOptionsCities(mapperOptions(response.data.sort((a, b) => a.name.localeCompare(b.name))));
			});
		}
	}, [selectedOptionsStates]);

	return (
		<>
			<div className="mb-6 flex w-full">
				<div className="mr-3 w-[45%]">
					<SingleSelect
						options={optionsStates}
						onChange={(value: Selectable) => {
							if (selectedOptionsStates.id === value.id) return;

							setValue("main_state_id", value.id, { shouldDirty: true });
							setValue("main_state", value.name, { shouldDirty: true });
							setSelectedOptionsStates(value);
							// @ts-ignore
							setSelectedOptionsCities({ id: null, name: "", value: null });
						}}
						value={selectedOptionsStates}
						error={
							!selectedOptionsStates &&
							(Boolean(errors.main_state_id) || Boolean(errors.main_state))
						}
						errorMessage={errors.main_state?.message}
					/>
				</div>
				<div className="mr-1 w-[52%]">
					<SingleSelect
						options={optionsCities}
						onChange={(value: Selectable) => {
							if (selectedOptionsCities.id === value.id) return;

							setValue("main_city_id", value.id, { shouldDirty: true });
							setValue("main_city", value.name, { shouldDirty: true });
							setSelectedOptionsCities(value);
						}}
						value={selectedOptionsCities}
						error={
							!selectedOptionsCities && (Boolean(errors.main_city) || Boolean(errors.main_city_id))
						}
						errorMessage={errors.main_city?.message}
					/>
				</div>
			</div>

			<div className="flex w-full">
				<Button
					loading={loadingStep}
					disabled={!selectedOptionsStates.id || !selectedOptionsCities.id}
					size="large"
					onClick={(e) => {
						e.preventDefault();

						onSubmit({
							main_state_id: getStepData("main_state_id"),
							main_state: getStepData("main_state"),
							main_city_id: getStepData("main_city_id"),
							main_city: getStepData("main_city")
						});
					}}
				>
					Próximo
				</Button>
			</div>
		</>
	);
};

export default CompanyLocal;
