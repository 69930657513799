import { FC } from 'react';
import InputField from '../base/input';
import { Input } from './models';

const EMAIL_REGEX =
	/^([a-zA-Z0-9+\.\_\-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EmailField: FC<Input> = ({ onChange, initialValue, onError }) => {
	const validateEmail = (email: string) => {
		const isValid = EMAIL_REGEX.test(email);
		if (onError) onError(!isValid)
		return isValid;
	};

	return (
		<>
			<InputField
				initialValue={initialValue}
				fieldLabel="Email"
				type="email"
				id="email"
				placeholder="voce@exemplo.com"
				errorText="Verifique se o email foi digitado corretamente"
				validateValue={validateEmail}
				onChange={onChange}
			/>
		</>
	);
};

export default EmailField;
