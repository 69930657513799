import { removeSpecialChars } from 'src/helpers';
import { formatBusinessForm } from './formatBusinessForm';
import { formatFinderForm } from './formatFinderForm';

export const registerUser = async (
	// token: string,
	// setToken: Dispatch<SetStateAction<string>>,
	userRole: string,
	registerForm: any
) => {
	let requestBody = '';
	if (userRole === 'finder') {
		requestBody = formatFinderForm(registerForm);
	} else if (userRole === 'business') {
		requestBody = formatBusinessForm(registerForm);
	}

	return fetch(`${process.env.REACT_APP_API_URL ?? 'http://localhost:8000'}/${userRole}/user/`, {
		headers: {
			'Content-Type': 'application/json'
			// Authorization: `Bearer ${token}`
		},
		method: 'POST',
		body: requestBody
	}).then(async (x) => {
		if (x.status === 200) {
			const response = await x.json()
			return { email: registerForm.email, password: registerForm.password, user_message: response.user_message };
		} else {
			return await x.json();
		}
	});
};
