import { ErrorMessage, Field } from "formik";
import InputMask from "react-input-mask";

const PreRegisterInputPhone = ({ label, name, placeholder, type, responsiveStyle }) => {
    return (
        <div className={responsiveStyle}>
            <label htmlFor={name} className="mb-2 block text-sm font-medium text-auxiliar-highlight-orange">
                {label}
            </label>
            <Field
                render={({ field }) => (
                    <InputMask
                        {...field}
                        mask="(99) 99999-9999"
                        placeholder="(00) 0000-0000"
                        className="w-full rounded-lg border border-b-auxiliar-grey-500 px-4 py-3 text-sm"
                    />
                )}
                type={type}
                id={name}
                name={name}
                placeholder={placeholder}
            />
            <ErrorMessage className="text-[12px] text-red-500" name={name} component="p" />
        </div>
    );
};

export default PreRegisterInputPhone;