import { DownloadIcon } from '@heroicons/react/outline';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FC, useEffect, useState } from 'react';
import Loading from 'src/assets/icons/Loading';
import Tag from 'src/components/Tag';
import { Selectable } from 'src/components/ui/form/models';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import { Contract } from 'src/domain/Contract';
import { useAuth } from 'src/hooks/useAuth';
import Page from 'src/layouts/Logged/components/Page';
import { getContractsByCompanyId } from 'src/services/contract/getContractsByCompanyId';
interface ContractPageProps {}
const ContractPage: FC<ContractPageProps> = () => {
	const [loadingContracts, setLoadingContracts] = useState<boolean>(true);
	// const [options, setOptions] = useState<Array<Selectable>>([]);
	const [optionSelected, setOptionSelected] = useState<Selectable>({} as Selectable);
	const [contracts, setContracts] = useState<Array<Contract>>();
	const [originalContracts, setOriginalContracts] = useState<Array<Contract>>();
	const { user } = useAuth();

	const options: Array<Selectable> = [
		{
			id: 1,
			slug: 'approved',
			name: 'Assinado',
			value: true
		},
		{
			id: 2,
			slug: 'pending',
			name: 'Aguardando assinatura',
			value: false
		},
		{
			id: 3,
			slug: 'all',
			name: 'Todos',
			value: null
		}
	];

	useEffect(() => {
		getContractsByCompanyId(String(user?.role), Number(user?.business_id))
			.then((response) => {
				setContracts(response.data);
				setOriginalContracts(response.data);
			})
			.finally(() => {
				setLoadingContracts(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleFiltering = (value: Selectable) => {
		setLoadingContracts(true);
		setContracts(searchTable(value.value));
		setOptionSelected(value);
	};

	const searchTable = (signed: boolean | null) => {
		setTimeout(() => {
			setLoadingContracts(false);
		}, 500);
		if (signed === null) {
			return originalContracts;
		}
		if (originalContracts) {
			return originalContracts.filter((contract) => contract.signed === signed);
		}
	};
	return (
		<>
			<Page>
				<h1 className="text-brand-primary text-2xl font-bold mb-6">Contratos</h1>
				<div className="w-96 mb-6 ">
					<SingleSelect
						placeholder="Filtrar por status"
						bgColor="bg-neutral-high"
						options={options}
						value={optionSelected}
						onChange={(e: any) => {
							handleFiltering(e);
						}}
					/>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="w-full">
						<thead className="">
							<tr>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Contrato
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Data de envio
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Responsável
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Status
								</th>
								<th scope="col" className="relative px-6 py-3 text-sm font-normal text-neutral-low">
									Download
								</th>
							</tr>
						</thead>
						<tbody className=" divide-y divide-neutral-medium divide-opacity-50">
							{contracts && contracts.length === 0 && !loadingContracts ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										Nenhum contrato encontrado
									</td>
								</tr>
							) : loadingContracts ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										<Loading color="primary" size={12} />
									</td>
								</tr>
							) : (
								contracts?.map((contract) => (
									<tr key={contract.contract_id}>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="flex items-center justify-start">
												<div className="text-sm font-normal text-left text-neutral-medium">
													{contract.contract_name}
												</div>
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{moment(contract.created_at).locale('pt-br').format('DD MMM YYYY')}
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{contract.manager || 'Sem responsável'}
											</div>
										</td>
										<td className=" pr-6 py-4 whitespace-nowrap text-sm font-normal text-neutral-medium">
											<Tag status={String(contract.signed)} />
										</td>
										<td className="text-center pr-2 py-4 whitespace-nowraptext-sm font-normal  text-neutral-medium">
											<a
												href={contract.contract_url}
												className="inline-flex  text-brand-secondary ml-0 hover:brightness-110 hover:saturate-100 active:scale-105"
											>
												<DownloadIcon width={20} color="" />
											</a>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>
			</Page>
		</>
	);
};

export default ContractPage;
