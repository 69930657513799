import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Investor } from 'src/domain/Investor';
import { getInvestorList } from 'src/services/investor/getInvestorList';
import Select from '../../base/select';
import { Input, Selectable } from '../models';

const InvestorSelect: FC<Input> = ({ onChange, initialValue }) => {
	const [investors, setInvestors] = useState<Selectable[]>([]);

	const { id } = useParams();

	useEffect(() => {
		getInvestorList('igc', Number(id)).then(({ data }) => {
			setInvestors(mapData(data));
		});
	}, [id]);

	const handleChange = (selected: Selectable[]) => {
		const selecteds = selected.map(function (obj) {
			return obj.id;
		});
		onChange(selecteds, true);
	};

	const mapData = (interestedInvestor: Array<any>): Array<Selectable> => {
		let newArray: Array<Selectable> = [{ id: 0, name: '', value: 0 }];
		newArray = interestedInvestor.map((ob: Investor) => {
			return {
				id: ob.buyerId,
				name: `${ob.buyer} (${ob.buyerSector})`,
				value: ob.buyerId
			};
		});

		return newArray;
	};

	return (
		<>
			<Select
				//initialValue={initialValue}
				fieldLabel="Setor"
				selectableValues={investors}
				singleSelect={false}
				onChange={handleChange}
			/>
		</>
	);
};

export default InvestorSelect;
