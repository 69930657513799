import { XIcon } from '@heroicons/react/solid';
import { FC } from 'react';

interface TargetModalProps {
	modalOpen: boolean;
	handleModalClose: () => void;
	children: React.ReactNode;
}

const TargetModal: FC<TargetModalProps> = ({ modalOpen, handleModalClose, children }) => {
	return (
		<div className={`${!modalOpen && "hidden"} absolute top-0 left-0 z-50 h-screen w-screen bg-auxiliar-grey-500/50`}>
			<div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
				<div className="relative flex h-[90vh] w-[95vw] flex-col items-center justify-start rounded-xl bg-white px-4 pt-8 pb-4 md:w-[80vw] xl:w-[70vw] 2xl:w-[60w]">
					<button className="absolute right-3 top-3" onClick={() => handleModalClose()}>
						<XIcon className="h-6 w-6 text-brand-primary" />
					</button>
					{children}
				</div>
			</div>
		</div>
	);
};

export default TargetModal;
