import { FC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import business from './images/business.jpg';
import { ArrowLeftIcon } from '@heroicons/react/solid';

export interface IBusinessSessionProps {}

const BusinessSession: FC<IBusinessSessionProps> = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="max-h-screen ">
				<div className="grid md:grid-cols-[1.5fr_2fr] sm:grid-cols-1 overflow-hidden">
					<div className="flex hidden md:block w-full h-full max-h-screen object-fill">
						<img
							className="h-screen max-h-full w-full object-cover object-top"
							src={business}
							alt="businesswoman with glasses"
						/>
					</div>
					<div className="flex w-full items-center justify-center overflow-auto max-h-screen px-4 relative">
						<span 
							className='absolute flex top-2 left-4 items-center cursor-pointer' 
							onClick={() => navigate('/')}
						>
							<ArrowLeftIcon width={24} className='pr-2'/>
							Voltar
						</span>
						<div className="w-[500px]">
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BusinessSession;
