import CloseMenu from "../images/close-menu.svg";
import OverALogoWhite from "../images/overa-logo-white.svg";
import YoutubeBrown from "../images/youtube-brown.svg";
import LinkedinBrown from "../images/linkedin-brown.svg";
import { useNavigate } from "react-router-dom";

interface SideMenuProps {
	signed: boolean;
	handleNavigate: (path: "register" | "login") => void;
	handleNavigateDashboard: (path: "dashboard") => void;
	setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideMenu = ({
	signed,
	handleNavigateDashboard,
	handleNavigate,
	setMenuOpen
}: SideMenuProps) => {
	const navigate = useNavigate()

	return (
		<section className="side-menu fixed z-10 h-[100vh] w-[100vw] bg-brand-secondary py-8 pt-4">
			<div className="mb-8 flex items-center justify-around">
				<img src={OverALogoWhite} className="mb-2 h-[50px] w-[250px]" alt="OverA Logo" />
				<button
					onClick={() => {
						setMenuOpen((prevState) => !prevState);
					}}
				>
					<img src={CloseMenu} alt="Hamburg Menu Icon" />
				</button>
			</div>
			<div className="mb-8 flex items-center justify-center gap-4">
				<button
					className="m-4 rounded-[40px] bg-white py-1 px-5
                        text-base font-semibold uppercase text-brand-secondary"
					onClick={() => { setMenuOpen((prevState) => !prevState) }}
				>
					<a href="/#pre-register">Cadastro</a>
				</button>
				<button
					className="m-4 rounded-[40px] bg-white py-1 px-5
                        text-base font-semibold uppercase text-brand-secondary"
				>
					<a href="https://client.overacapital.com.br">Login</a>
				</button>
			</div>
			<div className="mb-8 flex items-center justify-center gap-4">
				<a href="https://www.linkedin.com/company/over-a-capital/" target="_blank" rel="noreferrer">
					<img src={LinkedinBrown} alt="Linkedin Icon" />
				</a>
				<a href="#" target="_blank" rel="noreferrer">
					<img src={YoutubeBrown} alt="Youtube Icon" />
				</a>
			</div>
		</section>
	);
};

export default SideMenu;
