import { initializeApp } from "firebase/app";
import { ReCaptchaV3Provider, initializeAppCheck } from "firebase/app-check";
import {
	getFirestore,
	collection,
	getDoc,
	getDocs,
	setDoc,
	doc,
	CollectionReference,
	Timestamp,
	updateDoc,
	DocumentData
} from "firebase/firestore";
import { FullMetadata, getDownloadURL, getMetadata, getStorage, ref, uploadBytes } from "firebase/storage";
interface IUser {
	isAdmin: boolean;
	id: number;
	userFullName: string;
	company?: string;
	last_login?: Date;
	last_chat_view?: Date;
}

interface IChat {
	id: number;
	membersIds: number[];
	lastMessage: number;
	senderLastMessage: number;
}

interface IMessage {
	chatId: number;
	senderId: number;
	text: string;
	senderRole: "igc" | "business";
	createdAt?: number;
}

export interface FileMetadata extends FullMetadata {
	url: string;
}

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_FIREBASE_APPID
};

const app = initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "development") {
	const token = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;

	Object.assign(window, {
		FIREBASE_APPCHECK_DEBUG_TOKEN: token
	});
}

const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider(process.env.REACT_APP_APPCHECK!),
	isTokenAutoRefreshEnabled: true
});

export const db = getFirestore(appCheck.app);

export const storage = getStorage(app);

export const createUser = async (userId: number, userFullName: string, company: string) => {
	await addUser({
		isAdmin: false,
		id: userId,
		userFullName: userFullName,
		company: company
	});
};

export const getCurrentFirebaseTimestamp = () => {
	const firebaseTimestamp = Timestamp.fromDate(new Date());
	const timestamp = new Date(firebaseTimestamp.seconds * 1000).getTime();

	return timestamp;
};

export const handleAdminCreation = async (userId: number, userFullName: string) => {
	const adminAlreadyExists = await adminExists(userId);

	if (adminAlreadyExists) return;

	await addUser({
		isAdmin: true,
		id: userId,
		userFullName: userFullName
	});
};

const createChat = async (userId: number) => {
	try {
		const timestamp = getCurrentFirebaseTimestamp();

		const chatRef = doc(db, "chats", userId.toString());

		await setDoc(chatRef, {
			id: parseInt(userId.toString()),
			membersIds: [userId],
			lastMessage: timestamp
		});

		const messagesRef = doc(db, "chats", userId.toString(), "messages", timestamp.toString());
		await setDoc(messagesRef, {
			text: "Bem vindo à OverA Capital! Aqui você pode conversar conosco e tirar suas dúvidas.",
			createdAt: timestamp,
			senderId: 0
		});

		if (process.env.NODE_ENV === "development") {
			console.log("Chat created!");
		}

		return parseInt(chatRef.id);
	} catch (e) {
		console.error("Error creating chat : ", e);
	}
};

const addUser = async (user: IUser) => {
	const timestamp = getCurrentFirebaseTimestamp();

	try {
		const userRef = doc(db, user.isAdmin ? "admins" : "users", user.id.toString());

		const chatRef = !user.isAdmin && (await createChat(user.id));

		const regularUserObj = {
			name: user.userFullName,
			company: user.company,
			chatsIds: [chatRef],
			id: user.id,
			type: "regular",
			last_login: timestamp
		};

		const adminUserObj = {
			name: user.userFullName,
			company: "igc",
			id: user.id,
			type: "admin",
			last_login: timestamp
		};

		await setDoc(userRef, user.isAdmin ? adminUserObj : regularUserObj);

		if (process.env.NODE_ENV === "development") {
			console.log("User added!");
		}
	} catch (e) {
		console.error("Error adding new user: ", e);
	}
};

export const addNewMessage = async (message: IMessage) => {
	const timestamp = getCurrentFirebaseTimestamp();

	try {
		const messagesRef = doc(db, "chats", message.chatId.toString(), "messages", timestamp.toString());

		await setDoc(messagesRef, {
			text: message.text,
			createdAt: timestamp,
			senderId: message.senderId,
			senderRole: message.senderRole
		});

		if (process.env.NODE_ENV === "development") {
			console.log("Message added!");
		}
	} catch (e) {
		console.error("Error adding new message: ", e);
	}
};

export const getAllChats = async () => {
	const chatsRef = collection(db, "chats");
	const chatsSnapshot = await getDocs(chatsRef);
	const chats = chatsSnapshot.docs.map((doc) => doc.data());

	return chats;
};

const getUserByIdNCollection = async (userId: number, collection: CollectionReference) => {
	const userSnapshot = await getDocs(collection);
	const users = userSnapshot.docs.map((doc) => doc.data());
	const user = users.find((user) => user.id === userId);

	return user;
};

export const getUserObject = async (userId: number) => {
	const userRef = collection(db, "users");
	return getUserByIdNCollection(userId, userRef);
};

const getAdminObject = async (userId: number) => {
	const userRef = collection(db, "admins");
	return getUserByIdNCollection(userId, userRef);
};

export const getNameById = async (userId: number) => {
	const user = await getUserObject(userId);
	if (!user) return null;
	return user.name.toString();
};

export const userExists = async (userId: number) => {
	const user = await getUserObject(userId);
	return user ? true : false;
};

export const adminExists = async (userId: number) => {
	const admin = await getAdminObject(userId);
	return admin ? true : false;
};

export const updateUser = async (
	userType: "admins" | "users",
	userId: number,
	changes: "last_login" | "last_chat_view"
) => {
	const timestamp = getCurrentFirebaseTimestamp();

	try {
		const userRef = doc(db, userType, userId.toString());

		updateDoc(userRef, {
			[changes]: timestamp
		});
	} catch (err) {
		console.error(err);
	}
};

export const updateLastMessageInChat = (userId: number, chatId: number) => {
	const timestamp = getCurrentFirebaseTimestamp();

	try {
		const chatRef = doc(db, "chats", chatId.toString());

		updateDoc(chatRef, {
			lastMessage: timestamp,
			senderLastMessage: userId
		});
	} catch (err) {
		console.log(err);
	}
};

export const checkIfUserHasNewMessages = async (userId: number) => {
	try {
		const userSnap = (await getDoc(doc(db, "users", userId.toString()))).data() as IUser;

		const chatRef = (await getDoc(doc(db, "chats", userId.toString()))).data() as IChat;

		if (!chatRef) return false;
		if (!userSnap.last_chat_view) return true;

		if (new Date(chatRef.lastMessage) > new Date(userSnap.last_chat_view) && userId !== chatRef.senderLastMessage) {
			return true;
		}

		return false;
	} catch (err) {
		console.error(err);
	}
};

export const checkIfAdminHasNewMessages = async (adminId: number) => {
	const returnedObject: { haveNewMessages: boolean; newMessagesChatId: number[] } = {
		haveNewMessages: false,
		newMessagesChatId: []
	};

	const chatsObject: DocumentData[] = [];

	try {
		const adminSnap = (await getDoc(doc(db, "admins", adminId.toString()))).data() as IUser;

		const chatsSnap = await getDocs(collection(db, "chats"));

		if (!adminSnap.last_chat_view || !chatsSnap) return returnedObject;

		chatsSnap.forEach((item) => chatsObject.push(item.data()));

		for (const chat of chatsObject) {
			if (new Date(chat.lastMessage) > new Date(adminSnap.last_chat_view) && chat.senderLastMessage !== adminId) {
				returnedObject.newMessagesChatId.push(chat.id);
			}
		}

		if (returnedObject.newMessagesChatId.length > 0) {
			returnedObject.haveNewMessages = true;
			return returnedObject;
		} else {
			return returnedObject;
		}
	} catch (err) {
		console.error(err);
		return returnedObject;
	}
};

export function uploadChatFile(file: File, userId: number | undefined) {
	const imageRef = ref(storage, `chat/${userId}/${file.name}`);

	return uploadBytes(imageRef, file);
}

export async function getFileFromStorage(fileName: string, userId: number | undefined): Promise<FileMetadata> {
	const storageRef = ref(storage, `chat/${userId}/${fileName}`);

	const metadata = await getMetadata(storageRef);

	const url = await getDownloadURL(storageRef);

	return { ...metadata, url };
}
