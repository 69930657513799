import { Fragment, FC, useEffect, useState, useMemo } from 'react';
import { SearchIcon, ClockIcon, PlusCircleIcon } from '@heroicons/react/outline';
import Loading from 'src/assets/icons/Loading';
import Input from 'src/components/Input';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Selectable } from 'src/components/ui/form/models';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import { InvestorsDataDTO, getInvestors, InvestorsParams } from 'src/domain/Investor';
import Header from 'src/layouts/Logged/components/Header';
import Page from 'src/layouts/Logged/components/Page';
import { getSectors } from 'src/services/domain/getSectors';
import Button from 'src/components/Button/Button';
import InvestorModal from 'src/components/InvestorModal';
import TargetModal from 'src/components/TargetModal';
import { getTargets, TargetDataDTO } from 'src/domain/Target';
import { debounce, formatCriteria } from 'src/services/Utils';
import { formatCnpj } from 'src/helpers';

const IgcInvestor: FC = () => {
	const [isLoadingSectorsOptions, setIsLoadingSectorsOptions] = useState<boolean>(false);
	const [sectorsOptions, setSectorsOptions] = useState<Selectable[]>([]);

	const [selectedSector, setSelectedSector] = useState<Selectable>();

	const [isLoadingInvestors, setIsLoadingInvestor] = useState<boolean>(false);
	const [investors, setInvestors] = useState<InvestorsDataDTO[]>([]);

	const [expandedRow, setExpandedRow] = useState<number | null>(null);
	const [isLoadingRow, setIsLoadingRow] = useState<boolean>(false);

	const [targets, setTargets] = useState<TargetDataDTO[]>([]);

	const [isOpenInvestorModal, setOpenInvestorModal] = useState<boolean>(false);
	const [isOpenTargetModal, setOpenTargetModal] = useState<boolean>(false);

	const [query, setQuery] = useState<InvestorsParams>({
		page: 1,
		size: 10,
		total_pages: 0,
		search_string: null,
		sector_id: null
	});

	const fetchTableData = async () => {
		const { data: investors } = await getInvestors({
			page: query.page,
			size: query.size,
			sector_id: query.sector_id,
			search_string: query.search_string
		});
		setInvestors(investors.data);
		setQuery((obj) => ({ ...obj, total_pages: investors.total_pages }));
	};

	const fetchTargetData = async (investorID: number | null) => {
		if (!investorID) return;

		const { data: targets } = await getTargets(investorID);
		setTargets(targets);
	};
	const expandRow = async (item: InvestorsDataDTO) => {
		if (expandedRow === item.id) return setExpandedRow(null);

		setIsLoadingRow(true);
		setExpandedRow(item.id);
		fetchTargetData(item.id);
		setIsLoadingRow(false);
	};

	useEffect(() => {
		(async () => {
			setIsLoadingSectorsOptions(true);
			const { data: sectors } = await getSectors();
			setIsLoadingSectorsOptions(false);
			setSectorsOptions(sectors.map(({ id, name }) => ({ id, name, value: name })));
		})();
	}, [setIsLoadingSectorsOptions, setSectorsOptions]);

	useEffect(() => {
		fetchTableData();
	}, [query.page, query.size, query.sector_id, query.search_string]);

	return (
		<Fragment>
			<Header
				title="Investidores"
				button={
					<div className="grid grid-cols-2 items-center justify-center gap-2">
						<Button onClick={() => setOpenInvestorModal(true)}>Adicionar Investidor</Button>
						{isLoadingSectorsOptions ? (
							'Carregando opcoes'
						) : (
							<SingleSelect
								placeholder="Setor"
								options={sectorsOptions}
								value={selectedSector}
								onChange={(newValue) => {
									setQuery((obj) => ({
										...obj,
										sector_id: newValue === selectedSector ? null : newValue.id
									}));
									setSelectedSector(newValue === selectedSector ? undefined : newValue);
								}}
							/>
						)}
					</div>
				}
			/>

			{isOpenInvestorModal && (
				<InvestorModal
					isOpen={isOpenInvestorModal}
					setOpen={setOpenInvestorModal}
					fetchTableData={fetchTableData}
				/>
			)}

			{isOpenTargetModal && (
				<TargetModal
					isOpen={isOpenTargetModal}
					setOpen={setOpenTargetModal}
					investorID={expandedRow}
					fetchTargetData={fetchTargetData}
				/>
			)}

			<Page>
				<div className="mb-6 flex w-full justify-center sm:justify-start">
					<div className="w-full sm:w-96">
						<Input
							placeholder="Pesquisar"
							prefixIcon={<SearchIcon color="" height={20} />}
							bgColor="bg-neutral-high"
							error={false}
							errorMessage=""
							onChange={debounce(({ target }) => {
								setQuery((obj) => ({ ...obj, search_string: target.value }));
							}, 450)}
						/>
					</div>
				</div>

				<section className="w-full rounded-md bg-white shadow-lg">
					<div className="grid grid-cols-3 border-b border-auxiliar-grey-300 py-4 px-2 font-bold">
						<p>Investidor</p>

						<p>Website</p>

						<p>CNPJ</p>
					</div>

					<div className=" divide-neutral-medium divide-y divide-opacity-50">
						{isLoadingInvestors && (
							<div className="text-center text-2xl font-bold">
								<div className="py-4 pr-6">
									<Loading color="primary" size={12} />
								</div>
							</div>
						)}

						{!isLoadingInvestors && investors?.length === 0 && (
							<div className="text-center text-2xl font-bold">
								<div className="py-4 pr-6">Nenhum investidor encontrado</div>
							</div>
						)}

						{!isLoadingInvestors &&
							investors?.length !== 0 &&
							investors?.map((item, index) => (
								<Fragment key={index}>
									<div className="grid grid-cols-3 border-auxiliar-grey-300 py-4 px-2">
										<p>{item.trade_name ?? ''}</p>
										<p>{item.website ?? ''}</p>
										<p className="flex justify-between pr-20">
											{item.cnpj ? formatCnpj(item.cnpj) : 'N/A'}
											<span>
												{expandedRow === item.id && isLoadingRow ? (
													<ClockIcon height={20} />
												) : (
													<ChevronDownIcon
														className="cursor-pointer"
														onClick={() => expandRow(item)}
														height={20}
													/>
												)}
											</span>
										</p>
									</div>
									{expandedRow === item.id &&
										!isLoadingRow &&
										(targets.length === 0 ? (
											<div className="align-center flex justify-center gap-14 border-auxiliar-grey-300 bg-auxiliar-grey-100 py-4 px-2">
												<p>Não há nenhum target relacionado a esse investidor!</p>
												<p>
													<PlusCircleIcon
														height={20}
														className="cursor-pointer"
														onClick={() => setOpenTargetModal(true)}
													/>
												</p>
											</div>
										) : (
											<>
												{targets.map((target) => (
													<div className="grid grid-cols-3 border-auxiliar-grey-300 bg-auxiliar-grey-100 py-4 px-2">
														<p>{target.criteria ? formatCriteria(target.criteria) : ''}</p>
														<p>{target.contact.contact_email ?? ''}</p>
														<p>{target.sub_sector.name ?? ''}</p>
													</div>
												))}
												<div className="align-center flex justify-center gap-14 border-auxiliar-grey-300 bg-auxiliar-grey-100 py-4 px-2">
													<p>Você pode adicionar um target!</p>
													<p>
														<PlusCircleIcon
															height={20}
															className="cursor-pointer"
															onClick={() => setOpenTargetModal(true)}
														/>
													</p>
												</div>
											</>
										))}
								</Fragment>
							))}
					</div>
				</section>
				<div className="mt-6 flex w-40 gap-2">
					<Button
						onClick={() => setQuery((obj) => ({ ...obj, page: query.page - 1 }))}
						disabled={query.page === 1}
					>
						Voltar a página
					</Button>
					<Button
						onClick={() => setQuery((obj) => ({ ...obj, page: query.page + 1 }))}
						disabled={query.page === query.total_pages}
					>
						Próxima página
					</Button>
				</div>
			</Page>
		</Fragment>
	);
};

export default IgcInvestor;
