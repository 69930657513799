import { CheckIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import { BusinessDefaultSteps, BusinessStageType } from 'src/domain/BusinessStep';
import { mapIconsToComponents } from '../Utils';

interface DashboardStepsProps {
	businessSteps: BusinessStageType[] | undefined;
}

const Steps: FC<DashboardStepsProps> = ({ businessSteps }) => {
	return (
		<ol className="mb-3 flex items-center justify-center space-x-2 overflow-y-auto rounded-lg p-3 text-sm font-medium sm:space-x-2 sm:p-4 sm:text-base">
			{businessSteps &&
				businessSteps.map((stage, index) => (
					<li className="text-blue-600 flex w-min items-center" key={index}>
						<span
							className={`mr-2 hidden h-10 w-10 shrink-0 items-center justify-center rounded-full bg-white p-1 text-xs text-brand-secondary lg:flex ${
								stage.complete && 'bg-brand'
							} ${stage.current && 'border-4 border-double'}`}
						>
							{stage.complete ? (
								<CheckIcon className="h-8 w-8 text-white" />
							) : (
								mapIconsToComponents(stage.slug)
							)}
						</span>
						<span
							className={`${
								stage.complete
									? 'text-brand-primary'
									: stage.current && 'font-semibold text-brand-secondary'
							}`}
						>
							{stage.name}
						</span>
						{index !== BusinessDefaultSteps.length - 1 && (
							<div className="ml-1 whitespace-nowrap sm:ml-2">
								<ChevronDoubleRightIcon className="h-4 w-4" />
							</div>
						)}
					</li>
				))}
		</ol>
	);
};

export default Steps;
