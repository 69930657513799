import ReactTooltip from 'react-tooltip';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { FC } from 'react';

interface ITooltip {
	text: string;
	id: number;
}

const Tooltip: FC<ITooltip> = ({ text, id }) => {
	return (
		<>
			<a
				data-tip
				data-for={`tooltipId-${id}`}
				data-event="click"
				className="flex items-center cursor-pointer ml-2"
			>
				<InformationCircleIcon height={24} />
			</a>

			<ReactTooltip id={`tooltipId-${id}`} place="bottom" effect="solid" clickable={true}>
				{text.split('\n').map((str) => (
					<p>{str}</p>
				))}
			</ReactTooltip>
		</>
	);
};

export default Tooltip;
