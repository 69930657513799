export const formatCpf = (cpf: string) => {
	// const cleanCpf = removeSpecialChars(cpf) && removeLetters(cpf);

	// const splitIndex = cleanCpf.length - 2;
	// const beforeHifen = cleanCpf.substring(0, splitIndex);
	// const afterHifen = cleanCpf.substring(splitIndex, cleanCpf.length);

	// const beforeHifenFormatted = beforeHifen.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

	// const cpfFinal = `${beforeHifenFormatted}-${afterHifen}`;
	// return cpf !== '' ? cpfFinal : '';

	const cleanCpf = cpf.replace(/[^0-9 ]/g, '');
	if (cleanCpf.length <= 3) {
		return cleanCpf;
	}
	let chooseReplaceString: number = 0;

	if (cleanCpf.length < 7) {
		chooseReplaceString = 0;
	} else if (cleanCpf.length < 10) {
		chooseReplaceString = 1;
	} else {
		chooseReplaceString = 2;
	}

	const replaceStrings = ['$1.$2', '$1.$2.$3', '$1.$2.$3-$4'];

	const replaceString = replaceStrings[chooseReplaceString];
	return cleanCpf
		.replace(/(\d{1,3})?(\d{1,3})?(\d{1,3})?(\d{1,2})?/, replaceString)
		.substring(0, 14);
};
