import { CompanyInStep } from 'src/domain/CompanyInStep';
import client from '../client';

type step = {
	step_id: number;
};

export const getCompaniesByDemand = async (userRole: string, queryParams: step) => {
	return client.get<CompanyInStep>(`${userRole}/step/`, { params: queryParams });
};
