/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis } from 'recharts';
import { DemandsChartApiData } from 'src/domain/Demands';
import { useAuth } from 'src/hooks/useAuth';
import { getDemands } from 'src/services/charts/getDemands';
import { object } from 'yup';

type DemandsChartData = {
	value: number;
	name: string;
};

export const DemandsChart: FC<{ changeStepId: any }> = ({ changeStepId }) => {
	const { user } = useAuth();
	const [chartData, setChartData] = useState<DemandsChartData[]>([
		{
			name: 'Preparação',
			value: 0
		},
		{ name: 'Roadshow', value: 0 },
		{ name: 'Negociação', value: 0 },
		{ name: '1st Stage of Diligence', value: 0 },
		{ name: 'Diligência', value: 0 },
		{ name: 'Negociação de Contrato de Venda', value: 0 }
	]);
	const [currentIndex, setCurrentIndex] = useState<number>(0);

	useEffect(() => {
		getDemands(String(user?.role)).then((response) => {

			Object.entries(response.data).forEach(object => {
				const key = object[0].split(' ').join('_');
				response.data = {
					...response.data,
					[key]: object[1]
				};
			})
 
			setChartData([
				{
					name: 'Preparação',
					value: response.data.preparacao
				},
				{ name: 'Roadshow', value:  response.data.roadshow },
				{ name: 'Negociação e Diligência', value: response.data.negociacao_e_diligencia},
				{ name: 'Negociação de Contrato de Venda', value: response.data.transação_concluida }
			]);
		});
	}, [user]);

	const handleClick = (data, index) => {
		setCurrentIndex(index);
		changeStepId(index + 1);
	};

	function CustomizedLabelTick(props) {
		const { x, y } = props;
		const label = props.payload.value;
		const labelParts = stringDivider(label, 20, '\n').split('\n');

		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={-20} fill="#000">
					{labelParts.map((entry, index) => (
						<tspan textAnchor="middle" x="0" dy={index * 20}>
							{entry}
						</tspan>
					))}
				</text>
			</g>
		);
	}

	function stringDivider(str, width, spaceReplacer) {
		if (str.length > width) {
			var p = width;
			for (; p > 0 && str[p] !== ' '; p--) {}
			if (p > 0) {
				var left = str.substring(0, p);
				var right = str.substring(p + 1);
				return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
			}
		}
		return str;
	}

	function CustomizedValueTick(props) {
		const { x, y } = props;
		const label = props.payload.value;

		return (
			<g transform={`translate(${x},${y})`}>
				<text x={0} y={0} fill="#000">
					<tspan fontWeight="bold" fontSize="1,5rem" textAnchor="middle" x="0" dy={10}>
						{label || 0}
					</tspan>

					<tspan
						fontWeight="bold"
						fontSize="1rem"
						fill="#BB7127"
						textAnchor="middle"
						x="0"
						dy={20}
						cursor="pointer"
					>
						<a onClick={() => handleClick(0, props.index+3)}>{'Ver Demandas'}</a>
					</tspan>
				</text>
			</g>
		);
	}

	return (
		<ResponsiveContainer width={'100%'} height={400}>
			{
				<BarChart
					data={chartData}
					margin={{
						top: 40,
						right: 30,
						left: 20,
						bottom: 15
					}}
				>
					<Bar dataKey="value" radius={[5, 5, 0, 0]} onClick={handleClick}>
						{chartData.map((entry, index) => (
							<Cell
								cursor="pointer"
								fill={index === currentIndex ? '#BB7127' : '#bb7127'}
								key={`cell-${index}`}
							/>
						))}
					</Bar>

					<XAxis
						xAxisId="labels"
						dataKey="name"
						axisLine={false}
						tickLine={false}
						orientation="top"
						interval={0}
						tick={CustomizedLabelTick}
					/>
					<XAxis dataKey="value" axisLine={false} tickLine={false} tick={CustomizedValueTick} />
				</BarChart>
			}
		</ResponsiveContainer>
	);
};
