import { removeLetters } from "./stringHelpers";

const validMoney = (value: string) => {
	if (value == "NaN" || value.length === 0) return "";
	return `R$ ${value}`;
};

export const formatMoney = (money: string) => {
	let negative = money.includes("-") ? "-" : "";
	let value = removeLetters(money);

	if (value.length > 3 && value.length <= 6) {
		value = negative + value.replace(/(\d)(\d{3}$)/g, "$1.$2");
		return validMoney(value);
	}
	if (value.length > 6 && value.length <= 9) {
		value = negative + value.replace(/(\d)(\d{3})(\d{3}$)/g, "$1.$2.$3");
		return validMoney(value);
	}
	if (value.length > 9 && value.length <= 12) {
		value = negative + value.replace(/(\d)(\d{3})(\d{3})(\d{3}$)/g, "$1.$2.$3.$4");
		return validMoney(value);
	}
	if (value.length > 12 && value.length <= 15) {
		value = negative + value.replace(/(\d)(\d{3})(\d{3})(\d{3})(\d{3}$)/g, "$1.$2.$3.$4.$5");
		return validMoney(value);
	}
	if (value.length > 15 && value.length <= 18) {
		value =
			negative + value.replace(/(\d)(\d{3})(\d{3})(\d{3})(\d{3})(\d{3}$)/g, "$1.$2.$3.$4.$5.$6");
		return validMoney(value);
	}
	value =
		negative +
		value.replace(/(\d)(\d{3})(\d{3})(\d{3})(\d{3})(\d{3})(\d{3}$)/g, "$1.$2.$3.$4.$5.$6.$7");
	return validMoney(value);
};
