import { Auth } from '../../domain/Auth';
import client from '../client';
export const signIn = async (username: string, password: string) => {
	return client.post<Auth>(
		`/auth/login/`,
		{},
		{
			auth: {
				username,
				password
			}
		}
	);
};
