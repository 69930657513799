import { ArrowRightIcon, SearchIcon, TrashIcon, ExclamationIcon } from "@heroicons/react/outline";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "src/assets/icons/Loading";
import Input from "src/components/Input";
import Modal from "src/components/Modal";
import Pagination from "src/components/Pagination";
import Tag from "src/components/Tag";
import { Selectable } from "src/components/ui/form/models";
import SingleSelect from "src/components/ui/form/Selects/SingleSelect";
import { Company } from "src/domain/Company";
import { useAuth } from "src/hooks/useAuth";
import Header from "src/layouts/Logged/components/Header";
import Page from "src/layouts/Logged/components/Page";
import { deleteCompany } from "src/services/company/deleteCompany";
import { getCompanies } from "src/services/company/getCompanies";
export interface IgcCompanyProps {}

const IgcCompany: FC = () => {
	const [loadingCompanies, setLoadingCompanies] = useState<boolean>(true);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [total, setTotal] = useState<number>(1);
	const [companies, setCompanies] = useState<Array<Company>>([]);
	const [deleteModalOpen, setDeleteModal] = useState<boolean>(false);
	const [selectedDeleteBusiness, setSelectedDeleteBusiness] = useState<number>(0);
	const [originalCompanies, setOriginalCompanies] = useState<Array<Company>>([]);
	const { user } = useAuth();
	const pageLimit = 10;
	const [filterInput, setFilterInput] = useState<string>("");
	const [filter, setFilter] = useState<Selectable>({
		id: 1,
		name: "Todos",
		value: null
	});
	const options: Array<Selectable> = [
		{
			id: 1,
			name: "Todos",
			value: null
		}
	];

	const handleFiltering = (filter: Selectable) => {
		setFilter(filter);
		setLoadingCompanies(true);
		setCompanies(searchTable(filter.value));
	};

	const handleFilteringInput = (filter: string) => {
		setFilterInput(filter);
		setLoadingCompanies(true);
		setCompanies(searchTableInput(filter));
	};

	const searchTable = (progress: number): Array<Company> => {
		setTimeout(() => {
			setLoadingCompanies(false);
		}, 500);
		if (progress === null) {
			return originalCompanies;
		}
		if (originalCompanies) {
			return originalCompanies.filter((company) => company.progress === progress);
		}
		return [];
	};

	const eventDeleteCompany = async () => {
		await deleteCompany("igc", selectedDeleteBusiness);

		setLoadingCompanies(true);

		await getCompanies(String(user?.role))
			.then((response) => {
				setTotal(response.data.length);
				setCompanies(onPageChanged(response.data));
				setOriginalCompanies(response.data);
			})
			.finally(() => {
				setLoadingCompanies(false);
				setDeleteModal(false);
			});
	};

	const searchTableInput = (filter: string): Array<Company> => {
		setTimeout(() => {
			setLoadingCompanies(false);
		}, 500);
		if (filter === "") {
			return onPageChanged(originalCompanies);
		}
		filter = filter.toLowerCase();
		if (originalCompanies) {
			return onPageChanged(
				originalCompanies.filter((company) => {
					if (company.trade_name.toLowerCase().includes(filter) || company.email.toLowerCase().includes(filter)) {
						return company;
					}
					return "";
				})
			);
		}
		return onPageChanged([]);
	};

	useEffect(() => {
		getCompanies(String(user?.role))
			.then((response) => {
				setTotal(response.data.length);
				setCompanies(onPageChanged(response.data));
				setOriginalCompanies(response.data);
			})
			.finally(() => {
				setLoadingCompanies(false);
			});
	}, []);

	const onPageChanged = (value: Array<Company>) => {
		setTotal(value.length);
		const offset = (currentPage - 1) * pageLimit;
		const currentCompanies = value.slice(offset, offset + pageLimit);
		return currentCompanies;
	};

	useEffect(() => {
		setLoadingCompanies(true);
		setCompanies(onPageChanged(originalCompanies));
		setTimeout(() => {
			setLoadingCompanies(false);
		}, 500);
	}, [currentPage]);

	return (
		<>
			<Header
				title={"Lista de Empresas"}
				img=""
				button={
					<div className="w-96">
						<SingleSelect
							placeholder="Filtrar por"
							options={options}
							value={filter}
							onChange={(e: Selectable) => handleFiltering(e)}
						/>
					</div>
				}
			/>
			<Page>
				<div className="mb-6 flex w-full justify-center sm:justify-start">
					<div className="w-full sm:w-96">
						<Input
							placeholder="Pesquisar"
							prefixIcon={<SearchIcon color="text-neutral-medium" height={20} />}
							bgColor="bg-neutral-high"
							error={false}
							errorMessage={""}
							value={filterInput}
							onChange={(e) => {
								handleFilteringInput(e.currentTarget.value);
							}}
						/>
					</div>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="w-full">
						<thead>
							<tr>
								<th scope="col" className="text-neutral-low relative px-6 py-3 text-sm font-normal"></th>
								<th scope="col" className="text-neutral-low py-3 pr-6 text-left text-sm font-normal">
									Empresa
								</th>
								<th scope="col" className="text-neutral-low py-3 pr-6 text-left text-sm font-normal">
									Email
								</th>
								<th scope="col" className="text-neutral-low py-3 pr-6 text-left text-sm font-normal">
									Último login
								</th>
								<th scope="col" className="text-neutral-low py-3 pr-6 text-left text-sm font-normal">
									Progresso
								</th>
								<th scope="col" className="text-neutral-low relative px-6 py-3 text-sm font-normal"></th>
							</tr>
						</thead>
						<tbody className=" divide-neutral-medium divide-y divide-opacity-50">
							{companies && companies.length === 0 && !loadingCompanies ? (
								<tr className="text-neutral-medium text-center text-2xl font-bold">
									<td className="whitespace-nowrap py-4 pr-6" colSpan={6}>
										Nenhuma empresa encontrado
									</td>
								</tr>
							) : loadingCompanies ? (
								<tr className="text-neutral-medium text-center text-2xl font-bold">
									<td className="whitespace-nowrap py-4 pr-6" colSpan={6}>
										<Loading color="primary" size={12} />
									</td>
								</tr>
							) : (
								companies?.map((company, index) => (
									<tr key={index}>
										<td className="whitespace-nowraptext-sm text-neutral-medium py-4 pr-2 text-center  font-normal">
											<button
												className="ml-0  inline-flex text-brand-secondary hover:brightness-110 hover:saturate-100 active:scale-105"
												onClick={() => {
													setDeleteModal(true);
													setSelectedDeleteBusiness(company.business_id);
												}}
											>
												<TrashIcon width={20} />
											</button>
										</td>
										<td className="whitespace-nowrap py-4 pr-6">
											<div className="flex items-center justify-start">
												<div className="text-neutral-medium text-left text-sm font-normal">{company.trade_name}</div>
											</div>
										</td>
										<td className="whitespace-nowrap py-4 pr-6">
											<div className="text-neutral-medium text-left text-sm font-normal">{company.email}</div>
										</td>
										<td className="whitespace-nowrap py-4 pr-6">
											<div className="text-neutral-medium text-left text-sm font-normal">
												{company.last_login ? moment(company.last_login).locale("pt-br").format("DD MMM YYYY") : ""}
											</div>
										</td>
										<td className=" text-neutral-medium whitespace-nowrap py-4 pr-6 text-sm font-normal">
											<Tag status={company.progress} />
										</td>
										<td className="whitespace-nowraptext-sm text-neutral-medium py-4 pr-2 text-center  font-normal">
											<Link
												to={`/igc/company/${company.business_id}`}
												state={{ name: `${company.trade_name}` }}
												className="ml-0  inline-flex text-brand-secondary hover:brightness-110 hover:saturate-100 active:scale-105"
											>
												<ArrowRightIcon width={20} />
											</Link>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
					<Pagination
						currentPage={currentPage}
						total={total}
						pageLimit={pageLimit}
						setCurrentPage={setCurrentPage}
						loading={loadingCompanies}
					/>
				</div>
			</Page>
			<Modal
				title={`Você tem certeza que deseja deletar essa empresa?`}
				onClose={() => setDeleteModal(false)}
				open={deleteModalOpen}
			>
				<div className="mb-8 flex items-center gap-4">
					<ExclamationIcon width={50} color="red" />
					<p className="font-bold uppercase">
						A exclusão dessa empresa resulta em apagar todos os dados relacionados a ela de dentro da nossa base.
					</p>
				</div>

				<button
					data-modal-hide="popup-modal"
					type="button"
					className="mr-2 inline-flex items-center rounded-lg bg-red-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
					onClick={eventDeleteCompany}
				>
					Sim, tenho certeza.
				</button>
				<button
					onClick={() => setDeleteModal(false)}
					data-modal-hide="popup-modal"
					type="button"
					className="text-gray-500 hover:bg-gray-100 focus:ring-gray-200 border-gray-200 hover:text-gray-900 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-600 rounded-lg border bg-white px-5 py-2.5 text-sm font-medium focus:z-10 focus:outline-none focus:ring-4 dark:hover:text-white"
				>
					Não, cancelar!
				</button>
			</Modal>
		</>
	);
};

export default IgcCompany;
