import { FC } from "react";
import Button from "src/components/Button/Button";
import { Step } from "../../configs/models";

const Intro: FC<Step> = ({ onSubmit }) => {
	return (
		<>
			<Button type="button" size="medium" onClick={() => onSubmit(null)}>
				Continuar
			</Button>
		</>
	);
};

export default Intro;
