import client from "src/services/client";

export interface User {
	full_name: string;
	register_status: boolean;
	business_id: number;
	business_trade_name: string;
	business_main_sector: string | null;
	role: string;
	business_contract_signed: boolean;
	looking_platform: number;
	business_sub_sector: string;
	phone: string;
	igc_user_id: number;
}

export interface EditUserDTO {
	business_id?: number;
	full_name?: string;
	business_trade_name?: string;
	phone?: string;
	email_notifications_enabled?: boolean;
}

export const getUserInfo = (user: number | undefined) => {
	return client.get<EditUserDTO>(`business/user/info/${user}`);
};

export const editUser = (data: EditUserDTO) => {
	return client.patch("business/user/info/edit", data);
};
