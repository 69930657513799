import {
	Control,
	FieldErrors,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch
} from "react-hook-form";
import { ExtendedRegistrationForm } from "src/domain/Register";
import * as yup from "yup";

export type FieldData = {
	stepNumber: number;
	title: string;
	description?: string;
	helpText?: string;
	component: any;
	optional?: boolean;
	maxInputs?: number;
	dependsOn?: {
		path: string;
		value: any;
	};
	video?: string;
	isLast?: boolean;
};

export type Step = {
	step: FieldData;
	toPreviousStep: () => void;
	onSubmit?: any;
	getStepData: UseFormGetValues<ExtendedRegistrationForm>;
	loadingStep?: boolean;
	showLogo?: boolean;
	register: UseFormRegister<ExtendedRegistrationForm>;
	errors: FieldErrors<ExtendedRegistrationForm>;
	control: Control<ExtendedRegistrationForm, any>;
	setValue: UseFormSetValue<ExtendedRegistrationForm>;
	watch: UseFormWatch<ExtendedRegistrationForm>;
	maxInputs?: number;
};

const characterLimit = 1500;

export const validationSchemaBySteps = [
	yup.object().shape({}),
	yup.object().shape({
		briefing: yup
			.string()
			.min(50, "O mínimo são 50 caracteres")
			.max(characterLimit, `O máximo são ${characterLimit} caracteres`)
			.required("Campo obrigatório")
	}),
	yup.object().shape({
		business_differentials: yup
			.array()
			.of(
				yup.object().shape({
					name: yup
						.string()
						.max(characterLimit, `O máximo são ${characterLimit} caracteres`)
						.required("Campo obrigatório")
				})
			)
			.min(1)
			.max(10)
			.required("Campo obrigatório")
	}),
	yup.object().shape({
		business_timelines: yup.array().of(
			yup.object().shape({
				event_description: yup.string().required("Campo obrigatório"),
				date: yup.string().required("Campo obrigatório")
			})
		)
	}),
	yup.object().shape({}),
	yup.object().shape({
		business_investors_has_interest: yup.boolean(),
		business_interested_investors: yup.array().when(["business_investors_has_interest"], {
			is: (value: boolean) => {
				return value === true;
			},
			then: yup
				.array(
					yup.object().shape({
						investor: yup.string().required("Campo obrigatório"),
						investor_interest: yup.string().required("Campo obrigatório")
					})
				)
				.min(1, "Escolha ao menos 1 Investidor")
				.required("Campo obrigatório")
		})
	}),
	yup.object().shape({
		business_potential_investors: yup.array(
			yup.object().shape({
				investor: yup.string().required("Campo obrigatório")
			})
		)
	}),
	yup.object().shape({
		business_competitors: yup
			.array()
			.of(
				yup.object().shape({
					name: yup
						.string()
						.max(characterLimit, `O máximo são ${characterLimit} caracteres`)
						.required("Campo obrigatório")
				})
			)
			.min(1)
			.max(10)
			.required("Campo obrigatório")
	}),
	yup.object().shape({}),
	yup.object().shape({
		debt_amount: yup.string().required("Campo obrigatório")
	}),
	yup.object().shape({
		is_audited: yup.boolean(),
		audited_by: yup.string().when("is_audited", {
			is: (value: boolean) => {
				return value === true;
			},
			then: yup.string().required("Campo obrigatório").nullable()
		})
	}),
	yup.object().shape({
		client_amount: yup
			.string()
			.required("Campo obrigatório")
			.nullable()
			.notOneOf(["0"], "Campo deve ter valor superior a 0")
	}),
	yup.object().shape({
		business_main_clients: yup.array().of(
			yup.object().shape({
				name: yup.string().notOneOf([""], "Campo obrigatório"),
				share: yup.number().typeError("").notOneOf([0, undefined, null], " ")
			})
		)
	}),
	yup.object().shape({
		business_partners: yup
			.array()
			.of(
				yup.object().shape({
					name: yup.string().required("Campo obrigatório"),
					share: yup.number().typeError("").notOneOf([0, undefined, null], " ")
				})
			)
			.required("Campo obrigatório")
	}),
	yup.object().shape({
		clt_employees_amount: yup.string().required("Campo obrigatório").nullable(),
		pj_employees_amount: yup.string().required("Campo obrigatório").nullable()
	}),
	yup.object().shape({
		main_state_id: yup.number().required("Campo obrigatório").nullable(),
		main_state: yup.string().required("Campo obrigatório").nullable(),
		main_city_id: yup.number().required("Campo obrigatório").nullable(),
		main_city: yup.string().required("Campo obrigatório").nullable()
	}),
	yup.object().shape({
		has_operation_in_multiple_states: yup.boolean(),
		business_states: yup.array().when(["has_operation_in_multiple_states"], {
			is: (value: Boolean) => {
				return value === true;
			},
			then: yup
				.array(
					yup.object().shape({
						state_id: yup.number().required("Campo obrigatório"),
						state_name: yup.string().required("Campo obrigatório")
					})
				)
				.min(1, "Escolha ao menos 1 estado")
				.required("Campo obrigatório")
		})
	}),
	yup.object().shape({
		is_rented: yup.boolean(),
		business_leases: yup.array().when(["is_rented"], {
			is: (value: Boolean) => {
				return value === true;
			},
			then: yup
				.array(
					yup.object().shape({
						name: yup.string().required("Campo obrigatório"),
						valid_until: yup.string().required("Campo obrigatório").nullable()
					})
				)
				.required("Campo obrigatório")
		})
	}),
	yup.object().shape({
		business_main_products: yup
			.array()
			.of(
				yup.object().shape({
					name: yup.string().required("Campo obrigatório"),
					share: yup.number().typeError("").notOneOf([0, undefined, null], " ")
				})
			)
			.min(1, "Precisa ter ao menos um produto")
			.required("Campo obrigatório")
	}),
	yup.object().shape({
		business_sales_types: yup
			.array()
			.of(
				yup.object().shape({
					name: yup.string().required("Campo obrigatório"),
					share: yup.number().typeError("").notOneOf([0, undefined, null], " ")
				})
			)
			.required("Campo obrigatório")
			.min(1, "Campo obrigatório")
	})
];
