import { PaperClipIcon } from "@heroicons/react/outline";
import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import Button from "src/components/Button/Button";
import ChatList from "src/components/Chat/ChatList";
import ChatRoom from "src/components/Chat/ChatRoom";
import { PreviewImage } from "src/components/Chat/PreviewImage";
import { sendChatNotificationToUser } from "src/domain/Chat";
import { isFileImage } from "src/helpers/isFileImage";
import { useAuth } from "src/hooks/useAuth";
import { useChatMessages } from "src/hooks/useChatMessages";
import { debounce, useDebounce } from "src/services/Utils";
import {
	addNewMessage,
	handleAdminCreation,
	updateLastMessageInChat,
	updateUser,
	uploadChatFile
} from "src/services/firebase";

const IgcChatPage = () => {
	const { user } = useAuth();
	const { unreadedChats } = useChatMessages();
	const userId = user?.igc_user_id as number;
	const userFullName = user?.full_name;
	const [message, setMessage] = useState<string>("");
	const [sendingMessage, setSendingMessage] = useState(false);
	const [selectedChatId, setSelectedChatId] = useState<number | null>(14);
	const scrollPoint = useRef<null | HTMLDivElement>(null);
	const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
	const debouncedFn = useDebounce(sendChatNotificationToUser, 120000);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [previewOpened, setPreviewOpened] = useState(false);

	const handleAdminOnPageLoad = async () => {
		if (!userId || !userFullName) return;
		await handleAdminCreation(userId, userFullName);
	};

	function selectFile({ target }: { target: HTMLInputElement }) {
		if (!target.files || target.files.length === 0) return;

		const file = target.files[0];

		setPreviewOpened(true);

		return setSelectedFile(file);
	}

	function closePreview() {
		return setPreviewOpened(false);
	}

	async function uploadFile(file: File) {
		if (!selectedChatId) return;

		try {
			const { metadata } = await uploadChatFile(file, selectedChatId);

			const uploadMessage = isFileImage(file) ? `image:${metadata.name}` : `file:${metadata.name}`;

			handleSendMessage(uploadMessage);

			setPreviewOpened(false);
		} catch (err) {
			console.error(err);
		}
	}

	const handleSendMessage = async (message: string) => {
		if (selectedChatId == null || !message || message.trim().length === 0) {
			return;
		}

		setSendingMessage(true);

		await addNewMessage({
			chatId: selectedChatId,
			senderId: userId,
			text: message.trim(),
			senderRole: "igc"
		});

		updateLastMessageInChat(userId, selectedChatId);

		debouncedFn(selectedChatId, message.trim());

		setMessage("");
		scrollPoint.current?.scrollIntoView({ behavior: "smooth" });

		setSendingMessage(false);
	};

	useEffect(() => {
		handleAdminOnPageLoad();
		if (user) updateUser("admins", user.igc_user_id, "last_chat_view");
	}, []);

	return (
		<div className="flex h-[94vh] w-full gap-2 p-4 md:h-screen">
			<ChatList selectedChatId={selectedChatId} setSelectedChatId={setSelectedChatId} />
			<div className="relative flex w-full flex-col items-center rounded-lg border-2 border-brand-secondary border-opacity-50 bg-brand-secondaryMedium bg-opacity-5">
				{selectedChatId !== undefined && selectedChatId !== null ? (
					<ChatRoom scrollRef={scrollPoint} chatId={selectedChatId} />
				) : (
					<div className="h-full w-full">Selecione um Chat</div>
				)}
				<AnimatePresence>
					{previewOpened && <PreviewImage upload={uploadFile} closePreview={closePreview} file={selectedFile} />}
				</AnimatePresence>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSendMessage(message);
					}}
					className="flex w-full items-center gap-2 p-2"
				>
					<textarea
						name="message"
						ref={textAreaRef}
						value={message}
						className="textarea textarea-bordered h-full w-full resize-none bg-white leading-5 text-[#000]"
						placeholder="Mensagem"
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								handleSendMessage(message);
							}
						}}
						onChange={({ target }) => setMessage(target.value)}
					></textarea>
					<label
						htmlFor="file-upload"
						className="flex h-14 w-14 cursor-pointer items-center justify-center rounded-md bg-brand-secondary hover:brightness-110"
						tabIndex={0}
					>
						<PaperClipIcon height={30} color="white" />
					</label>
					<input id="file-upload" type="file" className="hidden" onChange={selectFile} />
					<Button type="submit" className={`w-max ${sendingMessage && "loading"}`}>
						{sendingMessage ? "Enviando" : "Enviar"}
					</Button>
				</form>
			</div>
		</div>
	);
};

export default IgcChatPage;
