import { TrashIcon } from "@heroicons/react/outline";
import { FC, useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { BusinessMainProduct } from "src/domain/Register";
import { toastNotify } from "src/helpers/toastNotify";
import { Step } from "../../configs/models";

const initialData: BusinessMainProduct = {
	name: "",
	share: null
};

const CompanyMainProducts: FC<Step> = ({
	onSubmit,
	getStepData,
	maxInputs,
	loadingStep,
	control,
	register,
	setValue,
	errors
}) => {
	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "business_main_products"
	});

	const [totalPercentage, setTotalPercentage] = useState(
		getStepData("business_main_products").length > 0
			? getStepData("business_main_products")
					.map((i) => i.share || 0)
					.reduce((soma, i) => soma + i)
			: 0
	);

	const otherPercent = () => {
		if (totalPercentage >= 100) {
			return 0;
		}

		return (100 - totalPercentage) % 1 !== 0 ? (100 - totalPercentage).toFixed(2) : 100 - totalPercentage;
	};

	const handlePercentChange = (value: string, index: number) => {
		let sumValues = 0;

		const values = getStepData("business_main_products");

		for (let i = 0; i < values.length; i++) {
			if (i === index) continue;
			sumValues = sumValues + Number(values[i].share || 0);
		}

		sumValues += Number(value);

		setTotalPercentage(sumValues);

		if (sumValues > 100) toastNotify("A soma dos itens deve ser equivalente a 100.", "warning");

		return setValue(`business_main_products.${index}.share`, Number(value), {
			shouldDirty: true,
			shouldValidate: true
		});
	};

	useEffect(() => {
		if (fields.length === 0) return append(initialData);
	}, [fields]);

	return (
		<div>
			<ul className="w-full ">
				{fields.map((field, index) => (
					<li className="mb-4 flex w-full" key={index}>
						<div className="mr-3 w-[80%]">
							<Input
								type="text"
								{...field}
								{...register(`business_main_products.${index}.name`)}
								fieldError={errors.business_main_products?.[index]?.name}
							/>
						</div>
						<div className="mr-1 w-[11%]">
							<Controller
								control={control}
								name={`business_main_products.${index}.share`}
								render={({ field, fieldState }) => (
									<Input
										{...field}
										type="number"
										onChange={({ target }) => handlePercentChange(target.value, index)}
										value={field.value ? field.value : undefined}
										fieldError={fieldState.error}
										sufix="%"
										ref={field.ref}
									/>
								)}
							/>
						</div>
						<div className={`flex w-6 items-center ${index === 0 && "hidden"}`}>
							<TrashIcon
								className="cursor-pointer"
								onClick={() => {
									const fieldValue = getStepData(`business_main_products.${index}.share`);

									setTotalPercentage((oldPercentage) => {
										return oldPercentage - Number(fieldValue);
									});

									remove(index);
								}}
								color="#bb7127"
							/>
						</div>
					</li>
				))}
			</ul>
			<li className="mb-4 flex w-full">
				<div className="mr-3 w-[80%]">
					<Input type="text" value="Outros" fieldError={undefined} disabled />
				</div>
				<div className="mr-1 w-[11%]">
					<Input type="text" value={otherPercent()} fieldError={undefined} sufix="%" disabled />
				</div>
			</li>
			<div className="flex w-full">
				<Button
					type="button"
					className={`mr-3`}
					color="outline-secondary"
					size="large"
					disabled={maxInputs === fields.length}
					onClick={() => {
						append(initialData);
					}}
				>
					Nova Entrada
				</Button>
				<Button
					loading={loadingStep}
					disabled={Boolean(errors.business_main_products) || totalPercentage > 100 || totalPercentage <= 0}
					onClick={(e) => {
						e.preventDefault();

						return onSubmit({
							business_main_products: getStepData("business_main_products")
						});
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</div>
	);
};

export default CompanyMainProducts;
