import { DownloadIcon, PencilAltIcon } from '@heroicons/react/outline';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'src/assets/icons/Loading';
import Button from 'src/components/Button/Button';
import FileList from 'src/components/FileList';
import Modal from 'src/components/Modal';
import Tag from 'src/components/Tag';
import { Selectable } from 'src/components/ui/form/models';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import Upload from 'src/components/Upload';
import { useFiles } from 'src/contexts/files';
import { Contract } from 'src/domain/Contract';
import { useAuth } from 'src/hooks/useAuth';
import Page from 'src/layouts/Logged/components/Page';
import { getContractsByCompanyId } from 'src/services/contract/getContractsByCompanyId';
import {
	changeContractStatus,
	ChangeContractStatus
} from 'src/services/contract/changeContractStatus';
import * as Yup from 'yup';
import { toastNotify } from 'src/helpers/toastNotify';

interface ContractPageProps {}

const ContractPage: FC<ContractPageProps> = () => {
	const [loadingContracts, setLoadingContracts] = useState<boolean>(true);
	const [filter, setFilter] = useState<Selectable>({
		id: 3,
		slug: 'todos',
		name: 'Todos',
		value: null
	});
	const [contracts, setContracts] = useState<Array<Contract>>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [originalContracts, setOriginalContracts] = useState<Array<Contract>>();
	const { user } = useAuth();
	const { id } = useParams();
	const { handleUploadFiles, uploadLoading, render, uploadedFiles, clearUploadedFiles } =
		useFiles();
	const [renderChangeStatus, setRenderChangeStatus] = useState<boolean>(false);
	const [isOpenChangeStatus, setIsOpenChangeStatus] = useState<boolean>(false);
	const [statusSelected, setStatusSelected] = useState<Selectable>({} as Selectable);

	const options: Array<Selectable> = [
		{
			id: 1,
			slug: 'approved',
			name: 'Assinado',
			value: true
		},
		{
			id: 2,
			slug: 'pending',
			name: 'Aguardando assinatura',
			value: false
		},
		{
			id: 3,
			slug: 'todos',
			name: 'Todos',
			value: null
		}
	];

	const optionsStatusChange = [
		{
			id: 1,
			slug: 'approved',
			name: 'Assinado',
			value: true
		},
		{
			id: 2,
			slug: 'pending',
			name: 'Aguardando assinatura',
			value: false
		}
	];

	const validationSchema = Yup.object().shape({
		signed: Yup.string().required('Campo obrigatório')
	});

	const formik = useFormik<ChangeContractStatus>({
		initialValues: {
			contract_id: 0,
			signed: null
		},
		validationSchema: validationSchema,
		validateOnMount: true,
		enableReinitialize: true,
		onSubmit: (values, { resetForm }) => {
			changeContractStatus(String(user?.role), values)
				.then((response) => {
					toastNotify(response.data.user_message, 'success');
					setRenderChangeStatus(!renderChangeStatus);
				})
				.finally(() => {
					resetForm();
					setIsOpenChangeStatus(false);
				});
		}
	});

	const handleOpenModalChange = (contract_id: number, signed: boolean) => {
		formik.setFieldValue('contract_id', contract_id);
		const result = optionsStatusChange.filter((ob) => ob.value === signed);
		setStatusSelected(result[0]);
		setIsOpenChangeStatus(true);
	};

	const handleSelect = (field: string, item: Selectable) => {
		formik.setFieldValue(field, item.value);
		setStatusSelected(item);
	};

	const handleCancel = () => {
		setIsOpenChangeStatus(false);
		formik.resetForm();
	};

	useEffect(() => {
		getContractsByCompanyId(String(user?.role), Number(id))
			.then((response) => {
				setContracts(response.data);
				setOriginalContracts(response.data);
			})
			.finally(() => {
				setLoadingContracts(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [render, renderChangeStatus]);

	const handleFiltering = (filter: Selectable) => {
		setFilter(filter);
		setLoadingContracts(true);
		setContracts(searchTable(Boolean(filter.value)));
	};

	const searchTable = (signed: boolean | null) => {
		setTimeout(() => {
			setLoadingContracts(false);
		}, 500);
		if (signed === null) {
			return originalContracts;
		}
		if (originalContracts) {
			return originalContracts.filter((contract) => contract.signed === signed);
		}
	};

	const notHaveFileToUpload = (): boolean => {
		let response = true;
		if (uploadedFiles.length === 0) return true;
		uploadedFiles.forEach((file) => {
			if (!file.uploaded) response = false;
		});
		return response;
	};

	const handleCloseModal = () => {
		clearUploadedFiles();
		setIsOpen(false);
	};

	return (
		<>
			<Page>
				<div className="flex justify-between items-center mb-6">
					<h1 className="text-brand-primary text-2xl font-bold ">Contratos</h1>
					<Button onClick={() => setIsOpen(true)} size="large">
						Adicionar contrato
					</Button>
				</div>

				<div className="w-full sm:w-96 mb-6">
					<SingleSelect
						bgColor="bg-neutral-high"
						options={options}
						value={filter}
						onChange={(e: any) => handleFiltering(e)}
					/>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="w-full">
						<thead>
							<tr>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Contrato
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Data de emissão
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Data assinatura
								</th>
								<th
									scope="col"
									className="pr-6 py-3 text-left text-sm font-normal text-neutral-low"
								>
									Status
								</th>
								<th scope="col" className="relative px-6 py-3 text-sm font-normal text-neutral-low">
									Ações
								</th>
							</tr>
						</thead>
						<tbody className=" divide-y divide-neutral-medium divide-opacity-50">
							{contracts && contracts.length === 0 && !loadingContracts ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										Nenhum contrato encontrado
									</td>
								</tr>
							) : loadingContracts ? (
								<tr className="text-center text-neutral-medium font-bold text-2xl">
									<td className="pr-6 py-4 whitespace-nowrap" colSpan={6}>
										<Loading color="primary" size={12} />
									</td>
								</tr>
							) : (
								contracts?.map((contract) => (
									<tr key={contract.contract_id}>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="flex items-center justify-start">
												<div className="text-sm font-normal text-left text-neutral-medium">
													{contract.contract_name}
												</div>
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{moment(contract.created_at).locale('pt-br').format('DD MMM YYYY')}
											</div>
										</td>
										<td className="pr-6 py-4 whitespace-nowrap">
											<div className="text-sm font-normal text-left text-neutral-medium">
												{contract.signed
													? moment(contract.updated_at).locale('pt-br').format('DD MMM YYYY')
													: ''}
											</div>
										</td>
										<td className=" pr-6 py-4 whitespace-nowrap text-sm font-normal text-neutral-medium">
											<Tag status={String(contract.signed)} />
										</td>
										<td className="text-center pr-2 py-4 whitespace-nowraptext-sm font-normal  text-neutral-medium">
											<button
												onClick={() => handleOpenModalChange(contract.contract_id, contract.signed)}
												className="inline-flex bg-transparent text-brand-secondary ml-0 mr-2 hover:brightness-110 hover:saturate-100 active:scale-105"
											>
												<PencilAltIcon width={20} color="" />
											</button>
											<a
												target="_blank"
												rel="noopener noreferrer"
												href={contract.contract_url}
												className="inline-flex  text-brand-secondary ml-0 hover:brightness-110 hover:saturate-100 active:scale-105"
											>
												<DownloadIcon width={20} color="" />
											</a>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>
				<FormikProvider value={formik}>
					<Modal
						open={isOpenChangeStatus}
						onClose={setIsOpenChangeStatus}
						title="Mudar status"
						size="small"
						description="Selecione abaixo o status do contrato. Clique em confirmar para enviar."
					>
						<form onSubmit={formik.handleSubmit} className="w-full">
							<div className="mt-8">
								<SingleSelect
									options={optionsStatusChange}
									value={statusSelected}
									placeholder="Escolha o status"
									onChange={(e: Selectable) => handleSelect('signed', e)}
								/>
							</div>
							<div className="flex justify-between mt-8">
								<Button
									className="mr-3"
									type="button"
									color="outline-secondary"
									onClick={() => handleCancel()}
								>
									Cancelar
								</Button>
								<Button className="ml-3" color="secondary" disabled={!formik.isValid} type="submit">
									Confirmar
								</Button>
							</div>
						</form>
					</Modal>
				</FormikProvider>
				<Modal
					open={isOpen}
					onClose={handleCloseModal}
					title="Adicionar Contrato"
					description="Insira abaixo o(s) contrato(s) que deseja adicionar para o empresário. Clique em confirmar para enviar."
				>
					<Upload businessId={Number(id)} />
					<FileList />
					<div className="flex justify-between mt-7">
						<Button onClick={() => handleCloseModal()} className="mr-3" color="outline-secondary">
							Cancelar
						</Button>
						<Button
							loading={uploadLoading}
							onClick={() => handleUploadFiles('igc/upload_business_contract/')}
							className="ml-3"
							color="secondary"
							disabled={notHaveFileToUpload()}
						>
							Confirmar
						</Button>
					</div>
				</Modal>
			</Page>
		</>
	);
};

export default ContractPage;
