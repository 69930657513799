import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from "react";
import { Categories } from "src/domain/Summary";

function classNames(...classes: any) {
	return classes.filter(Boolean).join(" ");
}

const Summary: FC<{
	isOpen: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	step: number;
	setStep: Function;
	categories: Categories[];
	setCategories: Dispatch<SetStateAction<Categories[]>>;
}> = ({ isOpen, setOpen, step, setStep, categories, setCategories }) => {
	const handleChangeStepSummary = () => {
		let index = step;
		const copyCategories = categories.map((category, categoryIndex) => {
			if (categoryIndex === index) category.current = true;
			else category.current = false;
			return category;
		});
		setCategories(copyCategories);
	};

	useEffect(() => {
		handleChangeStepSummary();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step]);
	return (
		<>
			<Transition.Root show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-40 " onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-opacity-75" />
					</Transition.Child>

					<div className="fixed inset-0 z-40 flex">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="translate-x-[100vw]"
							enterTo="translate-x-[calc(100vw-20rem)]"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-[calc(100vw-20rem)]"
							leaveTo="translate-x-[100vw]"
						>
							<Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 left-2 -mr-12 pt-2">
										<button
											type="button"
											className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => setOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								<div className="-z-10 h-0 flex-1 flex-col overflow-y-auto bg-brand-primary px-8 pb-5">
									<div className="sticky-top flex flex-shrink-0 items-center justify-center bg-brand-primary py-8 px-4 text-2xl font-bold text-white">
										Sumário
									</div>
									<nav className="mt-8 space-y-1 px-2">
										{categories.map((item, index) => {
											return (
												<div
													key={index}
													className={classNames(
														item.current
															? "bg-auxiliar-highlight-orange text-white"
															: "text-white hover:bg-[#647a34] hover:bg-opacity-75",
														"grid cursor-pointer grid-cols-[3.75fr_1fr] items-center gap-2 rounded-md px-2 py-2 text-base font-medium"
													)}
												>
													<span onClick={() => (index !== 21 ? setStep(item.initialStep) : true)}>
														{index}- {item.name}
													</span>
													{item.status === 1 ? (
														<ExclamationCircleIcon color={"orange"} height={20} />
													) : (
														<CheckCircleIcon color={item.status === 0 ? "#5c5c5c" : "white"} height={20} />
													)}
												</div>
											);
										})}
									</nav>
								</div>
							</Dialog.Panel>
						</Transition.Child>
						<div className="w-14 flex-shrink-0" aria-hidden="true"></div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export default Summary;
