import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import finder from './images/finder.png';

export interface IFinderSessionProps {}

const FinderSession: FC<IFinderSessionProps> = () => {
	return (
		<>
			<div className="max-h-screen overflow-hidden">
				<div className="flex justify-center">
					<div className="hidden md:block w-1/2 h-full max-h-screen object-fill">
						<img
							className="h-screen max-h-full w-full object-cover object-top"
							src={finder}
							alt="businesswoman with glasses"
						/>
					</div>
					<div className="w-1/2 flex items-center justify-center">
						<div className="w-[400px]">
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default FinderSession;
