import { FC, useEffect, useState } from "react";
import Button from "src/components/Button/Button";
import { Selectable } from "src/components/ui/form/models";
import MultipleSelect from "src/components/ui/form/Selects/MultipleSelect";
import SingleSelect from "src/components/ui/form/Selects/SingleSelect";
import { getStates } from "src/services/domain/getStates";
import { Step } from "../../configs/models";

const options: Selectable[] = [
	{ id: 0, name: "Não", value: false },
	{ id: 1, name: "Sim", value: true }
];

const mapperOptions = (values: any[], flag: boolean): Selectable[] =>
	flag
		? values.map((value) => ({
				id: value.state_id,
				name: value.state_name,
				value: value.state_id
		  }))
		: values.map((value) => ({ id: value.id, name: value.name, value: value.id }));

const CompanyState: FC<Step> = ({
	onSubmit,
	getStepData,
	loadingStep,
	setValue,
	errors,
	watch
}) => {
	const [optionsStates, setOptionsStates] = useState<Array<Selectable>>([]);

	const [selected, setSelected] = useState<Selectable>(
		getStepData("business_states").length > 0 ? options[1] : options[0]
	);

	const [selectedState, setSelectedState] = useState<Array<Selectable>>(
		mapperOptions(getStepData("business_states"), true)
	);

	const handleSelectState = (items: Selectable[]) => {
		const reducedItems: Selectable[] = [];
		let itemDuplicated: Selectable | undefined;

		for (const item of items) {
			const duplicated = reducedItems.some((redItem) => item.id === redItem.id);
			if (!duplicated) {
				reducedItems.push(item);
			} else {
				itemDuplicated = item;
			}
		}

		if (itemDuplicated) {
			reducedItems.splice(
				reducedItems.findIndex((ob) => ob.id === itemDuplicated?.id),
				1
			);
		}

		setSelectedState(reducedItems);

		const mapItems = reducedItems.map((item) => ({
			state_id: item.id,
			state_name: item.name
		}));

		setValue("business_states", mapItems, { shouldDirty: true });
	};

	const isInputDisabled = () => {
		if (selected.value && selectedState.length === 0) return true;

		return false;
	};

	useEffect(() => {
		getStates().then((res) => setOptionsStates(mapperOptions(res.data, false)));
	}, []);

	return (
		<>
			<div className="mb-6">
				<SingleSelect
					options={options}
					onChange={(e: Selectable) => {
						setSelected(e);
						setValue("has_operation_in_multiple_states", e.value, { shouldDirty: true });
					}}
					value={selected}
				/>
			</div>

			{Boolean(selected?.value) && (
				<>
					<h3 className={`text-neutral-low mb-4 text-left text-2xl font-bold`}>
						Em quais estados a empresa se encontra? <br />
						<span className="text-base font-normal">(selecione todos que se aplicam)</span>
					</h3>
					<div className="mb-4 w-full">
						<MultipleSelect
							error={!selectedState && Boolean(errors.business_states)}
							errorMessage={errors.business_states?.message}
							options={optionsStates}
							onChange={handleSelectState}
							values={selectedState}
						/>
					</div>
				</>
			)}

			<div className="flex w-full">
				<Button
					disabled={isInputDisabled()}
					loading={loadingStep}
					onClick={(e) => {
						e.preventDefault();

						onSubmit({
							business_states: selected.value ? getStepData("business_states") : []
						});
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</>
	);
};

export default CompanyState;
