import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { checkIfAdminHasNewMessages, checkIfUserHasNewMessages } from "src/services/firebase";

interface IMessageContext {
	newMessages: boolean | undefined;
	setNewChatMessages: (userType: string, userId: number, location?: string) => Promise<void>;
	unreadedChats: number[];
}

export const MessageContext = createContext<IMessageContext>({} as IMessageContext);

export const MessageProvider = ({ children }: any) => {
	const [newMessages, setNewMessages] = useState<boolean | undefined>(false);
	const [unreadedChats, setUnreadedChats] = useState<number[]>([]);

	const setNewChatMessages = useCallback(
		async (userRole: string, userId: number, location?: string) => {
			if (userRole === "igc" && location && location !== "/igc/chat") {
				const { haveNewMessages, newMessagesChatId } = await checkIfAdminHasNewMessages(userId);
				setNewMessages(haveNewMessages);
				return setUnreadedChats(newMessagesChatId);
			}
			return setNewMessages(await checkIfUserHasNewMessages(userId));
		},
		[]
	);

	const value = useMemo(
		() => ({
			newMessages,
			setNewChatMessages,
			unreadedChats
		}),
		[newMessages, setNewChatMessages, unreadedChats]
	);

	return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>;
};
