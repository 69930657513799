export const removeSpecialChars = (text: string) => {
	if (text)
		return text.replace(/[^a-zA-Z0-9]/g, '');
	else
		return ''
};

export const removeSpaces = (text: string) => {

	if (text)
		return text.replace(/\s/g, '');
	else
		return ''
};

export const removeLetters = (text: string) => {
	if (text)
		return text.replace(/[\D]+/g, '')
	else
		return ''
};
