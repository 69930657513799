import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { postContact } from 'src/domain/Contact';
import { criteriaOptions, postTarget } from 'src/domain/Target';
import { getSubSectors } from 'src/services/domain/getSubSectors';
import * as Yup from 'yup';
import Button from '../Button/Button';
import Input from '../Input';
import Modal from '../Modal';
import { Selectable } from '../ui/form/models';
import SingleSelect from '../ui/form/Selects/SingleSelect';

interface params {
	isOpen: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	investorID: number | null;
	fetchTargetData: (investorID: number | null) => Promise<void>;
}

function TargetModal({ isOpen, setOpen, investorID, fetchTargetData }: params) {
	const validationSchema = Yup.object().shape({
		sub_sector_id: Yup.number().required('Campo obrigatório'),
		criteria: Yup.string().required('Campo obrigatório'),
		contact_name: Yup.string().required('Campo obrigatório'),
		contact_email: Yup.string().email('Email inválido!').required('Campo obrigatório'),
		contact_phone: Yup.string().required('Campo obrigatório')
	});

	const [subSectorsOptions, setSubSectorsOptions] = useState<Selectable[]>([]);
	const [subSectorSelected, setSubSectorSelected] = useState<Selectable>();

	const [criteriaSelected, setCriteriaSelected] = useState<Selectable>();

	useEffect(() => {
		(async () => {
			const { data: subSectors } = await getSubSectors();

			setSubSectorsOptions(subSectors.map(({ id, name }) => ({ id, name, value: name })));
		})();
	}, []);

	const formik = useFormik({
		initialValues: {
			sub_sector_id: 0,
			criteria: '',
			contact_name: '',
			contact_email: '',
			contact_phone: '',
			last_contact_date: null,
			investor_id: investorID,
			contact_id: 0
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				const { data } = await postContact(values);

				values = { ...values, contact_id: data.contact_id };

				await postTarget(values);

				fetchTargetData(investorID);

				setOpen(false);
			} catch (err) {
				console.error(err);
			}
		}
	});

	return investorID ? (
		<Modal
			title="Targets"
			description=""
			onClose={() => setOpen(false)}
			open={isOpen}
		>
			<form onSubmit={formik.handleSubmit}>
				<h2 className="mb-3 text-lg font-semibold text-brand-primary">Sub-setor:</h2>
				<SingleSelect
					options={subSectorsOptions}
					value={subSectorSelected}
					onChange={(value) => {
						setSubSectorSelected(value);
						formik.setFieldValue('sub_sector_id', value.id);
					}}
				/>
				<h2 className="mb-3 text-lg font-semibold text-brand-primary">Critério:</h2>
				<SingleSelect
					options={criteriaOptions.map((item, index) => ({ id: index, name: item, value: item }))}
					value={criteriaSelected}
					onChange={(value) => {
						setCriteriaSelected(value);
						formik.setFieldValue('criteria', value.name);
					}}
				/>
				<h2 className="mb-3 text-lg font-semibold text-brand-primary">Nome do contato:</h2>
				<Input
					onChange={formik.handleChange('contact_name')}
					error={Boolean(formik.errors.contact_name)}
					errorMessage={formik.errors.contact_name}
				/>
				<h2 className="mb-3 text-lg font-semibold text-brand-primary">Email do contato:</h2>
				<Input
					onChange={formik.handleChange('contact_email')}
					error={Boolean(formik.errors.contact_email)}
					errorMessage={formik.errors.contact_email}
				/>
				<h2 className="mb-3 text-lg font-semibold text-brand-primary">Telefone do contato:</h2>
				<Input
					onChange={formik.handleChange('contact_phone')}
					error={Boolean(formik.errors.contact_phone)}
					errorMessage={formik.errors.contact_phone}
				/>

				<Button className="mt-3" type="submit">
					Adicionar target
				</Button>
			</form>
		</Modal>
	) : null;
}

export default TargetModal;
