import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';
import LoadingIcon from 'src/assets/icons/Loading';

interface ButtonProps
	extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	color?: 'primary' | 'secondary' | 'outline-primary' | 'outline-secondary';
	loading?: boolean;
	size?: 'small' | 'medium' | 'large' | 'extra-large';
	block?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ color = 'secondary', children, loading, className, size, block, ...otherProps }, ref) => {
		return (
			<button
				ref={ref}
				className={`${
					block ? 'block w-full' : 'inline-block'
				}  ${className} h-14 min-w-fit text-center align-middle border py-2 px-6 rounded-lg border-transparent  hover:brightness-110 hover:saturate-100 active:scale-105
			disabled:transform-none disabled:text-neutral-high  disabled:bg-neutral-medium disabled:border-neutral-medium disabled:opacity-50 disabled:transition-none disabled:cursor-not-allowed ${
				color === 'primary'
					? 'text-neutral-high bg-brand-primary active:button-active-primary'
					: color === 'outline-primary'
					? 'bg-neutral-high border  text-brand-primary border-brand-primary active:button-active-primary'
					: color === 'secondary'
					? 'text-neutral-high bg-brand-secondary  transition-colors active:button-active-secondary text-white font-semibold'
					: 'bg-neutral-high border disabled:text-neutral-high  text-brand-secondary border-brand-secondary active:button-active-secondary'
			}
			${
				size === 'small'
					? 'w-[132px]'
					: size === 'medium'
					? 'w-[214px]'
					: size === 'large'
					? 'w-[248px]'
					: size === 'extra-large'
					? 'w-[280px]'
					: 'w-full'
			}

			`}
				{...otherProps}
			>
				{loading ? (
					<LoadingIcon color={color.includes('outline') ? 'secondary' : 'neutral-high'} />
				) : (
					children
				)}
			</button>
		);
	}
);

export default Button;
