import { FC, Fragment, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SidebarMenuDesktop from '../../components/SidebarMenu/SidebarDesktop';
import SidebarMenuMobile from '../../components/SidebarMenu/SidebarMobile';
import ToggleMenuMobile from '../../components/SidebarMenu/ToggleSidebarMobile';
export interface ILoggedLayoutProps {}

const LoggedLayout: FC<ILoggedLayoutProps> = () => {
	const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);

	return (
		<div className="h-screen w-full">
			<SidebarMenuMobile isOpen={mobileSidebarOpen} setOpen={setMobileSidebarOpen} />
			<SidebarMenuDesktop />
			<div className="flex min-h-full w-full flex-col bg-white md:pl-64">
				<ToggleMenuMobile setOpen={setMobileSidebarOpen} />
				<Outlet />
			</div>
		</div>
	);
};

export default LoggedLayout;
