import { FC } from 'react';
import InputField from '../base/input';
import { Input } from './models';

const NAME_REGEX: RegExp = /^[a-zA-ZÀ-ú ]+$/;

const NameField: FC<Input> = ({ onChange, initialValue }) => {
	const validateName = (name: string) => {
		return name.length >= 3 && NAME_REGEX.test(name);
	};

	return (
		<>
			<InputField
				initialValue={initialValue}
				fieldLabel="Nome completo"
				type="text"
				id="name"
				placeholder="Digite seu nome"
				errorText=""
				validateValue={validateName}
				onChange={onChange}
			/>
		</>
	);
};

export default NameField;
