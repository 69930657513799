import React, { FC, useEffect, useState } from 'react';
import { useTabs } from 'react-headless-tabs';
import { useLocation, useParams } from 'react-router-dom';
import { TabSelector } from 'src/components/TabSelector';
import Header from 'src/layouts/Logged/components/Header';
import history from 'src/services/history';
import ContractPage from './components/TabsContent/Contract';
import DocumentPage from './components/TabsContent/Document';
import QAPage from './components/TabsContent/QA';
import TargetPage from './components/TabsContent/Target';
import TeaserPage from './components/TabsContent/Teaser';
import TemplatePage from './components/TabsContent/Template';
import SummaryPage from './components/TabsContent/Summary';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import client from 'src/services/client';
import { Selectable } from 'src/components/ui/form/models';
import { toast } from 'react-toastify';
import clsx from 'clsx';

const stageOptions = [
	{
		id: 1,
		name: 'Preparação',
		value: 'Preparação',
	},
	{
		id: 2,
		name: 'Roadshow',
		value: 'Roadshow',
	},
	{
		id: 3,
		name: 'Negociação e Diligência',
		value: 'Negociação e Diligência',
	},
	{
		id: 4,
		name: 'Transação Concluída',
		value: 'Transação Concluída',
	}
];

const IgcCompanyDetails: FC = () => {
	const location = useLocation();
	const [selectedStage, setSelectedStage] = useState<Selectable>();
	const [isLoadingStageUpdate, setIsLoadingStageUpdate] = useState(true);
	const { name }: any = location.state;
	const { id } = useParams();

	const [selectedTab, setSelectedTab] = useTabs([
		'summary',
		'teaser',
		'documents',
		'contracts',
		'targets',
		'qa',
		'template'
	]);

	useEffect(() => {
		client.get<string>(`igc/business/${id}/step`).then(res => {
			const stageOption = stageOptions.find(option => option.name === res.data);
			setSelectedStage(stageOption);
			setIsLoadingStageUpdate(false);
		});
	}, []);

	const onStageChange = (newStageOption: Selectable) => {
		const previousStage = selectedStage;
		setSelectedStage(newStageOption);
		setIsLoadingStageUpdate(true);

		client.patch(`igc/business/${id}/step`, { business_step: newStageOption.name })
			.catch(_err => {
				setSelectedStage(previousStage);
				toast.error('Erro ao atualizar o estágio');
			})
			.finally(() => {
				setIsLoadingStageUpdate(false);
			});
	};

	return (
		<div>
			<Header
				title={`Empresa: ${name}`}
				goBack={history.back}
				button={
					<div className="z-100 flex items-center">
						<div className="mr-1">Estágio:</div>
						<div className={`${isLoadingStageUpdate && "pointer-events-none opacity-50"}`}>
							<div className="w-72">
								<SingleSelect
									value={selectedStage}
									onChange={onStageChange}
									options={stageOptions}
									isLoading={isLoadingStageUpdate}
								/>
							</div>
						</div>
					</div>
				}
			/>

			<nav className="bg-neutral-high border-neutral-medium flex gap-4 overflow-x-auto border-b border-opacity-50 px-4 md:gap-0 md:px-10">
				<TabSelector isActive={selectedTab === "summary"} onClick={() => setSelectedTab("summary")}>
					Resumo
				</TabSelector>

				<TabSelector isActive={selectedTab === "teaser"} onClick={() => setSelectedTab("teaser")}>
					Teaser
				</TabSelector>

				<TabSelector isActive={selectedTab === "documents"} onClick={() => setSelectedTab("documents")}>
					Documentos
				</TabSelector>

				<TabSelector isActive={selectedTab === "contracts"} onClick={() => setSelectedTab("contracts")}>
					Contratos
				</TabSelector>

				<TabSelector isActive={selectedTab === "targets"} onClick={() => setSelectedTab("targets")}>
					Alvos
				</TabSelector>

				<TabSelector isActive={selectedTab === "qa"} onClick={() => setSelectedTab("qa")}>
					Q&A
				</TabSelector>

				<TabSelector isActive={selectedTab === "template"} onClick={() => setSelectedTab("template")}>
					Template
				</TabSelector>
			</nav>

			{selectedTab === "summary" && <SummaryPage />}
			{selectedTab === "teaser" && <TeaserPage />}
			{selectedTab === "documents" && <DocumentPage />}
			{selectedTab === "contracts" && <ContractPage />}
			{selectedTab === "qa" && <QAPage />}
			{selectedTab === "template" && <TemplatePage />}
			{selectedTab === "targets" && <TargetPage companyName={name} />}
		</div>
	);
};

export default IgcCompanyDetails;
