import { TrashIcon } from "@heroicons/react/outline";
import { FC, useEffect } from "react";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { Step } from "../../configs/models";
import { useFieldArray } from "react-hook-form";

const CompanyCompetitors: FC<Step> = ({
	onSubmit,
	getStepData,
	maxInputs,
	loadingStep,
	errors,
	control,
	register
}) => {
	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "business_competitors"
	});

	useEffect(() => {
		if (fields.length === 0) append({ name: "" });
	}, []);

	return (
		<div className="flex flex-col items-start justify-between">
			<ul className="w-full ">
				{fields.map((input, index) => (
					<li className="mb-4 flex w-full" key={index}>
						<div className="mr-3 w-[93%]">
							<Input
								{...input}
								type="text"
								{...register(`business_competitors.${index}.name`)}
								placeholder={"Digite sua resposta"}
								fieldError={errors.business_competitors?.[index]?.name}
							/>
						</div>
						<div className={`flex w-6 items-center ${index === 0 && "hidden"}`}>
							<TrashIcon
								className="cursor-pointer"
								onClick={() => {
									remove(index);
								}}
								color="#bb7127"
							/>
						</div>
					</li>
				))}
			</ul>

			<div className="flex w-full">
				<Button
					type="button"
					className="mr-3"
					color="outline-secondary"
					size="large"
					disabled={fields.length === maxInputs}
					onClick={() => {
						append({ name: "" });
					}}
				>
					Nova Entrada
				</Button>
				<Button
					disabled={Boolean(errors.business_competitors)}
					loading={loadingStep}
					onClick={(e) => {
						e.preventDefault();

						const convertedValues = getStepData("business_competitors");

						const unconvertedValues = convertedValues.map(({ name }) => name);

						onSubmit({ business_competitors: unconvertedValues });
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</div>
	);
};

export default CompanyCompetitors;
