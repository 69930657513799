import { FC } from "react";
import { Navigate, Route, Routes, unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./contexts/auth";
import { FileProvider } from "./contexts/files";
import { PrivateRoute } from "./helpers/privateRoute";
import "./input.css";
import LoggedLayout from "./layouts/Logged";
import BusinessSession from "./layouts/Session/Business";
import FinderSession from "./layouts/Session/Finder";
import IgcSession from "./layouts/Session/Igc";
import ChatPage from "./pages/Business/Chat";
import BusinessDashboardPage from "./pages/Business/Dashboard";
import BusinessLogin from "./pages/Business/Login";
import BusinessMaterialsPage from "./pages/Business/Materials";
import BusinessDeleteResetSolicitation from "./pages/Business/Recovery/delete";
import BusinessResetPassword from "./pages/Business/Recovery/reset";
import BusinessSetPassword from "./pages/Business/Recovery/set";
import BusinessRegister from "./pages/Business/Register";
import BusinessTargetsPage from "./pages/Business/Targets";
import BusinessTargetCompanyPage from "./pages/Business/Targets/pages/TargetCompany";
import DevTests from "./pages/DevTests";
import ExtendedRegister from "./pages/ExtendedRegister";
import CompanyCollaboration from "./pages/ExtendedRegister/components/Steps/CompanyCollaboration";
import AboutPage from "./pages/Finder/About";
import FinderCaseIgcPage from "./pages/Finder/CaseIgc";
import FinderDashboardPage from "./pages/Finder/Dashboard";
import FinderLogin from "./pages/Finder/Login";
import FinderOpportunitiesPage from "./pages/Finder/Opportunities";
import FinderRecommendationSuccessPage from "./pages/Finder/RecommendationSuccess";
import FinderRecommendBusinessPage from "./pages/Finder/RecommendBusiness";
import FinderDeleteResetSolicitation from "./pages/Finder/Recovery/delete";
import FinderResetPassword from "./pages/Finder/Recovery/reset";
import FinderSetPassword from "./pages/Finder/Recovery/set";
import FinderRegister2 from "./pages/Finder/Register";
import HomePage from "./pages/Home";
import IgcCollaborator from "./pages/Igc/Collaborator";
import IgcCompany from "./pages/Igc/Company";
import IgcCompanyDetails from "./pages/Igc/CompanyDetails";
import IgcDashboardPage from "./pages/Igc/Dashboard";
import IgcChatPage from "./pages/Igc/Chat";
import IgcEditTeaser from "./pages/Igc/EditTeaser";
import IgcInvestor from "./pages/Igc/Investor";
import IgcLogin from "./pages/Igc/Login";
import IgcDeleteResetSolicitation from "./pages/Igc/Recovery/delete";
import IgcResetPassword from "./pages/Igc/Recovery/reset";
import IgcSetPassword from "./pages/Igc/Recovery/set";
import history from "./services/history";
import CompanyRegisterCompleteNotification from "./pages/ExtendedRegister/components/Steps/CompanyRegisterCompleteNotification";
import BusinessConfig from "./pages/Business/Config";
import { MessageProvider } from "./contexts/chat_messages";
import { Helmet } from "react-helmet";
import IGCSharepoint from "./pages/Igc/Sharepoint";

export interface IApplicationPageProps {}

const Application: FC<IApplicationPageProps> = () => {
	return (
		<>
			<Helmet>
				<meta
					name="decription"
					content="A OverA Capital é a referência em M&A para o segmento lower-middle market. Somos a única casa de M&A focada exclusivamente em transações para esse empresário, atuando sempre do lado do vendedor, o que nos proporciona um conhecimento aprofundado do mercado, sem conflito de interesse."
				/>
			</Helmet>
			<AuthProvider>
				<MessageProvider>
					<FileProvider>
						<HistoryRouter history={history}>
							<Routes>
								<Route path="/devtests" element={<DevTests />} />
								<Route path="/" element={<HomePage />} />

								<Route path="business">
									<Route element={<BusinessSession />}>
										<Route path="" element={<Navigate to="login" />} />
										<Route path="login" element={<BusinessLogin />} />
										<Route path="register" element={<BusinessRegister />} />
										<Route path="reset_password" element={<BusinessResetPassword />} />
										<Route path="set_new_password/:token" element={<BusinessSetPassword />} />
										<Route path="delete_solicitation/:token" element={<BusinessDeleteResetSolicitation />} />
									</Route>
									<Route element={<PrivateRoute />}>
										<Route element={<BusinessSession />}>
											<Route
												path="company-register-complete-notification"
												element={<CompanyRegisterCompleteNotification />}
											/>
											<Route path="complete-seu-cadastro-no-side-bar" element={<ExtendedRegister />} />
										</Route>
										<Route element={<LoggedLayout />}>
											<Route path="dashboard" element={<BusinessDashboardPage />} />
											<Route path="target">
												<Route path="" element={<BusinessTargetsPage />} />
												<Route path="company/:id" element={<BusinessTargetCompanyPage />} />
											</Route>
											<Route path="materials" element={<BusinessMaterialsPage />} />
											<Route path="complete-seu-cadastro" element={<ExtendedRegister />} />
											<Route path="cadastro-finalizado" element={<CompanyCollaboration />} />
											<Route path="chat" element={<ChatPage />} />
											<Route path="config" element={<BusinessConfig />} />
										</Route>
										<Route path="investor" element={<IgcInvestor />} />
									</Route>
								</Route>

								<Route path="finder">
									<Route element={<PrivateRoute />}>
										<Route element={<LoggedLayout />}>
											<Route path="dashboard" element={<FinderDashboardPage />} />
											<Route path="recommendbusiness" element={<FinderRecommendBusinessPage />} />
											<Route path="recommendedbusinesssuccess" element={<FinderRecommendationSuccessPage />} />
											<Route path="case-igc" element={<FinderCaseIgcPage />} />
											<Route path="about" element={<AboutPage />} />
											<Route path="opportunities" element={<FinderOpportunitiesPage />} />
										</Route>
									</Route>
									<Route element={<FinderSession />}>
										<Route path="login" element={<FinderLogin />} />
										<Route path="register" element={<FinderRegister2 />} />
										<Route path="reset_password" element={<FinderResetPassword />} />
										<Route path="set_new_password/:token" element={<FinderSetPassword />} />
										<Route path="delete_solicitation/:token" element={<FinderDeleteResetSolicitation />} />
									</Route>
								</Route>

								<Route path="igc">
									<Route element={<IgcSession />}>
										<Route path="" element={<Navigate to="login" />} />
										<Route path="login" element={<IgcLogin />} />
										<Route path="reset_password" element={<IgcResetPassword />} />
										<Route path="set_new_password/:token" element={<IgcSetPassword />} />
										<Route path="delete_solicitation/:token" element={<IgcDeleteResetSolicitation />} />
									</Route>
									<Route element={<PrivateRoute />}>
										<Route element={<LoggedLayout />}>
											<Route path="dashboard" element={<IgcDashboardPage />} />
											<Route path="chat" element={<IgcChatPage />} />
											<Route path="company">
												<Route path="" element={<IgcCompany />} />
												<Route path=":id" element={<IgcCompanyDetails />} />
											</Route>
											<Route path="collaborator" element={<IgcCollaborator />} />
											<Route path="investor" element={<IgcInvestor />} />
											<Route path="sharepoint" element={<IGCSharepoint />} />
											<Route path="editTeaser/:id" element={<IgcEditTeaser />} />
										</Route>
									</Route>
								</Route>

								<Route path="*" element={<Navigate to="/" />} />
							</Routes>
						</HistoryRouter>

						<ToastContainer
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
					</FileProvider>
				</MessageProvider>
			</AuthProvider>
		</>
	);
};

export default Application;
