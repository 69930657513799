import { FC } from "react";
import Button from "src/components/Button/Button";
import Textarea from "src/pages/ExtendedRegister/components/custom-components/Textarea";
import { Step } from "../../configs/models";

const CompanySummary: FC<Step> = ({ getStepData, onSubmit, loadingStep, register, errors }) => {
	return (
		<>
			<div className="flex flex-col">
				<Textarea
					{...register("briefing")}
					placeholder="Digite sua resposta"
					maxLength={1500}
					fieldError={errors.briefing}
					height="h-96"
				/>
			</div>
			<Button
				disabled={Boolean(errors.briefing)}
				loading={loadingStep}
				onClick={(e) => {
					e.preventDefault();
					onSubmit({ briefing: getStepData("briefing") });
				}}
				size="medium"
				className="mt-4"
			>
				Próximo
			</Button>
		</>
	);
};

export default CompanySummary;
