import { TrashIcon } from "@heroicons/react/outline";
import { FC } from "react";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { Step } from "../../configs/models";
import { useFieldArray } from "react-hook-form";

const CompanyPotencialInvestors: FC<Step> = ({
	onSubmit,
	getStepData,
	maxInputs,
	loadingStep,
	control,
	errors,
	register
}) => {
	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "business_potential_investors"
	});

	return (
		<div className="flex flex-col items-start justify-between">
			<ul className="w-full ">
				{fields.map((input, index) => (
					<li className="mb-4 flex w-full" key={index}>
						<div className="mr-3 w-[93%]">
							<Input
								{...input}
								type="text"
								{...register(`business_potential_investors.${index}.investor`)}
								placeholder={"Digite sua resposta"}
								fieldError={errors.business_potential_investors?.[index]?.investor}
							/>
						</div>
						<div className={`flex w-6 items-center`}>
							<TrashIcon
								className="cursor-pointer"
								onClick={() => {
									remove(index);
								}}
								color="#bb7127"
							/>
						</div>
					</li>
				))}
			</ul>

			<div className="flex w-full">
				<Button
					type="button"
					className="mr-3"
					color="outline-secondary"
					size="large"
					disabled={fields.length === maxInputs}
					onClick={() => {
						append({ investor: "" });
					}}
				>
					Nova Entrada
				</Button>
				<Button
					disabled={Boolean(errors.business_potential_investors)}
					loading={loadingStep}
					onClick={(e) => {
						e.preventDefault();

						onSubmit({ business_potential_investors: getStepData("business_potential_investors") });
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</div>
	);
};

export default CompanyPotencialInvestors;
