import Slider from "react-slick";
import { posts } from "../../../assets/news/posts";
import Article from "./Article";

export const NewsSlider = () => {
	const numOfPosts = posts.length;

	if (numOfPosts === 1) return <Article post={posts[0]} fixedWidth />;

	let defaultSlidesToShow = 3;

	if (numOfPosts === 1) {
		defaultSlidesToShow = 1;
	} else if (numOfPosts === 2) {
		defaultSlidesToShow = 2;
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: defaultSlidesToShow,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: numOfPosts < 2 ? numOfPosts : 2,
					slidesToScroll: 2,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				}
			}
		]
	};
	return (
		<Slider {...settings}>
			{posts.map((post) => (
				<Article post={post} />
			))}
		</Slider>
	);
};

export default NewsSlider;
