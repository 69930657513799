import { FC } from 'react';
import { formatCpf } from '../../../helpers';
import InputField from '../base/input';
import { Input } from './models';

const CPFField: FC<Input> = ({ onChange, initialValue }) => {
	const handleMask = (cpf: string) => {
		return formatCpf(cpf);
	};

	function validateCPF(cpf: string) {
		cpf = cpf.replace(/[\s.-]*/igm, '')
		if (cpf.length !== 11 || Array.from(cpf).filter(e => e !== cpf[0]).length === 0) {
     		return false
		}
		var soma = 0
		var resto: number;
		for (var i = 1; i <= 9; i++) 
			soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11))  resto = 0
		if (resto !== parseInt(cpf.substring(9, 10)) ) return false
		soma = 0
		for (i = 1; i <= 10; i++) 
			soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
		resto = (soma * 10) % 11
		if ((resto === 10) || (resto === 11))  resto = 0
		if (resto !== parseInt(cpf.substring(10, 11) ) ) return false
		return true
	}

	return (
		<>
			<InputField
				initialValue={initialValue}
				fieldLabel="CPF"
				type="text"
				id="cpf"
				placeholder="Digite seu CPF"
				errorText="Verifique se o CPF foi digitado corretamente"
				useMask={true}
				mask={handleMask}
				validateValue={validateCPF}
				onChange={onChange}
			/>
		</>
	);
};

export default CPFField;
