import filesize from 'filesize';
import { createContext, FC, useCallback, useContext, useState } from 'react';
import client from 'src/services/client';
import { v4 as uuidv4 } from 'uuid';

export interface IFileProvider {
	children: React.ReactNode;
}

export interface IFile {
	id: string;
	business_id?: number;
	name: string;
	readableSize: string;
	uploaded?: boolean;
	file: File | null;
	progress?: number;
	error?: boolean;
	waiting?: boolean;
	url: string;
	file_type: string;
}

interface IFileContextData {
	uploadedFiles: IFile[];
	clearUploadedFiles(): void;
	deleteFile(id: string): void;
	handleAddFile(business_id: number, file: any, file_type: string): void;
	setUploadedFiles(file: IFile[]): void;
	handleUploadFiles(url: string): void;
	uploadLoading: boolean;
	render: boolean;
}

const FileContext = createContext<IFileContextData>({} as IFileContextData);

const FileProvider: FC<IFileProvider> = ({ children }) => {
	const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
	const [uploadLoading, setUploadLoading] = useState<boolean>(false);
	const [render, setRender] = useState<boolean>(false);

	const clearUploadedFiles = () => {
		setUploadedFiles([]);
	};

	// const handleGetUploadedFiles = (url: string) => {
	// 	client.get<any[]>(url).then((response) => {
	// 		console.log(response.data);
	// 		const filesOrDocumentsFormatted: IFile[] = response.data.map((fileOrDocument) => {
	// 			if (url.includes('contract')) {
	// 				fileOrDocument['contract_size'] = 10000;
	// 				fileOrDocument['contract_url'] =
	// 					'https://dev-match-business.s3.amazonaws.com/business/file/3/Captura_de_tela_de_2022-02-23_07-39-43.png?AWSAccessKeyId=AKIAZTYOMBEXLQTF6ALW&Signature=QQHOHNCEPyeN8DxP0rvaUtBrz%2Fw%3D&Expires=1658413499';
	// 				return {
	// 					id: fileOrDocument.contract_id,
	// 					name: fileOrDocument.contract_name,
	// 					url: fileOrDocument.contract_url,
	// 					readableSize: filesize(fileOrDocument.contract_size),
	// 					file: null,
	// 					error: false,
	// 					uploaded: true,
	// 					waiting: false
	// 				};
	// 			} else {
	// 				fileOrDocument['file_size'] = 10000;
	// 				fileOrDocument['file_url'] =
	// 					'https://dev-match-business.s3.amazonaws.com/business/file/3/Captura_de_tela_de_2022-02-23_07-39-43.png?AWSAccessKeyId=AKIAZTYOMBEXLQTF6ALW&Signature=QQHOHNCEPyeN8DxP0rvaUtBrz%2Fw%3D&Expires=1658413499';
	// 				return {
	// 					id: fileOrDocument.file_id,
	// 					name: fileOrDocument.file_name,
	// 					url: fileOrDocument.file_url,
	// 					readableSize: filesize(fileOrDocument.file_size),
	// 					file: null,
	// 					error: false,
	// 					uploaded: true,
	// 					waiting: false
	// 				};
	// 			}
	// 		});
	// 		setUploadedFiles(filesOrDocumentsFormatted);
	// 	});
	// };

	const updateFile = useCallback((id, data) => {
		setUploadedFiles((state) =>
			state.map((file) => (file.id === id ? { ...file, ...data } : file))
		);
	}, []);

	const processUpload = useCallback(
		(url: string, uploadedFile: IFile) => {
			setUploadLoading(true);
			const data = new FormData();
			if (uploadedFile.file) {
				data.append('file', uploadedFile.file, uploadedFile.name);
				data.append('business_id', String(uploadedFile.business_id));
				data.append('file_type', uploadedFile.file_type);
			}

			client
				.post(url, data, {
					onUploadProgress: (progressEvent) => {
						let progress: number = Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1));

						console.log(`O arquivo ${uploadedFile.name} está ${progress}% carregado... `);

						updateFile(uploadedFile.id, { progress, waiting: false });
					}
				})
				.then((response) => {
					console.log(`o arquivo ${uploadedFile.name} já foi enviado para o servidor!`);

					updateFile(uploadedFile.id, {
						uploaded: true,
						waiting: false,
						id: response.data._id,
						url: response.data.url
					});
				})
				.catch((err) => {
					console.error(
						`Houve um problema para fazer upload da imagem ${uploadedFile.name} no servidor AWS`
					);
					console.log(err);

					updateFile(uploadedFile.id, {
						error: true
					});
				})
				.finally(() => {
					setUploadLoading(false);
					setRender(!render);
				});
		},
		[updateFile, render]
	);

	const handleAddFile = (business_id: number, files: File[], file_type: string) => {
		const newUploadedFiles: IFile[] = files.map((file: File) => ({
			file,
			id: uuidv4(),
			business_id: business_id,
			name: file.name,
			readableSize: filesize(file.size),
			progress: 0,
			uploaded: false,
			error: false,
			waiting: true,
			url: '',
			file_type: file_type
		}));
		setUploadedFiles([...uploadedFiles, ...newUploadedFiles]);
	};

	const handleUploadFiles = useCallback(
		(url: string) => {
			uploadedFiles.forEach((ob) => {
				if (!ob.uploaded) {
					processUpload(url, ob);
				}
			});
		},
		[uploadedFiles, processUpload]
	);

	const deleteFile = useCallback((id: string) => {
		setUploadedFiles((state) => state.filter((file) => file.id !== id));
	}, []);

	return (
		<FileContext.Provider
			value={{
				uploadedFiles,
				setUploadedFiles,
				clearUploadedFiles,
				deleteFile,
				handleAddFile,
				uploadLoading,
				handleUploadFiles,
				render
			}}
		>
			{children}
		</FileContext.Provider>
	);
};

function useFiles(): IFileContextData {
	const context = useContext(FileContext);

	if (!context) {
		throw new Error('useFiles must be used within FileProvider');
	}

	return context;
}
export { FileProvider, useFiles };
