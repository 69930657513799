import InputMask from "react-input-mask";
import { InputHTMLAttributes, LegacyRef, forwardRef } from "react";
import "./style.css";
import { FieldError } from "react-hook-form";
interface InputDateProps extends InputHTMLAttributes<HTMLInputElement> {
	fieldError: FieldError | undefined;
	bgColor?: string;
}

const InputDate = forwardRef(
	(
		{ fieldError, bgColor = "bg-[#F4F5F6]", ...otherProps }: InputDateProps,
		ref: LegacyRef<InputMask>
	) => {
		return (
			<>
				<div className="relative">
					<InputMask
						ref={ref}
						mask="99/9999"
						className={`}  mb-2  w-full
					py-3 pl-2 pr-3 ${bgColor} resize-none rounded ${
							Boolean(fieldError)
								? "border border-brand-error focus:border-brand-error focus:ring-brand-error active:border-brand-error "
								: "border-0"
						}`}
						{...otherProps}
					/>
				</div>
				{Boolean(fieldError) && (
					<span className="mb-2 text-brand-error">{fieldError?.message}</span>
				)}
			</>
		);
	}
);

export default InputDate;
