import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { FC, Fragment, useRef } from 'react';

interface ModalProps {
	open: boolean;
	onClose: (value: boolean) => void;
	title: string;
	description?: string;
	size?: 'xsmall' | 'small' | 'medium' | 'large';
	children: React.ReactNode;
	overflow?: boolean;
}

const Modal: FC<ModalProps> = ({
	open,
	children,
	title,
	description,
	onClose,
	size = 'medium',
	overflow = false
}: ModalProps) => {
	let completeButtonRef = useRef(null);
	return (
		<Transition show={open} as={Fragment}>
			<Dialog initialFocus={completeButtonRef} open={open} onClose={() => onClose(false)}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					{/* The backdrop, rendered as a fixed sibling to the panel container */}
					<div className="bg-black/30 fixed inset-0 " aria-hidden="true" />
				</Transition.Child>

				{/* Full-screen container to center the panel */}
				<div className="fixed inset-0 flex items-center justify-center bg-[#000] bg-opacity-20 p-4 backdrop-blur-sm">
					{/* Container to center the panel */}
					<div className={`flex min-w-full items-center justify-center ${overflow ? "h-full max-h-[95vh]" : "min-h-full"}`}>
						{/* The actual dialog panel  */}
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								className={`h-full w-full ${
									size === 'xsmall'
										? 'max-w-xl'
										: size === 'small'
										? 'max-w-2xl'
										: size === 'medium'
										? 'max-w-3xl'
										: size === 'large' && 'max-w-4xl'
								} transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
							>
								<button className="absolute right-3 top-3" onClick={() => onClose(false)}>
									<XIcon className="h-6 w-6 text-brand-primary" />
								</button>
								<div className="flex items-center justify-between">
									<Dialog.Title className="mb-4 text-2xl font-bold text-brand-primary">
										{title}
									</Dialog.Title>
								</div>
								<Dialog.Description className="text-neutral-low mb-4 text-base font-normal">
									{description}
								</Dialog.Description>
								{overflow ? (
									<div className="overflow-y-auto h-full">
										{children}
									</div>
								) : (
									children
								)}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default Modal;
