export const BusinessDefaultSteps = <BusinessStageType[]>[
	{
		name: 'Preparação',
		slug: 'preparacao',
		complete: false,
		current: false
	},
	{
		name: 'Roadshow',
		slug: 'roadshow',
		complete: false,
		current: false
	},
	{
		name: 'Negociação e Diligência',
		slug: 'negociacao e diligencia',
		complete: false,
		current: false
	},
	{
		name: 'Transação Concluída',
		slug: 'transacao concluida',
		complete: false,
		current: false
	}
];

export type BusinessSteps =
	| 'Preparação'
	| 'Roadshow'
	| 'Negociação e Diligência'
	| 'Transação Concluída';

export const BusinessStepsSlugs = [
	'preparacao',
	'roadshow',
	'negociacao e diligencia',
	'transacao concluida'
] as const;

export type BusinessStepSlug = (typeof BusinessStepsSlugs)[number];

export interface BusinessStageType {
	name: string;
	slug: BusinessStepSlug;
	complete: boolean;
	current: boolean;
}
