import { DownloadIcon } from "@heroicons/react/outline";
import moment from "moment";
import "moment/locale/pt-br";
import { FC, useEffect, useState } from "react";
import Loading from "src/assets/icons/Loading";
import Button from "src/components/Button/Button";
import Modal from "src/components/Modal";
import Tag from "src/components/Tag";
import { Selectable } from "src/components/ui/form/models";
import FileList from "src/components/FileList";
import SingleSelect from "src/components/ui/form/Selects/SingleSelect";
import Upload from "src/components/Upload";
import { useFiles } from "src/contexts/files";
import { Document } from "src/domain/Document";
import { useAuth } from "src/hooks/useAuth";
import Page from "src/layouts/Logged/components/Page";
import { getDocumentsByCompanyId } from "src/services/document/getDocumentsByCompanyId";
import { ChevronDownIcon } from "@heroicons/react/solid";

interface DocumentPageProps {}

const DocumentPage: FC<DocumentPageProps> = () => {
	const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);
	const [optionSelected, setOptionSelected] = useState<Selectable>({} as Selectable);
	const [documents, setDocuments] = useState<Array<Document>>();
	const [originalDocuments, setOriginalDocuments] = useState<Array<Document>>();
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const { handleUploadFiles, uploadLoading, render, uploadedFiles, clearUploadedFiles } = useFiles();
	const { user } = useAuth();
	const [order, setOrder] = useState<"ASC" | "DESC">("ASC");

	const options: Array<Selectable> = [
		{
			id: 1,
			slug: "denied",
			name: "Negado",
			value: "denied"
		},
		{
			id: 2,
			slug: "pending",
			name: "Aguardando aprovação",
			value: "pending"
		},
		{
			id: 3,
			slug: "approved",
			name: "Aprovado",
			value: "approved"
		},
		{
			id: 4,
			slug: "all",
			name: "Todos",
			value: ""
		}
	];

	useEffect(() => {
		getDocumentsByCompanyId(String(user?.role), Number(user?.business_id))
			.then((response) => {
				setDocuments(response.data);
				setOriginalDocuments(response.data);
			})
			.finally(() => {
				setLoadingDocuments(false);
			});
	}, [render]);

	const handleFiltering = (value: Selectable) => {
		setLoadingDocuments(true);
		setDocuments(searchTable(value.value));
		setOptionSelected(value);
	};

	const handleCloseModal = () => {
		clearUploadedFiles();
		setModalOpen(false);
	};

	const handleUpload = async () => {
		handleUploadFiles("business/upload_file/");

		handleCloseModal();
	};

	const notHaveFileToUpload = (): boolean => {
		let response = true;
		if (uploadedFiles.length === 0) return true;
		uploadedFiles.forEach((file) => {
			if (!file.uploaded) response = false;
		});
		return response;
	};

	const searchTable = (status: string) => {
		setTimeout(() => {
			setLoadingDocuments(false);
		}, 500);

		if (!status) return originalDocuments;

		if (originalDocuments) return originalDocuments.filter((document) => document.status === status);
	};

	function sortTable(column: "file_name" | "created_at" | "sender_name" | "status") {
		if (!documents) return;

		if (order === "ASC") {
			const sorted = [...documents]?.sort((a, b) => (a[column].toLowerCase() > b[column].toLowerCase() ? 1 : -1));

			setDocuments(sorted);
			setOrder("DESC");
		}

		if (order === "DESC") {
			const sorted = [...documents]?.sort((a, b) => (a[column].toLowerCase() < b[column].toLowerCase() ? 1 : -1));

			setDocuments(sorted);
			setOrder("ASC");
		}
	}

	return (
		<>
			<Page>
				<div className="mb-6 flex items-center justify-between">
					<h1 className="text-2xl font-bold text-brand-primary">Documentos</h1>
					<Button onClick={() => setModalOpen(true)} size="large">
						Adicionar documento
					</Button>
				</div>
				<div className="mb-6 w-96">
					<SingleSelect
						bgColor="bg-neutral-high"
						placeholder="Filtrar por status"
						options={options}
						value={optionSelected}
						onChange={(e: Selectable) => handleFiltering(e)}
					/>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="w-full">
						<thead>
							<tr>
								<th scope="col" className="text-left">
									Documento
									<button className="cursor-pointer align-middle" onClick={() => sortTable("file_name")}>
										<ChevronDownIcon height={20} />
									</button>
								</th>
								<th scope="col" className="text-left">
									Data de envio
									<button className="cursor-pointer align-middle" onClick={() => sortTable("created_at")}>
										<ChevronDownIcon height={20} />
									</button>
								</th>
								<th scope="col" className="text-left">
									Enviado por
									<button className="cursor-pointer align-middle" onClick={() => sortTable("sender_name")}>
										<ChevronDownIcon height={20} />
									</button>
								</th>
								<th scope="col" className="text-left">
									Status
									<button className="cursor-pointer align-middle" onClick={() => sortTable("status")}>
										<ChevronDownIcon height={20} />
									</button>
								</th>
								<th scope="col" className="">
									Download
								</th>
							</tr>
						</thead>
						<tbody className=" divide-neutral-medium divide-y divide-opacity-50">
							{documents && documents.length === 0 && !loadingDocuments ? (
								<tr className="text-neutral-medium text-center text-2xl font-bold">
									<td className="whitespace-nowrap py-4 pr-6" colSpan={6}>
										Nenhum documento encontrado
									</td>
								</tr>
							) : loadingDocuments ? (
								<tr className="text-neutral-medium text-center text-2xl font-bold">
									<td className="whitespace-nowrap py-4 pr-6" colSpan={6}>
										<Loading color="primary" size={12} />
									</td>
								</tr>
							) : (
								documents?.map((document) => (
									<tr key={document.file_id}>
										<td className="whitespace-nowrap py-4 pr-6">
											<div className="flex items-center justify-start">
												<div className="text-neutral-medium text-left text-sm font-normal">{document.file_name}</div>
											</div>
										</td>
										<td className="whitespace-nowrap py-4 pr-6">
											<div className="text-neutral-medium text-left text-sm font-normal">
												{moment(document.created_at).locale("pt-br").format("DD MMM YYYY")}
											</div>
										</td>
										<td className="whitespace-nowrap py-4 pr-6">
											<div className="text-neutral-medium text-left text-sm font-normal">{document.sender_name}</div>
										</td>
										<td className=" text-neutral-medium whitespace-nowrap py-4 pr-6 text-sm font-normal">
											<Tag status={document.status} />
										</td>
										<td className="whitespace-nowraptext-sm text-neutral-medium py-4 pr-2 text-center  font-normal">
											<a
												href={document.file_url}
												className="ml-0  inline-flex text-brand-secondary hover:brightness-110 hover:saturate-100 active:scale-105"
											>
												<DownloadIcon width={20} color="" />
											</a>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>
				<Modal
					open={isModalOpen}
					onClose={handleCloseModal}
					title="Adicionar documento"
					description="Insira abaixo o(s) documento(s) que deseja adicionar. Clique em confirmar para enviar."
				>
					<Upload businessId={Number(user?.business_id)} checkFileType markDocumentsAsOtherByDefault={true} />
					<FileList />
					<div className="mt-7 flex justify-between">
						<Button onClick={() => handleCloseModal()} className="mr-3" color="outline-secondary">
							Cancelar
						</Button>
						<Button
							loading={uploadLoading}
							onClick={handleUpload}
							className="ml-3"
							color="secondary"
							disabled={notHaveFileToUpload()}
						>
							Confirmar
						</Button>
					</div>
				</Modal>
			</Page>
		</>
	);
};

export default DocumentPage;
