import "moment/locale/pt-br";
import { FC, useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Loading from "src/assets/icons/Loading";
import Button from "src/components/Button/Button";
import Tooltip from "src/components/Tooltip";
import { Question } from "src/domain/Question";
import { toastNotify } from "src/helpers/toastNotify";
import { useAuth } from "src/hooks/useAuth";
import Page from "src/layouts/Logged/components/Page";
import { getQAList } from "src/services/qa/getQAList";
import { setQA } from "src/services/qa/setQA";

const QAPage: FC = () => {
	const [loadingQA, setLoadingQA] = useState<boolean>(true);
	const [questions, setQuestions] = useState<Array<Question>>([]);
	const [changedQuestions, setChangedQuestions] = useState<boolean[]>([]);

	const { user } = useAuth();

	const updateChangedQuestions = (questionIndex: number, value: boolean) => {
		const newChangedQuestions = [...changedQuestions];
		newChangedQuestions[questionIndex] = value;
		setChangedQuestions(newChangedQuestions);
	};

	const handleInputChange = (id, value) => {
		const newQuestions = [...questions];
		newQuestions[id] = { ...newQuestions[id], answer: value, approved: false };
		setQuestions(newQuestions);

		updateChangedQuestions(id, true);
	};

	const saveAnswer = async (questionIndex: number, saveAllRequest: boolean = false) => {
		const questionToSave = { ...questions[questionIndex], business_id: Number(user?.business_id) };
		const response = await setQA(questionToSave);

		if (response.status !== 200) return toastNotify("Erro ao enviar resposta", "error");

		setQuestions((prevQuestions) =>
			prevQuestions.map((prevQuestion, index) => (index === questionIndex ? questionToSave : prevQuestion))
		);
		!saveAllRequest && toastNotify("Resposta enviada", "success");

		updateChangedQuestions(questionIndex, false);
	};

	const handleList = async (business_id: number) => {
		try {
			setLoadingQA(true);
			const response = await getQAList(business_id);
			setQuestions(response.data);
		} finally {
			setLoadingQA(false);
		}
	};

	useEffect(() => {
		handleList(user?.business_id || 0);
	}, [user]);

	if (loadingQA) {
		return (
			<div className="flex h-screen items-center justify-center">
				<Loading color="primary" size={12} />
			</div>
		);
	}

	if (questions?.length === 0) {
		return (
			<Page>
				<div className="relative h-[75vh] max-h-[75vh] w-full">
					<span className="flex h-full flex-col items-center sm:justify-center md:flex-row md:gap-6">
						<h2 className="m-0 max-w-xl text-center text-lg font-medium sm:mb-2 sm:text-2xl">
							Nesta aba, iremos concentrar todas as perguntas para te preparar para o roadshow e, ao
							longo do processo, concentrar as perguntas de investidores.
						</h2>
					</span>
				</div>
			</Page>
		);
	}

	return (
		<Page>
			<h1 className="mb-6 text-2xl font-bold text-brand-primary">Q&A</h1>
			{questions.map(({ question, answer, explanation }, index) => (
				<section className="mb-8" key={index}>
					<div className="mb-2 flex">
						<p className="text-base font-semibold text-brand-primary">{question}</p>
						{explanation && <Tooltip text={explanation} id={index} />}
					</div>

					<div className="flex flex-col gap-2 md:flex-row">
						<TextareaAutosize
							minRows={1}
							maxRows={5}
							value={answer}
							className="mb-2 w-full resize-none rounded-lg border bg-white py-3 pl-2 pr-3"
							onChange={(e) => handleInputChange(index, e.target.value)}
						/>
						<Button
							type="button"
							size="small"
							style={{ height: 44 }}
							color="secondary"
							disabled={!changedQuestions[index]}
							onClick={() => saveAnswer(index)}
						>
							Salvar Resposta
						</Button>
					</div>
				</section>
			))}
		</Page>
	);
};

export default QAPage;
