import { FC } from 'react';
import InputField from '../base/input';
import { Input } from './models';

const WEBSITE_REGEX =
	/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@%_.~#?&//=]*)/;

const validateEmail = (website: string) => website === '' || WEBSITE_REGEX.test(website);

const WebsiteField: FC<Input> = ({ onChange, initialValue }) => (
	<InputField
		initialValue={initialValue}
		fieldLabel='Website (opcional)'
		type='url'
		id='website'
		placeholder='seusite.com'
		errorText='Endereço inválido'
		validateValue={validateEmail}
		onChange={onChange}
		optional={true}
	/>
);

export default WebsiteField;
