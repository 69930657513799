export const getLocalStorage = (name: string) => {
	const response = localStorage.getItem(name);

	return response;
};

export const setLocalStorage = (name: string, value: any) => {
	localStorage.setItem(name, value);
};

export const removeLocalStorage = (name: string) => {
	localStorage.removeItem(name);
};

export const clearLocalStorage = () => {
	localStorage.clear();
};
