import {
	BriefcaseIcon,
	CogIcon,
	DesktopComputerIcon,
	DocumentTextIcon,
	GlobeIcon,
	HomeIcon,
	LightBulbIcon,
	MapIcon,
	UsersIcon,
	XCircleIcon,
	ChartPieIcon
} from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import { MenuItem } from "./models";
import ChatIcon from "../components/ChatIcon";

const usersMenus = {
	default: ["dashboard_finders", "case_igc", "about"],
	finders: ["dashboard_finders", "case_igc", "about", "indicar_empresa"],
	business: ["dashboard", "my_targets", "my_materials", "chat", "settings", "help"],
	igc: ["dashboard_igc", "collaborators", "companies", "investors", "chat_igc", "sharepoint_igc"],
	finder: ["dashboard_finder", "case_igc", "about", "indicar_empresa", "opportunities_finder"]
};

const className = "mr-3 flex-shrink-0 h-6 w-6 text-indigo-300";
const businessItems = [
	{
		name: "dashboard",
		label: "Dashboard",
		icon: <ChartPieIcon className={className} />,
		toPath: "/business/dashboard"
	},
	{
		name: "my_targets",
		label: "Meus Alvos",
		icon: <XCircleIcon className={className} />,
		toPath: "/business/target"
	},
	{
		name: "my_materials",
		label: "Meus Materiais",
		icon: <DocumentTextIcon className={className} />,
		toPath: "/business/materials"
	},
	{
		name: "chat",
		label: "Chat",
		icon: <ChatIcon className={className} />,
		toPath: "/business/chat"
	},
	{
		name: "settings",
		label: "Configurações",
		icon: <CogIcon className={className} />,
		toPath: "/business/config"
	}
];

const igcItems = [
	{
		name: "dashboard_igc",
		label: "Dashboard",
		icon: <ChartPieIcon className={className} />,
		toPath: "/igc/dashboard"
	},
	{
		name: "collaborators",
		label: "Colaboradores",
		icon: <UsersIcon className={className} />,
		toPath: "/igc/collaborator"
	},
	{
		name: "companies",
		label: "Empresas",
		icon: <LightBulbIcon className={className} />,
		toPath: "/igc/company"
	},
	{
		name: "investors",
		label: "Investidores",
		icon: <BriefcaseIcon className={className} />,
		toPath: "/igc/investor"
	},
	{
		name: "chat_igc",
		label: "Chat",
		icon: <ChatIcon className={className} />,
		toPath: "/igc/chat"
	},
	{
		name: "sharepoint_igc",
		label: "Sharepoint",
		icon: <GlobeIcon className={className} />,
		toPath: "/igc/sharepoint"
	}
];

const finderItems = [
	{
		name: "dashboard_finder",
		label: "Dashboard",
		icon: <HomeIcon className={className} />,
		toPath: "/finder/dashboard"
	},
	{
		name: "case_igc",
		label: "Cases IGC",
		icon: <MapIcon className={className} />,
		toPath: "/finder/case-igc"
	},
	{
		name: "about",
		label: "Quem somos",
		icon: <DesktopComputerIcon className={className} />,
		toPath: "/finder/about"
	},
	{
		name: "indicar_empresa",
		label: "Indicar Empresa",
		icon: <PlusIcon className={className} />,
		toPath: "/finder/recommendbusiness"
	},
	{
		name: "opportunities_finder",
		label: "Oportunidades",
		icon: <GlobeIcon className={className} />,
		toPath: "/finder/opportunities"
	}
];
const menuItems = [...businessItems, ...igcItems, ...finderItems];

export const getUserMenuItems = (userRole: string = "default") => {
	const menu: Array<MenuItem> = [];
	const userMenuItems = (usersMenus as any)[userRole];

	userMenuItems.forEach((item: string) => {
		menuItems.forEach((itemData) => {
			if (itemData.name === item) {
				menu.push(itemData);
			}
		});
	});
	return menu;
};
