import { ChatAlt2Icon } from "@heroicons/react/outline";
import { useChatMessages } from "src/hooks/useChatMessages";

interface ChatProps {
	className: string;
}

const ChatIcon = ({ className }: ChatProps) => {
	const { newMessages } = useChatMessages();

	return (
		<span className={`${className} relative inline-block`}>
			<ChatAlt2Icon />
			{newMessages && (
				<span className="absolute top-0 right-0 inline-block h-2 w-2 translate-x-1/2 -translate-y-1/2 transform rounded-full bg-red-600"></span>
			)}
		</span>
	);
};

export default ChatIcon;
