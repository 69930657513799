import { collection, orderBy, query } from "firebase/firestore";
import { FC, MutableRefObject, useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "src/services/firebase";
import ChatMessage from "./ChatMessage";
import { useAuth } from "src/hooks/useAuth";

interface IChatRoom {
	scrollRef: MutableRefObject<null | HTMLDivElement>;
	chatId?: number | null;
}

const ChatRoom: FC<IChatRoom> = ({ scrollRef, chatId }) => {
	const { user } = useAuth();
	const messagesRef = collection(
		db,
		"chats",
		chatId ? chatId?.toString() : user?.business_id ? user?.business_id.toString() : "1",
		"messages"
	);
	const q = query(messagesRef, orderBy("createdAt"));
	const [data, loading, error] = useCollectionData(q);

	useEffect(() => {
		scrollRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [data]);

	if (chatId === null) {
		return <p>Selecione um chat</p>;
	}

	if (loading) {
		return <p>Loading...</p>;
	}

	if (error) {
		console.error(error);
		return <p>Error: {error.message}</p>;
	}

	return (
		<ul className="h-full max-h-full w-full overflow-y-auto">
			{data &&
				data.map((msg, idx) => {
					const message = msg.text;
					const createdAt = msg.createdAt;
					const senderId = msg.senderId;
					const senderRole = msg.senderRole;
					return (
						<ChatMessage
							key={idx}
							message={message}
							createdAt={createdAt}
							senderId={senderId}
							senderRole={senderRole}
							chatId={chatId}
						/>
					);
				})}

			<div ref={scrollRef}></div>
		</ul>
	);
};

export default ChatRoom;
