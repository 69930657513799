import { toast } from 'react-toastify';

type ToastType = 'default' | 'error' | 'success' | 'warning' | 'info';

export const toastNotify = (message?: string, type: ToastType = 'default') => {
	if (message) {
		if (type === 'error') {
			toast.error(message);
		} else if (type === 'success') {
			toast.success(message);
		} else if (type === 'warning') {
			toast.warning(message);
		} else if (type === 'info') {
			toast.info(message);
		} else {
			toast(message);
		}
	} else {
		toast.error('Error Interno no servidor');
	}
};
