import "moment/locale/pt-br";
import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "src/components/Button/Button";
import Input from "src/components/Input";
import { Selectable } from "src/components/ui/form/models";
import { InvestorBusinessDTO, InvestorIgcDTO } from "src/domain/Target";
import { Teaser } from "src/domain/Teaser";
import { toastNotify } from "src/helpers/toastNotify";
import { useAuth } from "src/hooks/useAuth";
import Page from "src/layouts/Logged/components/Page";
import { debounce } from "src/services/Utils";
import TargetTable from "./TargetTable";
import { getTeaserByCompanyId } from "src/services/teaser/getTeaserByCompanyId";
import TargetModal from "./TargetModal";
import {
	SelectedBusinessInvestorsParams,
	disableBusinessTargetList,
	enableBusinessTargetList,
	getBusinessInvestors,
	getBusinessInvestorsParams,
	getSelectedBusinessInvestors,
	setSelectedBusinessInvestors
} from "src/services/target";
import { XIcon } from "@heroicons/react/solid";
import SingleSelect from "src/components/ui/form/Selects/SingleSelect";
import TargetNavList from "./TargetNavList";
import LoadingIcon from "src/assets/icons/Loading";
interface TargetPageProps {
	companyName: string;
}

export interface IgcInvestorsObject extends InvestorIgcDTO {
	checked: boolean;
}

const mapInvestors = (investors: InvestorIgcDTO[]): IgcInvestorsObject[] => {
	return investors.map((investor) => {
		return {
			...investor,
			checked: false
		};
	});
};

export type Tab = "ALL" | "APPROVED" | "RECOMMENDED" | "REJECTED";

export const TABS = {
	ALL: "Todos" as Tab,
	APPROVED: "Aprovados" as Tab,
	RECOMMENDED: "Recomendados" as Tab,
	REJECTED: "Rejeitados" as Tab
};

const criteriaOptions = [
	{
		id: 0,
		slug: "ALL",
		name: "Sem critério",
		value: true
	},
	{
		id: 1,
		slug: "NDA_SIGNED",
		name: "1 - NDA Assinado",
		value: true
	},
	{
		id: 2,
		slug: "EXCESSIVE_SIZE",
		name: "2 - No Go por tamanho",
		value: false
	},
	{
		id: 3,
		slug: "LOOKED_FOR_DEAL_IN_SUB_SECTOR",
		name: "3 - Deal apresentado no subsetor",
		value: false
	},
	{
		id: 4,
		slug: "INTEL",
		name: "4 - Intel",
		value: false
	},
	{
		id: 5,
		slug: "NEOWAY",
		name: "5 - Neoway",
		value: false
	},
	{
		id: 6,
		slug: "LINKEDIN",
		name: "6 - LinkedIn",
		value: false
	}
];

const TargetPage: FC<TargetPageProps> = ({ companyName }) => {
	const modalScrollTopRef = useRef<null | HTMLDivElement>(null);
	const tabsScrollTopRef = useRef<null | HTMLDivElement>(null);
	const inputRef = useRef<null | HTMLInputElement>(null);
	const { user } = useAuth();
	const { id } = useParams();
	const [page, setPage] = useState<number>(0);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [teaser, setTeaser] = useState<Teaser>();
	const [loading, setLoading] = useState<boolean>(false);
	const [tradeName, setTradeName] = useState<string>("");
	const [investors, setInvestors] = useState<IgcInvestorsObject[]>([]);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [tabLoading, setTabLoading] = useState<boolean>(true);
	const [selectedTab, setSelectedTab] = useState<Tab>(TABS.ALL);
	const [modalSelectedInvestors, setModalSelectedInvestors] = useState<IgcInvestorsObject[]>([]);
	const [selectedInvestors, setSelectedInvestors] = useState<InvestorBusinessDTO[]>([]);
	const [targetListEnabled, setTargetListEnabled] = useState<boolean>(false);
	const [selectedCriteria, setSelectedCriteria] = useState<Selectable>({} as Selectable);

	const getMappedInvestors = async (params: getBusinessInvestorsParams) => {
		try {
			const businessId = Number(id);
			if (selectedCriteria.slug !== "ALL" && Object.keys(selectedCriteria).length > 0) {
				params = {
					...params,
					criteria: selectedCriteria.slug
				};
			}

			const responseData = (await getBusinessInvestors(businessId, params)).data;
			setTotalPages(responseData.total);
			return mapInvestors(responseData.data);
		} catch (error) {
			toastNotify("Erro ao carregar investidores", "error");
			return [];
		}
	};

	const fetchSelectedInvestors = async (params: SelectedBusinessInvestorsParams = {}) => {
		try {
			setTabLoading(true);
			const businessId = Number(id);
			const responseData = (await getSelectedBusinessInvestors(businessId, params)).data;
			setSelectedInvestors(responseData.data);
		} catch (error) {
			toastNotify("Erro ao carregar investidores selecionados", "error");
		} finally {
			setTabLoading(false);
			modalScrollTopRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	};

	const fetchTeaser = async () => {
		try {
			setLoading(true);
			const userRole = String(user?.role);
			const businessId = Number(id);
			const response = await getTeaserByCompanyId(userRole, businessId);
			setTeaser(response.data);
		} catch (error) {
			toastNotify("Erro ao carregar dados da empresa", "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchSelectedInvestors();
		fetchTeaser();
	}, []);

	const fetchInvestors = async () => {
		try {
			setPage(0);
			setTotalPages(0);
			const params = {
				page: page,
				size: 20,
				...(tradeName !== "" && { tradeName: tradeName }),
				...(selectedCriteria.slug !== "ALL" &&
					Object.keys(selectedCriteria).length > 0 && { criteria: selectedCriteria.slug })
			};
			const investors = await getMappedInvestors(params);
			setInvestors(investors);
			modalScrollTopRef.current?.scrollIntoView({ behavior: "smooth" });
		} catch (error) {
			toastNotify("Erro ao carregar investidores para recomendação", "error");
		}
	};

	useEffect(() => {
		fetchInvestors();
	}, [modalOpen, setModalOpen, selectedCriteria, setSelectedCriteria, tradeName, setTradeName]);

	const handleModalClose = () => {
		setPage(0);
		setTotalPages(0);
		setModalOpen(false);
		setInvestors([]);
		setModalSelectedInvestors([]);
		setSelectedCriteria({} as Selectable);
		setTradeName("");
		inputRef.current?.value && (inputRef.current.value = "");
		modalScrollTopRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const handleSendInvestors = async () => {
		setLoading(true);
		const selectedInvestorsIds = modalSelectedInvestors.map((investor) => investor.investorId);
		const params = {
			businessId: Number(id),
			body: selectedInvestorsIds
		};
		try {
			await setSelectedBusinessInvestors(params);
			fetchSelectedInvestors();
			toastNotify("Investidores adicionados com sucesso", "success");
			handleModalClose();
		} catch (error) {
			toastNotify("Erro ao adicionar investidores", "error");
		} finally {
			setLoading(false);
		}
	};

	const handleToggleTargetList = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;
		const businessId = Number(id);

		if (checked) {
			try {
				await enableBusinessTargetList(businessId);
				setTargetListEnabled(true);
				fetchTeaser();
				toastNotify("Lista de alvos ativada com sucesso", "success");
			} catch (error) {
				toastNotify("Erro ao ativar lista de alvos", "error");
			}
		}
		if (!checked) {
			try {
				await disableBusinessTargetList(businessId);
				setTargetListEnabled(false);
				fetchTeaser();
				toastNotify("Lista de alvos desativada com sucesso", "success");
			} catch (error) {
				toastNotify("Erro ao desativar lista de alvos", "error");
			}
		}
	};

	return (
		<>
			<Page>
				<TargetModal modalOpen={modalOpen} handleModalClose={handleModalClose}>
					<div className="flex h-full w-full flex-col">
						<span className="flex flex-col items-center sm:justify-center md:flex-row md:gap-6">
							<h2 className="m-0 text-lg font-bold text-brand-primary sm:mb-2 sm:text-2xl">
								Adicionar Alvo
							</h2>
							<h2 className="text-neutral-medium m-0 text-lg font-medium sm:mb-2 sm:text-2xl">
								{companyName}
							</h2>
							<h2 className="text-neutral-medium m-0 whitespace-nowrap text-lg sm:mb-2 sm:text-2xl">
								{teaser?.business_main_sector} - {teaser?.business_main_subsector}
							</h2>
						</span>

						<span className="relative mb-2 flex w-full flex-col items-center justify-between gap-2 md:flex-row md:items-end">
							<Input
								placeholder="Pesquise por uma empresa aqui"
								className="w-64 rounded-lg border-none bg-auxiliar-grey-100 pt-4 pb-5 text-sm"
								type="search"
								inputRef={inputRef}
								onChange={debounce((e: React.ChangeEvent<HTMLInputElement>) => {
									const inputText = e.target.value.trim();
									setPage(0);
									setTradeName(inputText);
								}, 1000)}
								error={false}
								errorMessage={""}
							/>
							<div className="w-72 self-center">
								<SingleSelect
									bgColor="bg-neutral-high"
									options={criteriaOptions}
									value={selectedCriteria}
									onChange={(e: Selectable) => {
										setPage(0);
										setSelectedCriteria(e);
									}}
								/>
							</div>
						</span>

						<TargetTable
							tradeName={tradeName}
							scrollTopRef={modalScrollTopRef}
							selectedCriteria={selectedCriteria}
							page={page}
							setPage={setPage}
							totalPages={totalPages}
							investors={investors}
							setInvestors={setInvestors}
							getMappedInvestors={getMappedInvestors}
							modalSelectedInvestors={modalSelectedInvestors}
							setModalSelectedInvestors={setModalSelectedInvestors}
						/>
						{modalSelectedInvestors.length > 0 && (
							<div className="flex w-full gap-2 overflow-x-auto overflow-y-hidden pt-2 pb-7 md:pb-4">
								{modalSelectedInvestors.map((investor, idx) => (
									<span
										key={idx}
										className="flex h-fit items-center whitespace-nowrap rounded-md bg-brand-secondary py-1 pl-2 pr-1 text-sm font-medium text-white sm:text-base"
									>
										{investor.tradeName}
										<button
											className="ml-2 rounded-md bg-brand-secondaryMedium p-1 hover:bg-opacity-70"
											onClick={() => {
												const filteredInvestors = modalSelectedInvestors.filter(
													(selectedInvestor) => selectedInvestor.investorId !== investor.investorId
												);
												setModalSelectedInvestors(filteredInvestors);
											}}
										>
											<XIcon className="h-4 w-4 text-white" />
										</button>
									</span>
								))}
							</div>
						)}
						<Button
							loading={loading}
							size="small"
							type="button"
							color="secondary"
							className="mt-2 self-end"
							onClick={handleSendInvestors}
						>
							<span className="text-lg text-white">Enviar</span>
						</Button>
					</div>
				</TargetModal>
				<div className="relative h-[75vh] max-h-[75vh] w-full rounded-lg bg-brand-warning bg-opacity-5 p-10 shadow-lg">
					<div className="mb-6 flex items-center justify-between gap-2">
						<h1 className="text-2xl font-bold text-brand-primary">Alvos</h1>
						<Button onClick={() => setModalOpen(true)} size="large">
							Adicionar alvo
						</Button>
					</div>
					<div className="my-2 flex items-center gap-2">
						{loading ? (
							<LoadingIcon />
						) : (
							<input
								defaultChecked={teaser?.target_list_approved}
								type="checkbox"
								checked={teaser?.target_list_approved}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggleTargetList(e)}
								className="bg-gray-100 border-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 h-4 w-4 rounded text-brand-primary focus:ring-2 focus:ring-brand-primary dark:focus:ring-brand-primary"
							/>
						)}
						Mostrar lista de alvos para o cliente
					</div>

					<TargetNavList
						fetchSelectedInvestors={fetchSelectedInvestors}
						selectedInvestors={selectedInvestors}
						setSelectedInvestors={setSelectedInvestors}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						tabLoading={tabLoading}
						tabsScrollTopRef={tabsScrollTopRef}
					/>
				</div>
			</Page>
		</>
	);
};

export default TargetPage;
