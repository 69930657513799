import { FC, Fragment, useEffect, useState } from "react";
import { TabSelector } from "src/components/TabSelector";
import { Tab, TABS } from ".";
import {
	SelectedBusinessInvestorsParams,
	deleteBusinessInvestor,
	setBusinessInvestorParams
} from "src/services/target";
import { InvestorBusinessDTO, MatchStatus } from "src/domain/Target";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import LoadingIcon from "src/assets/icons/Loading";
import { toastNotify } from "src/helpers/toastNotify";
import { useParams } from "react-router-dom";
import Modal from "../../../../../../components/Modal";
import { InvestorBusinessStageDTO } from "src/domain/Investor";
import SingleSelect from "src/components/ui/form/Selects/SingleSelect";
import { getInvestorBusinessStages, updateInvestorBusinessStage } from "src/services/investor";
import { Selectable } from "src/components/ui/form/models";

const badgeByMatchStatus = (matchStatus: MatchStatus) => {
	switch (matchStatus) {
		case "recomendado":
			return (
				<span className="w-32 rounded-full bg-brand-warning bg-opacity-70 px-2 py-1 text-center text-sm text-white">
					Recomendado
				</span>
			);
		case "aprovado":
			return (
				<span className="w-32 rounded-full bg-brand-primary bg-opacity-70 px-2 py-1 text-center text-sm text-white">
					Aprovado
				</span>
			);
		case "reprovado":
			return (
				<span className="w-32 rounded-full bg-brand-error bg-opacity-70 px-2 py-1 text-center text-sm text-white">
					Reprovado
				</span>
			);
		default:
			return (
				<span className="w-32 rounded-full bg-brand-warning bg-opacity-70 px-2 py-1 text-center text-sm text-white">
					{matchStatus}
				</span>
			);
	}
};

interface TargetNavListProps {
	selectedTab: Tab;
	setSelectedTab: (tab: Tab) => void;
	fetchSelectedInvestors: (params?: SelectedBusinessInvestorsParams) => Promise<void>;
	tabLoading: boolean;
	selectedInvestors: InvestorBusinessDTO[];
	setSelectedInvestors: (investors: InvestorBusinessDTO[]) => void;
	tabsScrollTopRef: React.MutableRefObject<null | HTMLDivElement>;
}

const TargetNavList: FC<TargetNavListProps> = ({
	selectedTab,
	setSelectedTab,
	fetchSelectedInvestors,
	tabLoading,
	selectedInvestors,
	setSelectedInvestors,
	tabsScrollTopRef
}) => {
	const { id } = useParams();
	const [investorBeingEdited, setInvestorBeingEdited] = useState<InvestorBusinessDTO | null>(null);
	const [singleSelectValue, setSingleSelectValue] = useState<Selectable | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [investorBusinessStages, setInvestorBusinessStages] = useState<InvestorBusinessStageDTO[] | null>([]);

	useEffect(() => {
		getInvestorBusinessStages().then((response) => {
			setInvestorBusinessStages(response.data);
		});
	}, []);

	const closeModal = () => {
		setIsOpen(false);
	};

	const openModal = () => {
		setIsOpen(true);
	};

	const handleItemRemoval = async (investor: InvestorBusinessDTO) => {
		try {
			const params = {
				businessId: Number(id),
				investorId: investor.id
			} as setBusinessInvestorParams;

			await deleteBusinessInvestor(params);

			const newSelectedInvestors = selectedInvestors.filter((selectedInvestor) => selectedInvestor.id !== investor.id);
			setSelectedInvestors(newSelectedInvestors);
			toastNotify(`Investidor ${investor.tradeName} removido com sucesso`, "success");
		} catch (error) {
			console.error(error);
			toastNotify("Erro ao remover investidor", "error");
		}
	};

	const handleChangeBusinessStageStep = async (stage: Selectable) => {
		const params = {
			stage_id: stage.id
		};

		try {
			await updateInvestorBusinessStage(Number(id), investorBeingEdited?.id as number, params);
			setSingleSelectValue(stage);
			const newSelectedInvestors = selectedInvestors.map((selectedInvestor) =>
				selectedInvestor.id === investorBeingEdited?.id ? { ...selectedInvestor, stage } : selectedInvestor
			) as InvestorBusinessDTO[];
			setSelectedInvestors(newSelectedInvestors);
			setInvestorBeingEdited({
				...investorBeingEdited,
				stage: stage
			} as InvestorBusinessDTO);
			toastNotify("Etapa atualizada com sucesso", "success");
		} catch (error) {
			console.error(error);
			toastNotify("Erro ao atualizar etapa", "error");
		}
	};

	const mapBusinessStagesOptions = (investorBusinessStages: InvestorBusinessStageDTO[]) =>
		investorBusinessStages
			.map((stage) => ({
				id: stage.id,
				name: stage.name,
				description: stage.description
			}))
			.sort((a, b) => a.id - b.id);

	return (
		<Fragment>
			<nav className="bg-neutral-high border-neutral-medium z-50 flex justify-around border-b border-opacity-50 md:justify-start md:px-10">
				<TabSelector
					isActive={selectedTab === TABS.ALL}
					onClick={() => {
						setSelectedTab(TABS.ALL);
						fetchSelectedInvestors();
					}}
				>
					{TABS.ALL}
				</TabSelector>
				<TabSelector
					isActive={selectedTab === TABS.RECOMMENDED}
					onClick={() => {
						fetchSelectedInvestors({ matchStatus: "recomendado" });
						setSelectedTab(TABS.RECOMMENDED);
					}}
				>
					{TABS.RECOMMENDED}
				</TabSelector>
				<TabSelector
					isActive={selectedTab === TABS.APPROVED}
					onClick={() => {
						fetchSelectedInvestors({ matchStatus: "aprovado" });
						setSelectedTab(TABS.APPROVED);
					}}
				>
					{TABS.APPROVED}
				</TabSelector>
				<TabSelector
					isActive={selectedTab === TABS.REJECTED}
					onClick={() => {
						fetchSelectedInvestors({ matchStatus: "reprovado" });
						setSelectedTab(TABS.REJECTED);
					}}
				>
					{TABS.REJECTED}
				</TabSelector>
			</nav>

			<div className="h-[75%] overflow-y-auto">
				{tabLoading ? (
					<div className="flex h-full items-center justify-center">
						<LoadingIcon />
					</div>
				) : (
					<Fragment>
						<div ref={tabsScrollTopRef}></div>
						{selectedInvestors.map((investor, idx) => (
							<div
								key={idx}
								className="flex items-start justify-between border-b border-[#000] border-opacity-10 py-1 px-2"
							>
								<span>{investor.tradeName}</span>
								<span className="flex gap-2">
									<span className="w-min whitespace-nowrap rounded-full bg-brand-secondaryMedium bg-opacity-70 px-2 py-1 text-sm text-white">
										{investor.stage.name}
									</span>
									<span className="flex items-center gap-2">
										{selectedTab === TABS.ALL && badgeByMatchStatus(investor.matchStatus)}

										<button onClick={() => handleItemRemoval(investor)}>
											<TrashIcon className="h-5 w-5 text-brand-secondary" />
										</button>

										<button
											onClick={() => {
												setInvestorBeingEdited(investor);
												setSingleSelectValue({
													id: investor.stage.id,
													name: investor.stage.name,
													description: investor.stage.description
												} as Selectable);
												openModal();
											}}
										>
											<PencilAltIcon className="h-5 w-5 text-brand-secondary" />
										</button>
									</span>
								</span>
							</div>
						))}
					</Fragment>
				)}
			</div>

			<Modal open={isOpen} onClose={closeModal} title={`Editar Alvo: ${investorBeingEdited?.tradeName}`}>
				{investorBeingEdited && (
					<section className="flex h-96 w-full flex-col gap-2">
						<span>
							<strong>Match status:</strong> {badgeByMatchStatus(investorBeingEdited.matchStatus)}
						</span>
						<span className="flex w-full items-center gap-2">
							<strong>Estágio:</strong>
							{singleSelectValue && investorBusinessStages && (
								<div className="w-64">
									<SingleSelect
										options={mapBusinessStagesOptions(investorBusinessStages)}
										value={singleSelectValue}
										onChange={(value) => {
											handleChangeBusinessStageStep(value);
										}}
									/>
								</div>
							)}
						</span>
						<span>
							<strong>Descrição:</strong> {investorBeingEdited.stage.description}
						</span>
					</section>
				)}
			</Modal>
		</Fragment>
	);
};

export default TargetNavList;
