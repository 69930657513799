import { FC } from 'react';
import OverALogo from '../../../../components/OverALogo';

interface CompanyCollaborationProps {}

const CompanyCollaboration: FC<CompanyCollaborationProps> = () => {
	return (
		<div className="max-w-[744px] mx-auto mt-40">
			<OverALogo color="dark" />
			<div className="max-w-[744px] mx-auto h-screen">
				<div className="mb-2">
					<h3 className={`text-2xl text-brand-primary font-bold`}>Agradecemos pela colaboração!</h3>
				</div>
				<p className="text-neutral-medium text-base font-normal mb-6">
					Obrigado! Iremos analisar todas as informações e entrar em contato em até 3 dias úteis
					para próximos passos para a captação que você busca.
				</p>
			</div>
		</div>
	);
};

export default CompanyCollaboration;
