import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { FC } from 'react';
export interface PaginationProps {
	currentPage: number;
	total: number;
	pageLimit?: number;
	setCurrentPage: Function;
	loading?: boolean;
}
const MAX_BUTTONS = 5;
const MAX_LEFT = (MAX_BUTTONS - 1) / 2;

const Pagination: FC<PaginationProps> = ({
	currentPage,
	total,
	pageLimit = 10,
	setCurrentPage,
	loading
}) => {
	const pages = Math.ceil(total / pageLimit);
	const maxFirst = Math.max(pages - (MAX_BUTTONS - 1), 1);
	const first = Math.min(Math.max(currentPage - MAX_LEFT, 1), maxFirst);
	const onChangePage = (page) => {
		setCurrentPage(page);
	};
	return (
		<div className="bg-transparent px-4 py-3 flex items-center justify-between sm:px-6">
			<div className="justify-center flex-1 flex mt-4 items-center sm:justify-end">
				<div>
					<nav
						className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
						aria-label="Pagination"
					>
						<button
							onClick={() => onChangePage(currentPage - 1)}
							disabled={loading || currentPage === 1}
							className="relative inline-flex items-center px-[0.625rem] py-1 rounded-l-lg border-0 bg-brand-secondary text-base font-normal text-neutral-high hover:bg-brand-secondaryMedium"
						>
							<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
						</button>
						{Array.from({ length: Math.min(MAX_BUTTONS, pages) })
							.map((_, i) => i + first)
							.map((page) => (
								<button
									key={page}
									onClick={() => onChangePage(page)}
									disabled={loading}
									className={`z-10 ${
										currentPage === page
											? 'bg-brand-secondary text-neutral-high'
											: 'bg-neutral-high  text-neutral-low'
									}  relative inline-flex items-center px-5 py-2 border-0 text-base font-normal`}
								>
									{page}
								</button>
							))}

						<button
							onClick={() => onChangePage(pages)}
							disabled={loading || currentPage === pages}
							className="relative inline-flex items-center px-[0.625rem] py-1 rounded-r-lg border-0 bg-brand-secondary text-base font-normal text-neutral-high hover:bg-brand-secondaryMedium"
						>
							<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
						</button>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
