/* This example requires Tailwind CSS v2.0+ */
import { FC, Dispatch, SetStateAction } from 'react';
import { MenuIcon } from '@heroicons/react/outline';

const ToggleMenuMobile: FC<{
	setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ setOpen }) => {
	return (
		<div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-brand-primary">
			<button
				type="button"
				className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-white hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-auxiliar-highlight-orange"
				onClick={() => setOpen(true)}
			>
				<span className="sr-only">Open sidebar</span>
				<MenuIcon className="h-6 w-6" aria-hidden="true" />
			</button>
		</div>
	);
};

export default ToggleMenuMobile;
