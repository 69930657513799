import client from "src/services/client";
import { ContactDataDTO } from "./Contact";
import { InvestorBusinessStageDTO, InvestorsDataDTO } from "./Investor";
import { Tab } from "../pages/Igc/CompanyDetails/components/TabsContent/Target";

export type TargetResponse = {
	approved_match_status: string;
	buyer: string;
	buyerEmail: string;
	buyerId: number;
	buyerPhone: string;
	buyerSector: string;
	buyerSectorId: number;
	buyerSubSector: string;
	buyerSubSectorId: number;
	criteria: string;
	interestSector: string;
	interestSubSector: string;
	match_status: string;
	pastDeals: string;
	recommended_at: Date;
	target_id: number;
	website: string;
};

export type ChangeTargetRequest = {
	target_id: number;
	match_status: string;
};

export type ChangeTargetResponse = {
	user_message: string;
};

export type TargetResponseLambda = {
	approved_match_status: string;
	buyer: string;
	buyerEmail: string;
	buyerId: number;
	buyerPhone: string;
	buyerSector: string;
	buyerSectorId: number;
	buyerSubSector: string;
	buyerSubSectorId: number;
	criteria: string;
	interestSector: string;
	interestSubSector: string;
	match_status: string;
	recommended_at: Date;
	target_id: number;
	website: string;
};

export const postTarget = (target: TargetPostDTO) => client.post(`igc/target/create/`, target);

export const getTargets = (investorID: number) =>
	client.get<TargetDataDTO[]>(`igc/investors/${investorID}`);

export type MatchStatus = "aprovado" | "recomendado" | "reprovado";

export type ApprovedMatchStatus = "Aguardando match" | "Em roadshow" | "Processo descontinuado";

export type PaginatedDataDTO<T> = {
	page: number | null;
	size: number | null;
	total: number;
	data: T[];
};

export type InvestorIgcDTO = {
	tradeName: string;
	investorId: number;
	criteria: string;
};

export const TABS = {
	ALL: "Todos" as Tab,
	APPROVED: "Aprovados" as Tab,
	RECOMMENDED: "Recomendados" as Tab,
	REJECTED: "Rejeitados" as Tab
};

export type InvestorBusinessDTO = {
	id: number;
	tradeName: string;
	matchStatus: MatchStatus;
	stage: InvestorBusinessStageDTO;
};

export const criteriaOptions = [
	"NDA_SIGNED",
	"EXCESSIVE_SIZE",
	"LOOKED_FOR_DEAL_IN_SUB_SECTOR",
	"INTEL",
	"NEOWAY"
];

export type TargetDataDTO = {
	id: number;
	criteria: string;
	contact: ContactDataDTO;
	investor: InvestorsDataDTO;
	sub_sector: SubSectorDataDTO;
};

export type TargetPostDTO = {
	criteria: string;
	contact_id: number;
	investor_id: number | null;
	sub_sector_id: number;
};

export type SubSectorDataDTO = {
	custom_multiplier: boolean;
	custom_multiplier_item: string;
	description: string;
	id: number;
	max_valuation_multiplier: string;
	min_valuation_multiplier: string;
	multiplier_item: number;
	name: string;
	sector: number;
};
