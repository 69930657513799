import { ArrowRightIcon } from "@heroicons/react/solid";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "src/assets/icons/Loading";
import { CompanyInStep, StepTime } from "src/domain/CompanyInStep";
import { useAuth } from "src/hooks/useAuth";
import Page from "src/layouts/Logged/components/Page";
import { getCompaniesByDemand } from "src/services/company/getCompaniesByDemand";
import { DemandsChart } from "./components/DemandsChart";

const IgcDashboardPage: FC = () => {
	const { user } = useAuth();
	const [companies, setCompanies] = useState<CompanyInStep>();
	const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
	const [stepId, setStepId] = useState<number>(4);

	useEffect(() => {
		setLoadingCompanies(true);
		getCompaniesByDemand(String(user?.role), { step_id: stepId })
			.then((response) => {
				setCompanies(response.data);
			})
			.catch(() => {
				setCompanies({
					business_in_step: []
				})
			})
			.finally(() => {
				setLoadingCompanies(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepId]);

	const transformStepTime = (stepTime: StepTime) => {
		let stepTimeString = "";
		if (stepTime.years) {
			stepTimeString += `${stepTime.years} anos `;
		}
		if (stepTime.mounths) {
			stepTimeString += `${stepTime.mounths} meses `;
		}
		if (stepTime.days) {
			stepTimeString += `${stepTime.days} dias `;
		}
		if (stepTime.hours) {
			stepTimeString += `${stepTime.hours} horas `;
		}
		if (stepTime.minutes !== 0) {
			stepTimeString += `${stepTime.minutes} minutos `;
		}

		return stepTimeString;
	};

	const DEMANDS = {
		4: "Preparação",
		5: "Roadshow",
		6: "Negociação",
		7: "Diligência",
		8: "Negociação de Contrato de Venda"
	};
	return (
		<>
			<Page>
				<div className="bg-neutral-high my-8 flex min-h-[20.5rem] flex-col rounded-2xl py-10 px-8">
					<DemandsChart changeStepId={setStepId} />
				</div>
				<div className="w-full overflow-x-auto">
					<h1 className="text-left text-xl font-bold text-brand-primary">
						Demandas em "{DEMANDS[stepId]}"
					</h1>
					<table className="mt-6 w-full">
						<thead className="">
							<tr>
								<th
									scope="col"
									className="text-neutral-low py-3 pr-6 text-left text-sm font-normal"
								>
									Empresa
								</th>
								<th
									scope="col"
									className="text-neutral-low py-3 pr-6 text-left text-sm font-normal"
								>
									Tempo nessa Etapa
								</th>
								<th
									scope="col"
									className="text-neutral-low py-3 pr-6 text-left text-sm font-normal"
								>
									Urgência
								</th>
								<th
									scope="col"
									className="text-neutral-low relative px-6 py-3 text-sm font-normal"
								></th>
							</tr>
						</thead>
						<tbody>
							{companies && companies.business_in_step.length === 0 && !loadingCompanies ? (
								<tr className="text-neutral-medium text-center text-2xl font-bold ">
									<td className="whitespace-nowrap py-4 pr-6" colSpan={6}>
										Nenhum empresa encontrada
									</td>
								</tr>
							) : loadingCompanies ? (
								<tr className="text-neutral-medium text-center text-2xl font-bold">
									<td className="whitespace-nowrap py-4 pr-6" colSpan={6}>
										<Loading color="primary" size={12} />
									</td>
								</tr>
							) : (
								companies?.business_in_step.map((company) => (
									<tr
										className="border-neutral-medium border-b-[0.0625rem] border-opacity-50"
										key={company.business_id}
									>
										<td className="whitespace-nowrap py-4 pr-6">
											<div className="flex items-center justify-start">
												<div className="text-neutral-medium text-left text-sm font-normal">
													{company.business_full_name}
												</div>
											</div>
										</td>
										<td className="whitespace-nowrap py-4 pr-6">
											<div className="text-neutral-medium text-left text-sm font-normal">
												{transformStepTime(company.business_step_time)}
											</div>
										</td>
										<td className=" text-neutral-medium whitespace-nowrap py-4 pr-6 text-sm font-normal">
											<span
												className={`inline-flex rounded-[6.25rem] px-2 py-1 text-xs
                      font-normal  leading-5 ${
												company.business_urgency === 1
													? "bg-tags-success text-brand-primary"
													: company.business_urgency === 2
													? "bg-tags-warning text-brand-warning"
													: "bg-tags-error text-brand-error"
											} `}
											>
												{company.business_urgency === 1
													? "Baixa"
													: company.business_urgency === 2
													? "Média"
													: "Alta"}
											</span>
										</td>
										<td className="whitespace-nowraptext-sm text-neutral-medium py-4 pr-2 text-center  font-normal">
											<Link
												to={`/igc/company/${company.business_id}`}
												state={{ name: `${company.business_full_name}` }}
												className="ml-0  inline-flex text-brand-secondary hover:brightness-110 hover:saturate-100 active:scale-105"
											>
												<ArrowRightIcon width={20} color="" />
											</Link>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>
			</Page>
		</>
	);
};

export default IgcDashboardPage;
