import { Contract, Deal } from 'src/domain/Contract';
import client from '../client';

export const getDeals = async (userRole: string) => {
	return client.get<Array<Deal>>(`${userRole}/recommendation_list/`);
};

export const getContracts = async (userRole: string) => {
	return client.get<Array<Contract>>(`${userRole}/contract_list/`);
};
