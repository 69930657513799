import { FC, useState, useEffect } from 'react';
import { transformSectorDomain } from '../../../../helpers/transformSectorDomain';
import Select from '../../base/select';
import { Input, Selectable } from '../models';

const SectorSelect: FC<Input> = ({ onChange, initialValue }) => {
	const [sectors, setSectors] = useState<Selectable[]>([]);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL ?? 'http://localhost:8000'}/activity_sectors/`)
			.then((res) => res.json())
			.then((json) => {
				const json_formatado = json.map((sector: any) => {
					return transformSectorDomain(sector);
				});
				setSectors(json_formatado);
			});
	}, []);

	const handleChange = (selected: Selectable[]) => {
		onChange(selected[0].id, true);
	};

	return (
		<>
			<Select
				//initialValue={initialValue}
				fieldLabel="Setor"
				selectableValues={sectors}
				singleSelect={true}
				onChange={handleChange}
			/>
		</>
	);
};

export default SectorSelect;
