import { UploadIcon } from '@heroicons/react/outline';
import { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from 'src/components/Button/Button';
import Modal from 'src/components/Modal';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import { Selectable } from 'src/components/ui/form/models';
import { useFiles } from 'src/contexts/files';
import { DocumentTypeOptions } from 'src/domain/Document';

export interface UploadProps {
	businessId: number;
	checkFileType?: boolean;
	acceptFileType?: any;
	multiple?: boolean;
	markDocumentsAsOtherByDefault?: boolean;
}

const Upload: FC<UploadProps> = ({
	businessId,
	checkFileType = false,
	acceptFileType = undefined,
	multiple,
	markDocumentsAsOtherByDefault = false
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [files, setFiles] = useState<any>();
	const [typeSelected, setTypeSelected] = useState<Selectable>({
		id: 1,
		slug: 'other',
		name: 'Outros'
	} as Selectable);
	const { handleAddFile } = useFiles();

	const handleConfirm = () => {
		const file_type = typeSelected.slug || 'other';
		handleAddFile(businessId, files, file_type);
		setIsOpen(false);
	};

	const onDrop = (files) => {
		console.log(files)
		if (markDocumentsAsOtherByDefault) {
			setFiles(files);
		} else if (checkFileType) {
			setFiles(files);
			setIsOpen(true);
		}

		handleAddFile(businessId, files, 'other');
	};

	const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
		accept: acceptFileType,
		onDrop,
		multiple
	});

	const renderDragMessage = useCallback(() => {
		if (!isDragActive) {
			return (
				<div
					className={`flex h-52 flex-col items-center justify-around border-brand-secondaryMedium bg-[#F4F5F6] py-4`}
				>
					<UploadIcon className="text-neutral-low h-[30px] w-[30px] " />
					<p className="text-neutral-low text-center text-base font-normal text-opacity-40">
						Arraste o arquivo <br></br> ou <br></br>Selecione um arquivo para upload
					</p>
				</div>
			);
		}

		if (isDragReject) {
			return (
				<div className={`flex h-52 items-center justify-center border-[#e57878] py-4`}>
					Tipo de arquivo não suportado
				</div>
			);
		}
		return (
			<div className={`flex h-52 items-center justify-center border-[#78e5d5] py-4`}>
				Solte o arquivo aqui
			</div>
		);
	}, [isDragActive, isDragReject]);

	return (
		<>
			<div
				style={{ transition: "height 0.2s ease" }}
				className={`${
					isDragActive ? "border-[#78e5d5]" : isDragReject ? "border-[#e57878]" : "border-[#ddd]"
				} cursor-pointer rounded border-[1px] border-dashed`}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				{renderDragMessage()}
			</div>
			<Modal
				open={isOpen}
				onClose={setIsOpen}
				title="Tipo de documento"
				size="xsmall"
				description="Selecione abaixo o tipo de documento."
			>
				<div className="w-full">
					<div className="mt-8 mb-20">
						<SingleSelect
							options={DocumentTypeOptions}
							value={typeSelected}
							placeholder="Escolha o tipo de documento"
							onChange={(item: Selectable) => setTypeSelected(item)}
						/>
					</div>
					<div className="mt-8 flex justify-between">
						<Button className="ml-3" color="secondary" onClick={() => handleConfirm()}>
							Confirmar
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Upload;
