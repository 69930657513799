import { ChevronLeftIcon, ChevronRightIcon, LogoutIcon } from "@heroicons/react/outline";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "src/assets/icons/Loading";
import { ExtendedRegistrationForm, formatFormToRender } from "src/domain/Register";
import { toastNotify } from "src/helpers/toastNotify";
import history from "src/services/history";
import { getExtenderRegister } from "src/services/register/getExtenderRegister";
import { setExtenderRegisters } from "src/services/register/setExtenderRegisters";
import StepContent from "./components/StepContent";
import { validationSchemaBySteps } from "./configs/models";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import defaultSteps from "./configs/Steps/defaultSteps";

export interface IExtendedRegisterProps {}

const IgcEditTeaser: React.FunctionComponent<IExtendedRegisterProps> = () => {
	const [isLast, setIsLast] = useState(false);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [loadingStep, setLoadingStep] = useState<boolean>(false);
	const currentValidationSchema = validationSchemaBySteps[currentStep];

	const { id } = useParams();
	const {
		register,
		watch,
		control,
		trigger,
		getFieldState,
		setValue,
		getValues: getFormValues,
		formState: { errors, isLoading }
	} = useForm<ExtendedRegistrationForm>({
		defaultValues: async () => {
			const defaultValues = formatFormToRender((await getExtenderRegister("business", { business_id: id })).data);

			return defaultValues;
		},
		// resolver: yupResolver(currentValidationSchema),
		mode: "onChange"
	});

	const setExtenderRegisterData = (value: any) => {
		value["business_id"] = Number(id);

		setExtenderRegisters("business", value)
			.then((response) => {
				toastNotify(response.data.user_message, "success");
				if (currentStep === 18) return history.back();

				return setCurrentStep(currentStep + 1);
			})
			.finally(() => {
				setLoadingStep(false);
			});
	};

	const goBack = () => {
		history.back();
	};

	const nextStep = () => {
		setLoadingStep(false);

		setCurrentStep(currentStep + 1);
	};

	const previousStep = () => {
		setCurrentStep(currentStep - 1);
	};

	const setStepData = async (stepData: any) => {
		if (!stepData) return nextStep();

		const isStepValid = await trigger();

		if (!isStepValid) return;

		setLoadingStep(true);

		return setExtenderRegisterData(stepData);
	};

	useEffect(() => {
		trigger();

		const nextStep = defaultSteps.find((step) => step.stepNumber === currentStep) || defaultSteps[0];

		setIsLast(nextStep.isLast === true);

		setCurrentStep(nextStep.stepNumber);
	}, []);

	return (
		<>
			<div className="h-screen w-full bg-white px-12 pt-4 pb-10">
				<div className="flex justify-between">
					<button
						className="hidden items-center whitespace-nowrap bg-transparent text-[16px] font-normal text-brand-secondary"
						onClick={goBack}
					>
						<LogoutIcon className="mr-2 text-brand-secondary" height="25" /> Salvar e sair
					</button>
					<div className="flex items-center text-[16px] font-normal text-brand-secondary">
						<ChevronLeftIcon
							className={`cursor-pointer text-brand-secondary ${currentStep === 0 ? "hidden" : null}`}
							height={25}
							onClick={previousStep}
						/>
						<ChevronRightIcon
							className={`cursor-pointer text-brand-secondary ${currentStep === 18 ? "hidden" : null}`}
							height={25}
							onClick={nextStep}
						/>
					</div>
				</div>

				{isLoading ? (
					<div className="flex h-screen items-center justify-center">
						<Loading color="primary" size={12} />
					</div>
				) : (
					<StepContent
						step={defaultSteps[currentStep]}
						toPreviousStep={previousStep}
						onSubmit={setStepData}
						getStepData={getFormValues}
						loadingStep={loadingStep}
						register={register}
						errors={errors}
						control={control}
						setValue={setValue}
						watch={watch}
					/>
				)}
			</div>
		</>
	);
};

export default IgcEditTeaser;
