import { FC } from "react";
import type { Step } from "../configs/models";

const StepContent: FC<Step> = ({
	step,
	onSubmit,
	getStepData,
	loadingStep,
	register,
	errors,
	control,
	setValue,
	watch
}) => {
	return (
		<div className="mt-40 max-w-[744px]">
			<div className="mb-2">
				<h3 className={`text-2xl ${step.stepNumber === 0 ? "text-brand-primary" : "text-neutral-low "} font-bold`}>
					{step.title}
					{step.optional && <span className="text-base font-normal">(Opcional)</span>}
				</h3>
			</div>
			<p className="text-neutral-medium mb-6 justify-between text-base font-normal">{step.description}</p>
			<step.component
				onSubmit={onSubmit}
				getStepData={getStepData}
				maxInputs={step.maxInputs}
				loadingStep={loadingStep}
				register={register}
				errors={errors}
				control={control}
				setValue={setValue}
				watch={watch}
			/>
		</div>
	);
};

export default StepContent;
