import { FC } from 'react';
import { useTabs } from 'react-headless-tabs';
import { useNavigate } from 'react-router-dom';
import { TabSelector } from 'src/components/TabSelector';
import Header from 'src/layouts/Logged/components/Header';
export interface BusinessTargetCompanyPageProps {}

const BusinessTargetCompanyPage: FC<BusinessTargetCompanyPageProps> = () => {
	const [selectedTab, setSelectedTab] = useTabs(['summary', 'Q&A']);

	const navigate = useNavigate();
	// let params = useParams();
	return (
		<>
			<Header title="Empresa Verde" goBack={() => navigate('/business/target')}>
				<nav className="flex justify-around md:justify-start md:px-10 bg-neutral-high border-b border-neutral-medium border-opacity-50 z-50">
					<TabSelector
						isActive={selectedTab === 'summary'}
						onClick={() => setSelectedTab('summary')}
					>
						Resumo
					</TabSelector>
					<TabSelector isActive={selectedTab === 'Q&A'} onClick={() => setSelectedTab('Q&A')}>
						Q&A
					</TabSelector>
				</nav>
			</Header>
			<>{selectedTab === 'summary' ? <h1>Resumo</h1> : selectedTab === 'Q&A' && <h1>Q&A</h1>}</>
		</>
	);
};

export default BusinessTargetCompanyPage;
