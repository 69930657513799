import { FC } from 'react';
import OverA from 'src/assets/images/over_a_logo.svg';

const OverALogo: FC<{
	color: string;
	width?: number;
	height?: number;
	centered?: boolean;
}> = ({ color, width, height, centered }) => {
	const isLight = () => {
		return color === 'light';
	};

	const colors = {
		light: '#ffffff',
		dark: {
			st0: '#E2A160',
			st1: '#BA7027',
			st2: '#71883A',
			st3: '#CCC1AF'
		}
	};

	return (
		<div className={centered ? 'flex items-center content-center justify-center' : undefined}>
			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 866.47 407.14"
				width={width ? `${width}px` : undefined}
				height={height ? `${height}px` : undefined}
				className='w-full'
			>
				<g>
					<path
						d="M366.55,215.12h-4.73l-28.94,61.92v1.53h12.11l8.11-18.11h22.28l8.2,18.11h12.21v-1.53L366.55,215.12z
					M363.99,233.55h0.3l7.24,17.84h-14.62L363.99,233.55z"
						fill={isLight() ? colors.light : colors.dark.st0}
						stroke={isLight() ? colors.light : colors.dark.st0}
						strokeWidth="1.5004"
					/>
					<path
						d="M292.37,193.63c-17.25,0-31.9-12.04-31.9-32.53s15.01-32.53,32.08-32.53c17.16,0,31.99,12.04,31.99,32.53
					C324.54,180.24,311.33,193.63,292.37,193.63z M292.55,183.75c11.77,0,21.57-8,21.57-22.65c0-14.38-9.71-22.74-21.48-22.74
					c-11.86,0-21.57,8.18-21.57,22.74C271.07,175.75,280.68,183.75,292.55,183.75z"
						fill={isLight() ? colors.light : colors.dark.st1}
						stroke={isLight() ? colors.light : colors.dark.st1}
						strokeWidth="1.5004"
					/>
					<path
						d="M364.27,175.33l8.27-22.83l9.08-22.2h11.05v1.53l-26.42,61.92h-4.31l-26.69-61.92v-1.53h11.14l8.99,21.75
					l8.63,23.28H364.27z"
						fill={isLight() ? colors.light : colors.dark.st1}
						stroke={isLight() ? colors.light : colors.dark.st1}
						strokeWidth="1.5004"
					/>
					<path
						d="M452.71,139.43h-27.77v16.63h26.24v9.26h-26.24v18.33h28.31v9.53h-38.82v-62.91h38.28V139.43z"
						fill={isLight() ? colors.light : colors.dark.st1}
						stroke={isLight() ? colors.light : colors.dark.st1}
						strokeWidth="1.5004"
					/>
					<path
						d="M531.95,191.64v1.53h-10.6l-15.64-21.93h-13.84v21.93h-10.51v-62.91h25.97c13.66,0,21.84,9.26,21.84,20.49
					c0,8.72-5.21,16-13.21,18.87L531.95,191.64z M507.23,162.62c6.83,0,11.77-4.94,11.77-11.77c0-6.56-4.85-11.5-11.77-11.5h-15.55
					v23.28H507.23z"
						fill={isLight() ? colors.light : colors.dark.st1}
						stroke={isLight() ? colors.light : colors.dark.st1}
						strokeWidth="1.5004"
					/>
					<rect
						x="137.48"
						y="156.56"
						width="92.22"
						height="9.08"
						fill={isLight() ? colors.light : colors.dark.st2}
						stroke={isLight() ? colors.light : colors.dark.st2}
						strokeWidth="1.5004"
					/>
					<rect
						x="427.57"
						y="242.31"
						width="89.29"
						height="9.08"
						fill={isLight() ? colors.light : colors.dark.st2}
						stroke={isLight() ? colors.light : colors.dark.st2}
						strokeWidth="1.5004"
					/>
					<g>
						<path
							d="M554.99,238.95c0.48-0.96,1.09-1.73,1.81-2.28c0.68-0.52,1.47-0.78,2.42-0.78c0.78,0,1.48,0.18,2.17,0.54
						c0.68,0.36,1.29,0.94,1.81,1.7l0.65,0.94l3.6-3.93l-0.4-0.6c-0.9-1.33-2.07-2.34-3.47-3c-1.37-0.64-2.9-0.96-4.53-0.96
						c-1.78,0-3.41,0.44-4.85,1.3c-1.41,0.85-2.62,2.04-3.6,3.53c-0.96,1.46-1.7,3.21-2.21,5.19c-0.51,1.96-0.76,4.13-0.76,6.43
						c0,2.3,0.25,4.45,0.74,6.4c0.5,1.97,1.23,3.72,2.17,5.19c0.97,1.51,2.18,2.71,3.61,3.56c1.45,0.86,3.1,1.3,4.9,1.3
						c1.84,0,3.43-0.4,4.74-1.2c1.29-0.78,2.36-1.83,3.18-3.11l0.38-0.59l-3.57-3.9l-0.62,1.06c-0.41,0.69-0.96,1.29-1.64,1.77
						c-0.65,0.45-1.37,0.67-2.22,0.67c-0.95,0-1.75-0.25-2.45-0.78c-0.74-0.55-1.36-1.33-1.85-2.3c-0.52-1.02-0.91-2.23-1.18-3.59
						c-0.27-1.39-0.4-2.89-0.4-4.48s0.14-3.1,0.4-4.5C554.1,241.17,554.49,239.96,554.99,238.95z"
							fill={isLight() ? colors.light : colors.dark.st3}
							stroke={isLight() ? colors.light : colors.dark.st3}
							strokeWidth="1.5004"
						/>
						<path
							d="M585.07,231.31l-7.91,31.47h5.69l1.72-6.95h7.16l1.68,6.95h5.81l-7.73-31.47H585.07z M590.8,251.04h-5.3
						l2.69-11.58L590.8,251.04z"
							fill={isLight() ? colors.light : colors.dark.st3}
							stroke={isLight() ? colors.light : colors.dark.st3}
							strokeWidth="1.5004"
						/>
						<path
							d="M625.1,233.29c-0.85-0.72-1.82-1.24-2.88-1.54c-1.01-0.29-2.02-0.43-3-0.43h-8.43v31.47h5.59v-12.69h3.11
						c1.12,0,2.2-0.21,3.21-0.61c1.02-0.41,1.94-1.02,2.73-1.81c0.8-0.8,1.44-1.81,1.9-3.02c0.46-1.19,0.69-2.57,0.69-4.1
						c0-1.71-0.27-3.19-0.82-4.38C626.66,234.99,625.96,234.01,625.1,233.29z M622.44,240.6c0,1.57-0.34,2.74-1.01,3.47
						c-0.66,0.72-1.5,1.07-2.58,1.07h-2.47v-8.78h2.47c1.09,0,1.95,0.32,2.61,0.97C622.11,237.97,622.44,239.07,622.44,240.6z"
							fill={isLight() ? colors.light : colors.dark.st3}
							stroke={isLight() ? colors.light : colors.dark.st3}
							strokeWidth="1.5004"
						/>
						<rect
							x="640.24"
							y="231.31"
							width="5.59"
							height="31.47"
							fill={isLight() ? colors.light : colors.dark.st3}
							stroke={isLight() ? colors.light : colors.dark.st3}
							strokeWidth="1.5004"
						/>
						<polygon
							points="657.49,236.35 663.15,236.35 663.15,262.78 668.74,262.78 668.74,236.35 674.39,236.35 
						674.39,231.31 657.49,231.31 		"
							fill={isLight() ? colors.light : colors.dark.st3}
							stroke={isLight() ? colors.light : colors.dark.st3}
							strokeWidth="1.5004"
						/>
						<path
							d="M689.3,231.31l-7.91,31.47h5.69l1.72-6.95h7.16l1.68,6.95h5.81l-7.73-31.47H689.3z M695.03,251.04h-5.3
						l2.69-11.58L695.03,251.04z"
							fill={isLight() ? colors.light : colors.dark.st3}
							stroke={isLight() ? colors.light : colors.dark.st3}
							strokeWidth="1.5004"
						/>
						<polygon
							points="720.61,257.44 720.61,231.31 715.02,231.31 715.02,262.78 728.99,262.78 728.99,257.44 		"
							fill={isLight() ? colors.light : colors.dark.st3}
							stroke={isLight() ? colors.light : colors.dark.st3}
							strokeWidth="1.5004"
						/>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default OverALogo;
