import { FC } from 'react';

const TabTitle: FC<{
	activeTab: number;
	index: number;
	tab: { id: string; title: string; content: string };
	onClick: any;
}> = ({ tab, onClick, activeTab, index }) => {
	return (
		<li
			onClick={() => onClick(index)}
			className={`mx-6 ${activeTab === index ? 'text-3xl' : 'text-2xl opacity-80'}`}
		>
			{tab.title}
		</li>
	);
};

export default TabTitle;
