import { getValueFromMap } from "src/helpers/getValueFromMap";
import { ExtendedRegistration, ExtendedRegistrationForm } from "./Register";

export interface BusinessFinanceReportEntity {
	amount: number;
	year: number;
	type: number;
}

export interface BusinessSummaryView {
	finance_reports: BusinessFinanceReportEntity[];
	cnpj: string;
	email: string;
	phone: string;
	website: string;
	full_name: string;
	is_political: boolean;
}

export interface Categories {
	name: string;
	href: string;
	current: boolean;
	initialStep: number;
	status?: 0 | 1 | 2;
	optional?: boolean;
}

export const summaryCategories: Categories[] = [
	{ name: "Intro", href: "#", current: true, initialStep: 0 },
	{ name: "Resumo", href: "#", current: false, initialStep: 1, status: 0 },
	{
		name: "Diferenciais do seu negócio",
		href: "#",
		current: false,
		initialStep: 2,
		status: 0
	},
	{
		name: "Linha do tempo da empresa",
		href: "#",
		current: false,
		initialStep: 3,
		status: 0
	},
	{ name: "Objetivo da venda", href: "#", current: false, initialStep: 4, status: 0 },
	{
		name: "Investidores previamente interessados",
		href: "#",
		current: false,
		initialStep: 5,
		status: 0,
		optional: true
	},
	{
		name: "Potenciais investidores",
		href: "#",
		current: false,
		initialStep: 6,
		status: 0,
		optional: true
	},
	{ name: "Concorrentes", href: "#", current: false, initialStep: 7, status: 0 },
	{ name: "Documentos Financeiros", href: "#", current: false, initialStep: 8, status: 0 },
	{ name: "Endividamento", href: "#", current: false, initialStep: 9, status: 0 },
	{ name: "Auditoria", href: "#", current: false, initialStep: 10, status: 0, optional: true },
	{ name: "Clientes", href: "#", current: false, initialStep: 11, status: 0 },
	{
		name: "Percentual de cada cliente",
		href: "#",
		current: false,
		initialStep: 12,
		status: 0,
		optional: true
	},
	{ name: "Estrutura Societária", href: "#", current: false, initialStep: 13, status: 0 },
	{ name: "Equipe", href: "#", current: false, initialStep: 14, status: 0 },
	{ name: "Local da sede", href: "#", current: false, initialStep: 15, status: 0 },
	{
		name: "Locais de operação",
		href: "#",
		current: false,
		initialStep: 16,
		status: 0,
		optional: true
	},
	{
		name: "Tipos de Imóveis",
		href: "#",
		current: false,
		initialStep: 17,
		status: 0,
		optional: true
	},
	{
		name: "Principais Produtos e Serviços",
		href: "#",
		current: false,
		initialStep: 18,
		status: 0
	},
	{ name: "Canais de Distribuição", href: "#", current: false, initialStep: 19, status: 0 }
];

export const setMap = new Map<string | string[], number>([
	["briefing", 1],
	["business_differentials", 2],
	["business_timelines", 3],
	["business_goals", 4],
	[["business_investors_has_interest", "business_interested_investors"], 5],
	["business_potential_investors", 6],
	["business_competitors", 7],
	["business_files", 8],
	["debt_amount", 9],
	[["is_audited", "audited_by"], 10],
	["client_amount", 11],
	["business_main_clients", 12],
	["business_partners", 13],
	[["clt_employees_amount", "pj_employees_amount"], 14],
	[["main_state_id", "main_state", "main_city_id", "main_city"], 15],
	[["has_operation_in_multiple_states", "business_states"], 16],
	[["is_rented", "business_leases"], 17],
	["business_main_products", 18],
	["business_sales_types", 19]
]);

export function getStepByField(field: string | undefined) {
	if (!field) return;

	const mapValue = getValueFromMap(setMap, field);

	return mapValue;
}

export function updateManyCheckedState(arrayState: Categories[], stepArray: number[]) {
	const updatedArray: Categories[] = arrayState.map((obj) => {
		if (stepArray.includes(obj.initialStep)) {
			return {
				...obj,
				status: 2
			};
		}
		return obj;
	});

	return updatedArray;
}

export function reviewEveryCheckedState(categories: Categories[], data: ExtendedRegistrationForm) {
	const stepArray: number[] = [];

	for (const [key, value] of Object.entries(data)) {
		if (
			value === null ||
			value === undefined ||
			value === "" ||
			(Array.isArray(value) && value.length === 0)
		) {
			continue;
		} else {
			const mapValue = getValueFromMap(setMap, key);

			if (mapValue) stepArray.push(mapValue);
		}
	}

	return updateManyCheckedState(categories, stepArray);
}
