import { posts } from "../../../assets/news/posts";
import clsx from "clsx";

const Article = ({ post, fixedWidth = false }: { post: (typeof posts)[0]; fixedWidth?: boolean }) => {
	return (
		<article
			key={post.id}
			className={clsx("flex flex-col items-start justify-between rounded-lg p-4 shadow-xl", {
				"sm:w-64 md:w-96": fixedWidth
			})}
		>
			<div className="flex items-center gap-x-4 text-xs">
				<time dateTime={post.datetime} className="text-gray-500">
					{post.date}
				</time>
			</div>
			<div className="group relative">
				<h3 className="text-gray-900 group-hover:text-gray-600 mt-3 mb-1 text-lg font-semibold leading-6">
					<a href={post.href} target="_blank" rel="noreferrer">
						<span className="absolute inset-0" />
						{post.title}
					</a>
				</h3>
				<p className="line-clamp-3 leading-2 text-gray-600 mt-5 text-sm">{post.headline}</p>
			</div>
			<div className="relative mt-2 flex items-center gap-x-4">
				<img src={post.company.image} alt={`Logo ${post.company.name}`} className="h-10 w-auto rounded p-2" />
			</div>
		</article>
	);
};

export default Article;
