import { removeLetters, removeSpecialChars } from '../../helpers';

const currentYear = new Date().getFullYear();

const generateFinanceReports = (data) => {
	const financeReports: any = [];
	const years = [currentYear - 3, currentYear - 2, currentYear - 1, currentYear];
	for (let year of years) {
		for (let reportType of ['ebitda', 'liquid_revenue']) {
			if (removeLetters(data[`finance_report${year}${reportType}`])) {
				const financeReport = {
					amount: parseInt(removeLetters(data[`finance_report${year}${reportType}`])),
					year: year,
					type: reportType === 'ebitda' ? 1 : 2
				};
				financeReports.push(financeReport);
			}
		}
	}
	return financeReports;
};
export const formatBusinessForm = (registerForm: any) => {
	const userName = registerForm.email;
	const [firstName, ...last_name_parts] = registerForm.name.split('-');
	const lastName = last_name_parts.join(' ');
	const cpf = removeSpecialChars(registerForm.cpf);
	const cnpj = removeSpecialChars(registerForm.cnpj);
	const phone = removeSpecialChars(registerForm.phone);

	const requestBody = JSON.stringify({
		username: userName,
		first_name: firstName,
		last_name: lastName,
		email: registerForm.email,
		password: registerForm.password,
		phone: phone,
		cpf: cpf,
		business_full_name: registerForm.trade_name,
		business_trade_name: registerForm.trade_name,
		business_website: registerForm.website,
		business_activity_sector: registerForm.sector,
		cnpj: [cnpj],
		primary_subsector: registerForm.subsector,
		secundary_subsector: registerForm.subsector2 ? registerForm.subsector2 : '',
		is_political: registerForm.politically_exposed,
		finance_report: generateFinanceReports(registerForm),
		looking_platform: registerForm.looking_platform
	});
	return requestBody;
};

/* _formato_do_cadastro_
{
    "username": "elonmusk@tesla.com",ok
    "first_name": "Elon", ok
    "last_name": "Musk", ok
    "email": "businessuser1@mail.com", ok
    "password": "senha_de_teste", ok
    "phone": "5521988888888", ok
    "cpf": "00000000011", ok

    "business_full_name": "Tesla AutoCars and Energy Corp LTD.", ok
    "business_trade_name": "Tesla Motors", ok
    "business_website": "www.teslamotors.com", ok
    "business_activity_sector": 1, ok
    "cnpj": [
        "00000000000" ok
    ],

    "primary_subsector": 1,
    "secundary_subsector": 2,
    "finance_report": [
        {
            "amount": 1000.00,
            "year": 2022,
            "type": 1
        },
        {
            "amount": 1500.00,
            "year": 2021,
            "type": 1
        }
    ],
    "is_political": true
}
*/
