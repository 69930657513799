import { FC, useEffect, useState } from "react";
import Button from "src/components/Button/Button";
import Checkbox from "src/components/ui/base/checkbox";
import { CheckboxOption } from "src/components/ui/form/models";
import { BusinessGoals } from "src/domain/Register";
import Textarea from "../../custom-components/Textarea";
import { Step } from "../../configs/models";

const CompanyPurposeSale: FC<Step> = ({ onSubmit, getStepData, loadingStep, register, errors, setValue }) => {
	const [selected, setSelected] = useState<CheckboxOption[]>([]);
	const [selectedOthers, setSelectedOthers] = useState<boolean>(false);
	const [isFormValid, setFormValid] = useState<boolean>(false);
	const [customGoal, setCustomGoal] = useState<string>(getStepData("business_custom_goal") ?? "");

	const options: Array<CheckboxOption> = [
		{
			id: 1,
			label: "Realizar o ganho que gerei com a minha empresa"
		},
		{
			id: 2,
			label: "Investimento para acelerar o crescimento da empresa"
		},
		{
			id: 3,
			label: "Liquidar dívidas"
		},
		{
			id: 4,
			label: "Outros objetivos",
			description: "(especificar em seguida)"
		}
	];

	const validationStep = () => {
		const businessGoals = getStepData("business_goals");

		if (businessGoals?.length === 0) return setFormValid(false);

		for (const goal of businessGoals) if (goal.goal_id === 4 && customGoal.length === 0) return setFormValid(false);

		return setFormValid(true);
	};

	const handleCustomGoals = () => {
		if (selectedOthers) {
			setValue("business_custom_goal", "", { shouldDirty: true });
			setCustomGoal("");
		}

		return setSelectedOthers((old) => !old);
	};

	const handleSelect = (item) => {
		const businessGoals = getStepData("business_goals");

		if (item.id === 4) {
			handleCustomGoals();
		}

		if (isSelected(item.id)) {
			setSelected(selected.filter((opt: CheckboxOption) => opt.id !== item.id));
			return setValue(
				"business_goals",
				businessGoals?.filter((opt) => opt.goal_id !== item.id),
				{ shouldDirty: true }
			);
		}

		setSelected([...selected, item]);

		const newBusinessGoals: BusinessGoals = {
			goal_id: item.id,
			name: item.label
		};

		setValue("business_goals", [...businessGoals, newBusinessGoals], {
			shouldDirty: true
		});
	};

	const isSelected = (itemId) => {
		return getStepData("business_goals")?.find((i: BusinessGoals) => i.goal_id === itemId) ? true : false;
	};

	const hasCustomGoals = () => {
		getStepData("business_goals")?.forEach((i: BusinessGoals) => {
			if (i.goal_id === 4) {
				setSelectedOthers((old) => !old);
			}
		});
	};

	useEffect(() => {
		hasCustomGoals();
	}, []);

	useEffect(() => validationStep(), [selected, customGoal]);

	return (
		<>
			<fieldset className="mb-6 space-y-5">
				{options.map((option, index) => {
					return (
						<Checkbox key={option.id} option={option} handleSelect={handleSelect} isSelected={isSelected(option.id)} />
					);
				})}
			</fieldset>
			<Textarea
				className={`${
					selectedOthers ? "block" : "hidden"
				} mb-5 h-28 w-full resize-none rounded border-0 bg-[#F4F5F6] px-4 py-3`}
				placeholder="Digite sua resposta"
				{...register("business_custom_goal")}
				onChange={({ target }) => setCustomGoal(target.value)}
				fieldError={errors.business_custom_goal}
			/>
			<Button
				loading={loadingStep}
				type="button"
				size="medium"
				onClick={() =>
					onSubmit({
						business_goals: getStepData("business_goals"),
						business_custom_goal: getStepData("business_custom_goal")
					})
				}
				disabled={!isFormValid}
			>
				Próximo
			</Button>
		</>
	);
};

export default CompanyPurposeSale;
