import { FC, useEffect, useState } from 'react';
import Loading from 'src/assets/icons/Loading';
import Page from 'src/layouts/Logged/components/Page';
import { useParams } from 'react-router-dom';
import Button from 'src/components/Button/Button';
import { getTemplate } from 'src/services/template/getTemplate';
import { CheckSendEmail } from 'src/domain/Template';
import { getCheckSendEmail } from 'src/services/checkSendEmail/getCheckSendEmail';
import { setTemplate } from 'src/services/template/setTemplate';
import Tooltip from 'src/components/Tooltip';
import { toastNotify } from 'src/helpers/toastNotify';
import * as Yup from 'yup';
interface TemplatePageProps {}

const TemplatePage: FC<TemplatePageProps> = () => {
	const [loadingTemplate, setLoadingTemplate] = useState<boolean>(false);
	const [checkSendEmailData, setCheckSendEmailData] = useState<CheckSendEmail | undefined>();
	const [templateValue, setTemplateValue] = useState('');
	const [isValid, setIsValid] = useState<boolean>(true);
	const { id } = useParams();

	const handleInit = async () => {
		const checkSendEmailData = await getCheckSendEmail(Number(id));
		setCheckSendEmailData(checkSendEmailData.data);
		const { data } = await getTemplate(Number(id));
		setTemplateValue(data.template);
		setLoadingTemplate(false);
	};

	const handleSave = async () => {
		try {
			setLoadingTemplate(true);
			await setTemplate({ business_id: Number(id), save_template: true, template: templateValue });
			toastNotify('Template salvo', 'success');
		} finally {
			setLoadingTemplate(false);
		}
	};

	useEffect(() => {
		handleInit();
	}, []);

	const generateTooltipText = () => {
		const text = `Para o envio de email todos os seguintes items devem estar com o status 'OK': \n teaser - ${
			checkSendEmailData?.teaser ? 'OK' : 'Pendente'
		} \n welcomeletter - ${checkSendEmailData?.welcomeletter ? 'OK' : 'Pendente'} \n infopack - ${
			checkSendEmailData?.infopack ? 'OK' : 'Pendente'
		}\n contrato - ${checkSendEmailData?.contract ? 'OK' : 'Pendente'}`;

		return text;
	};

	const enableSendButton = () => {
		return (
			checkSendEmailData?.contract &&
			checkSendEmailData.infopack &&
			checkSendEmailData.teaser &&
			checkSendEmailData.welcomeletter
		);
	};

	return (
		<>
			{loadingTemplate ? (
				<div className="flex justify-center items-center h-screen">
					<Loading color="primary" size={12} />
				</div>
			) : (
				<>
					<Page>
						<section className="mb-8">
							<textarea
								className={`mb-2 px-4 py-3 w-full h-96 bg-[#F4F5F6] rounded resize-none`}
								value={templateValue}
								onChange={({ target }) => setTemplateValue(target.value)}
							/>
							<div className="flex flex-row">
								<Button size="large" onClick={handleSave} disabled={!enableSendButton()}>
									Enviar
								</Button>
								<Tooltip text={generateTooltipText()} id={1} />
							</div>
						</section>
					</Page>
				</>
			)}
		</>
	);
};

export default TemplatePage;
