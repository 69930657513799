import { FC } from 'react';
import { Link } from 'react-router-dom';
import Page from '../../../layouts/Logged/components/Page';
import history from 'src/services/history';
export interface FindersDashboardPageProps {}

const FinderRecommendationSuccessPage: FC = () => {
	return (
		<>
			<Page>
				<div className="w flex items-center justify-center">
					<div className="w-[400px]">
						{/* Step 5 */}

						<p className="font-bold text-brand-primary text-medium mb-4">Cadastro realizado</p>
						<p className="text-sm text-neutral-medium">
							O cadastro da empresa foi realizado com sucesso.
							<br />
							<br /> Estamos enviando um convite para o empresário entrar na plataforma com
							informações para os próximos passos do processo.
						</p>
						<button
							type="button"
							className="inline-flex align-center items-center px-4 py-2 shadow-sm text-base font-sm rounded-md text-white justify-center bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full mt-4 mb-8"
							onClick={() => history.push('/finder/recommendbusiness')}
						>
							Cadastrar nova empresa
						</button>
						<Link to={`/finder/dashboard`} className="block text-brand-secondary text-center">
							Voltar para o dashboard
						</Link>
					</div>
				</div>
			</Page>
		</>
	);
};

export default FinderRecommendationSuccessPage;
