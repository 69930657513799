import { FC } from 'react';
import InputField from '../base/input';
import { Input } from './models';

const AnswerField: FC<Input> = ({ onChange, placeholder, initialValue, disabled, maxLength }) => {
	const validateAnswer = (answer: string) => {
		return answer.length >= 3;
	};

	return (
		<>
			<InputField
				type="text"
				id="answer"
				placeholder={placeholder || ''}
				validateValue={validateAnswer}
				onChange={onChange}
				showIcon={false}
				initialValue={initialValue}
				disabled={disabled}
				maxLength={maxLength}
			/>
		</>
	);
};

export default AnswerField;
