import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Button from "src/components/Button/Button";
import Input from "src/components/Input";
import Checkbox from "src/components/ui/base/checkbox";
import { editUser, EditUserDTO, getUserInfo } from "src/domain/User";
import { toastNotify } from "src/helpers/toastNotify";
import { useAuth } from "src/hooks/useAuth";
import Header from "src/layouts/Logged/components/Header";
import Page from "src/layouts/Logged/components/Page";

const BusinessConfig = () => {
	const { user, updateUserWithoutNavigate } = useAuth();

	const [initialValues, setInitialValues] = useState<EditUserDTO>({});

	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			const { data } = await getUserInfo(user?.business_id);

			formik.setValues(data);
		})();
	}, [user?.business_id]);

	const formik = useFormik({
		initialValues,
		onSubmit: async (values) => {
			try {
				setIsLoading(true);

				values = {
					...values,
					business_id: user?.business_id
				};

				await editUser(values);
				updateUserWithoutNavigate(user?.role);
				toastNotify("Seus dados foram alterados!", "success");

				setIsLoading(false);
			} catch (err) {
				toastNotify("Houve um erro na sua alteração, tente novamente mais tarde.", "error");
				setIsLoading(false);
			}
		}
	});

	return (
		<>
			<Header title="Configurações" />
			<Page>
				<h2>Altere seu perfil</h2>

				<form className="w-[60%]" onSubmit={formik.handleSubmit}>
					<h3 className="my-4 text-left text-lg">Nome</h3>
					<Input
						type="text"
						placeholder="Preencha seu nome"
						value={formik.values.full_name}
						onChange={formik.handleChange("full_name")}
						error={false}
						errorMessage=""
					/>
					<h3 className="my-4 text-left text-lg">Empresa</h3>
					<Input
						type="text"
						placeholder="Preencha sua empresa"
						value={formik.values.business_trade_name}
						onChange={formik.handleChange("business_trade_name")}
						error={false}
						errorMessage=""
					/>
					<h3 className="my-4 text-left text-lg">Telefone</h3>
					<Input
						type="text"
						placeholder="Preencha seu telefone"
						value={formik.values.phone}
						onChange={formik.handleChange("phone")}
						mask="phone"
						error={false}
						errorMessage=""
					/>

					<h3 className="my-4 text-left text-lg">Notificações de E-mail</h3>
					<Checkbox
						handleSelect={() =>
							formik.setFieldValue("email_notifications_enabled", !formik.values.email_notifications_enabled)
						}
						isSelected={formik.values.email_notifications_enabled}
						option={{ id: 1, label: "Habilitar notificações de e-mail" }}
					/>
					<Button className="mt-4 w-10" type="submit" disabled={isLoading}>
						Atualizar perfil
					</Button>
				</form>
			</Page>
		</>
	);
};

export default BusinessConfig;
