import { FC, useState } from "react";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { Selectable } from "src/components/ui/form/models";
import SingleSelect from "src/components/ui/form/Selects/SingleSelect";
import { Step } from "../../configs/models";

const options: Selectable[] = [
	{ id: 1, name: "Sim", value: true },
	{ id: 2, name: "Não", value: false }
];

const CompanyAuditedBy: FC<Step> = ({
	onSubmit,
	getStepData,
	loadingStep,
	setValue,
	register,
	errors
}) => {
	const [selected, setSelected] = useState<Selectable>(
		getStepData("audited_by") ? options[0] : options[1]
	);

	return (
		<>
			<div className="mb-6">
				<SingleSelect
					options={options}
					onChange={(e: Selectable) => {
						setSelected(e);
						setValue("is_audited", e.value, { shouldDirty: true });

						if (!e.value) return setValue("audited_by", "", { shouldDirty: true });
					}}
					value={selected}
				/>
			</div>
			{Boolean(selected?.value) && (
				<>
					<h3 className={`text-neutral-low mb-4 text-left text-2xl font-bold`}>
						Quem auditou a empresa?
					</h3>
					<div className="mb-4 w-full">
						<Input
							type="text"
							placeholder="Digite sua resposta"
							{...register("audited_by")}
							fieldError={errors.audited_by}
						/>
					</div>
				</>
			)}

			<div className="flex w-full">
				<Button
					loading={loadingStep}
					disabled={getStepData("is_audited") ? Boolean(errors.audited_by) : false}
					onClick={(e) => {
						e.preventDefault();

						onSubmit({
							audited_by: getStepData("is_audited") ? getStepData("audited_by") : ""
						});
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</>
	);
};

export default CompanyAuditedBy;
