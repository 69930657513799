import { FC, useEffect } from "react";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { removeLetters } from "src/helpers";
import { Step } from "../../configs/models";

const CompanyDebtAmount: FC<Step> = ({ onSubmit, getStepData, loadingStep, errors, register }) => {
	return (
		<div className="flex flex-col items-start justify-between">
			<div className="mb-4 w-full">
				<Input
					type="text"
					placeholder="Digite sua resposta"
					{...register("debt_amount")}
					fieldError={errors.debt_amount}
					maxLength={10}
					mask="currency"
					prefixIcon="R$"
				/>
			</div>
			<div className="flex w-full">
				<Button
					disabled={Boolean(errors.debt_amount)}
					loading={loadingStep}
					onClick={(e) => {
						e.preventDefault();

						const value = getStepData("debt_amount");

						if (value)
							return onSubmit({
								debt_amount: Number(removeLetters(value.toString()))
							});
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</div>
	);
};

export default CompanyDebtAmount;
