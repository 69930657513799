import { TrashIcon } from "@heroicons/react/outline";
import { BaseSyntheticEvent, FC, useEffect } from "react";
import { Controller, ControllerRenderProps, useFieldArray } from "react-hook-form";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { BusinessPartner, ExtendedRegistrationForm } from "src/domain/Register";
import { Step } from "../../configs/models";

const initialData: BusinessPartner = {
	name: "",
	share: null
};

const CompanyInterested: FC<Step> = ({
	onSubmit,
	getStepData,
	maxInputs,
	loadingStep,
	control,
	errors,
	register,
	setValue
}) => {
	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "business_partners"
	});

	useEffect(() => {
		if (fields.length === 0) append(initialData);
	}, [fields, append]);

	function handleOnChange(
		event: BaseSyntheticEvent,
		field: ControllerRenderProps<ExtendedRegistrationForm, `business_partners.${number}.share`>
	) {
		const { target } = event;

		if (Number(target.value) > 100)
			return setValue(field.name, 100, {
				shouldDirty: true,
				shouldValidate: true
			});

		return setValue(field.name, Number(target.value), {
			shouldDirty: true,
			shouldValidate: true
		});
	}

	return (
		<>
			<ul className="w-full ">
				{fields.map((field, index) => (
					<li className="mb-4 flex w-full" key={index}>
						<div className="mr-3 w-[80%]">
							<div>
								<Input
									{...field}
									type="text"
									{...register(`business_partners.${index}.name`)}
									fieldError={errors.business_partners?.[index]?.name}
								/>
							</div>
						</div>
						<div className="mr-1 w-[13%]">
							<div>
								<Controller
									control={control}
									name={`business_partners.${index}.share`}
									render={({ field, fieldState }) => (
										<Input
											{...field}
											type="number"
											onChange={(e) => handleOnChange(e, field)}
											value={field.value ? field.value : undefined}
											fieldError={fieldState.error}
											sufix="%"
											ref={field.ref}
										/>
									)}
								/>
							</div>
						</div>
						<div className={`flex w-6 items-center ${index === 0 && "hidden"}`}>
							<TrashIcon className="cursor-pointer" onClick={() => remove(index)} color="#bb7127" />
						</div>
					</li>
				))}
			</ul>
			<div className="flex w-full">
				<Button
					type="button"
					className={`mr-3`}
					color="outline-secondary"
					size="large"
					disabled={maxInputs === fields.length}
					onClick={() => append(initialData)}
				>
					Nova Entrada
				</Button>
				<Button
					loading={loadingStep}
					disabled={Boolean(errors.business_partners)}
					onClick={(e) => {
						e.preventDefault();

						onSubmit({ business_partners: getStepData("business_partners") });
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</>
	);
};

export default CompanyInterested;
