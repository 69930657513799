import {
	MatchStatus,
	InvestorBusinessDTO,
	PaginatedDataDTO,
	InvestorIgcDTO
} from "src/domain/Target";
import client from "../client";

export interface getBusinessInvestorsParams {
	page?: number;
	size?: number;
	tradeName?: string;
	criteria?: string;
}

export const getBusinessInvestors = (id: number, params: getBusinessInvestorsParams) => {
	return client.get<PaginatedDataDTO<InvestorIgcDTO>>(`igc/${id}/investors`, {
		params
	});
};

export interface SelectedBusinessInvestorsParams {
	page?: number;
	size?: number;
	matchStatus?: MatchStatus;
}

export const getSelectedBusinessInvestors = (
	id: number,
	params: SelectedBusinessInvestorsParams
) => {
	return client.get<PaginatedDataDTO<InvestorBusinessDTO>>(`business/${id}/investors`, {
		params
	});
};

export interface setSelectedBusinessInvestorsParams {
	businessId: number;
	body: number[];
}

export const setSelectedBusinessInvestors = ({
	businessId,
	body
}: setSelectedBusinessInvestorsParams) => {
	return client.post<PaginatedDataDTO<InvestorBusinessDTO>>(
		`business/${businessId}/investors`,
		body,
		{
			headers: {
				"Content-Type": "application/json"
			}
		}
	);
};

export const enableBusinessTargetList = (id: number) => {
	return client.patch(`business/${id}/enable-target-list`);
};

export const disableBusinessTargetList = (id: number) => {
	return client.patch(`business/${id}/disable-target-list`);
};

export interface setBusinessInvestorParams {
	businessId: number;
	investorId: number;
}

export const deleteBusinessInvestor = ({ businessId, investorId }: setBusinessInvestorParams) => {
	return client.delete(`business/${businessId}/investors/${investorId}`);
};

export const approveBusinessInvestor = ({ businessId, investorId }: setBusinessInvestorParams) => {
	return client.patch(`business/${businessId}/investor/${investorId}/approve`);
};

export const rejectBusinessInvestor = ({ businessId, investorId }: setBusinessInvestorParams) => {
	return client.patch(`business/${businessId}/investor/${investorId}/reject`);
};
