import { TrashIcon } from "@heroicons/react/outline";
import { FC, useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import { Selectable } from "src/components/ui/form/models";
import SingleSelect from "src/components/ui/form/Selects/SingleSelect";
import { Step } from "../../configs/models";

const options = [
	{ id: 0, name: "Não", value: false },
	{ id: 1, name: "Sim", value: true }
];
const initialData = {
	investor: "",
	investor_interest: ""
};

const CompanyInterestedInvestors: FC<Step> = ({
	onSubmit,
	getStepData,
	maxInputs,
	loadingStep,
	control,
	setValue,
	errors,
	register,
	watch
}) => {
	const [selected, setSelected] = useState<Selectable>(
		getStepData("business_interested_investors").length > 0 ? options[1] : options[0]
	);

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "business_interested_investors"
	});

	return (
		<>
			<div className="mb-6">
				<SingleSelect
					options={options}
					onChange={(e: Selectable) => {
						setValue("business_investors_has_interest", e.value, { shouldDirty: true });
						setSelected(e);

						if (!e.value) setValue("business_interested_investors", [], { shouldDirty: true });
						if (!selected.value && e.value) return append(initialData);
					}}
					value={selected}
				/>
			</div>
			<div className="mb-4">
				{selected.value && (
					<>
						<div className="mb-4 flex w-full">
							<div className="mr-3">
								<h3 className={`text-neutral-low mt-6 mb-6 text-lg font-bold`}>
									Quais investidores demonstraram interesse na empresa? e Até qual marco você
									avançou com o investidor? (adicione uma nova entrada caso tenha mais de uma
									opção).
								</h3>
							</div>
						</div>
						<ul className="w-full ">
							{fields.length > 0 &&
								fields.map((field, index) => {
									return (
										<li className="mb-4 flex w-full" key={field.id}>
											<div className="mr-3 w-[50%]">
												<Input
													type="text"
													{...field}
													{...register(`business_interested_investors.${index}.investor`)}
													fieldError={errors.business_interested_investors?.[index]?.investor}
												/>
											</div>
											<div className="mr-1 w-[43%]">
												<Input
													type="text"
													{...field}
													{...register(`business_interested_investors.${index}.investor_interest`)}
													fieldError={
														errors.business_interested_investors?.[index]?.investor_interest
													}
												/>
											</div>
											<div className={`flex w-6 items-center ${index === 0 && "hidden"}`}>
												<TrashIcon
													className="cursor-pointer"
													onClick={() => remove(index)}
													color="#bb7127"
												/>
											</div>
										</li>
									);
								})}
						</ul>
					</>
				)}
			</div>

			<div className="flex w-full">
				<Button
					type="button"
					className={`${!selected?.value && "hidden"} mr-3`}
					color="outline-secondary"
					size="large"
					disabled={maxInputs === fields.length}
					onClick={() => append(initialData)}
				>
					Nova Entrada
				</Button>
				<Button
					loading={loadingStep}
					disabled={
						selected.value ? control.getFieldState("business_interested_investors").invalid : false
					}
					onClick={(e) => {
						e.preventDefault();
						onSubmit({
							business_interested_investors: getStepData("business_investors_has_interest")
								? getStepData("business_interested_investors")
								: []
						});
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</>
	);
};

export default CompanyInterestedInvestors;
