
import download from 'downloadjs';
import { FC, useEffect, Fragment } from 'react';
import { useTabs } from 'react-headless-tabs';
import { TabSelector } from 'src/components/TabSelector';
import { useAuth } from 'src/hooks/useAuth';
import Header from 'src/layouts/Logged/components/Header';
import Page from 'src/layouts/Logged/components/Page';
import ContractPage from './components/TabsContent/Contract';
import DocumentPage from './components/TabsContent/Document';
import QAPage from './components/TabsContent/QA';
import TeaserPage from './components/TabsContent/Teaser';

const TABS = {
	TEASER: 'teaser',
	DOCUMENTS: 'documents',
	CONTRACTS: 'contracts',
	QA: 'qa'
};

const BusinessMaterialsPage: FC = () => {
	const { user } = useAuth();
	const [selectedTab, setSelectedTab] = useTabs(Object.values(TABS));

	const getFirstLetters = (businessName: string) => {
		const nameArray = businessName.split(' ');

		return nameArray?.length === 1
			? nameArray[0].substring(0, 2)
			: nameArray[0][0] + nameArray[1][0];
	};

	if (!user?.business_contract_signed) {
		return (
			<Fragment>
				<Header title="Meus Materiais"></Header>
				<Page>
					<div className="relative h-[75vh] max-h-[75vh] w-full">
						<span className="flex h-full flex-col items-center sm:justify-center md:flex-row md:gap-6">
							<h2 className="m-0 max-w-xl text-center text-lg font-medium sm:mb-2 sm:text-2xl">
								Você poderá acessar aqui os materiais da sua companhia assim que assinarmos o contrato de trabalho.
							</h2>
						</span>
					</div>
				</Page>
			</Fragment>
		);
	}

	return (
		<div>
			<div className="row flex items-center gap-4 px-8 pt-4 pb-2">
				<div className="flex h-14 w-14 items-center justify-center rounded-lg bg-auxiliar-highlight-orange">
					<p className="text-2xl font-bold uppercase tracking-wide text-white">
						{getFirstLetters(user.business_trade_name)}
					</p>
				</div>
				<h1 className="flex items-center justify-center text-3xl font-bold text-brand-primary">
					{user?.business_trade_name}
				</h1>
			</div>

			<nav className="bg-neutral-high border-neutral-medium z-50 flex justify-around border-b border-opacity-50 md:justify-start md:px-10">
				<TabSelector
					isActive={selectedTab === TABS.TEASER}
					onClick={() => setSelectedTab(TABS.TEASER)}
				>
					Teaser
				</TabSelector>

				<TabSelector
					isActive={selectedTab === TABS.DOCUMENTS}
					onClick={() => setSelectedTab(TABS.DOCUMENTS)}
				>
					Documentos
				</TabSelector>

				<TabSelector
					isActive={selectedTab === TABS.CONTRACTS}
					onClick={() => setSelectedTab(TABS.CONTRACTS)}
				>
					Contratos
				</TabSelector>

				<TabSelector isActive={selectedTab === TABS.QA} onClick={() => setSelectedTab(TABS.QA)}>
					Q&A
				</TabSelector>
			</nav>

			{selectedTab === TABS.TEASER && <TeaserPage />}
			{selectedTab === TABS.DOCUMENTS && <DocumentPage />}
			{selectedTab === TABS.CONTRACTS && <ContractPage />}
			{selectedTab === TABS.QA && <QAPage />}
		</div>
	);
};

export default BusinessMaterialsPage;
