import { FC, useEffect, useState } from "react";
import LoadingIcon from "src/assets/icons/Loading";
import { Document } from "src/domain/Document";
import { translateStatus } from "../Utils";
import { getDocumentsByCompanyId } from "../../../../../services/document/getDocumentsByCompanyId";
import { useAuth } from "../../../../../hooks/useAuth";

interface DocumentsProps {
	showStatus?: boolean;
	updateDependencyArray?: any;
	clientId?: number;
}

const Documents: FC<DocumentsProps> = ({ showStatus = true, updateDependencyArray, clientId }) => {
	const { user } = useAuth();
	const [documents, setDocuments] = useState<Document[]>();
	const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);

	const fetchDocuments = async () => {
		try {
			setLoadingDocuments(true);
			const response = await getDocumentsByCompanyId(String(user?.role), clientId ?? Number(user?.business_id));
			setDocuments(response.data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingDocuments(false);
		}
	};

	useEffect(() => {
		fetchDocuments();
	}, [updateDependencyArray]);

	const downloadDocument = (document: Document) => {
		const link = document?.file_url;
		if (link) {
			window.open(link, "_blank");
		}
	};

	if (documents?.length === 0) {
		return (
			<div className="text-medium w-full overflow-y-auto rounded-xl border-2 border-brand-secondary border-opacity-30 p-2 shadow-lg lg:h-1/2">
				<h4 className="mb-2 border-b border-brand-secondary border-opacity-20 font-medium text-brand-secondary">
					Documentos do meu processo
				</h4>
				<div className="flex h-4/5 flex-col items-center justify-center overflow-y-auto">
					Nenhum documento encontrado.
				</div>
			</div>
		);
	}

	if (loadingDocuments) {
		return (
			<div className="text-medium relative w-full overflow-y-hidden rounded-xl border-2 border-brand-secondary border-opacity-30 p-2 shadow-lg lg:h-1/2">
				<h4 className="mb-2 border-b border-brand-secondary border-opacity-20 font-medium text-brand-secondary">
					Documentos do meu processo
				</h4>
				<LoadingIcon />
			</div>
		);
	}

	return (
		<div className="text-medium relative w-full overflow-y-hidden rounded-xl border-2 border-brand-secondary border-opacity-30 p-2 shadow-lg xl:h-1/2">
			<h4 className="mb-2 border-b border-brand-secondary border-opacity-20 font-medium text-brand-secondary">
				Documentos do meu processo
			</h4>
			<div className="mb-20 max-h-60 overflow-y-auto xl:mb-0 xl:max-h-[90%]">
				<table className="h-full w-full text-left text-sm">
					<thead className="sticky top-0 bg-brand-secondary text-xs uppercase text-white">
						<th scope="col" className="rounded-l-md px-2 py-3 text-center"></th>
						<th scope="col" className="px-2 py-3 text-center">
							Arquivo
						</th>
						<th scope="col" className="rounded-r-md px-2 py-3 text-center">
							Status
						</th>
					</thead>
					<tbody>
						{documents &&
							documents.map((document, idx) => (
								<tr className="font-sm border-b border-brand-secondary border-opacity-50 " key={idx}>
									<td className="w-fit py-2 pl-4">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="2"
											stroke="currentColor"
											className="h-6 w-6 rounded-md bg-brand-secondary p-1 text-white hover:cursor-pointer hover:bg-opacity-80"
											onClick={() => downloadDocument(document)}
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
											/>
										</svg>
									</td>
									<th scope="row" className="whitespace-nowrap px-2 py-2 text-center font-medium">
										{document.file_name}
									</th>
									<td className={`text-center`}>
										<span
											className={`${document.status === "pending" && "bg-brand-warning bg-opacity-60"}
															${document.status === "approved" && "bg-brand bg-opacity-60"}
															${document.status === "denied" && "bg-brand-error bg-opacity-60"}
															rounded-xl px-2 py-1 font-medium text-white`}
										>
											{translateStatus(document.status)}
										</span>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Documents;
