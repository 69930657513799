import { FC, MouseEventHandler, useEffect } from "react";
import Button from "src/components/Button/Button";
import FileList from "src/components/FileList";
import Upload from "src/components/Upload";
import { useFiles } from "src/contexts/files";
import Documents from "../../../../Business/Dashboard/components/Documents";
import { toastNotify } from "src/helpers/toastNotify";
import { InformationCircleIcon } from "@heroicons/react/outline";
import client from "../../../../../services/client";
import { Step } from "../../configs/models";
import { useParams } from "react-router-dom";

const CompanyDocs: FC<Step> = ({ onSubmit, getStepData, loadingStep, setValue }) => {
	const { id } = useParams();
	const { handleUploadFiles, uploadLoading, uploadedFiles } = useFiles();

	useEffect(() => {
		setValue("business_files", uploadedFiles, { shouldDirty: true });
	}, [uploadedFiles]);

	const handleBalanceSheetModelDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
		try {
			e.preventDefault();
			const filename = "Modelos de Balancetes.zip";
			const response = await client.get("/business/balance_sheet_examples");
			const link = document.createElement("a");
			link.href = response.data.url;
			link.setAttribute("download", filename);
			link.setAttribute("target", "_blank");
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			console.error(err);
		}
	};

	const handleNextValidate = () => {
		if (uploadLoading) {
			return toastNotify("Para continuar, verifique se todos os arquivos foram enviados!", "error");
		}

		handleUploadFiles("business/upload_file/");

		return onSubmit({ business_files: getStepData("business_files") });
	};

	return (
		<>
			<span className="relative flex gap-2">
				<div className="group">
					<InformationCircleIcon className="h-6 w-6 text-brand-secondary" />

					<div className="absolute top-0 left-0 m-4 hidden rounded-md bg-white p-4 shadow-md transition-all active:block group-hover:block">
						<p>Seu balancete deve ser enviado preferencialmente no formato excel (.XLSX)</p>
						<p>Recomendamos que você organize o balancete em uma das seguintes maneiras:</p>
						<ul className="ml-5 list-disc">
							<li>Cada período em abas diferentes.</li>
							<li>Cada período em arquivos diferentes.</li>
							<li>
								Todos os períodos em um único arquivo, com uma coluna com o título "Período" contendo o "mês/ano"
								respectivo.
							</li>
						</ul>
					</div>
				</div>
				<p className="whitespace-normal">
					Clique{" "}
					<button className="font-bold text-brand-secondary" onClick={handleBalanceSheetModelDownload}>
						aqui
					</button>{" "}
					para fazer o download dos modelos de balancetes.
				</p>
			</span>
			&nbsp;
			<Upload businessId={Number(id)} multiple={true} />
			<FileList />
			<div className="my-6 flex w-full items-center justify-between gap-2">
				<Button
					loading={loadingStep}
					type="button"
					size="medium"
					disabled={!uploadedFiles.length || uploadLoading}
					onClick={(e) => {
						e.preventDefault();
						handleNextValidate();
					}}
				>
					Próximo
				</Button>
			</div>
			<div className="w-full pb-10">
				<Documents showStatus={false} updateDependencyArray={uploadLoading} clientId={Number(id)} />
			</div>
		</>
	);
};

export default CompanyDocs;
