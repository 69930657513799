import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { postInvestor } from 'src/domain/Investor';
import { formatCnpj, removeLetters } from 'src/helpers';
import { getSubSectors } from 'src/services/domain/getSubSectors';
import * as Yup from 'yup';
import Button from '../Button/Button';
import Input from '../Input';
import Modal from '../Modal';
import { Selectable } from '../ui/form/models';
import SingleSelect from '../ui/form/Selects/SingleSelect';

interface params {
	isOpen: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	fetchTableData: () => Promise<void>;
}

function InvestorModal({ isOpen, setOpen, fetchTableData }: params) {
	const validationSchema = Yup.object().shape({
		trade_name: Yup.string().required('Campo obrigatório'),
		website: Yup.string().required('Campo obrigatório'),
		sub_sector_id: Yup.number().required('Campo obrigatório'),
		cnpj: Yup.string().nullable()
	});

	const [subSectorsOptions, setSubSectorsOptions] = useState<Selectable[]>([]);
	const [subSectorSelected, setSubSectorSelected] = useState<Selectable>();

	useEffect(() => {
		(async () => {
			const { data: subSectors } = await getSubSectors();

			const filteredOptions: Selectable[] = subSectors
				.filter(
					(obj: Selectable, index: number, self: Selectable[]) =>
						index === self.findIndex((o) => o.name === obj.name)
				)
				.map(({ id, name }) => ({ id, name, value: name }))
				.sort((a: Selectable, b: Selectable) => a.name.localeCompare(b.name));

			setSubSectorsOptions(filteredOptions);
		})();
	}, []);

	const formik = useFormik({
		initialValues: {
			trade_name: '',
			website: '',
			cnpj: '',
			sub_sector_id: 0
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			postInvestor(values)
				.then(() => {
					fetchTableData();
					setOpen(false);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	});

	return (
		<Modal
			title="Investidor"
			onClose={() => setOpen(false)}
			open={isOpen}
			overflow={true}
		>
			<form onSubmit={formik.handleSubmit}>
				<h2 className="mb-3 text-lg font-semibold text-brand-primary">Sub-setor:</h2>
				<SingleSelect
					placeholder="Sub-Setor"
					options={subSectorsOptions}
					value={subSectorSelected}
					onChange={(value) => {
						setSubSectorSelected(value);
						formik.setFieldValue('sub_sector_id', value.id);
					}}
				/>
				<h2 className="mb-3 text-lg font-semibold text-brand-primary">Nome da empresa:</h2>
				<Input
					onChange={formik.handleChange('trade_name')}
					error={Boolean(formik.errors.trade_name)}
					errorMessage={formik.errors.trade_name}
				/>
				<h2 className="mb-3 text-lg font-semibold text-brand-primary">Website:</h2>
				<Input
					onChange={formik.handleChange('website')}
					error={Boolean(formik.errors.website)}
					errorMessage={formik.errors.website}
				/>
				<h2 className="mb-3 text-lg font-semibold text-brand-primary">CNPJ:</h2>
				<Input
					value={formatCnpj(formik.values.cnpj)}
					onChange={({ target }) => formik.setFieldValue('cnpj', removeLetters(target.value))}
					error={Boolean(formik.errors.cnpj)}
					errorMessage={formik.errors.cnpj}
				/>

				<Button className="mt-3 mb-24" type="submit">
					Adicionar investidor
				</Button>
			</form>
		</Modal>
	);
}

export default InvestorModal;
