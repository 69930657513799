import ChatListBlock from "./ChatListBlock";
import { DocumentData, collection, query } from "firebase/firestore";
import { db } from "src/services/firebase";
import { FC } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useChatMessages } from "src/hooks/useChatMessages";

interface IChatList {
	selectedChatId: number | null;
	setSelectedChatId: (id: number) => void;
}

const ChatList: FC<IChatList> = ({ selectedChatId, setSelectedChatId }) => {
	const [chats, loading, error] = useCollectionData(query(collection(db, "chats")));
	const { unreadedChats } = useChatMessages();

	if (loading) {
		return <p>Loading...</p>;
	}
	if (error) {
		console.error(error);
		return <p>Error: {error.message}</p>;
	}

	if (chats?.length === 0) {
		return <p className="text-2xl">Nenhum chat encontrado</p>;
	}

	const handleSelectChat = (id: number) => {
		setSelectedChatId(id);
	};

	return (
		<ul className="flex h-full min-w-fit flex-col gap-2 overflow-y-auto rounded-md bg-brand-warning bg-opacity-10 py-2 px-4 text-[#000]">
			{chats &&
				chats.map((chat: DocumentData, idx) => {
					const { id } = chat;
					return unreadedChats.includes(id) ? (
						<span className="relative inline-block">
							<li key={idx} onClick={() => handleSelectChat(id)}>
								<ChatListBlock isSelected={selectedChatId === chat.id} chat={chat} />
							</li>
							<span className="absolute bottom-0 right-3 inline-block h-[0.75rem] w-[0.75rem] translate-x-1/2 -translate-y-1/2 transform rounded-full bg-red-600"></span>
						</span>
					) : (
						<li key={idx} onClick={() => handleSelectChat(id)}>
							<ChatListBlock isSelected={selectedChatId === chat.id} chat={chat} />
						</li>
					);
				})}
		</ul>
	);
};

export default ChatList;
