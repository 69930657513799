import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { FC, Fragment } from 'react';
import Loading from 'src/assets/icons/Loading';
import { Selectable } from '../../form/models';

interface SingleSelectProps {
	error?: boolean;
	errorMessage?: string | undefined;
	options: Selectable[];
	value: Selectable | undefined;
	onChange: any;
	bgColor?: string;
	placeholder?: string;
	isLoading?: boolean;
}

const SingleSelect: FC<SingleSelectProps> = ({
	error,
	errorMessage,
	options,
	value,
	onChange,
	placeholder = 'Selecione uma opção',
	bgColor = 'bg-[#F4F5F6]',
	isLoading = false
}) => {
	const handleDisplayValue = () => {
		const text =
			JSON.stringify(value) === JSON.stringify({}) || value?.id === null || String(value?.id) === ''
				? placeholder
				: value?.name;

		return (
			<Fragment>
				<div className="justify-betweeen mr-4 flex w-full items-center truncate">
					{text}
					{isLoading && <Loading size={6} />}
				</div>
				<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
					<ChevronDownIcon className="text-neutral-low h-5 w-5" aria-hidden="true" />
				</span>
			</Fragment>
		);
	};

	const optionIsSelected = (option: Selectable): boolean => {
		if (value) return value.id === option.id;
		return false;
	};
	return (
		<>
			<Listbox value={value} onChange={onChange}>
				<div className="relative mt-1">
					<Listbox.Button
						className={`flex h-12 items-center ${bgColor} focus:ring-indigo-500 focus:border-indigo-500 relative w-full cursor-default rounded-lg bg-auxiliar-grey-100 px-3 py-3 text-left shadow-sm focus:outline-none focus:ring-1 sm:text-sm`}
					>
						{handleDisplayValue()}
					</Listbox.Button>
					<Transition
						as={Fragment}
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Listbox.Options
							className={`absolute z-50 mt-1 w-full ${bgColor} ring-black max-h-60 overflow-auto rounded-md bg-auxiliar-grey-100 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm`}
						>
							{options.map((option, index) => (
								<Listbox.Option
									key={index}
									className={({ active }) =>
										`relative cursor-default select-none py-2 pl-10 pr-4 ${
											active
												? 'bg-brand-primary text-white'
												: optionIsSelected(option)
												? 'font-bold text-brand'
												: 'text-gray-900'
										}`
									}
									value={option}
								>
									{({ selected }) => (
										<>
											<span
												className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
											>
												{option.name}
											</span>
											{selected ? (
												<span className="text-amber-600 absolute inset-y-0 left-0 flex items-center pl-3">
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											) : null}
										</>
									)}
								</Listbox.Option>
							))}
						</Listbox.Options>
					</Transition>
				</div>
			</Listbox>
			{error && <span className="mb-2 text-brand-error">{errorMessage}</span>}
		</>
	);
};

export default SingleSelect;
