import { FC, useEffect, useRef, useState } from "react";
import { getFolderItems } from "src/services/sharepoint/getFolderItems";
import { FolderItem } from "src/domain/Sharepoint";
import Header from "src/layouts/Logged/components/Header";
import ItemCard from "./components/ItemCard";
import { getDocLibs } from "src/services/sharepoint/getDocLibs";
import LoadingIcon from "src/assets/icons/Loading";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import Button from "src/components/Button/Button";
import Modal from "src/components/Modal";
import { createFolder } from "src/services/sharepoint/createFolder";
import { createFile as sendFileToSharepoint } from "src/services/sharepoint/createFile";

const IgcSharepoint: FC = () => {
	const [folderItems, setFolderItems] = useState<FolderItem[] | null>(null);
	const [folderName, setFolderName] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [folderModalOpen, setFolderModalOpen] = useState<boolean>(false);
	const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
	const [createFolderName, setCreateFolderName] = useState<string>("");
	const [createFile, setCreateFile] = useState<File | null>(null);

	useEffect(() => {
		setIsLoading(true);

		folderName === ""
			? getDocLibs().then((response) => {
					setFolderItems(response.data);
					setIsLoading(false);
			  })
			: getFolderItems(folderName).then((response) => {
					setFolderItems(response.data);
					setIsLoading(false);
			  });
	}, [folderName]);

	function backFolder() {
		return setFolderName((oldName) => oldName.replace(/[^\/]+\/?$/gm, ""));
	}

	async function createSharepointFolder() {
		const folderNameFormatted = folderName.slice(-1) === "/" ? folderName.slice(0, -1) : folderName;

		await createFolder(`${folderNameFormatted}/${createFolderName}`);

		setIsLoading(true);

		getFolderItems(folderName).then((response) => {
			setFolderItems(response.data);
			setIsLoading(false);
		});

		setFolderModalOpen(false);
	}

	async function createSharepointFile() {
		if (!createFile) return;

		const formData = new FormData();

		formData.append("file", createFile);

		formData.append("folder", folderName);

		formData.append("file_name", createFile.name);

		await sendFileToSharepoint(formData);

		setIsLoading(true);

		getFolderItems(folderName).then((response) => {
			setFolderItems(response.data);
			setIsLoading(false);
		});

		setFileModalOpen(false);
	}

	return (
		<>
			<div className="grid grid-cols-[3fr_1fr] items-center">
				<Header title={`Sharepoint - /${folderName}`} />
				{folderName && (
					<div className="flex gap-4 pr-8">
						<Button onClick={() => setFolderModalOpen(true)}>Criar pasta</Button>
						<Button onClick={() => setFileModalOpen(true)}>Criar arquivo</Button>
					</div>
				)}
			</div>

			{folderName && (
				<ArrowNarrowLeftIcon
					onClick={backFolder}
					height={30}
					className="max-w-fit  cursor-pointer px-4 sm:px-6 md:px-8"
					color="#708939"
				/>
			)}

			{isLoading ? (
				<div className="px-4 pb-3 pt-6 sm:px-6 md:px-8">
					<LoadingIcon size={10} />
				</div>
			) : folderItems?.length === 0 ? (
				<strong className="px-4 pb-3 pt-6 text-lg sm:px-6 md:px-8">Não há documentos nessa pasta!</strong>
			) : (
				<section className="bg-white px-4 pb-3 pt-6 sm:px-6 md:px-8">
					{folderItems?.map((item) => (
						<ItemCard folderItem={item} setFolderName={setFolderName} folderName={folderName} />
					))}
				</section>
			)}

			<Modal
				title={`Criar uma pasta em /${folderName}`}
				onClose={() => setFolderModalOpen(false)}
				open={folderModalOpen}
				description="Insira o nome da pasta"
			>
				<input type="text" className="w-full" onChange={({ target }) => setCreateFolderName(target.value)} />
				<div className="mt-5 flex justify-end gap-5">
					<Button className="w-32" onClick={createSharepointFolder}>
						Criar
					</Button>
					<Button className="w-32" onClick={() => setFolderModalOpen(false)}>
						Cancelar
					</Button>
				</div>
			</Modal>

			<Modal
				title={`Criar um arquivo em /${folderName}`}
				onClose={() => setFileModalOpen(false)}
				open={fileModalOpen}
				description="Insira o arquivo"
			>
				<input
					type="file"
					className="w-full"
					onChange={({ target }) => setCreateFile(target.files && target.files[0])}
				/>
				<div className="mt-5 flex justify-end gap-5">
					<Button className="w-32" onClick={createSharepointFile}>
						Criar
					</Button>
					<Button className="w-32" onClick={() => setFileModalOpen(false)}>
						Cancelar
					</Button>
				</div>
			</Modal>
		</>
	);
};

export default IgcSharepoint;
