import { AsYouType, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { FC, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { removeLetters } from '../../../helpers';
import InputField from '../base/input';
import { Input } from './models';

const PhoneField: FC<Input> = ({ onChange, initialValue }) => {
	const [isValidValue, setIsValidValue] = useState(true);
	const [firstBlur] = useState(true);
	const [, setIsTyping] = useState(false);
	const [value] = useState<any>('');
	const [validValue, setValidValue] = useState<any>();
	const optional = true;

	useUpdateEffect(() => {
		if (validatePhone(value)) {
			const phone = parsePhoneNumber(value, 'BR');
			setValidValue(phone.number);
		} else {
			setValidValue(undefined);
		}
	}, [isValidValue, value]);

	useUpdateEffect(() => {
		onChange(validValue, isValidValue);
	}, [validValue]);

	const validatePhone = (newValue: string) => {
		setIsTyping(true);
		const targetValue = newValue;
		if (targetValue.length >= 3) {
			const pData = parsePhoneNumber(newValue, 'BR');

			const isValid = isValidPhoneNumber(pData.number);

			if (!firstBlur) {
				setIsValidValue(isValid);
			}

			return isValid;
		} else {
			return targetValue === '' && optional === true;
		}
	};

	const asYouTypeFormat = (phone: string) => {
		return new AsYouType('BR').input(phone).substring('+55 '.length);
	};

	const maskPhone = (newValue: string) => {
		newValue = removeLetters(newValue);
		if (newValue.length > 3) {
			const pData = parsePhoneNumber(newValue, 'BR');
			//let displayNumber = asYouTypeFormat(pData.number);

			const displayNumber = pData.isValid()
				? pData.formatNational()
				: asYouTypeFormat(pData.number);
			return displayNumber;
		} else {
			return newValue;
		}
	};

	return (
		<>
			<InputField
				initialValue={initialValue}
				fieldLabel="Celular (Opcional)"
				type="text"
				id="phone"
				placeholder="Insira um telefone com DDD (Opcional)"
				errorText="Verifique se o telefone foi digitado corretamente"
				validateValue={validatePhone}
				onChange={onChange}
				optional={optional}
				useMask={true}
				mask={maskPhone}
			/>
		</>
	);
};

export default PhoneField;
