import { TrashIcon } from "@heroicons/react/outline";
import moment from "moment";
import "moment/locale/pt-br";
import { FC, useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import Button from "src/components/Button/Button";
import Input from "src/pages/ExtendedRegister/components/custom-components/Input";
import InputDate from "src/components/InputDate";
import { Selectable } from "src/components/ui/form/models";
import SingleSelect from "src/components/ui/form/Selects/SingleSelect";
import { Step } from "../../configs/models";
import { BusinessLease } from "src/domain/Register";

const options: Selectable[] = [
	{ id: 1, name: "Próprios", value: false },
	{ id: 2, name: "Alugados", value: true }
];

const inititalData: BusinessLease = {
	name: "",
	valid_until: moment(new Date()).format("yyyy-MM-DD")
};

const CompanyLeases: FC<Step> = ({
	onSubmit,
	getStepData,
	maxInputs,
	loadingStep,
	control,
	register,
	errors,
	setValue,
	watch
}) => {
	const [selected, setSelected] = useState<Selectable>(
		getStepData("business_leases").length > 0 ? options[1] : options[0]
	);

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "business_leases"
	});

	return (
		<>
			<div className="mb-6">
				<SingleSelect
					options={options}
					onChange={(e) => {
						setValue("is_rented", e.value, { shouldDirty: true });
						setSelected(e);

						if (!e.value) return setValue("business_leases", [], { shouldDirty: true });
						if (!selected.value && e.value) return append(inititalData);
					}}
					value={selected}
				/>
			</div>
			<ul className="w-full ">
				{selected.value && (
					<>
						<h3 className={`text-neutral-low mb-4 text-left text-2xl font-bold`}>
							Liste abaixo os imóveis e o prazo de validade dos contratos:
						</h3>
						{fields.map((field, index) => (
							<li className="mb-3 flex w-full" key={index}>
								<div className="mr-3 w-[63%]">
									<Input
										type="text"
										{...field}
										{...register(`business_leases.${index}.name`)}
										fieldError={errors.business_leases?.[index]?.name}
									/>
								</div>
								<div className="mr-1 w-[30%]">
									<Controller
										control={control}
										name={`business_leases.${index}.valid_until`}
										render={({ field }) => (
											<InputDate
												{...field}
												type="text"
												value={`${field.value.split("-")[1]}/${field.value.split("-")[0]}`}
												onChange={({ target }) => {
													const [month, year] = target.value.split("/");

													setValue(`business_leases.${index}.valid_until`, `${year}-${month}-01`, {
														shouldDirty: true
													});
												}}
												fieldError={errors.business_leases?.[index]?.valid_until}
											/>
										)}
									/>
								</div>
								<div className={`flex w-6 items-center ${index === 0 && "hidden"}`}>
									<TrashIcon
										className="cursor-pointer"
										onClick={() => remove(index)}
										color="#bb7127"
									/>
								</div>
							</li>
						))}
					</>
				)}
			</ul>
			<div className="flex w-full">
				{selected.value && (
					<>
						<Button
							type="button"
							className="mr-3"
							color="outline-secondary"
							size="large"
							disabled={maxInputs === fields.length}
							onClick={() => append(inititalData)}
						>
							Nova Entrada
						</Button>
					</>
				)}
				<Button
					loading={loadingStep}
					disabled={Boolean(errors.business_leases)}
					onClick={(e) => {
						e.preventDefault();

						onSubmit({ business_leases: selected.value ? getStepData("business_leases") : [] });
					}}
					size="large"
				>
					Próximo
				</Button>
			</div>
		</>
	);
};

export default CompanyLeases;
