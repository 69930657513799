import { Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { TrashIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import Button from 'src/components/Button/Button';
import Input from 'src/components/Input';
import { Selectable } from 'src/components/ui/form/models';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import { removeLetters } from 'src/helpers';
import { formatMoney } from 'src/helpers/formatMoney';
import * as Yup from 'yup';
interface CompanyAntecipateNoClientsProps {
	handleNext: Function;
	getStepData: Function;
	maxInputs: number;
	loadingStep: boolean;
}

type Client = {
	yearStartContract: number;
	yearEndContract: number;
	value: number;
	correctionIndex: string;
	correctionRate: string;
	modality: number;
	otherModality: string;
};

type MainClient = {
	mainClients: Array<Client>;
};

const currentYear = new Date().getFullYear();

const CompanyAntecipateNoClients: FC<CompanyAntecipateNoClientsProps> = ({
	handleNext,
	getStepData,
	loadingStep
}) => {
	const validationSchema = Yup.object().shape({
		mainClients: Yup.array()
			.of(
				Yup.object().shape({
					yearStartContract: Yup.number().required('Campo obrigatório'),
					yearEndContract: Yup.number().required('Campo obrigatório'),
					value: Yup.string().required('Campo obrigatório'),
					correctionIndex: Yup.string().required('Campo obrigatório'),
					correctionRate: Yup.string().required('Campo obrigatório'),
					modality: Yup.number().required('Campo obrigatório'),
					otherModality: Yup.string()
						.nullable()
						.when('modality', {
							is: (value) => {
								return value === 4;
							},
							then: Yup.string().required('Campo obrigatório').nullable()
						})
				})
			)
			.required('Campo obrigatório')
	});

	const formik = useFormik<MainClient>({
		initialValues: {
			mainClients: getStepData('antecipate_no_clients')
		},
		validationSchema: validationSchema,
		validateOnMount: true,
		enableReinitialize: true,
		onSubmit: (values) => {
			values.mainClients.forEach((value) => {
				value.value = parseInt(removeLetters(value.value.toString()));
			});
			handleNext({
				antecipate_no_clients: values.mainClients
			});
		}
	});

	const initialData: Client = {
		yearEndContract: currentYear,
		yearStartContract: currentYear,
		correctionIndex: '-',
		correctionRate: '-',
		modality: 1,
		otherModality: '',
		value: 0
	};

	const options: Array<Selectable> = [
		{
			id: 1,
			name: 'Aluguel',
			value: 1
		},
		{
			id: 2,
			name: 'Prestação de serviço',
			value: 2
		},
		{
			id: 3,
			name: 'Fornecimento de produtos',
			value: 3
		},
		{
			id: 4,
			name: 'Outros',
			value: 4
		}
	];

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit} className="w-full">
				<FieldArray
					name="mainClients"
					render={(arrayHelpers) => (
						<div>
							{formik.values.mainClients && (
								<ul className="w-full ">
									{formik.values.mainClients.length > 0 &&
										formik.values.mainClients.map((_, index) => {
											return (
												<li
													className="flex flex-col w-full mb-12 px-5 pt-2 border rounded-md"
													key={index}
													style={{ borderColor: '#CCC' }}
												>
													<div className="flex justify-between mb-2">
														<div className="flex w-[54%]">
															<span className="w-2/3 flex items-center pb-2">
																Ano de início do contrato
															</span>
															<div className="w-1/3">
																<Field name={`mainClients_${index}.yearStartContract`}>
																	{({ field, form }) => (
																		<Input
																			type="number"
																			placeholder="Ano de início do contrato"
																			{...field}
																			value={form.values.mainClients[index].yearStartContract}
																			onChange={form.handleChange(
																				`mainClients[${index}].yearStartContract`
																			)}
																			error={Boolean(
																				form.errors.mainClients &&
																					form.errors.mainClients[index] &&
																					form.errors.mainClients[index].yearStartContract
																			)}
																			errorMessage={
																				form.errors.mainClients && form.errors.mainClients[index]
																					? form.errors.mainClients[index].yearStartContract
																					: ''
																			}
																		/>
																	)}
																</Field>
															</div>
														</div>
														<div className="flex w-[36%]">
															<span className="w-2/3 flex items-center pb-2">
																Índice de correção
															</span>
															<div className="w-1/3">
																<Field name={`mainClients_${index}.correctionIndex`}>
																	{({ field, form }) => (
																		<div>
																			<Input
																				type="string"
																				{...field}
																				placeholder="Índice de correção"
																				value={form.values.mainClients[index].correctionIndex}
																				onChange={(e) => {
																					form.setFieldValue(
																						`mainClients[${index}].correctionIndex`,
																						e.target.value
																					);
																				}}
																				error={Boolean(
																					form.errors.mainClients &&
																						form.errors.mainClients[index] &&
																						form.errors.mainClients[index].correctionIndex
																				)}
																				errorMessage={
																					form.errors.mainClients && form.errors.mainClients[index]
																						? form.errors.mainClients[index].correctionIndex
																						: ''
																				}
																			/>
																		</div>
																	)}
																</Field>
															</div>
														</div>
													</div>
													<div className="flex justify-between mb-2">
														<div className="flex w-[54%]">
															<span className="w-2/3 flex items-center pb-2">
																Ano de fim do contrato
															</span>
															<div className="w-1/3">
																<Field name={`mainClients_${index}.value`}>
																	{({ field, form }) => (
																		<div>
																			<Input
																				type="number"
																				{...field}
																				placeholder="Ano de fim do contrato"
																				value={form.values.mainClients[index].yearEndContract}
																				onChange={(e) => {
																					form.setFieldValue(
																						`mainClients[${index}].yearEndContract`,
																						e.target.value
																					);
																				}}
																				error={Boolean(
																					form.errors.mainClients &&
																						form.errors.mainClients[index] &&
																						form.errors.mainClients[index].yearEndContract
																				)}
																				errorMessage={
																					form.errors.mainClients && form.errors.mainClients[index]
																						? form.errors.mainClients[index].yearEndContract
																						: ''
																				}
																			/>
																		</div>
																	)}
																</Field>
															</div>
														</div>
														<div className="flex w-[36%]">
															<span className="w-2/3 flex items-center pb-2">Taxa de correção</span>
															<div className="w-1/3">
																<Field name={`mainClients_${index}.correctionRate`}>
																	{({ field, form }) => (
																		<div>
																			<Input
																				type="string"
																				{...field}
																				placeholder="Taxa de correção"
																				value={form.values.mainClients[index].correctionRate}
																				onChange={(e) => {
																					form.setFieldValue(
																						`mainClients[${index}].correctionRate`,
																						e.target.value
																					);
																				}}
																				error={Boolean(
																					form.errors.mainClients &&
																						form.errors.mainClients[index] &&
																						form.errors.mainClients[index].correctionRate
																				)}
																				errorMessage={
																					form.errors.mainClients && form.errors.mainClients[index]
																						? form.errors.mainClients[index].correctionRate
																						: ''
																				}
																			/>
																		</div>
																	)}
																</Field>
															</div>
														</div>
													</div>
													<div className="flex justify-between mb-2">
														<div className="flex w-[54%]">
															<span className="w-2/3 flex items-center pb-2">
																Valor anual de recebíveis
															</span>
															<div className="w-1/3">
																<Field name={`mainClients_${index}.value`}>
																	{({ field, form }) => (
																		<div>
																			<Input
																				type="text"
																				{...field}
																				placeholder="Valor"
																				value={form.values.mainClients[index].value}
																				onChange={(e) => {
																					form.setFieldValue(
																						`mainClients[${index}].value`,
																						formatMoney(e.target.value)
																					);
																				}}
																				error={Boolean(
																					form.errors.mainClients &&
																						form.errors.mainClients[index] &&
																						form.errors.mainClients[index].value
																				)}
																				errorMessage={
																					form.errors.mainClients && form.errors.mainClients[index]
																						? form.errors.mainClients[index].value
																						: ''
																				}
																			/>
																		</div>
																	)}
																</Field>
															</div>
														</div>
														<div className="flex w-[36%] justify-between">
															<span className="flex items-center">Modalidade</span>
															<div>
																<Field name={`mainClients_${index}.correctionRate`}>
																	{({ field, form }) => (
																		<div style={{ width: 160 }}>
																			<SingleSelect
																				options={options}
																				onChange={(e) => {
																					formik.setFieldValue(
																						`mainClients[${index}].modality`,
																						e.value
																					);
																				}}
																				value={options.find(
																					(x) =>
																						x.value === formik.values.mainClients[index].modality
																				)}
																				error={Boolean(
																					form.errors.mainClients &&
																						form.errors.mainClients[index] &&
																						form.errors.mainClients[index].modality
																				)}
																				errorMessage={
																					form.errors.mainClients && form.errors.mainClients[index]
																						? form.errors.mainClients[index].modality
																						: ''
																				}
																			/>
																		</div>
																	)}
																</Field>
															</div>
														</div>
													</div>
													{formik.values.mainClients[index].modality === 4 && (
														<div>
															<Field name={`mainClients_${index}.otherModality`}>
																{({ field, form }) => (
																	<div>
																		<Input
																			type="string"
																			{...field}
																			placeholder="Outros..."
																			value={form.values.mainClients[index].otherModality}
																			onChange={(e) => {
																				form.setFieldValue(
																					`mainClients[${index}].otherModality`,
																					e.target.value
																				);
																			}}
																			error={Boolean(
																				form.errors.mainClients &&
																					form.errors.mainClients[index] &&
																					form.errors.mainClients[index].otherModality
																			)}
																			errorMessage={
																				form.errors.mainClients && form.errors.mainClients[index]
																					? form.errors.mainClients[index].otherModality
																					: ''
																			}
																		/>
																	</div>
																)}
															</Field>
														</div>
													)}

													<div className={`w-[5%] ${index === 0 && 'hidden'}`}>
														<Button
															type="button"
															size="small"
															className="rounded-none border-0 px-0 w-auto"
															color="outline-secondary"
															onClick={() => {
																arrayHelpers.remove(index);
															}}
														>
															<TrashIcon />
														</Button>
													</div>
												</li>
											);
										})}
								</ul>
							)}
							<div className="flex w-full">
								<Button
									type="button"
									className={`mr-3`}
									color="outline-secondary"
									size="large"
									onClick={() => {
										arrayHelpers.push(initialData);
									}}
								>
									Nova Entrada
								</Button>
								<Button loading={loadingStep} disabled={!formik.isValid} type="submit" size="large">
									Próximo
								</Button>
							</div>
						</div>
					)}
				/>
			</form>
		</FormikProvider>
	);
};

export default CompanyAntecipateNoClients;
