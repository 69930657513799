import { FC, useState, useEffect } from 'react';
import Select from '../../base/select';
import { Input, Selectable } from '../models';

const UFSelect: FC<Input> = ({ onChange }) => {
	const [ufs, setUfs] = useState<Selectable[]>([]);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL ?? 'http://localhost:8000'}/business/states/`)
			.then((res) => res.json())
			.then((json) => {
				setUfs(json);
			});
	}, []);

	const handleChange = (selected: Selectable[]) => {
		onChange(selected[0].id);
	};

	return (
		<>
			<Select
				fieldLabel="Estado"
				selectableValues={ufs}
				singleSelect={true}
				onChange={handleChange}
			/>
		</>
	);
};

export default UFSelect;
