import { InputHTMLAttributes, Ref, forwardRef, useCallback } from "react";
import { cep, cpf, currency, percentage } from "./masks";
import { phoneMask } from "src/pages/Business/Dashboard/components/Utils";
import { FieldError } from "react-hook-form";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	fieldError: FieldError | undefined;
	sufix?: string | React.ReactElement;
	prefixIcon?: string | React.ReactElement;
	bgColor?: string;
	mask?: "percentage" | "cep" | "currency" | "cpf" | "phone";
}

const Input = forwardRef(
	(
		{ fieldError, sufix, mask, prefixIcon, bgColor = "bg-[#F4F5F6]", ...otherProps }: InputProps,
		ref: Ref<HTMLInputElement>
	) => {
		const handleKeyUp = useCallback(
			(e: React.FormEvent<HTMLInputElement>) => {
				if (mask === "cep") {
					cep(e);
				}
				if (mask === "currency") {
					currency(e);
				}
				if (mask === "cpf") {
					cpf(e);
				}
				if (mask === "percentage") {
					percentage(e);
				}
				if (mask === "phone") {
					e.currentTarget.value = phoneMask(e.currentTarget.value);
				}
			},
			[mask]
		);
		return (
			<>
				<div className="relative">
					{sufix && (
						<span className="absolute bottom-[0.80rem] top-0 right-3 flex items-center pt-1">
							{sufix}
						</span>
					)}
					{prefixIcon && (
						<span className="text-neutral-medium absolute bottom-[0.80rem] top-0 left-3 flex items-center pt-1">
							{prefixIcon}
						</span>
					)}
					<input
						className={`mb-2 w-full py-3 px-3 ${bgColor} resize-none rounded ${
							Boolean(fieldError)
								? "border border-brand-error focus:border-brand-error focus:ring-brand-error active:border-brand-error "
								: "border-0"
						}
						${prefixIcon && "pl-12"}
						`}
						onKeyUp={handleKeyUp}
						ref={ref}
						{...otherProps}
					/>
				</div>
				{Boolean(fieldError) ? (
					<span className="mb-2 text-brand-error">{fieldError?.message}</span>
				) : (
					""
				)}
			</>
		);
	}
);

export default Input;
