import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BusinessDefaultSteps, BusinessStageType } from "src/domain/BusinessStep";
import { toastNotify } from "src/helpers/toastNotify";
import { useAuth } from "src/hooks/useAuth";
import { getBusinessStep } from "src/services/business";
import DashboardImage from "../../../assets/images/dashboard.png";
import Button from "../../../components/Button/Button";
import Page from "../../../layouts/Logged/components/Page";
import Documents from "./components/Documents";
import FAQ from "./components/FAQ/FAQ";
import MASection from "./components/MA";
import Notifications from "./components/Notifications";
import Steps from "./components/Steps";
import { maVideoProps, maVideoSections } from "./content";
import { createUser, updateUser, userExists } from "src/services/firebase";

export interface BusinessDashboardProps {}

const BusinessDashboardPage: FC = () => {
	const { user, getOrUpdateUserInfo } = useAuth();
	const navigate = useNavigate();

	const [selectedVideo, setSelectedVideo] = useState<maVideoProps>(maVideoSections[0].videos[0]);
	const [businessSteps, setBusinessSteps] = useState<BusinessStageType[]>();

	const fetchBusinessStep = async () => {
		try {
			const response = await getBusinessStep(Number(user?.business_id));
			const currentStep = response.data;
			const currentStepIdx = BusinessDefaultSteps.findIndex((step) => step.name === currentStep);

			const mappedSteps = BusinessDefaultSteps.map((step, idx) => {
				if (currentStepIdx === BusinessDefaultSteps.length - 1 || idx < currentStepIdx) {
					return {
						...step,
						complete: true
					};
				} else if (idx === currentStepIdx) {
					return {
						...step,
						current: true
					};
				} else {
					return {
						...step
					};
				}
			});

			setBusinessSteps(mappedSteps);
		} catch (error) {
			toastNotify("Erro ao buscar etapas do negócio", "error");
		}
	};

	const handleUserFirebase = async () => {
		const userId = user?.business_id!;
		const userFullName = user?.full_name;
		const company = user?.business_trade_name;

		if (!userId || !userFullName || !company) return;

		const userAlreadyExists = await userExists(userId);

		if (userAlreadyExists) {
			return updateUser("users", user.business_id, "last_login");
		}

		await createUser(userId, userFullName, company);

		return;
	};

	useEffect(() => {
		getOrUpdateUserInfo(user?.role);
		fetchBusinessStep();
		handleUserFirebase();
	}, []);

	return (
		<>
			<Page>
				{!user?.register_status && (
					<div
						className="bg-rounded relative h-fit min-h-[30vh] min-w-[50vw] rounded-2xl bg-cover bg-no-repeat p-2 sm:mt-10 sm:p-10"
						style={{
							backgroundColor: "#FFF",
							backgroundImage: `url(${DashboardImage})`
						}}
					>
						<div className="m-auto rounded-md p-2">
							<span className="text- flex px-1.5 text-lg font-medium text-white sm:text-xl sm:font-semibold">
								Complete suas informações para uma análise detalhada antes de uma primeira reunião
								entre o time da overA e você.
							</span>
							<Button
								hidden={true}
								onClick={() => navigate("/business/complete-seu-cadastro")}
								size="medium"
								type="button"
								color="secondary"
								className="absolute bottom-10 right-10"
							>
								Complete o seu cadastro
							</Button>
						</div>
					</div>
				)}
				<div className="flex h-full w-full flex-col items-center justify-start">
					{/* Steps */}
					<Steps businessSteps={businessSteps} />
					{/* Main Grid */}
					<div className="flex w-full flex-col gap-2 xl:max-h-[70vh] xl:flex-row">
						{/* M&A */}
						<MASection
							selectedVideo={selectedVideo}
							maVideoSections={maVideoSections}
							setSelectedVideo={setSelectedVideo}
						/>
						<div className="flex w-full flex-col lg:flex-row">
							<div className="flex w-full flex-col gap-2">
								{/* Documentos */}
								<Documents />
								{/* Avisos */}
								<Notifications />
							</div>
						</div>
					</div>

					{/* Faq + Contato */}
					<FAQ />
				</div>
			</Page>
		</>
	);
};

export default BusinessDashboardPage;
