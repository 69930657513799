import { FC, FormEvent, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import OverA from 'src/assets/images/over_a_marca_01.png';
import Button from 'src/components/Button/Button';
import { toastNotify } from 'src/helpers/toastNotify';
import PasswordField from '../../../components/ui/form/PasswordField';
import { setNewPassword } from 'src/services/recovery/setPassword';
import Logo from '../images/Logo.png';
import { useParams } from 'react-router-dom';
import history from '../../../services/history';

export interface SetPasswordProps {}

const SetPassword: FC<SetPasswordProps> = () => {
	const [password, setPassword] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
    const {token} = useParams()
	const role = 'igc';

	const handlesetNewPassword = (event: FormEvent) => {
		event.preventDefault();
		setNewPassword(password, String(token)).then(()=>{
			history.push(`/${role}/login`);
			toastNotify("Senha redefinida com sucesso!", "success")
		}).catch((error)=>{
			console.log(error);
			toastNotify(error.response.data.user_message , 'error');
		})
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const isDisabled = (): boolean | undefined => {
		return !password;
	};
	return (
		<div>
			<form onSubmit={handlesetNewPassword} className=" flex flex-col">
				<div className=" flex justify-center items-center mb-12">
					<img className="text-center" height={45} width={184} src={OverA} alt="overa" />
				</div>
				<p className="font-bold mb-6 text-2xl text-neutral-low">Escolha uma nova senha</p>
				<div className="block mb-8">
					<PasswordField onChange={(val: any) => setPassword(val)} showRules={true} />
				</div>
				<div>
					<Button type="submit" color="secondary" loading={loading} disabled={isDisabled()}>
						Redefinir Senha
					</Button>
				</div>
				<div className="flex justify-center items-center mt-20">
					{' '}
					<img src={Logo} alt="man on the phone" />
				</div>
			</form>
		</div>
	);
};

export default SetPassword;
