import client from 'src/services/client';

export const postContact = (contact: ContactPostDTO) => client.post(`igc/contact/create/`, contact);

export type ContactPostDTO = {
	contact_name: string;
	contact_email: string;
	contact_phone: string;
	last_contact_date: string | null;
	investor_id: number | null;
};

export type ContactResponseDTO = {
	contact_id: number;
};

export type ContactDataDTO = {
	id: number;
	contact_name: string;
	contact_email: string;
	contact_phone: string;
	last_contact_date: string | null;
	investor: number | null;
};
