import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { FC, Fragment, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { Selectable } from '../form/models';

const Select: FC<{
	fieldLabel?: string;
	selectableValues: Selectable[];
	singleSelect: boolean;
	onChange: any;
	selectedValueInput?: any;
	initialValue?: Selectable[];
}> = ({
	fieldLabel,
	selectableValues,
	singleSelect,
	onChange,
	selectedValueInput,
	initialValue = []
}) => {
	const [selectedValue, setSelectedValue] = useState<Selectable[]>(initialValue);

	useUpdateEffect(() => {
		onChange(selectedValue);
	}, [selectedValue]);

	useUpdateEffect(() => {
		setSelectedValue(selectedValueInput);
	}, [selectedValueInput]);

	const handleChange = (selectedValue: any) => {
		singleSelect ? handleSingleSelect(selectedValue) : handleMultiSelect(selectedValue);
	};

	const handleSingleSelect = (val: Selectable) => {
		setSelectedValue([val]);
	};

	const handleMultiSelect = (val: Selectable) => {
		if (selectedValue.find((s) => s.id === val.id)) {
			let currentSelectedValues = selectedValue.filter((s) => s.id !== val.id);
			setSelectedValue(currentSelectedValues);
		} else {
			setSelectedValue([...selectedValue, val]);
		}
	};

	const optionIsSelected = (option: Selectable) => {
		return selectedValue.find((item) => item.id === option.id);
	};

	const countSelected = () => {
		return (
			<>
				<div className="flex justify-center items-center text-xs font-bold text-brand-primary rounded-full bg-brand-secondary ml-4 h-6 w-6">
					+{selectedValue.length - 1}
				</div>
			</>
		);
	};

	const handleDisplayValue = () => {
		return (
			<>
				<div className="flex justify-betweeen items-center w-full truncate whitespace-normal">
					{selectedValue.length >= 1 ? selectedValue[0]?.name : 'Selecione uma opção'}{' '}
					{!singleSelect && selectedValue.length > 1 && countSelected()}
				</div>
				<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
					<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
				</span>
			</>
		);
	};

	return (
		<Listbox value={selectedValue} onChange={(e) => handleChange(e)}>
			{({ open }) => (
				<>
					{fieldLabel && (
						<Listbox.Label className="block text-sm font-medium text-gray-700">
							{fieldLabel}
						</Listbox.Label>
					)}
					<div className="mt-1 relative">
						<Listbox.Button className="flex items-center h-14 bg-[#F4F5F6] relative w-full rounded-lg shadow-sm px-4 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
							{handleDisplayValue()}
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-10 mt-1 w-full bg-[#F4F5F6] shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
								{selectableValues.map((selectable) => (
									<Listbox.Option
										key={selectable.id}
										className={({ active }) =>
											`cursor-default select-none relative py-2 pl-8 pr-4 ${
												active
													? 'bg-auxiliar-grey-300'
													: optionIsSelected(selectable)
													? 'text-white bg-brand-primary'
													: 'text-gray-900'
											}`
										}
										value={selectable}
									>
										{({ active }) => (
											<>
												<span
													className={`block truncate ${
														optionIsSelected(selectable) ? 'font-semibold' : 'font-normal'
													} whitespace-normal`}
												>
													{selectable.name}
												</span>
												{optionIsSelected(selectable) ? (
													<span
														className={`absolute inset-y-0 right-6 flex items-center pl-1.5 ${
															active ? 'text-white' : 'text-indigo-600'
														}`}
													>
														<CheckIcon className="h-5 w-5" aria-hidden="true" />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
};

export default Select;
