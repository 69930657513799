import { useEffect, useState } from 'react';
import { DocumentData } from 'firebase/firestore';
import { FC } from 'react';
import { getUserObject } from 'src/services/firebase';

interface IChatListBlock {
	isSelected: boolean;
	chat: DocumentData;
	onClick?: () => void;
}

const ChatListBlock: FC<IChatListBlock> = ({ isSelected, chat }) => {
	const memberId = chat.membersIds[0];
	const [firebaseUser, setFirebaseUser] = useState<DocumentData | null>(null);

	const fetchFirebaseUser = async () => {
		const user = await getUserObject(memberId);
		if (!user) return null;
		setFirebaseUser(user);
	};

	useEffect(() => {
		fetchFirebaseUser();
	}, []);

	if (!firebaseUser) return null;

	return (
		<div
			className={`py-2 px-4 rounded-md shadow-md bg-opacity-90 min-w-fit
        hover:bg-brand-warning hover:text-white cursor-pointer transition-colors transition-100
        ${isSelected ? 'bg-brand-primaryMedium' : 'bg-[#fdfdfd]'}
        ${isSelected ? 'text-white' : 'text-brand-secondary'}
      `}
		>
			<div className={`text-base whitespace-nowrap`}>{firebaseUser.name}</div>
			<div className={`text-sm font-semibold relative`}>{firebaseUser.company}</div>
		</div>
	);
};

export default ChatListBlock;
