import { DownloadIcon, PencilAltIcon } from '@heroicons/react/outline';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'src/assets/icons/Loading';
import Button from 'src/components/Button/Button';
import FileList from 'src/components/FileList';
import Modal from 'src/components/Modal';
import Tag from 'src/components/Tag';
import { Selectable } from 'src/components/ui/form/models';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import Upload from 'src/components/Upload';
import { useFiles } from 'src/contexts/files';
import { Document } from 'src/domain/Document';
import { toastNotify } from 'src/helpers/toastNotify';
import { useAuth } from 'src/hooks/useAuth';
import Page from 'src/layouts/Logged/components/Page';
import { changeDocumentStatus, ChangeStatus } from 'src/services/document/changeDocumentStatus';
import { getDocumentsByCompanyId } from 'src/services/document/getDocumentsByCompanyId';
import { mapDocumentFileType } from 'src/services/Utils';
import * as Yup from 'yup';

interface DocumentPageProps {}

const DocumentPage: FC<DocumentPageProps> = () => {
	const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isOpenChangeStatus, setIsOpenChangeStatus] = useState<boolean>(false);
	const [filter, setFilter] = useState<Selectable>({
		id: 4,
		slug: 'todos',
		name: 'Todos',
		value: ''
	});
	const [statusSelected, setStatusSelected] = useState<Selectable>({} as Selectable);
	const [documents, setDocuments] = useState<Array<Document>>();
	const [originalDocuments, setOriginalDocuments] = useState<Array<Document>>();
	const { user } = useAuth();
	const { id } = useParams();
	const { handleUploadFiles, uploadLoading, render, uploadedFiles, clearUploadedFiles } =
		useFiles();
	const [renderChangeStatus, setRenderChangeStatus] = useState<boolean>(false);

	const options: Array<Selectable> = [
		{
			id: 1,
			slug: 'denied',
			name: 'Negado',
			value: 'denied'
		},
		{
			id: 2,
			slug: 'pending',
			name: 'Aguardando aprovação',
			value: 'pending'
		},
		{
			id: 3,
			slug: 'approved',
			name: 'Aprovado',
			value: 'approved'
		},
		{
			id: 4,
			slug: 'todos',
			name: 'Todos',
			value: ''
		}
	];

	const optionsStatusChange = [
		{
			id: 1,
			slug: 'denied',
			name: 'Negado',
			value: 1
		},
		{
			id: 2,
			slug: 'pending',
			name: 'Aguardando aprovação',
			value: 2
		},
		{
			id: 3,
			slug: 'approved',
			name: 'Aprovado',
			value: 3
		}
	];

	useEffect(() => {
		getDocumentsByCompanyId(String(user?.role), Number(id))
			.then((response) => {
				setDocuments(response.data);
				setOriginalDocuments(response.data);
			})
			.finally(() => {
				setLoadingDocuments(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [render, renderChangeStatus]);
	const handleFiltering = (filter: Selectable) => {
		setFilter(filter);
		setLoadingDocuments(true);
		setDocuments(searchTable(filter.value));
	};

	const searchTable = (status: string) => {
		setTimeout(() => {
			setLoadingDocuments(false);
		}, 500);
		if (!status) {
			return originalDocuments;
		}
		if (originalDocuments) {
			return originalDocuments.filter((document) => document.status === status);
		}
	};

	const handleUpload = () => {
		handleUploadFiles('igc/upload_business_file/');
	};

	const notHaveFileToUpload = (): boolean => {
		let response = true;
		if (uploadedFiles.length === 0) return true;
		uploadedFiles.forEach((file) => {
			if (!file.uploaded) response = false;
		});
		return response;
	};

	const handleCloseModal = () => {
		clearUploadedFiles();
		setIsOpen(false);
	};

	const validationSchema = Yup.object().shape({
		status_id: Yup.string().required('Campo obrigatório')
	});
	const formik = useFormik<ChangeStatus>({
		initialValues: {
			file_id: 0,
			status_id: null
		},
		validationSchema: validationSchema,
		validateOnMount: true,
		enableReinitialize: true,
		onSubmit: (values, { resetForm }) => {
			changeDocumentStatus(String(user?.role), values)
				.then((response) => {
					toastNotify(response.data.user_message, 'success');
					setRenderChangeStatus(!renderChangeStatus);
				})
				.finally(() => {
					resetForm();
					setIsOpenChangeStatus(false);
				});
		}
	});

	const handleOpenModalChange = (fileId: number, status: string) => {
		formik.setFieldValue('file_id', fileId);
		const result = optionsStatusChange.filter((ob) => ob.slug === status);
		setStatusSelected(result[0]);
		setIsOpenChangeStatus(true);
	};

	const handleSelect = (field: string, item: Selectable) => {
		formik.setFieldValue(field, item.value);
		setStatusSelected(item);
	};

	const handleCancel = () => {
		setIsOpenChangeStatus(false);
		formik.resetForm();
	};

	return (
		<>
			<Page>
				<div className="mb-6 flex items-center justify-between">
					<h1 className="text-2xl font-bold text-brand-primary">Documentos</h1>
					<Button onClick={() => setIsOpen(true)} size="large">
						Adicionar documento
					</Button>
				</div>
				<div className="mb-6 w-full sm:w-96">
					<SingleSelect
						bgColor="bg-neutral-high"
						options={options}
						value={filter}
						onChange={(e: Selectable) => handleFiltering(e)}
					/>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="w-full">
						<thead className="">
							<tr>
								<th
									scope="col"
									className="text-neutral-low py-3 pr-6 text-left text-sm font-medium"
								>
									Documento
								</th>
								<th
									scope="col"
									className="text-neutral-low py-3 pr-6 text-left text-sm font-medium"
								>
									Data de envio
								</th>
								<th
									scope="col"
									className="text-neutral-low py-3 pr-6 text-left text-sm font-medium"
								>
									Tipo
								</th>
								<th
									scope="col"
									className="text-neutral-low py-3 pr-6 text-left text-sm font-medium"
								>
									Enviado por
								</th>
								<th
									scope="col"
									className="text-neutral-low py-3 pr-6 text-left text-sm font-medium"
								>
									Status
								</th>
								<th scope="col" className="text-neutral-low relative px-6 py-3 text-sm font-medium">
									Ações
								</th>
							</tr>
						</thead>
						<tbody className=" divide-neutral-medium divide-y divide-opacity-50">
							{documents && documents.length === 0 && !loadingDocuments ? (
								<tr className="text-neutral-medium text-center text-2xl font-bold">
									<td className="whitespace-nowrap py-4 pr-6" colSpan={6}>
										Nenhum documento encontrado
									</td>
								</tr>
							) : loadingDocuments ? (
								<tr className="text-neutral-medium text-center text-2xl font-bold">
									<td className="whitespace-nowrap py-4 pr-6" colSpan={6}>
										<Loading color="primary" size={12} />
									</td>
								</tr>
							) : (
								documents?.map((document, idx) => (
									<>
										<tr key={`${document.file_id}${idx}`}>
											<td className="whitespace-nowrap py-4 pr-6">
												<div className="flex items-center justify-start">
													<div className="text-neutral-medium text-left text-sm font-normal">
														{document.file_name}
													</div>
												</div>
											</td>
											<td className="whitespace-nowrap py-4 pr-6">
												<div className="text-neutral-medium text-left text-sm font-normal">
													{moment(document.created_at).locale('pt-br').format('DD MMM YYYY')}
												</div>
											</td>
											<td className="whitespace-nowrap py-4 pr-6">
												<div className="text-neutral-medium text-left text-sm font-normal">
													{mapDocumentFileType(document.file_type)}
												</div>
											</td>
											<td className="whitespace-nowrap py-4 pr-6">
												<div className="text-neutral-medium text-left text-sm font-normal">
													{document.sender_name}
												</div>
											</td>
											<td className=" text-neutral-medium whitespace-nowrap py-4 pr-6 text-sm font-normal">
												<Tag status={document.status} />
											</td>
											<td className="whitespace-nowraptext-sm text-neutral-medium flex items-center justify-center py-4 pr-2  font-normal">
												<button
													onClick={() => handleOpenModalChange(document.file_id, document.status)}
													className="ml-0 mr-2 inline-flex bg-transparent text-brand-secondary hover:brightness-110 hover:saturate-100 active:scale-105"
												>
													<PencilAltIcon width={20} color="" />
												</button>
												<a
													target="_blank"
													rel="noopener noreferrer"
													href={document.file_url}
													className="ml-0  inline-flex text-brand-secondary hover:brightness-110 hover:saturate-100 active:scale-105"
												>
													<DownloadIcon width={20} color="" />
												</a>
											</td>
										</tr>
									</>
								))
							)}
						</tbody>
					</table>
				</div>
				<FormikProvider value={formik}>
					<Modal
						open={isOpenChangeStatus}
						onClose={setIsOpenChangeStatus}
						title="Mudar status"
						size="small"
						description="Selecione abaixo o status que deseja mudar o documento. Clique em confirmar para enviar."
					>
						<form onSubmit={formik.handleSubmit} className="w-full">
							<div className="mt-8">
								<SingleSelect
									options={optionsStatusChange}
									value={statusSelected}
									placeholder="Escolha o status"
									onChange={(e: Selectable) => handleSelect('status_id', e)}
								/>
							</div>
							<div className="mt-8 flex justify-between">
								<Button
									className="mr-3"
									type="button"
									color="outline-secondary"
									onClick={() => handleCancel()}
								>
									Cancelar
								</Button>
								<Button className="ml-3" color="secondary" disabled={!formik.isValid} type="submit">
									Confirmar
								</Button>
							</div>
						</form>
					</Modal>
				</FormikProvider>
				<Modal
					open={isOpen}
					onClose={handleCloseModal}
					title="Adicionar documento"
					description="Insira abaixo o(s) documento(s) que deseja adicionar para o empresário. Clique em confirmar para enviar."
				>
					<Upload businessId={Number(id)} checkFileType />
					<FileList />
					<div className="mt-7 flex justify-between">
						<Button onClick={() => handleCloseModal()} className="mr-3" color="outline-secondary">
							Cancelar
						</Button>
						<Button
							loading={uploadLoading}
							onClick={() => handleUpload()}
							className="ml-3"
							color="secondary"
							disabled={notHaveFileToUpload()}
						>
							Confirmar
						</Button>
					</div>
				</Modal>
			</Page>
		</>
	);
};

export default DocumentPage;
