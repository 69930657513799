import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import CNPJField from 'src/components/ui/form/CNPJField';
import EmailField from 'src/components/ui/form/EmailField';
import SectorSelect from 'src/components/ui/form/Selects/SectorSelect';
import SubsectorSelect from 'src/components/ui/form/Selects/SubSectorSelect';
import TradeNameField from 'src/components/ui/form/TradeNameField';
import Page from '../../../layouts/Logged/components/Page';
import history from 'src/services/history';
import InvestorSelect from 'src/components/ui/form/Selects/InvestorSelect';
import { createRecommendedBusiness } from 'src/services/recommendedBusiness/createRecommendedBusiness';
export interface FindersDashboardPageProps {}

const FinderRecommendBusinessPage: FC = () => {
	const [currentStep, setCurrentStep] = useState(0);
	const [valid, setValid] = useState([[false, false, false], [false, false], [true]]);
	const [values, setValues] = useState({});
	const [stepValid, setStepValid] = useState(false);
	const [chosenSector, setChosenSector] = useState(1);
	const role = 'finder';
	const maxStep = 2;

	const nextStep = () => {
		changeStep(currentStep + 1);
	};
	const handleBackButton = () => {
		if (currentStep !== 0) {
			changeStep(currentStep - 1);
		} else {
			history.push('/finder/dashboard');
		}
	};

	const changeStep = (newStep) => {
		setCurrentStep(newStep);
		updateStepValid(newStep);
	};

	const updateValue = (step, index, value, isValueValid, field) => {
		valid[step][index] = isValueValid;
		setValid(valid);
		updateStepValid(step);
		values[field] = value;
		setValues(values);
	};

	const updateStepValid = (step = currentStep) => {
		setStepValid(!valid[step].includes(false));
	};

	const finishRegister = () => {
		createRecommendedBusiness(values).finally(() => {
			history.push('/finder/recommendedbusinesssuccess');
		});
	};

	return (
		<>
			<Page>
				<div className="w flex items-center justify-center">
					<div className="w-[400px]">
						{/* Step 5 */}
						<div className={currentStep === 0 ? '' : 'hidden'}>
							<p className="font-bold text-brand-primary text-large mb-4">Cadastrar Empresa</p>
							<div>
								<TradeNameField
									onChange={(val: string, valid: boolean) => updateValue(0, 0, val, valid, 'name')}
								></TradeNameField>
								<CNPJField
									onChange={(val: string, valid: boolean) => updateValue(0, 1, val, valid, 'cnpj')}
								></CNPJField>

								<EmailField
									onChange={(val: string, valid: boolean) => updateValue(0, 2, val, valid, 'email')}
								></EmailField>
							</div>
							<button
								type="button"
								className={`inline-flex align-center items-center px-4 py-2 shadow-sm text-base font-sm rounded-md text-white justify-center bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full mt-4 mb-8 ${
									stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
								}`}
								onClick={nextStep}
								disabled={!stepValid}
							>
								{false ? 'Finalizar cadastro' : 'Próximo'}
							</button>
							<Link to={`/${role}/dashboard`} className="block text-brand-secondary text-center">
								Voltar
							</Link>
						</div>

						{/* Step 4 */}
						<div className={currentStep === 1 ? '' : 'hidden'}>
							<p className="font-bold text-brand-primary text-large mb-4">Cadastrar Empresa</p>
							<div>
								<SectorSelect
									onChange={(val: string, valid: boolean) => {
										setChosenSector(parseInt(val));
										updateValue(1, 0, val, valid, 'activity_sector');
									}}
								></SectorSelect>
								<SubsectorSelect
									onChange={(val: string, valid: boolean) =>
										updateValue(1, 1, val, valid, 'primary_subsector')
									}
									chosenSector={chosenSector}
								></SubsectorSelect>
							</div>
							<button
								type="button"
								className={`inline-flex align-center items-center px-4 py-2 shadow-sm text-base font-sm rounded-md text-white justify-center bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full mt-4 mb-8 ${
									stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
								}`}
								onClick={currentStep === maxStep ? finishRegister : nextStep}
								disabled={!stepValid}
							>
								{currentStep === maxStep ? 'Finalizar cadastro' : 'Próximo'}
							</button>
							<button
								onClick={handleBackButton}
								className={`block mx-auto text-brand-secondary text-center`}
							>
								Voltar
							</button>
						</div>

						<div className={currentStep === 2 ? '' : 'hidden'}>
							<p className="font-bold text-brand-primary text-large mb-4">
								Quais investidores podem se interessar pela companhia indicada? (Opcional)
								{values['trade_name']}
							</p>
							<p className="text-sm text-neutral-medium">
								Selecione na nossa base de investidores, perfis que você considera que se encaixam
								com a empresa cadastrada.
							</p>
							<div>
								<br />
								<InvestorSelect
									onChange={(val: string, valid: boolean) =>
										updateValue(2, 0, val, valid, 'possible_investor')
									}
								></InvestorSelect>
							</div>
							<button
								type="button"
								className={`inline-flex align-center items-center px-4 py-2 shadow-sm text-base font-sm rounded-md text-white justify-center bg-brand-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 w-full mt-4 mb-8 ${
									stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
								}`}
								onClick={currentStep === maxStep ? finishRegister : nextStep}
								disabled={!stepValid}
							>
								{currentStep === maxStep ? 'Finalizar cadastro' : 'Próximo'}
							</button>
							<button
								onClick={handleBackButton}
								className={`block mx-auto text-brand-secondary text-center`}
							>
								Voltar
							</button>
						</div>
					</div>
				</div>
			</Page>
		</>
	);
};

export default FinderRecommendBusinessPage;
