import client from "src/services/client";

export type Investor = {
	business_id: number;
	buyer: string;
	buyerId: number;
	website: string;
	buyerSector: string;
	buyerSectorId: number;
	buyerSubSector: string;
	buyerSubSectorId: number;
	buyerEmail: string;
	buyerPhone: string;
	interestSector: string;
	interestSubSector: string;
	criteria: string;
	pastDeals: string;
};

export const getInvestors = (params: InvestorsParams) =>
	client.get<InvestorsResponseDTO<InvestorsDataDTO>>(`igc/investors`, {
		params
	});

export const postInvestor = (investor: InvestorPostDTO) =>
	client.post(`igc/investor/create/`, investor);

export type InvestorsResponseDTO<T> = {
	data: T[];
	page: number;
	total_pages: number;
};

export type InvestorsParams = {
	page: number;
	size: number;
	total_pages?: number;
	sector_id?: number | null;
	search_string?: string | null;
};

export type InvestorsDataDTO = {
	id: number;
	trade_name: string;
	website?: string;
	cnpj?: string;
	sub_sector_id?: number;
};

export type InvestorPostDTO = {
	trade_name: string;
	website: string;
	cnpj?: string;
	sub_sector_id: number;
};

export type InvestorBusinessStageDTO = {
	id: number;
	name: string;
	description: string;
};
