import { FC, useEffect, useState } from 'react';
import { Selectable } from 'src/components/ui/form/models';
import SingleSelect from 'src/components/ui/form/Selects/SingleSelect';
import { Opportunity } from 'src/domain/Opportunity';
import { Sector } from 'src/domain/Sector';
import { useAuth } from 'src/hooks/useAuth';
import Header from 'src/layouts/Logged/components/Header';
import Page from 'src/layouts/Logged/components/Page';
import { getSectors } from 'src/services/domain/getSectors';
import { getFinderOpportunities } from 'src/services/opportunities/getFinderOpportunities';

const FinderOpportunitiesPage: FC = () => {
	const [selected, setSelected] = useState<Selectable>();
	const [options, setOptions] = useState<Selectable[]>([]);
	const [opportunities, setOpportunities] = useState<Opportunity[]>([]);

	const { user } = useAuth();

	const filteredOpportunities: Opportunity[] =
		selected && selected.value !== 0
			? opportunities.filter(
					(opportunity) => Number(opportunity.sector_id) === Number(selected.value)
			  )
			: selected && selected.value === 0
			? opportunities
			: [];

	const mapperOptions = (sectors: Sector[]): Selectable[] => {
		const map = sectors.map((sector) => {
			return {
				id: sector.id,
				name: sector.name,
				slug: sector.slug,
				value: sector.id
			};
		});
		map.unshift({
			id: 17862367838,
			name: 'Todos',
			slug: 'all',
			value: 0
		});

		return map;
	};

	useEffect(() => {
		getFinderOpportunities(String(user?.role)).then((res) => {
			setOpportunities(res.data);
		});
		getSectors().then((res) => {
			setOptions(mapperOptions(res.data));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Header
				title="Oportunidades"
				img=""
				button={
					<div className="w-[16rem]">
						<SingleSelect options={options} value={selected} onChange={(e) => setSelected(e)} />
					</div>
				}
			></Header>
			<Page>
				{selected ? (
					filteredOpportunities.length ? (
						<div className="grid gap-4 xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
							{filteredOpportunities.map((opportunity) => {
								return (
									<div className="relative w-full h-[12rem] group" key={opportunity.opportunity_id}>
										<h1 className="text-center mb-5 mt-2 text-2xl ">
											No setor de {opportunity.sector}
										</h1>
										<p className="text-base ml-2">{opportunity.description}</p>
										<div className="h-full opacity-0 group-hover:opacity-80 duration-300 absolute left-0 bottom-0 right-0 z-10 flex justify-center items-center text-xl bg-brand text-black font-semibold">
											<div className="flex flex-col justify-center items-center text-neutral-high opacity-100">
												<p className="text-base">{opportunity.sector}</p>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					) : (
						<>
							<div className="flex w-full justify-center items-center">Sem resultados</div>
						</>
					)
				) : (
					<div className="grid gap-4 xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 ">
						{opportunities.map((opportunity) => {
							return (
								<div className="relative w-full h-[12rem] group" key={opportunity.opportunity_id}>
									<h1 className="text-center mb-5 mt-2 text-2xl ">
										No setor de {opportunity.sector}
									</h1>
									<p className="text-base ml-2">{opportunity.description}</p>
									<div className="h-full opacity-0 group-hover:opacity-80 duration-300 absolute left-0 bottom-0 right-0 z-10 flex justify-center items-center text-xl bg-brand text-black font-semibold">
										<div className="flex flex-col justify-center items-center text-neutral-high opacity-100">
											<p className="text-base">{opportunity.sector}</p>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</Page>
		</>
	);
};

export default FinderOpportunitiesPage;
