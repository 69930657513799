import InfiniteScroll from 'react-infinite-scroll-component';
import { IgcInvestorsObject } from '.';
import { FC } from 'react';
import { getBusinessInvestorsParams } from 'src/services/target';
import { Selectable } from 'src/components/ui/form/models';
import { formatCriteria } from 'src/services/Utils';

interface TargetTableProps {
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	tradeName: string;
	investors: IgcInvestorsObject[];
	totalPages: number;
	scrollTopRef: React.MutableRefObject<null | HTMLDivElement>;
	setInvestors: (investors: IgcInvestorsObject[]) => void;
	selectedCriteria: Selectable;
	getMappedInvestors: (params: getBusinessInvestorsParams) => Promise<IgcInvestorsObject[]>;
	modalSelectedInvestors: IgcInvestorsObject[];
	setModalSelectedInvestors: (selectedInvestors: IgcInvestorsObject[]) => void;
}

const TargetTable: FC<TargetTableProps> = ({
	page,
	setPage,
	tradeName,
	investors,
	totalPages,
	scrollTopRef,
	setInvestors,
	selectedCriteria,
	getMappedInvestors,
	modalSelectedInvestors,
	setModalSelectedInvestors
}) => {
	const fetchMoreData = async () => {
		setPage((page) => page + 1);
		const nextPage = page + 1;
		const params = {
			page: nextPage,
			size: 20,
			...(tradeName !== '' && { tradeName: tradeName }),
			...(selectedCriteria.slug !== 'ALL' &&
				Object.keys(selectedCriteria).length > 0 && { criteria: selectedCriteria.slug })
		};
		const newInvestors = await getMappedInvestors(params);
		setInvestors([...investors, ...newInvestors]);
	};

	return (
		<div className="overflow-y-scroll h-full" id="scrollableDiv">
			{investors.length === 0 && (
				<div className="flex justify-center items-center h-full min-h-full ">
					<h2 className="text-gray-500">Nenhum investidor encontrado</h2>
				</div>
			)}
			<InfiniteScroll
				next={fetchMoreData}
				dataLength={investors.length}
				hasMore={totalPages > investors.length}
				loader={investors.length === 0 ? '' : <h4>Loading...</h4>}
				scrollableTarget="scrollableDiv"
			>
				<div ref={scrollTopRef}></div>
				{investors.map((investor, index) => (
					<div
						className="min-w-fit border-b border-[#ddd] flex items-center px-2 cursor-pointer hover:bg-auxiliar-grey-100 transition-all duration-75"
						key={index}
						onClick={() => {
							investor.checked = !investor.checked;
							if (investor.checked) {
								setModalSelectedInvestors([...modalSelectedInvestors, investor]);
							} else {
								setModalSelectedInvestors(
									modalSelectedInvestors.filter((item) => item.investorId !== investor.investorId)
								);
							}
						}}
					>
						<input
							type="checkbox"
							checked={investor.checked ? true : false}
							className="w-4 h-4 text-brand-primary bg-gray-100 border-gray-300 rounded focus:ring-brand-primary dark:focus:ring-brand-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
						/>
						<div className="flex flex-col sm:flex-row justify-between w-full">
							<div className="px-6 sm:py-2 whitespace-nowrap">{investor.tradeName}</div>
							<div className="px-6 sm:py-2 whitespace-nowrap">
								{formatCriteria(investor.criteria)}
							</div>
						</div>
					</div>
				))}
			</InfiniteScroll>
		</div>
	);
};

export default TargetTable;
