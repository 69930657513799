/* eslint-disable jsx-a11y/anchor-is-valid */
import { BaseSyntheticEvent, FC, createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";

import Branding from "./images/branding.png";
import Expertise from "./images/expertise.svg";
import HamburgMenu from "./images/hamburg-menu.svg";
import Linkedin from "./images/linkedin.svg";
import IGCLogo from "./images/logo-full.png";
import OverALogoWhite from "./images/overa-logo-white.svg";
import OveraLogo from "./images/overa-logo.png";
import Structure from "./images/structure.svg";
import Team from "./images/team.svg";
import Youtube from "./images/youtube.svg";
import IGCWhiteLogo from "./images/white_logo.png";

import "./style.css";
import SideMenu from "./components/SideMenu";
import clsx from "clsx";
import { MailIcon, MapIcon } from "@heroicons/react/outline";
import NewsSlider from "./components/NewsSlider";
import Divider from "./components/Divider";
import { posts } from "../../assets/news/posts";
import Article from "./components/Article";
import PreRegisterSection from "./components/PreRegisterSection";

const initialVideos = [
	{
		id: 1,
		title: "Posicionamento",
		url: "https://www.youtube.com/embed/ZCRZH-28_Mo",
		active: true
	},
	{
		id: 2,
		title: "Nossos investidores",
		url: "https://www.youtube.com/embed/bPvBJ0kydGs",
		active: false
	},
	{
		id: 3,
		title: "Tudo o que você precisa saber sobre M&A",
		url: "https://www.youtube.com/embed/_UMzxzs2aC4",
		active: false
	},
	{
		id: 4,
		title: "Estou no momento certo?",
		url: "https://www.youtube.com/embed/MSzPWaTc3_w",
		active: false
	},
	{
		id: 5,
		title: "Quanto vale a minha empresa?",
		url: "https://www.youtube.com/embed/OX0gm0w5zuM",
		active: false
	}
];

const HomePage: FC = () => {
	const navigate = useNavigate();
	const [videos, setVideos] = useState(initialVideos);
	const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
	const { signed, user } = useAuth();
	const [scroll, setScroll] = useState<number>(0);
	const buttonRef = createRef<HTMLButtonElement>();
	const mainRef = createRef<HTMLButtonElement>();
	const [isBrowserLight, setBrowserTheme] = useState<boolean>(true);

	useEffect(() => {
		window.addEventListener("scroll", () => setScroll(window.scrollY));

		console.log(window.matchMedia("(prefers-color-scheme: light)").matches);

		setBrowserTheme(window.matchMedia("(prefers-color-scheme: light)").matches);

		return () => {
			window.removeEventListener("scroll", () => setScroll(window.scrollY));
		};
	}, []);

	const selectVideo = (event: BaseSyntheticEvent) => {
		const videoName = event.currentTarget.innerHTML.replace("amp;", "");
		const newVideos = videos.map((video) =>
			video.title === videoName ? { ...video, active: true } : { ...video, active: false }
		);
		setVideos(newVideos);
	};

	const handleNavigate = (path: "register" | "login") => {
		navigate(`business/${path}`);
	};

	const handleNavigateDashboard = (path: "dashboard") => {
		if (!user) {
			handleNavigate("login");
			return;
		}

		if (user.role === "igc") {
			navigate(`igc/${path}`);
		} else {
			navigate(`business/${path}`);
		}
	};

	return (
		<>
			{isMenuOpen && (
				<SideMenu
					handleNavigate={handleNavigate}
					handleNavigateDashboard={handleNavigateDashboard}
					setMenuOpen={setMenuOpen}
					signed={signed}
				/>
			)}
			<header className="full">
				<div className="header-grid">
					<img src={OveraLogo} className="logo-image" alt="OverA Logo" />
					<img src={Branding} className="branding-image" alt="OverA Branding" />
				</div>
				<div className="header-grid">
					<>
						<button className="nav-button register">
							<a href="/#pre-register">Cadastro</a>
						</button>
						<button className="nav-button login">
							<a href="https://client.overacapital.com.br">Login</a>
						</button>
					</>
				</div>
			</header>
			<header className={`responsive ${scroll > 0 && !isMenuOpen ? "sticky" : ""}`}>
				<div className="header-grid">
					<img src={OverALogoWhite} className="logo-image" alt="OverA Logo" />
				</div>
				<div className="header-grid">
					<button onClick={() => setMenuOpen((prevState) => !prevState)}>
						<img src={HamburgMenu} alt="Hamburg Menu Icon" />
					</button>
				</div>
			</header>
			<main ref={mainRef} className="home-main">
				<div className="text-box">
					<h1>
						a overA nasce
						<br /> de um sonho
					</h1>
					<h2>
						Ser a casa de M&A do empresário
						<br /> do lower-middle market.
						<br /> Indo{" "}
						<span>
							além da entrega de qualidade
							<br /> e excelência
						</span>{" "}
						padrão do mercado.
					</h2>
					<button className="nav-button register-secondary" >
						<a href="/#pre-register">Cadastre-se agora</a>
					</button>
				</div>
			</main>
			<section className="video-section">
				<h3>Mais sobre a overA</h3>
				<div className="mx-auto flex w-full flex-col justify-center gap-5 px-4 align-middle md:max-w-5xl md:flex-row md:gap-2">
					<div className="aspect-video w-full flex-grow">
						<iframe
							src={videos.find((video) => video.active)?.url || videos[0].url}
							className="h-full w-full"
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
					<div className="accordion flex flex-shrink flex-col gap-2">
						<label className="video-label">1. Sobre a overA</label>
						{videos.slice(0, 2).map(
							(video) =>
								video && (
									<button className={clsx({ selected: video.active })} onClick={selectVideo} ref={buttonRef}>
										{video.title}
									</button>
								)
						)}
						<label className="video-label">2. Trilha M&A</label>
						{videos.slice(2).map(
							(video) =>
								video && (
									<button className={clsx({ selected: video.active })} onClick={selectVideo} ref={buttonRef}>
										{video.title}
									</button>
								)
						)}
					</div>
				</div>
			</section>
			<Divider />
			<section
				className={clsx("mx:mx-0 mx-auto mb-16 flex flex-col justify-center py-8 px-8 md:max-w-4xl", {
					"items-center": posts.length === 1
				})}
			>
				<h3>Notícias</h3>
				<NewsSlider />
			</section>
			<PreRegisterSection />
			<Divider />
			<section className="mb-8 flex flex-col justify-center py-8 px-2 md:px-0">
				<h3>Nossos diferenciais</h3>
				<div className="card-grid">
					<div className="card">
						<div className="card-border">
							<img src={Structure} className="card-image" alt="Icone Estrutura" />
						</div>
						<p className="card-text">
							maior
							<br /> estrutura
						</p>
					</div>
					<div className="card">
						<div className="card-border">
							<img src={Team} className="card-image" alt="Icone Time" />
						</div>
						<p className="card-text">
							maior
							<br /> time
						</p>
					</div>
					<div className="card">
						<div className="card-border">
							<img src={IGCLogo} className="card-image dark:hidden" alt="Logo IGC" />
							<img src={IGCWhiteLogo} className="card-image hidden dark:block" alt="Logo IGC" />
						</div>
						<p className="card-text">
							investimento
							<br /> da igc
						</p>
					</div>
					<div className="card">
						<div className="card-border">
							<img src={Expertise} className="card-image" alt="Icone Expertise" />
						</div>

						<p className="card-text">
							expertise em
							<br /> transações do
							<br /> lower-middle market
						</p>
					</div>
				</div>
			</section>
			<footer>
				<img src={OverALogoWhite} className="overA-white-logo" alt="Overa Logo Branco" />
				<div className="social-grid">
					<>
						<button className="nav-button footer" >
							<a href="/#pre-register">Cadastro</a>
						</button>
						<button className="nav-button footer" >
							<a href="https://client.overacapital.com.br">Login</a>
						</button>
					</>
				</div>
				<div className="social-grid">
					<a href="https://www.linkedin.com/company/over-a-capital/" target="_blank" rel="noreferrer">
						<img src={Linkedin} alt="Linkedin Logo" />
					</a>
					<a href="https://www.youtube.com/channel/UCsgubxC_i5FXqadGn5p9D6g" target="_blank" rel="noreferrer">
						<img src={Youtube} alt="Youtube Logo" />
					</a>
				</div>
			</footer>
			<div className="flex flex-col justify-between gap-1 px-6 py-2 text-sm md:text-base lg:flex-row">
				<a
					href="https://goo.gl/maps/ZReWo2tJEiNpnNHr7"
					target="_blank"
					rel="noreferrer"
					className="flex w-fit items-start gap-1 md:flex-row md:items-center"
				>
					<MapIcon className="hidden h-5 w-5" />
					<span className="flex flex-col md:flex-row">
						<span>Avenida Brigadeiro Faria Lima, 2413, 10º andar,&nbsp;</span>
						<span>São Paulo - SP, 01452-000</span>
					</span>
				</a>
				<a href="mailto:atendimento@overacapital.com.br" className="flex w-fit items-center gap-1">
					<MailIcon className="hidden h-5 w-5" />
					atendimento@overacapital.com.br
				</a>
			</div>
		</>
	);
};

export default HomePage;
