import { FC } from 'react';

const DesktopMenuContainer: FC<{
	children: any;
}> = ({ children }) => {
	return (
		<div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 bg-brand-primary">
			<div className="flex-1 flex flex-col min-h-0">{children}</div>
		</div>
	);
};

export default DesktopMenuContainer;
