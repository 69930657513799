import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { FC, Fragment } from 'react';

interface DashboardFAQDisclosureProps {
	defaultOpen?: boolean;
	question: string;
	answer: string;
}

const DashboardFAQDisclosure: FC<DashboardFAQDisclosureProps> = ({
	defaultOpen,
	question,
	answer
}) => {
	return (
		<Disclosure defaultOpen={defaultOpen}>
			{({ open }) => (
				<Fragment>
					<Fragment>
						<Disclosure.Button className="mt-2 flex w-full justify-between border-b border-brand-secondary border-opacity-10 p-4 text-left text-base font-semibold text-brand-secondary hover:bg-[#fcf5e1] focus:outline-none">
							<span>{question}</span>
							<ChevronDownIcon
								className={`${
									open && 'rotate-180 transform'
								} text-purple-500 h-5 w-5 transition-all`}
							/>
						</Disclosure.Button>
						<Disclosure.Panel
							className={`my-1 rounded-md border border-none p-4 text-sm font-medium text-[#958777] shadow-sm`}
						>
							<span className={``}>{answer}</span>
						</Disclosure.Panel>
					</Fragment>
				</Fragment>
			)}
		</Disclosure>
	);
};

export default DashboardFAQDisclosure;
