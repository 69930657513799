import { useState } from "react";

import { toastNotify } from "src/helpers/toastNotify";
import sucessImage from "../images/success.svg";

import PreRegisterForm from "./PreRegisterForm";

const PRE_REGISTER_FUNCTION_URL = "https://3vjszblee2pyiz6m6qycvk7zkm0lhrdy.lambda-url.us-east-1.on.aws/"

const PreRegisterSection = () => {
    const [sendOk, setSendOk] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const data = {
                businessName: values.trade_name,
                contactName: values.partner_name,
                email: values.email,
                phone: values.phone.replace(/\D/g, ''),
                website: values.business_website,
                acceptTerms: values.terms_of_use_and_privacy
            }
            const response = await fetch(PRE_REGISTER_FUNCTION_URL, {
                method: 'POST',
                body: JSON.stringify(data)
            })
            if (!response.ok) {
                const respJson = await response.json()
                throw Error(respJson.errorMsg)
            }
            toastNotify("Seu cadastro foi enviado com sucesso!", "success");
            setSendOk(true);
        } catch (error) {
            toastNotify(`Estamos enfrentando instabilidade. Caso persista, nosso telefone de contato é
+55 11 97476 2160 para atendimento via Whatsapp.`, "info");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <section className="my-10  flex h-auto w-full justify-center rounded-md shadow-md" id="pre-register">
            <div className=" w-full lg:flex lg:justify-between">
                <div className="left-form-pre-register md:bg-secondary-100 flex w-full justify-center sm:bg-brand sm:p-10  lg:bg-[#EDEEF3] lg:p-20">
                    <div className="left-text-pre-register px-l-10 ">
                        <h1 className="font-thin sm:text-white md:text-[#fff] lg:text-[#7f8183]">
                            Agende uma conversa <br /> e tire suas dúvidas com um especialista
                        </h1>
                        <div className="py-20">
                            <ul className="grid list-inside list-none gap-6 text-[24px]	font-thin sm:text-[#fff] md:text-[#fff] lg:text-[#7f8183] ">
                                <li>Como me preparar para um processo de venda ou investimento?</li>
                                <li>Como se diferenciar frente aos investidores?</li>
                                <li>Como é conduzido o processo de M&A?</li>
                                <li>Quanto vale o meu negócio?</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {sendOk ? (
                    <div className=" flex w-full justify-center bg-white sm:py-20 md:py-20 md:text-[#A7A9AC] lg:p-20">
                        <div>
                            <div className="md: flex items-center justify-center ">
                                <img className="mr-11" src={sucessImage} alt="sucess" />
                                <div className="text-auxiliar-highlight-orange">
                                    <h1 className="font-thin">Recebemos suas</h1>
                                    <h1 className="font-thin">informaçoes.</h1>
                                </div>
                            </div>
                            <div className="py-10">
                                <p>
                                    Enquanto analisamos seu cadastro, assista nossos{" "}
                                    <a className="text-[#000]" href="#video-section">
                                        Vídeos
                                    </a>
                                    !
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="sm:bg-secondary-100 w-full items-center justify-center px-4 md:flex md:bg-white md:text-[#A7A9AC]">
                        <div className="p-5">
                            <h1 className="font-thin">Cadastro</h1>
                            <div className="right-form-pre-register flex  py-4 sm:block">
                                <PreRegisterForm onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default PreRegisterSection;