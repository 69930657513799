import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import igc from './images/igc.png';

export interface IgcSessionProps {}

const IgcSession: FC<IgcSessionProps> = () => {
	return (
		<>
			<div className="max-h-screen overflow-hidden">
				<div className="flex justify-center">
					<div className="hidden md:block w-1/2 h-full max-h-screen object-fill">
						<img
							className="h-screen max-h-full w-full object-cover object-top"
							src={igc}
							alt="businesswoman with glasses"
						/>
					</div>
					<div className="flex w-full md:w-1/2 items-center justify-center px-4">
						<div className="w-[400px]">
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default IgcSession;
