import { removeSpecialChars } from '../../helpers';


export const formatRecommendedBusinessForm = (
    registerForm: any
) => {
    const cnpj = removeSpecialChars(registerForm.cnpj)
    registerForm["cnpj"] = cnpj
    return registerForm
};


/* _formato_do_cadastro_
{
    "username": "elonmusk@tesla.com",ok
    "first_name": "Elon", ok
    "last_name": "Musk", ok
    "email": "businessuser1@mail.com", ok
    "password": "senha_de_teste", ok
    "phone": "5521988888888", ok
    "cpf": "00000000011", ok

    "business_full_name": "Tesla AutoCars and Energy Corp LTD.", ok
    "business_trade_name": "Tesla Motors", ok
    "business_website": "www.teslamotors.com", ok
    "business_activity_sector": 1, ok
    "cnpj": [
        "00000000000" ok
    ],
    
    "primary_subsector": 1,
    "secundary_subsector": 2,
    "finance_report": [
        {
            "amount": 1000.00,
            "year": 2022,
            "type": 1
        },
        {
            "amount": 1500.00,
            "year": 2021,
            "type": 1
        }
    ],
    "is_political": true
}
*/