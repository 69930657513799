import { FC, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'src/components/Modal';
import CNPJField from 'src/components/ui/form/CNPJField';
import CPFField from 'src/components/ui/form/CPFField';
import EmailField from 'src/components/ui/form/EmailField';
import FinanceReportField from 'src/components/ui/form/FinanceReportField';
import NameField from 'src/components/ui/form/NameField';
import PasswordField from 'src/components/ui/form/PasswordField';
import PhoneField from 'src/components/ui/form/PhoneField';
import PoliticallyExposedSelect from 'src/components/ui/form/Selects/PoliticallyExposedSelector';
import SectorSelect from 'src/components/ui/form/Selects/SectorSelect';
import SubsectorSelect from 'src/components/ui/form/Selects/SubSectorSelect';
import TradeNameField from 'src/components/ui/form/TradeNameField';
import WebsiteField from 'src/components/ui/form/WebsiteField';
import { toastNotify } from 'src/helpers/toastNotify';
import { useAuth } from 'src/hooks/useAuth';
import OverALogo from '../../../components/OverALogo';
import { registerUser } from '../../../services';
import { verifyUniqueness } from '../../../services/user/emailUniqueness';

const BusinessRegister: FC = () => {
	const { login } = useAuth();
	const [currentStep, setCurrentStep] = useState(0);
	const [valid, setValid] = useState([
		[],
		[false, false],
		[false, false, true],
		[false],
		[false, false],
		[false, false, true],
		[false, false, true, true],
		[false, false, true, true],
		[false]
	]);
	const [values, setValues] = useState({ website: '' });
	const [stepValid, setStepValid] = useState(false);
	const [chosenSector, setChosenSector] = useState(1);
	const [isOpenEbitdaDetails, setIsOpenEbitdaDetails] = useState<boolean>(false);
	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const role = 'business';

	const nextStep = () => {
		changeStep(currentStep + 1);
	};
	const previousStep = () => {
		changeStep(currentStep - 1);
	};

	const changeStep = (newStep) => {
		setCurrentStep(newStep);
		updateStepValid(newStep);
	};

	const updateValue = (step, index, value, isValueValid, field) => {
		valid[step][index] = isValueValid;
		setValid(valid);
		updateStepValid(step);
		values[field] = value;
		setValues(values);
	};

	const updateStepValid = (step = currentStep) => {
		setStepValid(!valid[step].includes(false));
	};

	const finishRegister = async () => {
		try {
			setSubmitLoading(true);

			updateValue(8, 0, 3, valid, 'looking_platform');

			registerUser(role, values)
				.then((login_dados) => {
					login(login_dados.email, login_dados.password, role);
					toastNotify('Usuário criado com sucesso', 'success');
				})
				.catch((error) => {
					toastNotify(error.response.data.user_message, 'error');
				})
				
		} catch (err) {
			toastNotify('Houve um erro ao finalizar o cadastro, tente novamente.', 'error');
			setSubmitLoading(false);
		}
	};

	const openEbitdaTip = () => {
		setIsOpenEbitdaDetails(true);
	};

	const handleVerifyUniqueness = (event: FormEvent) => {
		event.preventDefault();
		verifyUniqueness(values['email'])
			.then((res) => {
				if (res.data.email_available) {
					nextStep();
				} else {
					toastNotify('E-mail ou senha inválidos', 'error');
				}
			})
			.catch((error) => {
				toastNotify(error.response.data.user_message, 'error');
			});
		setTimeout(() => {}, 1000);
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			if (stepValid) {
				if (currentStep === 1) {
					verifyUniqueness(values['email'])
						.then((res) => {
							if (res.data.email_available) {
								nextStep();
							} else {
								toastNotify(res.data.user_message, 'error');
							}
						})
						.catch((error) => {
							toastNotify(error.response.data.user_message, 'error');
						});
					return;
				}
				nextStep();
			}
		}
	};

	return (
		<div onKeyDown={handleKeyDown} className="py-4">
			<OverALogo color="dark" width={400} height={200} centered />
			{currentStep === 0 && (
				<>
					<p className="mb-4 text-xl font-bold text-brand-primary">Faça seu cadastro</p>
					<p className="text-base">
						Para entender melhor sobre a sua empresa, precisaremos coletar algumas informações.
						<br />
						<br /> Nosso objetivo é oferecer um atendimento pensado para a sua necessidade através
						de uma análise detalhada do seu negócio.
						<br />
						<br /> Não se preocupe, todos os seus dados estarão seguros e protegidos.
					</p>
					<button
						type="button"
						className="align-center font-sm focus:ring-indigo-500 mt-4 mb-8 inline-flex w-full items-center justify-center rounded-md bg-brand-secondary px-4 py-2 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
						onClick={nextStep}
					>
						Continuar
					</button>
					<Link to={`/${role}/login`} className="block text-center text-brand-secondary">
						Voltar para o login
					</Link>
				</>
			)}
			<div className={currentStep === 1 ? '' : 'hidden'}>
				<p className="mb-4 text-xl font-bold text-brand-primary">Cadastro</p>
				<div>
					<EmailField
						onChange={(val: string, valid: boolean) => updateValue(1, 0, val, valid, 'email')}
					/>
					<PasswordField
						showRules={true}
						onChange={(val: string, valid: boolean) => updateValue(1, 1, val, valid, 'password')}
					/>
				</div>
				<button
					type="button"
					className={`align-center font-sm focus:ring-indigo-500 mt-4 mb-8 inline-flex w-full items-center justify-center rounded-md bg-brand-secondary px-4 py-2 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
						stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
					}`}
					onClick={handleVerifyUniqueness}
					disabled={!stepValid}
				>
					Próximo
				</button>
				<button onClick={previousStep} className={`mx-auto block text-center text-brand-secondary`}>
					Voltar
				</button>
			</div>

			<div className={currentStep === 2 ? '' : 'hidden'}>
				<p className="mb-4 text-xl font-bold text-brand-primary">Cadastro</p>
				<div>
					<NameField
						onChange={(val: string, valid: boolean) => updateValue(2, 0, val, valid, 'name')}
					></NameField>
					<br className="h-1" />
					<CPFField
						onChange={(val: string, valid: boolean) => updateValue(2, 1, val, valid, 'cpf')}
					></CPFField>
					<PhoneField
						onChange={(val: string, valid: boolean) => updateValue(2, 2, val, valid, 'phone')}
					></PhoneField>
				</div>
				<button
					type="button"
					className={`align-center font-sm focus:ring-indigo-500 mt-4 mb-8 inline-flex w-full items-center justify-center rounded-md bg-brand-secondary px-4 py-2 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
						stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
					}`}
					onClick={nextStep}
					disabled={!stepValid}
				>
					Próximo
				</button>
				<button onClick={previousStep} className={`mx-auto block text-center text-brand-secondary`}>
					Voltar
				</button>
			</div>
			<div className={currentStep === 3 ? '' : 'hidden'}>
				<p className="mb-4 text-xl font-bold text-brand-primary">Exposição Política</p>
				<div>
					<PoliticallyExposedSelect
						onChange={(val: string, valid: boolean) =>
							updateValue(3, 0, val, valid, 'politically_exposed')
						}
					/>
				</div>
				<button
					type="button"
					className={`align-center font-sm focus:ring-indigo-500 mt-4 mb-8 inline-flex w-full items-center justify-center rounded-md bg-brand-secondary px-4 py-2 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
						stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
					}`}
					onClick={nextStep}
					disabled={!stepValid}
				>
					Próximo
				</button>
				<button onClick={previousStep} className={`mx-auto block text-center text-brand-secondary`}>
					Voltar
				</button>
			</div>

			{/* Step 4 */}
			<div className={currentStep === 4 ? '' : 'hidden'}>
				<p className="mb-4 text-xl font-bold text-brand-primary">Informações Setoriais</p>
				<div>
					<SectorSelect
						onChange={(val: string, valid: boolean) => {
							setChosenSector(parseInt(val));
							updateValue(4, 0, val, valid, 'sector');
						}}
					/>
					<SubsectorSelect
						onChange={(val: string, valid: boolean) => updateValue(4, 1, val, valid, 'subsector')}
						chosenSector={chosenSector}
					/>
				</div>
				<button
					type="button"
					className={`align-center font-sm focus:ring-indigo-500 mt-4 mb-8 inline-flex w-full items-center justify-center rounded-md bg-brand-secondary px-4 py-2 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
						stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
					}`}
					onClick={nextStep}
					disabled={!stepValid}
				>
					Próximo
				</button>
				<button onClick={previousStep} className={`mx-auto block text-center text-brand-secondary`}>
					Voltar
				</button>
			</div>

			{/* Step 5 */}
			<div className={currentStep === 5 ? '' : 'hidden'}>
				<p className="mb-4 text-xl font-bold text-brand-primary">Dados Cadastrais da Empresa</p>
				<div>
					<TradeNameField
						onChange={(val: string, valid: boolean) => updateValue(5, 0, val, valid, 'trade_name')}
					/>
					<br className="h-1" />
					<CNPJField
						onChange={(val: string, valid: boolean) => updateValue(5, 1, val, valid, 'cnpj')}
					/>
					<WebsiteField
						onChange={(val: string, valid: boolean) => updateValue(5, 2, val, valid, 'website')}
					/>
				</div>
				<button
					type="button"
					className={`align-center font-sm focus:ring-indigo-500 mt-4 mb-8 inline-flex w-full items-center justify-center rounded-md bg-brand-secondary px-4 py-2 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
						stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
					}`}
					onClick={nextStep}
					disabled={!stepValid}
				>
					Próximo
				</button>
				<button onClick={previousStep} className={`mx-auto block text-center text-brand-secondary`}>
					Voltar
				</button>
			</div>

			{/* Step 6 */}
			<div className={currentStep === 6 ? '' : 'hidden'}>
				<p className="mb-4 text-xl font-bold text-brand-primary">Dados de Faturamento</p>
				<div>
					<FinanceReportField
						onChange={(val: string, valid: boolean) =>
							updateValue(6, 0, val, valid, 'finance_report2023ebitda')
						}
						parameters={{
							type: 'ebitda',
							year: '2023',
							showTip: true,
							tipMethod: openEbitdaTip,
							customText: '(estimado)'
						}}
					/>
					<FinanceReportField
						onChange={(val: string, valid: boolean) =>
							updateValue(6, 1, val, valid, 'finance_report2022ebitda')
						}
						parameters={{
							type: 'ebitda',
							year: '2022',
							showTip: true,
							tipMethod: openEbitdaTip
						}}
					/>
					<FinanceReportField
						onChange={(val: string, valid: boolean) =>
							updateValue(6, 2, val, valid, 'finance_report2021ebitda')
						}
						parameters={{
							type: 'ebitda',
							year: '2021',
							optional: true,
							showTip: true,
							tipMethod: openEbitdaTip
						}}
					/>
					<FinanceReportField
						onChange={(val: string, valid: boolean) =>
							updateValue(6, 3, val, valid, 'finance_report2020ebitda')
						}
						parameters={{
							type: 'ebitda',
							year: '2020',
							optional: true,
							showTip: true,
							tipMethod: openEbitdaTip
						}}
					/>
				</div>
				<button
					type="button"
					className={`align-center font-sm focus:ring-indigo-500 mt-4 mb-8 inline-flex w-full items-center justify-center rounded-md bg-brand-secondary px-4 py-2 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
						stepValid ? 'cursor-pointer' : 'cursor-not-allowed opacity-80'
					}`}
					onClick={nextStep}
					disabled={!stepValid || submitLoading}
				>
					Próximo
				</button>
				<button onClick={previousStep} className={`mx-auto block text-center text-brand-secondary`}>
					Voltar
				</button>
			</div>

			{/* Step 7  */}
			<div className={currentStep === 7 ? '' : 'hidden'}>
				<p className="mb-4 text-xl font-bold text-brand-primary">Dados de Faturamento</p>
				<div>
					<FinanceReportField
						onChange={(val: string, valid: boolean) =>
							updateValue(7, 0, val, valid, 'finance_report2023liquid_revenue')
						}
						parameters={{ type: 'liquid_revenue', year: '2023', customText: '(estimado)' }}
					/>
					<FinanceReportField
						onChange={(val: string, valid: boolean) =>
							updateValue(7, 1, val, valid, 'finance_report2022liquid_revenue')
						}
						parameters={{ type: 'liquid_revenue', year: '2022', customText: '(estimado)' }}
					/>
					<FinanceReportField
						onChange={(val: string, valid: boolean) =>
							updateValue(7, 2, val, valid, 'finance_report2021liquid_revenue')
						}
						parameters={{ type: 'liquid_revenue', year: '2021', optional: true }}
					/>
					<FinanceReportField
						onChange={(val: string, valid: boolean) =>
							updateValue(7, 3, val, valid, 'finance_report2020liquid_revenue')
						}
						parameters={{ type: 'liquid_revenue', year: '2020', optional: true }}
					/>
				</div>
				<button
					type="button"
					className={`align-center font-sm focus:ring-indigo-500 mt-4 mb-8 inline-flex w-full items-center justify-center rounded-md bg-brand-secondary px-4 py-2 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
						!stepValid || submitLoading ? 'cursor-not-allowed opacity-80' : 'cursor-pointer'
					}`}
					onClick={finishRegister}
					disabled={!stepValid || submitLoading}
				>
					Finalizar cadastro
				</button>
				<button onClick={previousStep} className={`mx-auto block text-center text-brand-secondary`}>
					Voltar
				</button>
			</div>

			<Modal
				open={isOpenEbitdaDetails}
				onClose={setIsOpenEbitdaDetails}
				title="O que é EBITDA?"
				size="small"
			>
				<>
					<p className="text-neutral-medium text-sm">
						EBITDA é a sigla que em inglês significa Earning Before Interest, Taxes, Depreciation
						and Amortization e serve como um medidor do lucro da companhia antes de levar em conta
						os juros e impostos pagos, assim como o valor de investimentos em capital (como ativos
						imobiliários, veículos, software, etc)
						<br />
						<br /> Para calcular o EBITDA da sua empresa, basta partir do seu Faturamento Líquido,
						deduzir os custos diretos (por exemplo, custo de matéria prima ou de pessoal responsável
						pela sua oferta) e os custos de SG&A (custos relacionados à venda, como custos do time
						comercial ou investimento em propagandas, custos gerais e de administração, como a
						locação de escritórios, serviços de contabilidade, etc).
						<br />
						<br />
						Ou seja: Receita Líquida - Custos Diretos - Custos Gerais e Administrativos = EBITDA
					</p>
				</>
			</Modal>
		</div>
	);
};
export default BusinessRegister;
