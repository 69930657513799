import { Dialog, Transition } from '@headlessui/react';
import {
	DocumentTextIcon,
	XCircleIcon,
	HomeIcon,
	CogIcon,
	XIcon,
	ChatAlt2Icon
} from '@heroicons/react/outline';
import { Dispatch, FC, Fragment, SetStateAction } from 'react';
import UserMenu from './components/UserMenu';
import OverALogo from '../OverALogo';
import { getUserMenuItems } from './configs/userConfigs';
import { useAuth } from 'src/hooks/useAuth';
import { useLocation } from 'react-router-dom';

const navigation = [
	{ name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
	{ name: 'Meus Alvos', href: '#', icon: XCircleIcon, current: false },
	{ name: 'Meus materiais', href: '#', icon: DocumentTextIcon, current: false },
	{ name: 'Chat', href: '/business/chat', icon: ChatAlt2Icon, current: false },
	{ name: 'Configurações', href: '#', icon: CogIcon, current: false }
];

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}

const SidebarMenuMobile: FC<{
	isOpen: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isOpen, setOpen }) => {
	const location = useLocation();
	const { user } = useAuth();

	return (
		<>
			<Transition.Root show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-40 " onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-opacity-75" />
					</Transition.Child>

					<div className="fixed inset-0 flex z-40">
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 right-0 -mr-12 pt-2">
										<button
											type="button"
											className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => setOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								<div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto bg-brand-primary">
									<div className="flex-shrink-0 flex items-center px-4">{/* logo */}</div>
									<nav className="mt-5 px-2 space-y-1">
										<div className="w-full sm:px-10">
											<OverALogo color="light" />
										</div>
										{getUserMenuItems(user?.role).map((item) => (
											<a
												key={item.name}
												href={item.toPath}
												className={classNames(
													location.pathname === item.toPath
														? 'bg-auxiliar-highlight-orange text-white'
														: 'text-white hover:bg-[#647a34] hover:bg-opacity-75',
													'group flex items-center px-2 py-2 text-base font-medium rounded-md'
												)}
											>
												{item.icon}
												{item.label}
											</a>
										))}
									</nav>
								</div>
								<UserMenu />
							</Dialog.Panel>
						</Transition.Child>
						<div className="flex-shrink-0 w-14" aria-hidden="true"></div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export default SidebarMenuMobile;
