import { FC, Fragment, useEffect, useRef, useState } from "react";
import { InvestorBusinessDTO, MatchStatus } from "src/domain/Target";
import { toastNotify } from "src/helpers/toastNotify";
import { useAuth } from "src/hooks/useAuth";
import Header from "src/layouts/Logged/components/Header";
import Page from "../../../layouts/Logged/components/Page";
import { getSelectedBusinessInvestors } from "src/services/target";
import { TABS, Tab } from "src/pages/Igc/CompanyDetails/components/TabsContent/Target";
import ClientTargetNavList from "src/pages/Business/Targets/components/ClientTargetNavList";
import { Teaser } from "src/domain/Teaser";
import { getTeaserByCompanyId } from "src/services/teaser/getTeaserByCompanyId";

const BusinessTargetPage: FC = () => {
	const tabsScrollTopRef = useRef<null | HTMLDivElement>(null);
	const [selectedTab, setSelectedTab] = useState<Tab>(TABS.RECOMMENDED);
	const [teaser, setTeaser] = useState<Teaser>();
	const [matchStatusTabOption, setMatchStatusTabOption] = useState<MatchStatus>("recomendado");
	const [tabLoading, setTabLoading] = useState(true);
	const [selectedInvestors, setSelectedInvestors] = useState<InvestorBusinessDTO[]>([]);
	const { user } = useAuth();

	const fetchSelectedInvestors = async () => {
		try {
			setTabLoading(true);
			const params = {
				matchStatus: matchStatusTabOption
			};
			const businessId = Number(user?.business_id);
			const responseData = (await getSelectedBusinessInvestors(businessId, params)).data;
			setSelectedInvestors(responseData.data);
		} catch (error) {
			toastNotify("Erro ao carregar investidores selecionados", "error");
		} finally {
			setTabLoading(false);
		}
	};

	const fetchTeaser = async () => {
		try {
			const userRole = String(user?.role);
			const business_id = Number(user?.business_id);
			const response = await getTeaserByCompanyId(userRole, business_id);
			setTeaser(response.data);
		} catch (error) {
			toastNotify("Erro ao carregar dados da empresa", "error");
		}
	};

	useEffect(() => {
		if (user?.register_status) {
			fetchTeaser();
			fetchSelectedInvestors();
		}
	}, [matchStatusTabOption, setMatchStatusTabOption]);

	if (!user?.register_status) {
		return (
			<Fragment>
				<Header title="Meus Alvos"></Header>
				<Page>
					<div className="relative h-[75vh] max-h-[75vh] w-full">
						<span className="flex h-full flex-col items-center sm:justify-center md:flex-row md:gap-6">
							<h2 className="m-0 max-w-xl text-center text-lg font-medium sm:mb-2 sm:text-2xl">
								É necessário completar seu cadastro para que possamos prosseguir com a análise do
								perfil do seu negócio.
							</h2>
						</span>
					</div>
				</Page>
			</Fragment>
		);
	}

	if (!user?.business_contract_signed) {
		return (
			<Fragment>
				<Header title="Meus Alvos"></Header>
				<Page>
					<div className="relative h-[75vh] max-h-[75vh] w-full">
						<span className="flex h-full flex-col items-center sm:justify-center md:flex-row md:gap-6">
							<h2 className="m-0 max-w-xl text-center text-lg font-medium sm:mb-2 sm:text-2xl">
								É necessário assinar o contrato para que possamos recomendar alvos personalizados
								para você.
							</h2>
						</span>
					</div>
				</Page>
			</Fragment>
		);
	}

	if (!teaser?.target_list_approved) {
		return (
			<Fragment>
				<Header title="Meus Alvos"></Header>
				<Page>
					<div className="relative h-[75vh] max-h-[75vh] w-full">
						<span className="flex h-full flex-col items-center sm:justify-center md:flex-row md:gap-6">
							<h2 className="m-0 max-w-xl text-center text-lg font-medium sm:mb-2 sm:text-2xl">
								Aguarde enquanto analisamos o seu perfil e recomendamos os melhores alvos para seu
								negócio.
							</h2>
						</span>
					</div>
				</Page>
			</Fragment>
		);
	}

	return (
		<section className="h-full w-full px-4 pt-6 sm:px-6 md:h-screen md:px-8">
			<Header title="Meus Alvos"></Header>
			<ClientTargetNavList
				tabLoading={tabLoading}
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
				tabsScrollTopRef={tabsScrollTopRef}
				selectedInvestors={selectedInvestors}
				fetchSelectedInvestors={fetchSelectedInvestors}
				setMatchStatusTabOption={setMatchStatusTabOption}
			/>
		</section>
	);
};

export default BusinessTargetPage;
