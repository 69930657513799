import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { User } from 'src/domain/User';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { signIn } from 'src/services/auth/signIn';
import { getUserInfo } from 'src/services/user/getUserInfo';
import client from '../services/client';
import history from '../services/history';
import { toastNotify } from 'src/helpers/toastNotify';
interface IAuthContext {
	signed: boolean;
	user: User | null;
	login(email: string, password: string, role: string): void;
	logout(): void;
	hasNewMessages: boolean;
	setHasNewMessages(newMessages: boolean): void;
	getOrUpdateUserInfo(userRole: string | undefined): void;
	updateUserWithoutNavigate(userRole: string | undefined): void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: any) => {
	const [user, setUser] = useLocalStorage('@App:user', null);
	const [token, setToken] = useLocalStorage('@App:token', null);
	const [tokenExpiry, setTokenExpiry] = useLocalStorage('@App:token-expiry', null);
	const [hasNewMessages, setHasNewMessages] = useState(false);

	useEffect(() => {
		if (token && user && tokenExpiry) {
			client.defaults.headers.common['Authorization'] = `Token ${token}`;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateUserWithoutNavigate = useCallback(
		(userRole: string) => {
			getUserInfo(userRole).then((response) => {
				const user = response.data;
				user.role = userRole;
				setUser(user);
			});
		},
		[setUser]
	);

	const getOrUpdateUserInfo = useCallback(
		(userRole: string) => {
			if (!userRole) {
				return;
			}
			getUserInfo(userRole)
				.then((response) => {
					const user = response.data;
					user.role = userRole;
					setUser(user);
					if (user.looking_platform !== 1 && user.looking_platform !== 2) {
						history.push(`/${userRole}/dashboard`);
					} else {
						if (user.register_status) {
							history.push(`/${userRole}/company-register-complete-notification`);
						} else {
							history.push(`/${userRole}/complete-seu-cadastro-no-side-bar`);
						}
					}
				})
				.catch((error) => {
					const roleMap = {
						finder: 'Finder',
						business: 'Empresário',
						igc: 'Administrador IGC'
					};
					toastNotify(`Este email não está associado a um ${roleMap[userRole]}`, 'error');
				});
		},
		[setUser]
	);

	const login = useCallback(
		(email: string, password: string, role: string): void => {
			signIn(email, password).then(async (response) => {
				const auth = response.data;
				if (auth && auth.token) {
					client.defaults.headers.common['Authorization'] = `Token ${auth.token}`;
					setToken(auth.token);
					setTokenExpiry(auth.expiry);
					getOrUpdateUserInfo(role);
				}
			});
		},
		[getOrUpdateUserInfo, setToken, setTokenExpiry]
	);

	const logout = useCallback((): void => {
		setUser(null);
		setToken(null);
		setTokenExpiry(null);
		history.push('/', { replace: true });
	}, [setToken, setTokenExpiry, setUser]);

	const value = useMemo(
		() => ({
			signed: Boolean(user),
			user,
			login,
			logout,
			hasNewMessages,
			setHasNewMessages,
			getOrUpdateUserInfo,
			updateUserWithoutNavigate
		}),
		[
			login,
			logout,
			user,
			hasNewMessages,
			setHasNewMessages,
			getOrUpdateUserInfo,
			updateUserWithoutNavigate
		]
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
