import { FC, FormEvent, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import OverA from 'src/assets/images/over_a_marca_01.png';
import Button from 'src/components/Button/Button';
import { toastNotify } from 'src/helpers/toastNotify';
import { resetPassword } from 'src/services/recovery/resetPassword';
import EmailField from '../../../components/ui/form/EmailField';
import Logo from '../images/Logo.png';
import { Link } from 'react-router-dom';
import history from '../../../services/history';

export interface ResetPasswordProps {}
	
const ResetPassword: FC<ResetPasswordProps> = () => {
	const [email, setEmail] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const role = 'igc';

	const handleResetPassword = (event: FormEvent) => {
		event.preventDefault();
		resetPassword(email, role).then(()=>{
			history.push(`/${role}/login`);
			toastNotify("Solicitação para reset de senha criado com sucesso! Você recebera um email com instruções.", "success")
		}).catch((error)=>{
			toastNotify(error.response.data.user_message , 'error');
		})
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const isDisabled = (): boolean | undefined => {
		return !email;
	};
	return (
		<div>
			<form onSubmit={handleResetPassword} className=" flex flex-col">
				<div className=" flex justify-center items-center mb-12">
					<img className="text-center" height={45} width={184} src={OverA} alt="overa" />
				</div>
				<p className="font-bold mb-6 text-2xl text-neutral-low">Esqueceu sua senha?</p>
				<div className="block mb-8">
					<EmailField onChange={(val: any) => setEmail(val)} />
				</div>
				<div>
					<Button type="submit" color="secondary" loading={loading} disabled={isDisabled()}>
						Resetar Senha
					</Button>
				</div>
				<p className="text-center mt-4">
					<Link to={`/${role}/login`} className="block text-brand-secondary text-center">
						Voltar para o login
					</Link>
				</p>
				<div className="flex justify-center items-center mt-20">
					{' '}
					<img src={Logo} alt="man on the phone" />
				</div>
			</form>
		</div>
	);
};

export default ResetPassword;
